import React from 'react'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import { ROUTE_PATH } from '../constants/paths'
import HomePage from '../pages/Home'
import LayoutPage from '../pages/Layout'
import LoginPage from '../pages/Login'
import PerfomancePage from '../pages/Perfomance'
import StaffPage from '../pages/Staff'
import ReportPage from '../pages/Report'
import EnterprisePage from '../pages/Enterprise'
import CreateStaffPage from '../pages/Staff/create'
import PerformanceDetailPage from '../pages/Perfomance/detail'
import ListPerfomancePage from '../pages/Perfomance/list'
import CreateEnterprisePage from '../pages/Enterprise/create'
import AreaManagementPage from '../pages/AreaManagement'
import CreateAreaPage from '../pages/AreaManagement/create'
import DetailTeamPage from '../pages/AreaManagement/detailTeam'
import AreaManagementStaffListPage from '../pages/AreaManagementStaffList'
import CreateAreaMapPage from '../pages/CreateAreaMap'
import RoleManagementPage from '../pages/RoleManagement'
import SchedulePage from '../pages/Schedule'
import CreateSchedulePage from '../pages/Schedule/create'
import StandardPage from '../pages/Standard'
import CreateStandardPage from '../pages/Standard/create'
import CreatePlantsPage from '../pages/Plants/create'
import PlantsPage from '../pages/Plants'
import TeamPage from '../pages/Team'
import CreateTeamPage from '../pages/Team/create'
import AreaManagementDetailPage from '../pages/AreaManagementDetail'
import CreatePlotPage from '../pages/Plot/create'
import { authHelper } from '../helper/helper'
import ListPlot from '../pages/Plot/listPlot'
import PrivacyCenter from '../pages/PrivacyCenter'
import UserPage from '../pages/User'
import CreateUserPage from '../pages/User/create'
import SizePage from '../pages/Size'
import ApiSettingsPage from '../pages/ApiSettings'

export default function AppRouter() {
    const ProtectedRoute = ({ children }) => {
        if (!authHelper.isAuthenticated()) {
            return <Navigate to={ROUTE_PATH.LOGIN} replace />
        }
        return <>{children}</>
    }
    return (
        <div>
            <Router>
                <Routes>
                    <Route path={ROUTE_PATH.LOGIN} element={<LoginPage />} />

                    <Route path={ROUTE_PATH.HOME}
                        element={
                            <ProtectedRoute>
                                <LayoutPage hideTitle={ROUTE_PATH.HOME} children={<HomePage />} />
                            </ProtectedRoute>
                        }
                    />
                    <Route path={ROUTE_PATH.ENTERPRISE}
                        element={
                            <ProtectedRoute>
                                <LayoutPage hideTitle={ROUTE_PATH.ENTERPRISE} children={<EnterprisePage />} />
                            </ProtectedRoute>
                        }
                    />
                    <Route path={ROUTE_PATH.CREATE_ENTERPRISE}
                        element={
                            <ProtectedRoute>
                                <LayoutPage hideTitle={ROUTE_PATH.CREATE_ENTERPRISE} children={<CreateEnterprisePage />} />
                            </ProtectedRoute>
                        }
                    />
                    <Route path={ROUTE_PATH.EDIT_ENTERPRISE}
                        element={
                            <ProtectedRoute>
                                <LayoutPage hideTitle={ROUTE_PATH.EDIT_ENTERPRISE} children={<CreateEnterprisePage />} />
                            </ProtectedRoute>
                        }
                    />
                    <Route path={ROUTE_PATH.STAFF}
                        element={
                            <ProtectedRoute>
                                <LayoutPage hideTitle={ROUTE_PATH.STAFF} children={<StaffPage />} />
                            </ProtectedRoute>
                        }
                    />
                    <Route path={ROUTE_PATH.CREATE_STAFF}
                        element={
                            <ProtectedRoute>
                                <LayoutPage hideTitle={ROUTE_PATH.CREATE_STAFF} children={<CreateStaffPage />} />
                            </ProtectedRoute>
                        }
                    />
                    <Route path={ROUTE_PATH.EDIT_STAFF}
                        element={
                            <ProtectedRoute>
                                <LayoutPage hideTitle={ROUTE_PATH.EDIT_STAFF} children={<CreateStaffPage />} />
                            </ProtectedRoute>
                        }
                    />
                    <Route path={ROUTE_PATH.TEAM}
                        element={
                            <ProtectedRoute>
                                <LayoutPage hideTitle={ROUTE_PATH.TEAM} children={<TeamPage />} />
                            </ProtectedRoute>
                        }
                    />
                    <Route path={ROUTE_PATH.CREATE_TEAM}
                        element={
                            <ProtectedRoute>
                                <LayoutPage hideTitle={ROUTE_PATH.CREATE_TEAM} children={<CreateTeamPage />} />
                            </ProtectedRoute>
                        }
                    />
                    <Route path={ROUTE_PATH.EDIT_TEAM}
                        element={
                            <ProtectedRoute>
                                <LayoutPage hideTitle={ROUTE_PATH.EDIT_TEAM} children={<CreateTeamPage />} />
                            </ProtectedRoute>
                        }
                    />
                    <Route path={ROUTE_PATH.PERFOMANCE}
                        element={
                            <ProtectedRoute>
                                <LayoutPage hideTitle={ROUTE_PATH.PERFOMANCE} children={<PerfomancePage />} />
                            </ProtectedRoute>
                        }
                    />
                    <Route path={ROUTE_PATH.LIST_PERFOMANCE}
                        element={
                            <ProtectedRoute>
                                <LayoutPage hideTitle={ROUTE_PATH.LIST_PERFOMANCE} children={<ListPerfomancePage />} />
                            </ProtectedRoute>
                        }
                    />
                    <Route path={ROUTE_PATH.DETAIL_PERFOMANCE}
                        element={
                            <ProtectedRoute>
                                <LayoutPage hideTitle={ROUTE_PATH.DETAIL_PERFOMANCE} children={<PerformanceDetailPage />} />
                            </ProtectedRoute>
                        }
                    />
                    <Route path={ROUTE_PATH.REPORT}
                        element={
                            <ProtectedRoute>
                                <LayoutPage hideTitle={ROUTE_PATH.REPORT} children={<ReportPage />} />
                            </ProtectedRoute>
                        }
                    />
                    <Route path={ROUTE_PATH.AREA_MANAGEMENT}
                        element={
                            <ProtectedRoute>
                                <LayoutPage hideTitle={ROUTE_PATH.AREA_MANAGEMENT} children={<AreaManagementPage />} />
                            </ProtectedRoute>
                        }
                    />
                    <Route path={ROUTE_PATH.CREATE_AREA}
                        element={
                            <ProtectedRoute>
                                <LayoutPage hideTitle={ROUTE_PATH.CREATE_AREA} children={<CreateAreaPage />} />
                            </ProtectedRoute>
                        }
                    />
                    <Route path={ROUTE_PATH.EDIT_AREA}
                        element={
                            <ProtectedRoute>
                                <LayoutPage hideTitle={ROUTE_PATH.EDIT_AREA} children={<CreateAreaPage />} />
                            </ProtectedRoute>
                        }
                    />
                    <Route path={ROUTE_PATH.DETAIL_TEAM_AREA}
                        element={
                            <ProtectedRoute>
                                <LayoutPage hideTitle={ROUTE_PATH.DETAIL_TEAM_AREA} children={<DetailTeamPage />} />
                            </ProtectedRoute>
                        }
                    />
                    <Route path={ROUTE_PATH.AREA_MANAGEMENT_STAFF_LIST}
                        element={
                            <ProtectedRoute>
                                <LayoutPage hideTitle={ROUTE_PATH.AREA_MANAGEMENT_STAFF_LIST} children={<AreaManagementStaffListPage />} />
                            </ProtectedRoute>
                        }
                    />
                    <Route path={ROUTE_PATH.PLOT}
                        element={
                            <ProtectedRoute>
                                <LayoutPage hideTitle={ROUTE_PATH.CREATE_PLOT} children={<ListPlot />} />
                            </ProtectedRoute>
                        }
                    />
                    <Route path={ROUTE_PATH.CREATE_PLOT}
                        element={
                            <ProtectedRoute>
                                <LayoutPage hideTitle={ROUTE_PATH.CREATE_PLOT} children={<CreatePlotPage />} />
                            </ProtectedRoute>
                        }
                    />
                    <Route path={ROUTE_PATH.CREATE_AREA_MAP}
                        element={
                            <ProtectedRoute>
                                <LayoutPage hideTitle={ROUTE_PATH.CREATE_AREA_MAP} children={<CreateAreaMapPage />} />
                            </ProtectedRoute>
                        }
                    />
                    <Route path={ROUTE_PATH.ROLE_MANAGEMENT}
                        element={
                            <ProtectedRoute>
                                <LayoutPage hideTitle={ROUTE_PATH.ROLE_MANAGEMENT} children={<RoleManagementPage />} />
                            </ProtectedRoute>
                        }
                    />
                    <Route path={ROUTE_PATH.SCHEDULE_MANAGEMENT}
                        element={
                            <ProtectedRoute>
                                <LayoutPage hideTitle={ROUTE_PATH.SCHEDULE_MANAGEMENT} children={<SchedulePage />} />
                            </ProtectedRoute>
                        }
                    />
                    <Route path={ROUTE_PATH.CREATE_SCHEDULE}
                        element={
                            <ProtectedRoute>
                                <LayoutPage hideTitle={ROUTE_PATH.CREATE_SCHEDULE} children={<CreateSchedulePage />} />
                            </ProtectedRoute>
                        }
                    />
                    <Route path={ROUTE_PATH.EDIT_SCHEDULE}
                        element={
                            <ProtectedRoute>
                                <LayoutPage hideTitle={ROUTE_PATH.EDIT_SCHEDULE} children={<CreateSchedulePage />} />
                            </ProtectedRoute>
                        }
                    />
                    <Route path={ROUTE_PATH.STANDARD}
                        element={
                            <ProtectedRoute>
                                <LayoutPage hideTitle={ROUTE_PATH.STANDARD} children={<StandardPage />} />
                            </ProtectedRoute>
                        }
                    />
                    <Route path={ROUTE_PATH.STANDARD_CREATE}
                        element={
                            <ProtectedRoute>
                                <LayoutPage hideTitle={ROUTE_PATH.STANDARD_CREATE} children={<CreateStandardPage />} />
                            </ProtectedRoute>
                        }
                    />
                    <Route path={ROUTE_PATH.STANDARD_EDIT}
                        element={
                            <ProtectedRoute>
                                <LayoutPage hideTitle={ROUTE_PATH.STANDARD_EDIT} children={<CreateStandardPage />} />
                            </ProtectedRoute>
                        }
                    />
                    <Route path={ROUTE_PATH.PLANTS}
                        element={
                            <ProtectedRoute>
                                <LayoutPage hideTitle={ROUTE_PATH.PLANTS} children={<PlantsPage />} />
                            </ProtectedRoute>
                        }
                    />
                    <Route path={ROUTE_PATH.CREATE_PLANTS}
                        element={
                            <ProtectedRoute>
                                <LayoutPage hideTitle={ROUTE_PATH.CREATE_PLANTS} children={<CreatePlantsPage />} />
                            </ProtectedRoute>
                        }
                    />
                    <Route path={ROUTE_PATH.EDIT_PLANTS}
                        element={
                            <ProtectedRoute>
                                <LayoutPage hideTitle={ROUTE_PATH.EDIT_PLANTS} children={<CreatePlantsPage />} />
                            </ProtectedRoute>
                        }
                    />
                    <Route path={ROUTE_PATH.AREA_MANAGEMENT_DETAIL}
                        element={
                            <ProtectedRoute>
                                <LayoutPage hideTitle={ROUTE_PATH.AREA_MANAGEMENT_DETAIL} children={<AreaManagementDetailPage />} />
                            </ProtectedRoute>
                        }
                    />
                    <Route path={ROUTE_PATH.PRIVACY_CENTER}
                        element={<PrivacyCenter />}
                    />
                    <Route path={ROUTE_PATH.USER}
                        element={
                            <ProtectedRoute>
                                <LayoutPage hideTitle={ROUTE_PATH.USER} children={<UserPage />} />
                            </ProtectedRoute>
                        }
                    />
                    <Route path={ROUTE_PATH.CREATE_USER}
                        element={
                            <ProtectedRoute>
                                <LayoutPage hideTitle={ROUTE_PATH.CREATE_USER} children={<CreateUserPage />} />
                            </ProtectedRoute>
                        }
                    />
                    <Route path={ROUTE_PATH.EDIT_USER}
                        element={
                            <ProtectedRoute>
                                <LayoutPage hideTitle={ROUTE_PATH.EDIT_USER} children={<CreateUserPage />} />
                            </ProtectedRoute>
                        }
                    />
                    <Route path={ROUTE_PATH.SIZE}
                        element={
                            <ProtectedRoute>
                                <LayoutPage hideTitle={ROUTE_PATH.SIZE} children={<SizePage />} />
                            </ProtectedRoute>
                        }
                    />
                    <Route path={ROUTE_PATH.API_SETTINGS}
                        element={<ProtectedRoute>
                            <LayoutPage children={<ApiSettingsPage />} />
                        </ProtectedRoute>}
                    />
                </Routes>
            </Router>
        </div>
    )
}