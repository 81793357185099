import { ApiUrl } from "../constants/api_url"
import AxiosHelper from "../helper/axiosHelper"

export default async function createPlot({ staffId, email, firstName, lastName, uid, cardId, addressUser,
    phoneNumber, size, plotName, detail, deedNo, addressPlot, coordinates, uploadMainImageId }) {
    return await AxiosHelper.post(ApiUrl.PLOT + "/create", {
        staffId, email, firstName,
        lastName, uid, phoneNumber, size, cardId, addressUser,
        plotName, detail, deedNo, addressPlot,
        coordinates, uploadMainImageId
    })
}

export async function updatePlot({ plotId, staffId, email, firstName, lastName, uid, cardId, addressUser,
    phoneNumber, size, plotName, detail, deedNo, addressPlot, coordinates }) {

    return await AxiosHelper.post(ApiUrl.PLOT + "/update/" + plotId, {
        staffId, email, firstName,
        lastName, uid, phoneNumber, size, cardId, addressUser,
        plotName, detail, deedNo, addressPlot,
        coordinates
    })
}

export async function getAllPlotByRole({ filterEnterpriseId = '', filterTeamId = '', filterAreaId = '', filterEmployeeId = '', pageSize = 10, currentPage = 1, searchKeyword = "" }) {
    let params = {
        pageSize, currentPage, searchKeyword
    }
    if (filterEnterpriseId.length > 0) {
        params.filterEnterpriseId = filterEnterpriseId
    }
    if (filterTeamId.length > 0) {
        params.filterTeamId = filterTeamId
    }
    if (filterAreaId.length > 0) {
        params.filterAreaId = filterAreaId
    }
    if (filterEmployeeId.length > 0) {
        params.filterEmployeeId = filterEmployeeId
    }
    let url = `${ApiUrl.PLOT}`
    if (Object.keys(params).length > 0) {
        params = new URLSearchParams(params)
        url = `${url}?${params.toString()}`
    }
    return await AxiosHelper.get(url)
}

export async function getPlotDetailByPlotId(plotId) {
    return await AxiosHelper.get(ApiUrl.PLOT + "/getDetailByPlotId" + "/" + plotId)
}

export async function getPlotViewDetailByPlotId(plotId) {
    return await AxiosHelper.get(ApiUrl.PLOT + "/getViewDetailByPlotId" + "/" + plotId)
}

export async function newImportPlot(formData, staffId) {

    return await AxiosHelper.post(ApiUrl.PLOT + "/import" + "/" + staffId, {}, {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data: formData
    })
}

export async function uploadFile(formData) {
    return await AxiosHelper.post(ApiUrl.UPLOAD_FILE + "/ImagePlot", {}, { headers: { 'Content-Type': 'multipart/form-data' }, data: formData })
}

