import { useEffect, useState } from "react";
import { API_RES_STATUS } from "../constants/apiResponseStatus";
import { getCategoryArea } from "../services/common";

/**
 * ฟังชันสำหรับ Get ค่าลิสรายการพื้นที่ สำหรับทำ SelectField
 * @returns 1. react state areas, 2. set state filter enterprise id
 */
export default function useCategoryArea() {
    const [areas, setAreas] = useState([])
    const [filterEnterpriseId, setFilterAreaByEnterpriseId] = useState('')
    useEffect(() => {
        const loadArea = async () => {
            const axiosRes = await getCategoryArea({ filterEnterpriseId: filterEnterpriseId })
            const json = JSON.parse(axiosRes.data)
            const { data, status, message } = json
            if (status === API_RES_STATUS.success) {
                setAreas(data)
            }
        }
        loadArea()
    }, [filterEnterpriseId])

    return { areas, setFilterAreaByEnterpriseId }
}