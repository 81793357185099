import React, { useContext, useEffect, useState, useRef } from 'react'
import { makeStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import { Button, Skeleton, Modal, TablePagination, Tooltip, InputLabel, InputAdornment } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import OutlinedInput from '@mui/material/OutlinedInput'
import SearchIcon from '@mui/icons-material/Search'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { styled } from '@mui/material/styles'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from '../../constants/paths'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'
import Select from '@mui/material/Select'
import getAllTeam, { deleteTeam, newImportTeam } from '../../services/team'
import Empty from '../../components/Empty'
import AlertDeleteModal from '../../components/AlertDeleteModal'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import * as XLSX from 'xlsx'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import { useSnackbar } from 'notistack'
import { API_RES_STATUS } from '../../constants/apiResponseStatus'
import { AppContext } from '../../contexts/AppContext'
import { ROLES } from '../../constants/enums'
import StyledDataGrid from '../../components/StyledDataGrid'
import useCategoryEnterprise from '../../reactHook/useCategoryEnterprise'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#12203A",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#D3DCEC",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 1,
    },
}))

export default function TeamPage() {
    const { employee = {} } = useContext(AppContext)
    const classes = useStyles()
    const navigation = useNavigate()
    const [listTeam, setListTeam] = useState([])
    const [loading, setLoading] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [openModalImport, setOpenModalImport] = useState(false)
    const [idDelete, setIdDelete] = useState(null)
    const [records, setRecords] = useState([])
    const [disableUploadFile, setDisableUploadFile] = useState(false)
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [acceptedFiles, setAcceptedFiles] = useState([])
    const { enqueueSnackbar, /*closeSnackbar*/ } = useSnackbar()

    const [tableCurrentPage, setTableCurrentPage] = useState(0)
    const [tablePageSize, setTablePageSize] = useState(10)
    const [tableTotalRow, setTableTotalRow] = useState(0)

    const [filterEnterpriseId, setFilterEnterpriseId] = useState('')
    const [searchKeyword, setSearchKeyword] = useState('')

    const { enterprises } = useCategoryEnterprise()

    useEffect(() => {
        loadTeam()
    }, [filterEnterpriseId])

    const loadTeam = async () => {
        setLoading(true)

        const axiosRes = await getAllTeam({ currentPage: tableCurrentPage + 1, pageSize: tablePageSize, filterEnterpriseId, searchKeyword })
        const { data, status, message } = JSON.parse(axiosRes.data)
        if (status === API_RES_STATUS.success) {
            const { listData, totalRow } = data
            setListTeam(listData)
            setTableTotalRow(totalRow)
        }
        setLoading(false)
    }

    const OnDownloadTeamTemplateFile = () => {
        var header = Object.values(columnNamesModal)

        const objDataMock = {
            teamName: 'testName',
            teamLead_Id: '00000000-0000-0000-0000-000000000000',
            // area_Id: '00000000-0000-0000-0000-000000000000', //areaId not requie
        }

        const ws = XLSX.utils.json_to_sheet([objDataMock], { header: header })
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1")

        XLSX.writeFile(wb, 'import_team_template.xlsx')
    }

    const onDropped = (e, isInput) => {
        e.preventDefault()

        const files = isInput ? e.target.files : e.dataTransfer.files
        let extension = files[0].name.split('.').pop()
        if (extension !== 'xlsx') {
            enqueueSnackbar('File type invalid.', { variant: 'error' })
            return
        }
        setAcceptedFiles(files)

        let i, f
        for (i = 0, f = files[i]; i !== files.length; ++i) {
            var reader = new FileReader()
            // var name = f.name
            reader.onload = function (e) {
                var data = e.target.result // <-- 'data' contains the sheet data

                var xlsx = XLSX.read(data, { type: 'binary', codepage: 65001 })  // <--'xlsx' is an object that holds the relevant data

                const json = XLSX.utils.sheet_to_json(xlsx.Sheets[xlsx.SheetNames[0]])

                const row = json[0]

                if (row === undefined) {
                    enqueueSnackbar('sorry data not found', { variant: 'error' })
                    return
                }

                const keys = Object.keys(row)

                loop1: for (let i = 0; i < keys.length; i++) {
                    const k = keys[i]
                    for (let j = 0; j < columnsModal.length; j++) {
                        const mk = columnsModal[j]
                        if (mk.value === k) {
                            continue loop1
                        }
                    }

                    enqueueSnackbar('column not found ' + k, { variant: 'error' })
                    return
                }

                if (json === null) {
                    enqueueSnackbar('File incorrect format.', { variant: 'error' })
                    return
                }
                if (json.length === 0) {
                    enqueueSnackbar('Empty file.', { variant: 'error' })
                    return
                }

                validate(json)
                setRecords(json)

            }
            reader.readAsBinaryString(f)
        }

    }

    const onDragOver = (event) => {
        event.preventDefault()
    }

    const handleChangePage = (event, newPage) => { setPage(newPage) }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const clear = () => {
        setRecords([])
        setDisableUploadFile(false)
    }

    const validate = jsonList => {

        jsonList.forEach(row => {

            columnsModal.forEach(column => {
                let validField = false

                switch (column.value) {
                    case columnNamesModal.teamName:
                        validField = column.isValid(row)
                        break
                    case columnNamesModal.teamLeadId:
                        validField = column.isValid(row)
                        break
                    case columnNamesModal.areaId:
                        validField = column.isValid(row)
                        break
                    default:
                        validField = true
                        break
                }
                if (!disableUploadFile && !validField) {
                    setDisableUploadFile(true)
                }
                row[column.value + '-valid'] = validField
            })


        })
    }

    const uploadNewTeam = async () => {
        const formData = new FormData()
        formData.append("teamFile", acceptedFiles[0])
        const jsonObj = records.map(item => ({
            teamName: item[columnNamesModal.teamName],
            teamLeadId: item[columnNamesModal.teamLeadId],
            areaId: item[columnNamesModal.areaId],
        }))
        const jsonString = JSON.stringify(jsonObj)
        formData.append("json", jsonString)

        try {
            const res = await newImportTeam(formData)
            let json = res.data
            if (json.status === API_RES_STATUS.success) {
                enqueueSnackbar(json.message, { variant: 'success' })
                loadTeam()
                setRecords([])
                setOpenModalImport(false)
            } else {
                enqueueSnackbar(json.message, { variant: 'error' })
            }

        } catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' })
        }


    }


    const OnClickSubmitModal = async (id) => {
        const res = await deleteTeam(id)
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            enqueueSnackbar('Delete team successfully.', { variant: 'success' })
            setIdDelete(null)
            loadTeam()
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
        setOpenModal(false)
    }

    const OnClickInModal = () => {
        setOpenModal(false)
        setIdDelete(null)
    }

    const OnClickDelete = (id) => {
        setIdDelete(id)
        setOpenModal(true)
    }


    const columns = [
        {
            field: 'name',
            headerName: 'ชื่อทีม',
            flex: 3
        },
        {
            field: 'teamLeadFullName',
            headerName: "ผู้ดูแลทีม",
            valueGetter: ({ row }) => {
                return `${row["teamLeadFirstName"]} ${row["teamLeadLastName"]}`
            },
            flex: 3
        },
        {
            field: 'action',
            headerName: "",
            renderCell: ({ row }) => {
                return <>
                    <IconButton
                        size="small"
                        onClick={() => navigation(ROUTE_PATH.EDIT_TEAM.replace(":id", row.id))}
                    >
                        <ModeEditOutlineOutlinedIcon />
                    </IconButton>
                    <Tooltip title="คัดลอก ID" placement='top'>
                        <IconButton
                            size="small"
                            onClick={() => {
                                const { id } = row
                                navigator.clipboard.writeText(id)
                            }}
                        >
                            <ContentCopyIcon />
                        </IconButton>
                    </Tooltip>
                    {/* <IconButton
                        size="small"
                        color='error'
                        onClick={() => OnClickDelete(row.id)}  >
                        <DeleteIcon />
                    </IconButton> */}

                </>
            },
            flex: 1

        },
    ]

    const onFilterSearchKeywordChange = (event) => {
        setSearchKeyword(event.target.value)
    }

    const onInputSearchKeyDown = (e) => {
        if (e.keyCode === 13) {
            onSearchClick()
        }
    }

    const onSearchClick = () => {
        loadTeam()
    }

    const inputRef = useRef(null)
    const handleClick = () => inputRef.current.click()

    return (
        <>
            <div style={{ margin: 40 }}>
                <Grid container rowSpacing={1} columnSpacing={1}>
                    <Grid item sm={12} md={6}>
                        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            {employee.role === ROLES.AdminLing &&
                                <FormControl size="small" sx={{ m: 1, minWidth: 220 }}>
                                    <InputLabel >เลือกบริษัท</InputLabel>
                                    <Select
                                        label="เลือกบริษัท"
                                        value={filterEnterpriseId}
                                        onChange={(e) => { setFilterEnterpriseId(e.target.value) }}
                                    >
                                        <MenuItem value={''}>ทั้งหมด</MenuItem>
                                        {enterprises.map((e) => {
                                            return <MenuItem key={`select-enterprise-${e.id}`} value={e.id}>{e.name}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>}
                            <FormControl sx={{ m: 1, width: '100%' }} size="small">
                                <OutlinedInput
                                    placeholder='ชื่อทีม, ชื่อหัวหน้าทีม'
                                    value={searchKeyword}
                                    onChange={onFilterSearchKeywordChange}
                                    onKeyDown={onInputSearchKeyDown}
                                    startAdornment={
                                        <InputAdornment position='start'>
                                            <SearchIcon onClick={onSearchClick} />
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>

                        </div>
                    </Grid>
                    {employee.role === ROLES.Staff
                        ? <></>
                        :
                        <Grid item sm={12} md={6} >
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                {employee.role !== ROLES.TeamLead
                                    ? <>
                                        <Button onClick={() => OnDownloadTeamTemplateFile()} style={{ color: '#12203A', borderColor: '#12203A', borderRadius: 5, marginRight: 16 }} variant="outlined">
                                            ดาวน์โหลด เทมเพลต
                                        </Button>
                                        <Button onClick={() => {
                                            setRecords([])
                                            setOpenModalImport(true)
                                        }} style={{ color: '#12203A', borderColor: '#12203A', borderRadius: 5, marginRight: 16 }} variant="outlined">
                                            อัพโหลดไฟล์
                                        </Button>
                                        <Button onClick={() => navigation(ROUTE_PATH.CREATE_TEAM)} style={{ color: 'white', borderRadius: 5, backgroundColor: '#12203A' }} variant="contained">
                                            <AddIcon />
                                            สร้างทีม
                                        </Button>
                                    </>
                                    :
                                    <Button onClick={() => navigation(ROUTE_PATH.CREATE_TEAM)} style={{ color: 'white', borderRadius: 5, backgroundColor: '#12203A' }} variant="contained">
                                        <AddIcon />
                                        สร้างทีม
                                    </Button>
                                }
                            </div>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Box sx={{ height: '70vh', width: '100%' }}>
                            <StyledDataGrid
                                rows={listTeam}
                                columns={columns}
                                paginationMode="server"
                                loading={loading}
                                rowCount={tableTotalRow}
                                page={tableCurrentPage}
                                pageSize={tablePageSize}
                                pagination
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                onPageChange={(newPage) => setTableCurrentPage(newPage)}
                                onPageSizeChange={(newPageSize) => setTablePageSize(newPageSize)}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </div>
            <AlertDeleteModal id={idDelete} open={openModal} OnClickInModal={OnClickInModal} OnClickSubmitModal={OnClickSubmitModal} />

            <Modal
                open={openModalImport}
                onClose={() => setOpenModalImport(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <>
                    {records.length > 0 ?
                        <div style={{ marginLeft: '10%', marginRight: '10%', marginTop: '10%', backgroundColor: 'white', padding: 20, maxHeight: '70vh', paddingBottom: 40 }}>
                            <Box display="flex" flexDirection="row-reverse" my={2}>
                                <Button style={{ minWidth: 200 }} onClick={() => clear()} variant='contained' color='error' disabled={records.length === 0}>ยกเลิก</Button>
                                <Button style={{ minWidth: 200, marginRight: 5 }} onClick={() => { uploadNewTeam() }} disabled={disableUploadFile}
                                    variant='contained' color='success'>ยืนยัน</Button>
                            </Box>

                            <TableContainer className={classes.container} style={{ maxHeight: '50vh' }}>
                                <Table stickyHeader aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            {columnsModal.map((c, ci) =>
                                                <StyledTableCell
                                                    align={c.align}
                                                    style={{ minWidth: c.columnWidth }}
                                                    key={ci}>{c.value}</StyledTableCell>)}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {records.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowi) => {

                                            return <StyledTableRow key={rowi}>
                                                {columnsModal.map((c, ci) => {

                                                    return <StyledTableCell
                                                        align="center"
                                                        key={rowi + '' + ci}>
                                                        <span >{row[c.value] ? row[c.value] + '' : null} </span>
                                                        {!row[c.value + '-valid'] && <><br /><span style={{ color: 'red' }}>{c.errorMessage}</span></>}
                                                    </StyledTableCell>
                                                })}

                                            </StyledTableRow>
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={records.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </div>
                        :
                        <div
                            style={{
                                display: 'flex', flexDirection: 'column', justifyContent: 'center',
                                alignItems: 'center', backgroundColor: 'rgb(245, 245, 245)', cursor: 'pointer',
                                minHeight: '200px', border: '4px dashed gray',
                                marginLeft: '10%', marginRight: '10%', marginTop: '10%'
                            }}
                            onClick={handleClick}
                            dropzone="true"
                            onDragOver={(event) => { onDragOver(event) }}
                            onDrop={(event) => { onDropped(event, false) }}>
                            <input type="file" style={{ display: 'none' }} ref={inputRef} onChange={(event) => { onDropped(event, true) }} />
                            <CloudUploadOutlinedIcon style={{ fontSize: 100, color: 'gray' }} />
                            <span style={{ fontsize: '1.5rem', fontWeight: 'bold' }}>Drop file here</span>
                            <span style={{ fontsize: '1.5rem', fontWeight: 'bold' }}>You have to drop xls file only</span>
                        </div>
                    }
                </>
            </Modal>
        </>
    )
}

const useStyles = makeStyles((theme) => ({

}))

const columnNamesModal = {
    teamName: 'teamName',
    teamLeadId: 'teamLead_Id',
    areaId: 'area_Id',
}

const columnsModal = [
    {
        columnWidth: 120,
        value: columnNamesModal.teamName,
        errorMessage: 'กรุณากรอกชื่อทีม',
        align: 'center',
        isValid: row => row[columnNamesModal.teamName] !== " " && row[columnNamesModal.teamName]
    },
    {
        columnWidth: 120,
        value: columnNamesModal.teamLeadId,
        errorMessage: 'กรุณาตรวจสอบรหัส TeamLead',
        align: 'center',
        isValid: row => {
            var pattern = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$', 'i')
            return pattern.test(row[columnNamesModal.teamLeadId])
        }
    },
    {
        columnWidth: 120,
        value: columnNamesModal.areaId,
        errorMessage: 'กรุณาตรวจสอบรหัส Area',
        align: 'center',
        isValid: row => {
            if (!row[columnNamesModal.areaId]) {
                return true
            }
            var pattern = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$', 'i')
            return pattern.test(row[columnNamesModal.areaId])
        }
    }
]
