import React from 'react'
import Modal from '@mui/material/Modal'
import { makeStyles } from '@mui/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'

export default function AlertStandardValueModal({ open = false, OnAlertClickInModal = () => { }, note }) {
    const classes = useStyles()

    return (
        <div style={{ alignContent: 'center' }}>
            <Modal scrollable
                open={open}
                onClose={OnAlertClickInModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={classes.modalStyle}>
                    <div onClick={() => OnAlertClickInModal()} style={{ position: 'absolute', top: 0, right: 0, }}>
                        <CloseIcon fontSize='large' style={{ color: '#fff' }} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 10, marginTop: 10 }}>
                        <Typography id="modal-modal-title" variant="h6" component="h3">
                            คำแนะนำ : เนื่องจากการเติบโตน้อยกว่ามาตรฐาน
                        </Typography>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10, marginBottom: 10 }}>
                        <Typography id="modal-modal-title" variant="h7" component="h3" color="#fff">
                            {note}
                        </Typography>
                    </div>
                </Box>
            </Modal>
        </div >
    )
}

const useStyles = makeStyles((theme) => ({
    modalStyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        border: '1px solid #000',
        boxShadow: 24,
        backgroundColor: '#12203A',
        color: '#F08E26',
        padding: 20,
        // height: 500,
        overflow: "scroll",
        borderRadius: 10,
    },
    divLine: {
        height: 1.1, backgroundColor: '#12203A',
        width: '100%', marginTop: 10
    },
}))