import { Button, Card, CardContent, Grid } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import TextField from '@mui/material/TextField'
import PerformanceDetailCard from '../../components/PerformanceDetailCard'

export default function PerformanceDetailPage() {
    const navigation = useNavigate()
    return (
        <div style={{ margin: 40 }}>
            <Grid container rowSpacing={1} >
                <Grid item xs={6}>
                    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <Button onClick={() => navigation(-1)} style={{ width: '15%', borderRadius: 10, borderWidth: 2, marginRight: 20 }} variant="outlined">
                            ย้อนกลับ
                        </Button>
                    </div>
                </Grid>
                <Grid item xs={6} ></Grid>
            </Grid>
            <div style={{ marginTop: 30 }}>
                <PerformanceDetailCard />
            </div>
        </div>

    )
}
