import { Button, CardContent, Grid } from '@mui/material'
import React, { useState, useEffect, useContext } from 'react'
import Card from '@mui/material/Card'
import TextField from '@mui/material/TextField'
import { useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { Controller, useForm } from 'react-hook-form'
import Skeleton from '@mui/material/Skeleton'
import TextFieldValidate from '../../components/TextFieldValidate'
import { API_RES_STATUS } from '../../constants/apiResponseStatus'
import { createNewUser, getUserById, updateUser } from '../../services/user'
import dayjs from 'dayjs'
import { AppContext } from '../../contexts/AppContext'
import { ROLES } from '../../constants/enums'

export default function CreateUserPage() {
    const { employee = {} } = useContext(AppContext)
    let { id: userId } = useParams()
    const navigation = useNavigate()
    const { enqueueSnackbar, /*closeSnackbar*/ } = useSnackbar()
    const [loading, setLoading] = useState(false)
    const { register, control, handleSubmit, setValue, formState: { errors } } = useForm()
    const [messageTax, setMessageTax] = useState(true)

    useEffect(() => {
        if (userId) loadUser(userId)
    }, [])

    const loadUser = async (id) => {
        setLoading(true)
        const axiosRes = await getUserById(id)
        const { data, status, message = "" } = JSON.parse(axiosRes.data)

        if (status === API_RES_STATUS.success) {

            setValue('firstName', data.firstName)
            setValue('lastName', data.lastName)
            setValue('phoneNumber', data.phoneNumber)
            setValue('email', data.email)
            setValue('uid', data.uid)
            setValue('cardId', data.cardId)
            setValue('birthDate', data.birthDate)
            setValue('address', data.address)
            setValue('lineId', data.lineId)

        } else {
            enqueueSnackbar(message, { variant: 'error' })
        }
        setLoading(false)
    }

    const onSubmitForm = async (data) => {
        if (data.cardId.length === 13 && data.cardId.length !== '') {
            let idCard = data.cardId.split("")
            let total = 0
            let mul = 13

            for (let i = 0; i < idCard.length - 1; i++) {
                total = total + idCard[i] * mul
                mul = mul - 1
            }

            let mod = total % 11
            let nsub = 11 - mod
            let mod2 = nsub % 10
            if (mod2 === Number(idCard[12])) {
                setLoading(true)
                setMessageTax(true)
                if (userId) {
                    await onEdit(userId, data)
                } else {
                    await onCreate(data)
                }
                setLoading(false)
            }
            else {
                setLoading(true)
                setMessageTax(false)
                setLoading(false)
            }
        }
    }
    const onCreate = async (dataSubmit) => {

        const axiosRes = await createNewUser({
            firstName: dataSubmit.firstName,
            lastName: dataSubmit.lastName,
            phoneNumber: dataSubmit.phoneNumber,
            uid: dataSubmit.uid,
            email: dataSubmit.email,
            cardId: dataSubmit.cardId,
            birthDate: dataSubmit.birthDate,
            address: dataSubmit.address,
            lineId: dataSubmit.lineId !== "" ? dataSubmit.lineId : null,
            enterpriseId: employee.enterpriseId
        })
        const { data, status, message } = JSON.parse(axiosRes.data)
        if (status === API_RES_STATUS.success) {
            enqueueSnackbar('Create user successfully.', { variant: 'success' })
            navigation(-1)
        } else {
            enqueueSnackbar(message, { variant: 'error' })
        }

    }

    const onEdit = async (id, dataSubmit) => {

        const axiosRes = await updateUser({
            firstName: dataSubmit.firstName,
            lastName: dataSubmit.lastName,
            phoneNumber: dataSubmit.phoneNumber,
            uid: dataSubmit.uid,
            email: dataSubmit.email,
            cardId: dataSubmit.cardId,
            birthDate: dataSubmit.birthDate,
            address: dataSubmit.address,
            lineId: dataSubmit.lineId !== "" ? dataSubmit.lineId : null,
            userId: id,
            enterpriseId: employee.enterpriseId
        })
        const { data, status, message } = JSON.parse(axiosRes.data)
        if (status === API_RES_STATUS.success) {
            enqueueSnackbar('Update user successfully.', { variant: 'success' })
            navigation(-1)
        } else {
            enqueueSnackbar(message, { variant: 'error' })
        }
    }


    const loadingPanel = <div style={{ marginRight: 10, marginLeft: 10 }}>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Skeleton variant="rect" height={50} width={"100%"} />
        </Grid>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="start"
            alignItems="center"
        >
            <Grid item xs={3} >
                <Skeleton variant="rect" height={50} width={"80%"} />
            </Grid>
            <Grid item xs={9} >
                <Skeleton variant="rect" height={50} width={"100%"} />
            </Grid>
        </Grid>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="start"
            alignItems="center"
        >
            <Grid item xs={3} >
                <Skeleton variant="rect" height={50} width={"80%"} />
            </Grid>
            <Grid item xs={9} >
                <Skeleton variant="rect" height={50} width={"100%"} />
            </Grid>
        </Grid>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="start"
            alignItems="center"
        >
            <Grid item xs={3} >
                <Skeleton variant="rect" height={50} width={"80%"} />
            </Grid>
            <Grid item xs={9} >
                <Skeleton variant="rect" height={50} width={"100%"} />
            </Grid>
        </Grid>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="start"
            alignItems="center"
        >
            <Grid item xs={3} >
                <Skeleton variant="rect" height={50} width={"80%"} />
            </Grid>
            <Grid item xs={9} >
                <Skeleton variant="rect" height={50} width={"100%"} />
            </Grid>
        </Grid>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="start"
            alignItems="center"
        >
            <Grid item xs={3} >
                <Skeleton variant="rect" height={50} width={"80%"} />
            </Grid>
            <Grid item xs={9} >
                <Skeleton variant="rect" height={50} width={"100%"} />
            </Grid>
        </Grid>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="start"
            alignItems="center"
        >
            <Grid item xs={3} >
                <Skeleton variant="rect" height={50} width={"80%"} />
            </Grid>
            <Grid item xs={9} >
                <Skeleton variant="rect" height={50} width={"100%"} />
            </Grid>
        </Grid>
    </div>


    return (
        <div style={{ margin: 40 }}>
            {
                loading
                    ? loadingPanel
                    : <form onSubmit={handleSubmit(onSubmitForm)}>
                        <Grid container rowSpacing={1} >
                            <Grid item xs={6}>
                                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button onClick={() => navigation(-1)} style={{ width: '20%', borderRadius: 7, borderWidth: 1.5, marginRight: 20, color: '#12203A', borderColor: '#12203A' }} variant="outlined">
                                        ยกเลิก
                                    </Button>
                                    {employee.role !== ROLES.AdminLing ?
                                        <Button type='submit' style={{ width: '20%', color: 'white', borderRadius: 7, backgroundColor: '#12203A' }} variant="contained">
                                            บันทึก
                                        </Button> : <></>}
                                </div>
                            </Grid>
                        </Grid>
                        <div style={{ marginTop: 30 }}>
                            <Card >
                                <CardContent style={{ margin: 20 }}>
                                    <Grid container  >
                                        <Grid item xs={5}>
                                            <Grid container  >
                                                <Grid item mt={3} xs={3}>
                                                    ชื่อ
                                                </Grid>
                                                <Grid item mt={1} xs={9}>
                                                    <Controller style={{ marginTop: 0 }}
                                                        control={control}
                                                        rules={{
                                                            required: { value: true, message: "firstName is required." },
                                                            min: { value: 0, message: "firstName much be greater than or equal 0." }
                                                        }}
                                                        name="firstName"
                                                        render={o => <TextFieldValidate vo={o} type='text' errorMessage={errors.firstName?.message} />} />
                                                </Grid>
                                                <Grid item mt={3} xs={3}>
                                                    นามสกุล
                                                </Grid>
                                                <Grid item mt={2} xs={9}>
                                                    <Controller style={{ marginTop: 0 }}
                                                        control={control}
                                                        rules={{
                                                            required: { value: true, message: "lastName is required." },
                                                            min: { value: 0, message: "lastName much be greater than or equal 0." }
                                                        }}
                                                        name="lastName"
                                                        render={o => <TextFieldValidate vo={o} type='text' errorMessage={errors.lastName?.message} />} />
                                                </Grid>
                                                <Grid item mt={3} xs={3}>
                                                    เบอร์โทรศัพท์
                                                </Grid>
                                                <Grid item mt={2} xs={9}>
                                                    <Controller style={{ marginTop: 0 }}
                                                        control={control}
                                                        rules={{
                                                            required: { value: false, message: "phoneNumber is required." },
                                                            min: { value: 0, message: "phoneNumber much be greater than or equal 0." }
                                                        }}
                                                        {...register("phoneNumber", {
                                                            required: { value: messageTax, message: "phoneNumber is required." },
                                                            minLength: {
                                                                value: 10,
                                                                message: "กรุณากรอกหมายเลขโทรศัพท์ 10 หลัก"
                                                            }
                                                        })}
                                                        name="phoneNumber"
                                                        render={o => <TextFieldValidate vo={o} type='text' maxLength="10" errorMessage={errors.phoneNumber?.message} />} />
                                                </Grid>

                                                <Grid item mt={3} xs={3}>
                                                    หมายเลขเกษตรกร
                                                </Grid>
                                                <Grid item mt={2} xs={9}>
                                                    <Controller style={{ marginTop: 0 }}
                                                        control={control}
                                                        rules={{
                                                            required: { value: true, message: "uid is required." },
                                                            min: { value: 0, message: "uid much be greater than or equal 0." }
                                                        }}
                                                        name="uid"
                                                        render={o => <TextFieldValidate vo={o} type='text' errorMessage={errors.uid?.message} />} />
                                                </Grid>
                                                <Grid item mt={3} xs={3}>
                                                    หมายเลขบัตรประชาชน
                                                </Grid>
                                                <Grid item mt={2} xs={9}>
                                                    <Controller
                                                        control={control}
                                                        rules={{
                                                            required: { value: true, message: "กรุณากรอกเลขบัตรประจําตัวประชาชน" }
                                                        }}
                                                        name="cardId"
                                                        render={({ field: { value, onChange, ref } }) =>
                                                            <TextField
                                                                // label="กรุณากรอกเลขบัตรประจำตัวประชาชน 13 หลัก"
                                                                value={value ?? ""}
                                                                onChange={onChange}
                                                                disabled={false}
                                                                inputRef={ref}
                                                                type="text"
                                                                {...register("cardId", {
                                                                    required: { value: messageTax, message: "cardId is required." },
                                                                    minLength: {
                                                                        value: 13,
                                                                        message: "กรุณากรอกเลขบัตรประจำตัวประชาชน 13 หลัก"
                                                                    }
                                                                })}
                                                                error={errors.cardId?.message !== undefined && errors.cardId?.message !== ''}
                                                                helperText={messageTax !== true ? <div style={{ color: 'red' }}>กรุณากรอกเลขบัตรประจําตัวประชาชนให้ถูกต้อง</div> : errors.cardId?.message}
                                                                fullWidth size="small" margin="normal" variant="outlined"
                                                                inputProps={{ maxLength: 13 }}
                                                            />
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
                                            <div style={{ height: '100%', width: 1, background: '#F08E26' }}></div>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Grid container  >
                                                <Grid item mt={3} xs={3}>
                                                    อีเมล
                                                </Grid>
                                                <Grid item mt={2} xs={9}>
                                                    <Controller
                                                        control={control}
                                                        rules={{
                                                            required: { value: true, message: "กรุณากรอก Email" }
                                                        }}
                                                        name="email"
                                                        render={({ field: { value, onChange, ref } }) =>
                                                            <TextField
                                                                style={{ marginTop: 0 }}
                                                                value={value ?? ""}
                                                                onChange={onChange}
                                                                disabled={false}
                                                                inputRef={ref}
                                                                type="text"
                                                                {...register("email", {
                                                                    required: { value: true, message: "email is required." },
                                                                    pattern: { value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, message: "email is not valid." }
                                                                })}
                                                                error={errors.email?.message !== undefined && errors.email?.message !== ''}
                                                                helperText={errors.email?.message}
                                                                fullWidth size="small" margin="normal" variant="outlined"
                                                            />
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item mt={3} xs={3}>
                                                    วัน/เดือน/ปี เกิด
                                                </Grid>
                                                <Grid item mt={2} xs={9}>
                                                    <Controller
                                                        control={control}
                                                        rules={{
                                                            required: { value: true, message: "กรุณากรอก วันเดือนปี เกิด" }
                                                        }}
                                                        name="birthDate"
                                                        render={({ field: { value, onChange, ref } }) =>
                                                            <TextField
                                                                style={{ marginTop: 0 }}
                                                                color='primary'
                                                                InputProps={{
                                                                    style: {
                                                                        backgroundColor: "white",
                                                                    }
                                                                }}
                                                                value={userId ? dayjs(value).format('YYYY-MM-DD') : null}
                                                                onChange={onChange}
                                                                disabled={false}
                                                                inputRef={ref}
                                                                variant="outlined"
                                                                size="small"
                                                                fullWidth
                                                                type="date"
                                                                {...register("birthDate", {
                                                                    required: { value: true, message: "Birthdate is required." },
                                                                })}
                                                                helperText={errors.birthDate?.message}
                                                                error={errors.birthDate !== undefined}
                                                            // disabled={userId ? true : false}
                                                            />

                                                        }
                                                    />
                                                </Grid>
                                                <Grid item mt={3} xs={3}>
                                                    ที่อยู่
                                                </Grid>
                                                <Grid item mt={2} xs={9}>
                                                    <TextField
                                                        style={{ marginTop: 0 }}
                                                        color='primary'
                                                        InputProps={{
                                                            style: {
                                                                backgroundColor: "white",
                                                            }
                                                        }}
                                                        variant="outlined"
                                                        size="small"
                                                        margin="normal"
                                                        fullWidth
                                                        autoFocus
                                                        {...register("address", {
                                                            required: { value: false, message: "Address is required." },
                                                        })}
                                                        helperText={errors.address?.message}
                                                        error={errors.address !== undefined}
                                                    />
                                                </Grid>
                                                <Grid item mt={3} xs={3}>
                                                    Line ID
                                                </Grid>
                                                <Grid item mt={2} xs={9}>
                                                    <TextField
                                                        style={{ marginTop: 0 }}
                                                        color='primary'
                                                        InputProps={{
                                                            style: {
                                                                backgroundColor: "white",
                                                            }
                                                        }}
                                                        variant="outlined"
                                                        size="small"
                                                        margin="normal"
                                                        fullWidth
                                                        autoFocus
                                                        {...register("lineId", {
                                                            required: { value: false, message: "LineId is required." },
                                                        })}
                                                        helperText={errors.lineId?.message}
                                                        error={errors.lineId !== undefined}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </div>
                    </form>
            }
        </div >
    )
}
