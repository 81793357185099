import { useEffect, useState } from "react";
import { API_RES_STATUS } from "../constants/apiResponseStatus";
import { getCategoryEnterprise } from "../services/common";

/**
 * ฟังชันสำหรับ Get ค่าลิสรายการบริษัท สำหรับทำ SelectField
 * @returns 1. react state enterprises
 */
export default function useCategoryEnterprise() {
    const [enterprises, setEnterprises] = useState([])

    useEffect(() => {
        const loadEnterprise = async () => {
            const axiosRes = await getCategoryEnterprise()
            const json = JSON.parse(axiosRes.data)
            const { data, status, message } = json
            if (status === API_RES_STATUS.success) {
                setEnterprises(data)
            }
        }
        loadEnterprise()
    }, [])

    return { enterprises }
}