import { ApiUrl } from "../constants/api_url"
import AxiosHelper from "../helper/axiosHelper"

export default async function createFarm({ plantTypeId, standardId, startDate, endDate, plotId, measurementId }) {

    return await AxiosHelper.post(ApiUrl.FARM + "/create", { plantTypeId, standardId, startDate, endDate, plotId, measurementId })
}

export async function updateFarm(plantTypeId, standardId, startDate, endDate, farmId, isProcessing, measurementId) {

    return await AxiosHelper.post(ApiUrl.FARM + "/update" + "/" + farmId, { plantTypeId, standardId, startDate, endDate, isProcessing, measurementId })
}

export async function finishFarm(plantTypeId, standardId, startDate, endDate, farmId, isProcessing, quantityProduct, measurementId) {

    return await AxiosHelper.post(ApiUrl.FARM + "/finish" + "/" + farmId, { plantTypeId, standardId, startDate, endDate, isProcessing, quantityProduct, measurementId })
}

export async function deleteFarm(farmId) {

    return await AxiosHelper.delete(ApiUrl.FARM + "/delete" + "/" + farmId)
}