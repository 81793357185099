import { ApiUrl } from "../constants/api_url"
import AxiosHelper from "../helper/axiosHelper"

export default async function getAllStandard({ pageSize = 10, currentPage = 1, filterEnterpriseId = '', searchKeyword = '', staffId = "" }) {
    let params = {
        pageSize, currentPage, staffId
    }
    if (filterEnterpriseId.length > 0) {
        params.filterEnterpriseId = filterEnterpriseId
    }
    if (searchKeyword.length > 0) {
        params.searchKeyword = searchKeyword
    }
    let url = `${ApiUrl.STANDARD}`
    if (Object.keys(params).length > 0) {
        params = new URLSearchParams(params)
        url = `${url}?${params.toString()}`
    }
    return await AxiosHelper.get(url)
}

export async function getAllStandardByPlantTypeId(plantTypeId) {
    return await AxiosHelper.post(ApiUrl.STANDARD + "/getAllByPlantTypeId" + "/" + plantTypeId)
}

export async function getAllStandardById(id) {
    return await AxiosHelper.get(ApiUrl.STANDARD + "/" + id)
}

export async function getEndDateFarmByStandardId(standardId) {
    return await AxiosHelper.get(ApiUrl.STANDARD + "/getEndDateFarmByStandardId" + "/" + standardId)
}

export async function createStandard({ name, listStandardValue, enterpriseId, plantTypeId }) {
    return await AxiosHelper.post(ApiUrl.STANDARD + "/create", { name, listStandardValue, enterpriseId, plantTypeId })
}

export async function updateStandard({ id, name, listStandardValue, enterpriseId, plantTypeId }) {
    return await AxiosHelper.put(ApiUrl.STANDARD + "/update" + "/" + id, { name, listStandardValue, enterpriseId, plantTypeId })
}


export async function getStandardDefault(plotId) {
    return await AxiosHelper.get(ApiUrl.STANDARD + "/getStandardDefault/" + plotId)
}

export async function getStandardValue(plotId) {
    return await AxiosHelper.get(ApiUrl.STANDARD + "/GetStandardValueOfFarmId/" + plotId)
}

export async function createStandardValue({ plotId, value, days, measurementId, note }) {
    return await AxiosHelper.post(ApiUrl.STANDARD + "/CreateStandardValue", { plotId, value, days, measurementId, note })
}

export async function deleteStandard(id) {
    return await AxiosHelper.delete(ApiUrl.STANDARD + "/" + id)
}

export async function deleteStandardValue(id) {
    return await AxiosHelper.delete(ApiUrl.STANDARD + "/deleteStandardValue/" + id)
}