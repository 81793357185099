import React, { useEffect, useState } from 'react'
import { Button, Grid, Paper, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Line } from "react-chartjs-2"
import Avatar from '@mui/material/Avatar'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { useNavigate, useParams } from 'react-router-dom'
import StandardValueModal from '../../components/StandardValueModal'
import AlertStandardValueModal from '../../components/AlertStandardValueModal'
import { getPlotViewDetailByPlotId } from '../../services/plot'
import dayjs from 'dayjs'
import { getAllScheduleDetailByEnterpriseId } from '../../services/schedule'
import { createStandardValue, getStandardDefault, getStandardValue } from '../../services/standard'
import { useSnackbar } from 'notistack'
import { Chart as ChartJS } from 'chart.js/auto' // required
import Map from './map'
import { API_RES_STATUS } from '../../constants/apiResponseStatus'
import { getDefaultMeasurementByEnterpriseId } from '../../services/measurement'
import { getDefaultMeasurementDisplay } from '../../helper/measurementHelper'

export default function AreaManagementDetailPage() {
    let { id: plotId } = useParams()
    const className = useStyles()
    const navigation = useNavigate()
    const { enqueueSnackbar, /*closeSnackbar*/ } = useSnackbar()

    const [openModal, setOpenModal] = useState(false)
    const [openAlertModal, setOpenAlertModal] = useState(false)
    const [plotViewDetail, setPlotViewDetail] = useState({})
    const [listSchedule, setListSchedule] = useState([])
    const [standardDefault, setStandardDefault] = useState([])
    const [standardValue, setStandardValue] = useState([])
    const [objectStandard, setObjectStandard] = useState({})
    const [noteStandard, setNoteStandard] = useState("")
    const [defualtMeasurement, setDefualtMeasurement] = useState(null)

    const [standardName, setStandardName] = useState()

    const { coordinates = [] } = plotViewDetail

    useEffect(() => {
        loadPlot(plotId).then()
        loadStandardDefault()
        loadStandardValue()
    }, [])

    const loadPlot = async (plotId) => {
        const res = await getPlotViewDetailByPlotId(plotId)
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            let data = json.data
            setPlotViewDetail(data)
            await getMeasurementByEnterpriseId(data.size, data.enterpriseId)
            loadSchedule(data.enterpriseId)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
    }

    const getMeasurementByEnterpriseId = async (sizeSqMeter, enterpriseId) => {
        const res = await getDefaultMeasurementByEnterpriseId(enterpriseId)
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            let data = json.data
            let disPlayMeasurement = await getDefaultMeasurementDisplay(data.measurementName, sizeSqMeter)
            setDefualtMeasurement(disPlayMeasurement)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
    }

    const loadStandardValue = async () => {
        const res = await getStandardValue(plotId)
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            let data = json.data
            setStandardValue(data)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
    }

    const loadStandardDefault = async () => {
        const res = await getStandardDefault(plotId)
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            let data = json.data
            setStandardDefault(data.listStandardValue)
            setStandardName(data.name)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }

    }

    const loadSchedule = async (enterpriseId) => {
        const res = await getAllScheduleDetailByEnterpriseId(enterpriseId)
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            let data = json.data
            setListSchedule(data)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
    }


    const OpenValueModal = () => {
        for (let i = 0; i < standardDefault.length; i++) {
            const dateNowString = dayjs()
            var dateStringg = standardDefault[i].specifyDate
            var dateParts = dateStringg.split("/")
            // month is 0-based, that's why we need dataParts[1] - 1
            console.log("dateParts[2] >>>", dateParts[2])
            // var year = parseInt(dateParts[2]) - 543
            // var dateObject = new Date(+year, dateParts[1] - 1, +dateParts[0])
            var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])
            if (standardValue[i] !== undefined) {
                if (standardDefault[i].specifyDate === standardValue[i].specifyDate) {
                    continue
                }
            } else {
                setObjectStandard(standardDefault[i])
                if (dateNowString.valueOf() >= dateObject.valueOf()) {
                    setOpenModal(true)
                } else {
                    enqueueSnackbar("ยังไม่ถึงวันที่กำหนด", { variant: 'error' })
                    break
                }
                break
            }
        }
    }

    const OnClickInModal = () => {
        setOpenModal(false)
    }

    const OnClickSubmitModal = async (inputValue, objectStandard) => {
        const res = await createStandardValue({
            plotId: plotId, value: inputValue,
            days: objectStandard.days, measurementId: objectStandard.measurementId,
            note: objectStandard.note
        })
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            setOpenModal(false)
            enqueueSnackbar('Add Standard Value successfully.', { variant: 'success' })
            setNoteStandard(objectStandard.note)
            if (inputValue < objectStandard.value) {
                setOpenAlertModal(true)
            }
            loadStandardValue()
            // navigation(-1)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
    }

    const OnAlertClickInModal = () => {
        setOpenAlertModal(false)
    }

    const userData = {
        labels: standardDefault.map((data) => data.specifyDate),
        datasets: [
            {
                label: "ผลดำเนินการ",
                data: standardValue.map((data) => data.value),
                backgroundColor: [
                    "#F08E26",
                ],
                borderColor: "#F08E26",
                borderWidth: 2,
                tension: 0.3
            },
            {
                label: "ค่ามาตรฐาน",
                data: standardDefault.map((data) => data.value),
                backgroundColor: [
                    "green",
                ],
                borderColor: "green",
                borderWidth: 2,
                tension: 0.3
            },

        ],
    }

    return (
        <>
            <div style={{ margin: 40 }}>
                <Grid container  >
                    <Grid item xs={12}>
                        <div style={{ display: 'flex', justifyContent: 'start' }}>
                            <Button onClick={() => { navigation(-1) }}
                                style={{
                                    color: '#12203A', borderRadius: 10, marginRight: 20,
                                    backgroundColor: 'white',
                                }}
                                variant="contained">
                                <KeyboardBackspaceIcon style={{ marginRight: 10 }} />
                                ย้อนกลับ
                            </Button>
                        </div>
                    </Grid>
                </Grid>
                <Grid container direction={'row'} spacing={3} >
                    <Grid item sm={12} md={4}>
                        <Paper elevation={6} className={className.paperProfile}>
                            <div style={{ flexDirection: 'row', display: 'flex', overflow: 'hidden', }}>
                                <Avatar
                                    sx={{ width: 100, height: 100 }}
                                    alt="Remy Sharp"
                                    src={require("../../assets/images/Logo.png")} />
                                <div style={{ flexDirection: 'column', display: 'flex', paddingLeft: 20, overflow: 'hidden' }}>
                                    <Typography color={"#12203A"}
                                        style={{ marginBottom: 10 }}>
                                        ข้อมูลลูกไร่
                                    </Typography>
                                    <Typography
                                        style={{ marginBottom: 10 }}>
                                        ชื่อ-นามสกุล {plotViewDetail.firstName} {plotViewDetail.lastName}
                                    </Typography>
                                    <Typography
                                        style={{ marginBottom: 10 }}>
                                        UID : {plotViewDetail.uid}
                                    </Typography>
                                    {/* <Typography
                                        style={{ marginBottom: 10 }}>
                                        รหัสแปรง : {plotViewDetail.id}
                                    </Typography> */}
                                    <Typography
                                        style={{ marginBottom: 10 }}>
                                        เบอร์โทรศัพท์ : {plotViewDetail.phoneNumber}
                                    </Typography>
                                </div>

                            </div>
                            <div style={{ flexDirection: 'column', display: 'flex', overflow: 'hidden' }}>
                                <Typography
                                    style={{ marginBottom: 10 }}>
                                    หมายเลขทะเบียนเกษตรกร : {plotViewDetail.cardId}
                                </Typography>
                                <Typography
                                    style={{ marginBottom: 10 }}>
                                    ขนาดพื้นที่ : {defualtMeasurement}
                                </Typography>
                                {/* <Typography
                                    style={{ marginBottom: 10 }}>
                                    ค่าพิกัด : 122222222222212
                                </Typography> */}
                                <Typography
                                    style={{ marginBottom: 10 }}>
                                    ที่อยู่ : {plotViewDetail.addressPlot}
                                </Typography>
                                <Typography
                                    style={{ marginBottom: 10 }}>
                                    ชนิดพืช : {plotViewDetail.plantName}
                                </Typography>
                                <Typography
                                    style={{ marginBottom: 10 }}>
                                    สายพันธ์ : {plotViewDetail.plantType}
                                </Typography>
                                <Typography
                                    style={{ marginBottom: 10 }}>
                                    ช่วงเวลาที่ปลูก : {plotViewDetail.createDate != null ? dayjs(plotViewDetail.createDate, "YYYY-MM-DD+h:mm").format('DD-MM-YYYY') : "-"}
                                </Typography>
                                <Typography
                                    style={{ marginBottom: 10 }}>
                                    ช่วงเวลาที่เก็บเกี่ยว : {plotViewDetail.harvestDate != null ? dayjs(plotViewDetail.harvestDate, "YYYY-MM-DD+h:mm").format('DD-MM-YYYY') : "-"}
                                </Typography>
                                {/* <Typography
                                    style={{ marginBottom: 10 }}>
                                    ผลผลิตรวม : 122222222222212
                                </Typography> */}
                                <Typography
                                    style={{ marginBottom: 10 }}>
                                    รายละเอียด : {plotViewDetail.detail}
                                </Typography>
                                <Typography
                                    style={{ marginBottom: 30 }}>
                                </Typography>
                            </div>
                        </Paper>
                    </Grid>

                    <Grid item sm={12} md={8} style={{ marginBottom: 20 }}>
                        <div style={{ marginTop: 20 }}>
                            <Map polygonPath={coordinates} />
                        </div>
                    </Grid>
                </Grid>
                <Grid container flexDirection={'row'} spacing={3}>
                    <Grid item xs={12} sm={4} >
                        <Paper elevation={6} className={className.paperChart}>
                            <Typography
                                style={{ marginBottom: 10, justifyContent: 'center', display: 'flex', fontWeight: 'bold', fontSize: 18 }}>
                                การจัดการ
                            </Typography>
                            {
                                listSchedule.map((item, i) => {
                                    if (!item.isTimeInterval) {
                                        return (
                                            <div key={i} className={className.paperMethod}>
                                                <Typography color={'#12203A'} style={{ fontWeight: 'bold', marginBottom: 5 }}>
                                                    {item.name}
                                                </Typography>
                                                <Typography
                                                    style={{ marginBottom: 10, wordWrap: "break-word" }}>
                                                    {item.detail}
                                                </Typography>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div key={i}>
                                                <div className={className.paperMethod3}>
                                                    <Typography color={'#12203A'} style={{ fontWeight: 'bold' }}>
                                                        {item.name}
                                                    </Typography>
                                                </div>
                                                {item.listScheduleDateTime.map((sch, i) => {
                                                    var date = new Date(plotViewDetail.farmStartDate)
                                                    date.setDate(date.getDate() + sch.selectDate)
                                                    return (
                                                        <div key={i} className={className.paperMethod2}>
                                                            <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                                                                <Typography color={'white'}>
                                                                    {sch.schedule}
                                                                </Typography>
                                                                <Typography color={'white'}>
                                                                    {
                                                                        dayjs(date, "YYYY-MM-DD+h:mm").format('DD/MM/YYYY')
                                                                    }
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        )
                                    }
                                })
                            }
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Paper elevation={6} className={className.paperChart}>
                            <Typography style={{ justifyContent: 'flex-end', display: 'flex' }}>
                                <Button onClick={() => OpenValueModal()} style={{ color: '#F08E26', backgroundColor: '#12203A', borderRadius: 5, marginRight: 20, width: '20%' }} variant="contained">
                                    เพิ่มค่า
                                </Button>
                            </Typography>
                            <Typography
                                style={{ marginBottom: 10, justifyContent: 'center', display: 'flex' }}>
                                {standardName}
                            </Typography>
                            <Typography style={{ marginBottom: 10, marginTop: 20 }}>
                                {`ความสูง (เมตร)`}
                            </Typography>
                            <Line data={userData} />
                            <Typography
                                style={{ marginBottom: 10, marginTop: 20, justifyContent: 'flex-end', display: 'flex' }}>
                                ช่วงเวลา
                            </Typography>
                        </Paper>
                    </Grid>

                </Grid>
                <StandardValueModal open={openModal} OnClickInModal={OnClickInModal} OnClickSubmitModal={OnClickSubmitModal} objectStandard={objectStandard} />
                <AlertStandardValueModal open={openAlertModal} OnAlertClickInModal={OnAlertClickInModal} note={noteStandard} />
            </div>
        </>
    )
}


const useStyles = makeStyles({
    paperProfile: {
        borderRadius: 10,
        marginTop: 20,
        padding: 40,
    },
    paperChart: {
        borderRadius: 10,
        marginTop: 20,
        padding: 30,
        minHeight: 450
    },
    paperMethod: {
        borderRadius: 10,
        marginTop: 20,
        padding: 20,
        overflow: 'hidden',
        borderColor: '#12203A',
        borderWidth: 1,
        borderStyle: 'solid'
    },
    paperMethod2: {
        borderRadius: 10,
        marginTop: 20,
        padding: 10,
        overflow: 'hidden',
        borderColor: '#12203A',
        borderWidth: 1,
        borderStyle: 'solid',
        backgroundColor: '#12203A'
    },
    paperMethod3: {
        borderRadius: 10,
        marginTop: 20,
        padding: 10,
        overflow: 'hidden',
        borderColor: '#12203A',
        borderWidth: 1,
        borderStyle: 'solid'
    },
})