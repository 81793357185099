import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import { Box, Button, FormControl, InputAdornment, InputLabel, MenuItem, Select } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import OutlinedInput from '@mui/material/OutlinedInput'
import SearchIcon from '@mui/icons-material/Search'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from '../../constants/paths'
import Skeleton from '@mui/material/Skeleton'
import getAllPlant, { deletePlant } from '../../services/plant'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import AlertDeleteModal from '../../components/AlertDeleteModal'
import Empty from '../../components/Empty'
import { useSnackbar } from 'notistack'
import { API_RES_STATUS } from '../../constants/apiResponseStatus'
import { AppContext } from '../../contexts/AppContext'
import useCategoryEnterprise from '../../reactHook/useCategoryEnterprise'
import { ROLES } from '../../constants/enums'
import StyledDataGrid from '../../components/StyledDataGrid'

export default function PlantsPage() {
    const { employee = {} } = useContext(AppContext)
    const { enqueueSnackbar, /*closeSnackbar*/ } = useSnackbar()
    const classes = useStyles()
    const navigation = useNavigate()

    const [listPlant, setListPlant] = useState([])
    const [loading, setLoading] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [idDelete, setIdDelete] = useState(null)

    const [filterEnterpriseId, setFilterEnterpriseId] = useState('')
    const [searchKeyword, setSearchKeyword] = useState('')
    const { enterprises } = useCategoryEnterprise()

    const [tableCurrentPage, setTableCurrentPage] = useState(0)
    const [tablePageSize, setTablePageSize] = useState(10)
    const [tableTotalRow, setTableTotalRow] = useState(0)

    useEffect(() => {
        loadPlant()
    }, [tablePageSize, tableCurrentPage, filterEnterpriseId, searchKeyword])

    const loadPlant = async () => {
        setLoading(true)
        const axiosRes = await getAllPlant({ pageSize: tablePageSize, currentPage: tableCurrentPage + 1, filterEnterpriseId, searchKeyword })
        const { data, status, message = "" } = JSON.parse(axiosRes.data)
        if (status === API_RES_STATUS.success) {
            const { listData, totalRow = 0 } = data
            setListPlant(listData)
            setTableTotalRow(totalRow)
        } else {
            enqueueSnackbar(message, { variant: 'error', autoHideDuration: 10000 })
        }
        setLoading(false)
    }
    const OnClickSubmitModal = async (id) => {
        const res = await deletePlant(id)
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            enqueueSnackbar('Delete Plant successfully.', { variant: 'success' })
            setIdDelete(null)
            loadPlant()
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }

        setOpenModal(false)
    }

    const OnClickInModal = () => {
        setOpenModal(false)
        setIdDelete(null)
    }

    const OnClickDelete = (id) => {
        setIdDelete(id)
        setOpenModal(true)
    }

    const columns = [
        {
            field: 'name',
            headerName: "ชื่อพืช",
            valueGetter: ({ row }) => {
                return `${row["name"]}`
            },
            flex: 3
        },
        {
            field: 'action',
            headerName: "",
            renderCell: ({ row }) => {
                return <>
                    <IconButton
                        size="small"
                        onClick={() => navigation(ROUTE_PATH.EDIT_PLANTS.replace(":id", row.id))}
                    >
                        <ModeEditOutlineOutlinedIcon />
                    </IconButton>
                    {/* <IconButton
                        size="small"
                        color='error'
                        onClick={() => OnClickDelete(row.id)}  >
                        <DeleteIcon />
                    </IconButton> */}

                </>
            },
            flex: 1
        },
    ]


    const onFilterSearchKeywordChange = (event) => {
        setSearchKeyword(event.target.value)
    }

    const onInputSearchKeyDown = (e) => {
        if (e.keyCode === 13) {
            onSearchClick()
        }
    }

    const onSearchClick = () => {
        loadPlant()
    }

    const loadingPanel = <div style={{ marginRight: 10, marginLeft: 10 }}>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Skeleton variant="rect" height={50} width={"100%"} />
        </Grid>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Skeleton variant="rect" height={50} width={"100%"} />
        </Grid>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Skeleton variant="rect" height={50} width={"100%"} />
        </Grid>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Skeleton variant="rect" height={50} width={"100%"} />
        </Grid>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Skeleton variant="rect" height={50} width={"100%"} />
        </Grid>
    </div>

    return (
        <>
            <div style={{ margin: 40 }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6}>
                        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            {employee.role === ROLES.AdminLing &&
                                <FormControl size="small" sx={{ m: 1, minWidth: 220 }}>
                                    <InputLabel >เลือกบริษัท</InputLabel>
                                    <Select
                                        label="เลือกบริษัท"
                                        value={filterEnterpriseId}
                                        onChange={(e) => { setFilterEnterpriseId(e.target.value) }}
                                    >
                                        <MenuItem value={''}>ทั้งหมด</MenuItem>
                                        {enterprises.map((e) => {
                                            return <MenuItem key={`select-enterprise-${e.id}`} value={e.id}>{e.name}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>}
                            <FormControl sx={{ m: 1, width: '100%' }} size="small">
                                <OutlinedInput
                                    placeholder='ชื่อพืช'
                                    value={searchKeyword}
                                    onChange={onFilterSearchKeywordChange}
                                    onKeyDown={onInputSearchKeyDown}
                                    startAdornment={
                                        <InputAdornment position='start'>
                                            <SearchIcon onClick={onSearchClick} />
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        {/* ปิดให้ไม่สามารถสร้างได้ */}
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={() => { navigation(ROUTE_PATH.CREATE_PLANTS) }} style={{ color: 'white', borderRadius: 5, backgroundColor: '#12203A' }} variant="contained">
                                <AddIcon style={{ marginRight: 2 }} />
                                สร้าง
                            </Button>
                        </div>
                    </Grid>
                </Grid>
                {/* {
                    loading
                        ? loadingPanel
                        :
                        <> */}
                <div style={{ marginTop: 20 }}>
                    <Grid item xs={12}>
                        <Box sx={{ height: '70vh', width: '100%' }}>
                            <StyledDataGrid
                                rows={listPlant}
                                columns={columns}
                                paginationMode="server"
                                loading={loading}
                                rowCount={tableTotalRow}
                                page={tableCurrentPage}
                                pageSize={tablePageSize}
                                pagination
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                onPageChange={(newPage) => setTableCurrentPage(newPage)}
                                onPageSizeChange={(newPageSize) => setTablePageSize(newPageSize)}
                            />
                        </Box>
                    </Grid>
                </div>
                {/* </>
                } */}
            </div>
            <AlertDeleteModal id={idDelete} open={openModal} OnClickInModal={OnClickInModal} OnClickSubmitModal={OnClickSubmitModal} />
        </>
    )
}

const useStyles = makeStyles((theme) => ({

}))