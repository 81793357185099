import { ApiUrl } from "../constants/api_url"
import AxiosHelper from "../helper/axiosHelper"

export default async function getAllMeasurementLength() {
    return await AxiosHelper.get(ApiUrl.MEASUREMENT)
}

export async function getAllMeasurementWeight() {
    return await AxiosHelper.get(ApiUrl.MEASUREMENT + "/getMeasurementWeight")
}

export async function getAllMeasurementSizePlot() {
    return await AxiosHelper.get(ApiUrl.MEASUREMENT + "/GetMeasurementSizePlot")
}

export async function getDefaultMeasurementByEnterpriseId(enterpriseId) {
    return await AxiosHelper.get(ApiUrl.MEASUREMENT + "/GetDefaultMeasurementByEnterpriseId/" + enterpriseId)
}

export async function getDefaultMeasurementByEmployeeId(staffId) {
    return await AxiosHelper.get(ApiUrl.MEASUREMENT + "/GetDefaultMeasurementByEmployeeId/" + staffId)
}