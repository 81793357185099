import { ApiUrl } from "../constants/api_url"
import AxiosHelper from "../helper/axiosHelper"

export default async function getAllEmployee({ pageSize = 10, currentPage = 1, filterEnterpriseId = '', searchKeyword = '', filterRole = -1 }) {
    let params = {
        pageSize, currentPage
    }
    if (filterEnterpriseId.length > 0) {
        params.filterEnterpriseId = filterEnterpriseId
    }
    if (searchKeyword.length > 0) {
        params.searchKeyword = searchKeyword
    }
    if (filterRole > 0) {
        params.filterRole = filterRole
    }
    let url = `${ApiUrl.EMPLOYEE}`
    if (Object.keys(params).length > 0) {
        params = new URLSearchParams(params)
        url = `${url}?${params.toString()}`
    }
    return await AxiosHelper.get(url)
}

export async function getAllEmployeeNotHaveTeam() {
    return await AxiosHelper.get(ApiUrl.EMPLOYEE + "/getAllEmployeeNotHaveTeam")
}

export async function getAllEmployeeNotHaveTeamByTeamId(id) {
    return await AxiosHelper.get(ApiUrl.EMPLOYEE + "/getAllEmployeeNotHaveTeamByTeamId" + "/" + id)
}

export async function getEmployeeById(id) {
    return await AxiosHelper.get(ApiUrl.EMPLOYEE + "/" + id)
}

export async function createEmployee({ firstName, lastName, phoneNumber, email, staffId, citizenId, password, enterprisePositionId, enterpriseId }) {
    return await AxiosHelper.post(ApiUrl.EMPLOYEE + "/create",
        { firstName, lastName, phoneNumber, email, staffId, citizenId, password, enterprisePositionId, enterpriseId })
}

export async function updateEmployee({ id, firstName, lastName, phoneNumber, email, staffId, citizenId, enterprisePositionId, enterpriseId }) {
    return await AxiosHelper.put(ApiUrl.EMPLOYEE + "/update/" + id, { firstName, lastName, phoneNumber, email, staffId, citizenId, enterprisePositionId, enterpriseId })
}

export async function deleteEmployee(id) {
    return await AxiosHelper.delete(ApiUrl.EMPLOYEE + "/" + id)
}

export async function signInEmployee({ email, password }) {
    return await AxiosHelper.post(`${ApiUrl.ACCOUNT_EMPLOYEE}/signin`, { email, password }, null, false)
}

export async function newImportStaff(formData) {
    return await AxiosHelper.post(ApiUrl.EMPLOYEE + "/import", {}, {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data: formData
    })
}