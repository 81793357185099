import { ApiUrl } from "../constants/api_url"
import AxiosHelper from "../helper/axiosHelper"

export default async function getAllEnterprisePosition() {
    return await AxiosHelper.get(ApiUrl.ENTERPRISE_POSITION)
}

export async function getAllEnterprisePositionByEnterpriseId(id) {
    return await AxiosHelper.get(ApiUrl.ENTERPRISE_POSITION + "/getAllEnterprisePositionByEnterpriseId/" + id)
}

export async function updateEnterprisePosition({ id, liseEnterprisePosition }) {
    return await AxiosHelper.put(ApiUrl.ENTERPRISE_POSITION + "/" + id, { enterpriseId: id, liseEnterprisePosition })
}

// export async function getPositionById(id) {
//     return await AxiosHelper.get(ApiUrl.POSITION + "/" + id)
// }

// export async function createPosition({ name, taxId, phoneNumber, email, address, isActive }) {
//     console.log("createEnterprise >>>", Boolean(isActive))
//     return await AxiosHelper.post(ApiUrl.POSITION, { name, taxId, phoneNumber, email, address, isActive: Boolean(isActive) })
// }

