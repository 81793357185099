import { useEffect, useState } from "react"
import { API_RES_STATUS } from "../constants/apiResponseStatus"
import { getCategoryEmployee } from "../services/common"

export default function useCategoryEmployee() {
    const [employees, setEmployees] = useState([])
    const [filterEnterpriseId, setFilterEmpByEnterpriseId] = useState('')
    const [filterAreaId, setFilterEmpByAreaId] = useState('')
    const [filterTeamId, setFilterEmpByTeamId] = useState('')
    const [filterRole, setFilterRole] = useState('')

    useEffect(() => {
        const loadStaff = async () => {
            const axiosRes = await getCategoryEmployee({ filterEnterpriseId: filterEnterpriseId, filterAreaId: filterAreaId, filterTeamId: filterTeamId, filterRole: filterRole })
            const json = JSON.parse(axiosRes.data)
            const { data, status, message } = json
            if (status === API_RES_STATUS.success) {
                setEmployees(data)
            }
        }
        loadStaff()
    }, [filterEnterpriseId, filterAreaId, filterTeamId, filterRole])

    return { employees, setFilterEmpByEnterpriseId, setFilterEmpByAreaId, setFilterEmpByTeamId, setFilterRole }
}