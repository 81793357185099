import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { styled } from '@mui/material/styles'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from '../../constants/paths'
import Skeleton from '@mui/material/Skeleton'
import getAllSchedule, { deleteSchedule } from '../../services/schedule'
import Empty from '../../components/Empty'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import AlertDeleteModal from '../../components/AlertDeleteModal'
import { useSnackbar } from 'notistack'
import { API_RES_STATUS } from '../../constants/apiResponseStatus'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#12203A",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#D3DCEC",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 1,
    },
}))

export default function SchedulePage() {
    const { enqueueSnackbar, /*closeSnackbar*/ } = useSnackbar()
    const classes = useStyles()
    const navigation = useNavigate()
    const [listSchedule, setListSchedule] = useState([])
    const [loading, setLoading] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [idDelete, setIdDelete] = useState(null)

    useEffect(() => {
        loadSchedule()
    }, [])

    const loadSchedule = async () => {
        setLoading(true)
        const res = await getAllSchedule()
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            setListSchedule(json.data)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
        setLoading(false)
    }

    const OnClickSubmitModal = async (id) => {
        const res = await deleteSchedule(id)
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            enqueueSnackbar('Delete schedule successfully.', { variant: 'success' })
            setIdDelete(null)
            loadSchedule()
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }

        setOpenModal(false)
    }

    const OnClickInModal = () => {
        setOpenModal(false)
        setIdDelete(null)
    }

    const OnClickDelete = (id) => {
        setIdDelete(id)
        setOpenModal(true)
    }

    const columns = [
        { label: "ลำดับ", minWidth: 50, align: 'left', },
        { label: "ชื่อกำหนดการ", minWidth: 120, align: 'center' },
        { label: "ประเภท", minWidth: 120, align: 'center' },
        { label: "", minWidth: 120, align: 'center' },
    ]

    const loadingPanel = <div style={{ marginRight: 10, marginLeft: 10 }}>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Skeleton variant="rect" height={50} width={"100%"} />
        </Grid>
        <Grid style={{ marginTop: 10 }}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Skeleton variant="rect" height={500} width={"100%"} />
        </Grid>
    </div>
    return (
        <>
            <div style={{ margin: 40 }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6}>
                        {/* <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: 20 }}>
                            <OutlinedInput size="small" style={{ width: '60%' }}
                                startAdornment={<SearchIcon />}
                            />
                        </div> */}
                    </Grid>
                    <Grid item xs={6}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={() => navigation(ROUTE_PATH.CREATE_SCHEDULE)} style={{ color: 'white', borderRadius: 5, backgroundColor: '#12203A' }} variant="contained">
                                <AddIcon style={{ marginRight: 2 }} />
                                สร้าง
                            </Button>
                        </div>
                    </Grid>
                </Grid>
                {
                    loading
                        ? loadingPanel
                        : <div style={{ marginTop: 20 }}>
                            <TableContainer className={classes.container}>
                                <Table stickyHeader aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column) => (
                                                <StyledTableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth }}
                                                >
                                                    {column.label}
                                                </StyledTableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            listSchedule.map((row, i) => {
                                                return (
                                                    <StyledTableRow>
                                                        <StyledTableCell >{i + 1}</StyledTableCell>
                                                        <StyledTableCell align="center" >{row.name}</StyledTableCell>
                                                        <StyledTableCell align="center" >
                                                            {row.isTimeInterval
                                                                ? "ระบุช่วงเวลา"
                                                                : "ไม่ระบุช่วงเวลา"
                                                            }
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center">
                                                            <IconButton aria-label="edit" size="small" color='inherit' style={{ marginRight: 10 }} onClick={() => navigation(ROUTE_PATH.EDIT_SCHEDULE.replace(":id", row.id))}>
                                                                <ModeEditOutlineOutlinedIcon />
                                                            </IconButton>
                                                            <IconButton aria-label="delete" size="small" color='error' onClick={() => OnClickDelete(row.id)}  >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                                {listSchedule.length === 0 ? <div style={{ display: 'flex', justifyContent: 'center' }}><Empty /></div> : null}
                            </TableContainer>
                        </div>
                }
            </div>
            <AlertDeleteModal id={idDelete} open={openModal} OnClickInModal={OnClickInModal} OnClickSubmitModal={OnClickSubmitModal} />
        </>
    )
}
const useStyles = makeStyles((theme) => ({

}))