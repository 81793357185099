import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import { Box, Button, InputAdornment, Modal, TablePagination } from '@mui/material'
import OutlinedInput from '@mui/material/OutlinedInput'
import SearchIcon from '@mui/icons-material/Search'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { styled } from '@mui/material/styles'
import { useNavigate, useParams } from 'react-router-dom'
import { ROUTE_PATH } from '../../constants/paths'
import VisibilityIcon from '@mui/icons-material/Visibility'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import { getAllPlotByRole, newImportPlot } from '../../services/plot'
import dayjs from 'dayjs'
import * as XLSX from 'xlsx'
import { useSnackbar } from 'notistack'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import { API_RES_STATUS } from '../../constants/apiResponseStatus'
import Empty from '../../components/Empty'
import StyledDataGrid from '../../components/StyledDataGrid'
import { getDefaultMeasurementDisplay } from '../../helper/measurementHelper'

export default function AreaManagementStaffListPage() {
    let { id: staffId } = useParams()
    const classes = useStyles()
    const navigation = useNavigate()
    const [openModal, setOpenModal] = useState(false)
    const [disableUploadFile, setDisableUploadFile] = useState(false)
    const [records, setRecords] = useState([])
    const { enqueueSnackbar, /*closeSnackbar*/ } = useSnackbar()
    const [acceptedFiles, setAcceptedFiles] = useState([])
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [searchKeyword, setSearchKeyword] = useState('')

    const [tablePageSize, setTablePageSize] = useState(10)
    const [tableCurrentPage, setTableCurrentPage] = useState(1)
    const [tableTotalRow, setTableTotalRow] = useState(0)
    const [tableLoading, setTableLoading] = useState(false)

    const [plots, setPlots] = useState([])
    useEffect(() => {
        if (staffId) {
            loadPlot()
        }
    }, [tablePageSize, tableCurrentPage])

    const loadPlot = async () => {
        if (staffId.length === 0 || staffId === "null") {
            return
        }
        setTableLoading(true)
        const axiosRes = await getAllPlotByRole({ filterEmployeeId: staffId, pageSize: tablePageSize, currentPage: tableCurrentPage, searchKeyword })
        const json = JSON.parse(axiosRes.data)
        const { data, status, message } = json
        if (status === API_RES_STATUS.success) {
            const { listData = [], totalRow = 0 } = data
            listData.map(async (l, i) => {
                l.size = await getDefaultMeasurementDisplay(l.measurementName, l.size)
            })
            setPlots(listData)
            setTableTotalRow(totalRow)
        }
        setTableLoading(false)
    }

    const columns = [
        {
            field: 'userFullName',
            headerName: 'ชื่อ-นามสกุล',
        },
        {
            field: 'userDeedNo',
            headerName: 'รหัสแปลง',
        },
        {
            field: 'userUid',
            headerName: 'หมายเลขทะเบียนเกษตรกร',
            width: 200
        },
        {
            field: 'size',
            headerName: 'ขนาดพื้นที่',

        },
        {
            field: 'plantName',
            headerName: 'ชนิดพืช',
        },
        {
            field: 'plantTypeName',
            headerName: 'ชนิดพันธุ์',
        },
        {
            field: 'LastActiveFramStartDate',
            headerName: 'วันที่ปลูก',
            valueGetter: (params) => {
                if (params.row["lastActiveFramStartDate"]) {
                    return dayjs(params.row["lastActiveFramStartDate"]).format("DD-MM-YYYY")
                } else {
                    return ''
                }
            }
        },
        {
            field: 'LastActiveFramEndDate',
            headerName: 'วันที่เก็บเกี่ยว',
            valueGetter: (params) => {
                if (params.row["lastActiveFramEndDate"]) {
                    return dayjs(params.row["lastActiveFramEndDate"]).format("DD-MM-YYYY")
                } else {
                    return ''
                }
            }
        },
        // {
        //     field: 'lastActiveFramQuantityProduct',
        //     headerName: "ผลผลิตรวม",
        // },
        {
            field: 'enterpriseName',
            headerName: "ชื่อบริษัท",
        },
        {
            field: 'areaName',
            headerName: "ชื่อพื้นที่"
        },
        {
            field: 'teamName',
            headerName: "ชื่อทีม"
        },
        {
            field: 'employeeFullName',
            headerName: "ชื่อพนักงาน",
            valueGetter: ({ row }) => {
                return `${row["employeeFirstName"]} ${row["employeeLastName"]}`
            }
        },
        {
            width: 150,
            field: 'action',
            headerName: "",
            renderCell: ({ row }) => {
                const empId = row["employeeId"]
                const plotId = row["id"]
                const startDate = row["lastActiveFramStartDate"]
                return <><Button onClick={() => checkFarmProcessing(plotId, startDate)} color='inherit' startIcon={<AssignmentIndIcon />}></Button>
                    <Button onClick={() => navigation(ROUTE_PATH.CREATE_PLOT, { state: { staffId: empId, plotId: plotId } })}
                        color='inherit' startIcon={<VisibilityIcon />}></Button></>
            }
        },
    ]

    const OnDownloadPlotTemplateFile = () => {
        var header = Object.values(columnNamesModal)

        const objDataMock = {
            index: 1,
            user_Id: '00000000-0000-0000-0000-000000000000',
            firstName: 'testImportUser',
            lastName: 'testImportUser',
            uid: 'testImportUser',
            latitude: 1.111,
            longitude: 1.111,
            'plan_size(ตารางเมตร)': 1,
            plan_name: 'plan_name_testImport',
            plant: 'plant_testImport',
            species: 'species_testImport',
            observation_point: null,
            plan_North: null,
            plan_South: null,
            plan_West: null,
            plan_East: null,
            address: 'address_testImport',
            date: '01/01/2022',
        }

        const ws = XLSX.utils.json_to_sheet([objDataMock], { header: header })
        const wb = XLSX.utils.book_new()

        XLSX.utils.book_append_sheet(wb, ws, "Sheet1")
        XLSX.writeFile(wb, 'import_plot_template.xlsx')
    }

    const onDropped = (e, isInput) => {
        e.preventDefault()

        const files = isInput ? e.target.files : e.dataTransfer.files

        let extension = files[0].name.split('.').pop()
        if (extension !== 'xlsx') {
            enqueueSnackbar('File type invalid.', { variant: 'error' })
            return
        }

        setAcceptedFiles(files)

        let i, f
        for (i = 0, f = files[i]; i !== files.length; ++i) {
            var reader = new FileReader()
            // var name = f.name
            reader.onload = function (e) {
                var data = e.target.result // <-- 'data' contains the sheet data

                var xlsx = XLSX.read(data, { type: 'binary', codepage: 65001 })  // <--'xlsx' is an object that holds the relevant data

                const json = XLSX.utils.sheet_to_json(xlsx.Sheets[xlsx.SheetNames[0]], { raw: false })

                const row = json[0]

                if (row == undefined) {
                    enqueueSnackbar('sorry data not found', { variant: 'error' })
                    return
                }

                const keys = Object.keys(row)

                loop1: for (let i = 0; i < keys.length; i++) {
                    const k = keys[i]
                    for (let j = 0; j < columnsModal.length; j++) {
                        const mk = columnsModal[j]
                        if (mk.value === k) {
                            continue loop1
                        }
                    }

                    enqueueSnackbar('column not found ' + k, { variant: 'error' })
                    return
                }

                if (json === null) {
                    enqueueSnackbar('File incorrect format.', { variant: 'error' })
                    return
                }
                if (json.length === 0) {
                    enqueueSnackbar('Empty file.', { variant: 'error' })
                    return
                }

                validatePlot(json)
                setRecords(json)

            }
            reader.readAsBinaryString(f)
        }

    }

    const onDragOver = (event) => {

        event.preventDefault()
    }

    const clear = () => {
        setRecords([])
        setDisableUploadFile(false)
    }

    const validatePlot = jsonList => {

        jsonList.forEach(row => {

            columnsModal.forEach(column => {
                let validField = false

                switch (column.value) {
                    case columnNamesModal.indexPlot:
                        validField = column.isValid(row)
                        break
                    case columnNamesModal.userId:
                        validField = column.isValid(row)
                        break
                    case columnNamesModal.firstname:
                        validField = column.isValid(row)
                        break
                    case columnNamesModal.lastName:
                        validField = column.isValid(row)
                        break
                    case columnNamesModal.uid:
                        validField = column.isValid(row)
                        break
                    case columnNamesModal.Latitude:
                        validField = column.isValid(row)
                        break
                    case columnNamesModal.Longitude:
                        validField = column.isValid(row)
                        break
                    case columnNamesModal.size:
                        validField = column.isValid(row)
                        break
                    case columnNamesModal.plotName:
                        validField = column.isValid(row)
                        break
                    case columnNamesModal.plantName:
                        validField = column.isValid(row)
                        break
                    case columnNamesModal.plantTypeName:
                        validField = column.isValid(row)
                        break
                    case columnNamesModal.address:
                        validField = column.isValid(row)
                        break
                    case columnNamesModal.createDate:
                        validField = column.isValid(row)
                        break
                    case columnNamesModal.observationPoint:
                    case columnNamesModal.planNorth:
                    case columnNamesModal.planSouth:
                    case columnNamesModal.planEast:
                    case columnNamesModal.planWest:
                    default:
                        validField = true
                        break
                }
                if (!disableUploadFile && !validField) {
                    setDisableUploadFile(true)
                }
                row[column.value + '-valid'] = validField
            })


        })
    }


    const uploadNewPlot = async () => {
        const formData = new FormData()

        formData.append("plotFile", acceptedFiles[0])
        const jsonObj = records.map(item => ({
            indexPlot: item[columnNamesModal.indexPlot],
            userId: item[columnNamesModal.userId],
            firstName: item[columnNamesModal.firstname],
            lastName: item[columnNamesModal.lastName],
            uid: item[columnNamesModal.uid],
            latitude: item[columnNamesModal.Latitude],
            longitude: item[columnNamesModal.Longitude],
            size: item[columnNamesModal.size],
            plotName: item[columnNamesModal.plotName],
            plantName: item[columnNamesModal.plantName],
            plantTypeName: item[columnNamesModal.plantTypeName],
            observationPoint: item[columnNamesModal.observationPoint],
            planNorth: item[columnNamesModal.planNorth],
            planSouth: item[columnNamesModal.planSouth],
            planWest: item[columnNamesModal.planWest],
            planEast: item[columnNamesModal.planEast],
            address: item[columnNamesModal.address],
            createDate: item[columnNamesModal.createDate],
        }))
        const jsonString = JSON.stringify(jsonObj)
        formData.append("json", jsonString)

        const res = await newImportPlot(formData, staffId)

        let json = res.data

        if (json.status === API_RES_STATUS.success) {
            enqueueSnackbar('Improt plot successfully.', { variant: 'success' })
            loadPlot(staffId)
            setRecords([])
            setOpenModal(false)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
            return
        }
    }

    const handleChangePage = (event, newPage) => { setPage(newPage) }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const checkFarmProcessing = (plotId, startDate) => {
        if (startDate) {
            navigation(ROUTE_PATH.AREA_MANAGEMENT_DETAIL.replace(":id", plotId))
        } else {
            enqueueSnackbar("ไม่มีข้อมูลของฟาร์ม กรุณาสร้างฟาร์มให้เสร็จก่อน", { variant: 'error' })
        }
    }

    const onInputSearchKeyDown = (e) => {
        if (e.keyCode === 13) {
            onSearchClick()
        }
    }

    const onSearchClick = () => {
        loadPlot()
    }

    const inputRef = useRef(null)
    const handleClick = () => inputRef.current.click()

    return (
        <>
            <div style={{ margin: 40 }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6}>
                        <OutlinedInput onKeyDown={onInputSearchKeyDown} onChange={(e) => { setSearchKeyword(e.target.value) }} value={searchKeyword} size="small" style={{ width: '50%' }}
                            startAdornment={
                                <InputAdornment position='start'>
                                    <SearchIcon onClick={onSearchClick} />
                                </InputAdornment>
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={OnDownloadPlotTemplateFile} style={{ color: '#12203A', borderColor: '#12203A', borderRadius: 5, marginRight: 16 }} variant="outlined">
                                ดาวน์โหลด เทมเพลต
                            </Button>
                            <Button onClick={() => {
                                setRecords([])
                                setOpenModal(true)
                            }} style={{ color: '#12203A', borderColor: '#12203A', borderRadius: 5, marginRight: 16 }} variant="outlined" >
                                อัพโหลดไฟล์
                            </Button>
                            <Button onClick={() => navigation(ROUTE_PATH.CREATE_AREA_MAP.replace(":id", staffId))} style={{ color: 'white', borderRadius: 5, backgroundColor: '#12203A' }} variant="contained">
                                เพิ่มแปลง
                            </Button>
                        </div>
                    </Grid>
                </Grid>

                <div style={{ marginTop: 20 }}>
                    <Box sx={{ height: '80vh', width: '100%' }}>
                        <StyledDataGrid
                            rows={plots}
                            columns={columns}
                            paginationMode="server"
                            loading={tableLoading}
                            rowCount={tableTotalRow}
                            page={tableCurrentPage}
                            pageSize={tablePageSize}
                            pagination
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            onPageChange={(newPage) => setTableCurrentPage(newPage)}
                            onPageSizeChange={(newPageSize) => setTablePageSize(newPageSize)}
                        />
                    </Box>
                </div>
            </div>

            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <>
                    {records.length > 0 ?
                        <div style={{ marginLeft: '10%', marginRight: '10%', marginTop: '10%', backgroundColor: 'white', padding: 20, maxHeight: '70vh', paddingBottom: 40 }}>
                            <Box display="flex" flexDirection="row-reverse" my={2}>
                                <Button style={{ minWidth: 200 }} onClick={() => clear()} variant='contained' color='error' disabled={records.length === 0}>ยกเลิก</Button>
                                <Button style={{ minWidth: 200, marginRight: 5 }} onClick={() => { uploadNewPlot() }} disabled={disableUploadFile}
                                    variant='contained' color='success'>ตกลง</Button>
                            </Box>

                            <TableContainer className={classes.container} style={{ maxHeight: '50vh' }}>
                                <Table stickyHeader aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            {columnsModal.map((c, ci) =>
                                                <StyledTableCell
                                                    align={c.align}
                                                    style={{ minWidth: c.columnWidth }}
                                                    key={ci}>{c.value}</StyledTableCell>)}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {records.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowi) => {

                                            return <StyledTableRow key={rowi}>
                                                {columnsModal.map((c, ci) => {

                                                    return <StyledTableCell
                                                        align="center"
                                                        key={rowi + '' + ci}>
                                                        <span >{c.value === "date" ? row[c.value] ? dayjs(row[c.value]).format("DD-MM-YYYY HH:mm:ss") + '' : null :
                                                            row[c.value] ? row[c.value] + '' : null
                                                        } </span>
                                                        {!row[c.value + '-valid'] && <><br /><span style={{ color: 'red' }}>{c.errorMessage}</span></>}
                                                    </StyledTableCell>
                                                })}

                                            </StyledTableRow>
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={records.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </div>
                        :
                        <div
                            style={{
                                display: 'flex', flexDirection: 'column', justifyContent: 'center',
                                alignItems: 'center', backgroundColor: 'rgb(245, 245, 245)', cursor: 'pointer',
                                minHeight: '200px', border: '4px dashed gray',
                                marginLeft: '10%', marginRight: '10%', marginTop: '10%'
                            }}
                            onClick={handleClick}
                            dropzone="true"
                            onDragOver={(event) => { onDragOver(event) }}
                            onDrop={(event) => { onDropped(event, false) }}>
                            <input type="file" style={{ display: 'none' }} ref={inputRef} onChange={(event) => { onDropped(event, true) }} />
                            <CloudUploadOutlinedIcon style={{ fontSize: 100, color: 'gray' }} />
                            <span style={{ fontsize: '1.5rem', fontWeight: 'bold' }}>Drop file here</span>
                            <span style={{ fontsize: '1.5rem', fontWeight: 'bold' }}>You have to drop xls file only</span>
                        </div>
                    }
                </>
            </Modal>
        </>
    )
}

const useStyles = makeStyles((theme) => ({

}))

const columnNamesModal = {
    indexPlot: 'index',
    userId: 'user_Id',
    firstname: 'firstName',
    lastName: 'lastName',
    uid: 'uid',
    Latitude: 'latitude',
    Longitude: 'longitude',
    size: 'plan_size(ตารางเมตร)',
    plotName: 'plan_name',
    plantName: 'plant',
    plantTypeName: 'species',

    observationPoint: 'observation_point',
    planNorth: 'plan_North',
    planSouth: 'plan_South',
    planWest: 'plan_West',
    planEast: 'plan_East',

    address: 'address',
    createDate: 'date',
}

const columnsModal = [
    {
        columnWidth: 120,
        value: columnNamesModal.indexPlot,
        errorMessage: 'กรุณากรอกลำดับแปลงที่จะทำการ import',
        align: 'center',
        isValid: row => !isNaN(row[columnNamesModal.indexPlot]) && Number(row[columnNamesModal.indexPlot])
    },
    {
        columnWidth: 120,
        value: columnNamesModal.userId,
        errorMessage: 'กรุณาตรวจสอบรหัสเกษตรกร',
        align: 'center',
        isValid: row => {
            var pattern = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$', 'i')
            return pattern.test(row[columnNamesModal.userId])
        }
    },
    {
        columnWidth: 120,
        value: columnNamesModal.firstname,
        errorMessage: 'กรุณากรอกชื่อเกษตรกร',
        align: 'center',
        isValid: row => row[columnNamesModal.firstname] !== " " && row[columnNamesModal.firstname]
    },
    {
        columnWidth: 120,
        value: columnNamesModal.lastName,
        errorMessage: 'กรุณากรอกนามสกุลเกษตรกร',
        align: 'center',
        isValid: row => row[columnNamesModal.lastName] !== " " && row[columnNamesModal.lastName]
    },
    {
        columnWidth: 120,
        value: columnNamesModal.uid,
        errorMessage: 'กรุณากรอก uid หมายเลขเกษตรกร',
        align: 'center',
        isValid: row => row[columnNamesModal.uid] !== " " && row[columnNamesModal.uid]
    },
    {
        columnWidth: 120,
        value: columnNamesModal.Latitude,
        errorMessage: 'กรุณาตรวจสอบ Latitude',
        align: 'center',
        isValid: row => !isNaN(row[columnNamesModal.Latitude]) && Number(row[columnNamesModal.Latitude])
    },
    {
        columnWidth: 120,
        value: columnNamesModal.Longitude,
        errorMessage: 'กรุณาตรวจสอบ Longitude',
        align: 'center',
        isValid: row => !isNaN(row[columnNamesModal.Longitude]) && Number(row[columnNamesModal.Longitude])
    },
    {
        columnWidth: 120,
        value: columnNamesModal.size,
        errorMessage: 'กรุณาตรวจสอบขนาดพื้นที่',
        align: 'center',
        isValid: row => !isNaN(row[columnNamesModal.size]) && Number(row[columnNamesModal.size])
    },
    {
        columnWidth: 120,
        value: columnNamesModal.plotName,
        errorMessage: 'กรุณากรอกชื่อแปลง',
        align: 'center',
        isValid: row => row[columnNamesModal.plotName] !== " " && row[columnNamesModal.plotName]
    },
    {
        columnWidth: 120,
        value: columnNamesModal.plantName,
        errorMessage: 'กรุณากรอกชนิดพืช',
        align: 'center',
        isValid: row => row[columnNamesModal.plantName] !== " " && row[columnNamesModal.plantName]
    },
    {
        columnWidth: 120,
        value: columnNamesModal.plantTypeName,
        errorMessage: 'กรุณากรอกชนิดพันธุ์',
        align: 'center',
        isValid: row => row[columnNamesModal.plantTypeName] !== " " && row[columnNamesModal.plantTypeName]
    },
    {
        columnWidth: 120,
        value: columnNamesModal.observationPoint,
        errorMessage: 'กรุณากรอกจุดสังเกตุ',
        align: 'center',
        isValid: row => row[columnNamesModal.observationPoint] !== " " && row[columnNamesModal.observationPoint]
    },
    {
        columnWidth: 120,
        value: columnNamesModal.planNorth,
        errorMessage: 'กรุณากรอกชื่อแปลงที่ติดกับทิศเหนือ',
        align: 'center',
        isValid: row => row[columnNamesModal.planNorth] !== " " && row[columnNamesModal.planNorth]
    },
    {
        columnWidth: 120,
        value: columnNamesModal.planSouth,
        errorMessage: 'กรุณากรอกชื่อแปลงที่ติดกับทิศใต้',
        align: 'center',
        isValid: row => row[columnNamesModal.planSouth] !== " " && row[columnNamesModal.planSouth]
    },
    {
        columnWidth: 120,
        value: columnNamesModal.planWest,
        errorMessage: 'กรุณากรอกชื่อแปลงที่ติดกับทิศตะวันออก',
        align: 'center',
        isValid: row => row[columnNamesModal.planWest] !== " " && row[columnNamesModal.planWest]
    },
    {
        columnWidth: 120,
        value: columnNamesModal.planEast,
        errorMessage: 'กรุณากรอกชื่อแปลงที่ติดกับทิศตะวันตก',
        align: 'center',
        isValid: row => row[columnNamesModal.planEast] !== " " && row[columnNamesModal.planEast]
    },
    {
        columnWidth: 120,
        value: columnNamesModal.address,
        errorMessage: 'กรุณากรอกกรอกที่อยู่แปลง',
        align: 'center',
        isValid: row => row[columnNamesModal.address] !== " " && row[columnNamesModal.address]
    },
    {
        columnWidth: 120,
        value: columnNamesModal.createDate,
        errorMessage: 'กรุณาตรวจสอบวันที่สร้างแปลง',
        align: 'center',
        isValid: row => {
            var date = dayjs(row[columnNamesModal.createDate])
            return dayjs(date).isValid() && row[columnNamesModal.createDate]
        }
    },
]


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#12203A",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#D3DCEC",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 1,
    },
}))