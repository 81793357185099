import { Button, CardContent, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Card from '@mui/material/Card'
import TextField from '@mui/material/TextField'
import { useNavigate, useParams } from 'react-router-dom'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { styled } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import ListStaffModal from '../../components/ListStaffModal'
import AddIcon from '@mui/icons-material/Add'
import TeamAdminModal from '../../components/TeamAdminModal'
import { Controller, useForm } from 'react-hook-form'
import TextFieldValidate from '../../components/TextFieldValidate'
import { addEmployeeJoinTeam, createTeam, deleteEmployeeInTeam, getAllEmployeeInTeam, getAllEmployeeTeamLead, getAllEmployeeTeamLeadByTeamId, getTeamById, updateTeam, } from '../../services/team'
import { useSnackbar } from 'notistack'
import { ROUTE_PATH } from '../../constants/paths'
import { getAllEmployeeNotHaveTeam, getAllEmployeeNotHaveTeamByTeamId } from '../../services/employee'
import DeleteIcon from '@mui/icons-material/Delete'
import Empty from '../../components/Empty'
import AlertDeleteModal from '../../components/AlertDeleteModal'
import IconButton from '@mui/material/IconButton'
import AlertConfirmModal from '../../components/AlertConfirmModal'
import { API_RES_STATUS } from '../../constants/apiResponseStatus'
import { getCategoryEmployee } from '../../services/common'
import { ROLES } from '../../constants/enums'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#12203A",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#D3DCEC",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 1,
    },
}))

export default function CreateTeamPage() {
    let { id: teamId } = useParams()
    const classes = useStyles()
    const navigation = useNavigate()
    const { control, handleSubmit, setValue, formState: { errors } } = useForm()
    const { enqueueSnackbar, /*closeSnackbar*/ } = useSnackbar()
    const [openTeamModal, setOpenTeamModal] = useState(false)
    const [openStaffModal, setOpenStaffModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [listTeamLead, setListTeamLead] = useState([])
    const [listTeamLeadFull, setListTeamLeadFull] = useState([])
    const [listEmployee, setListEmployee] = useState([])
    const [listEmployeeFull, setListEmployeeFull] = useState([])
    const [teamLead, setTeamLead] = useState(null)
    const [listEmployeeInTeam, setListEmployeeInTeam] = useState([])

    const [openModal, setOpenModal] = useState(false)
    const [idDelete, setIdDelete] = useState(null)

    const [openConfirmModal, setOpenConfirmModal] = useState(false)
    const [idConfirm, setIdConfirm] = useState(null)

    const [page, setPage] = useState("")
    const [search, setSearch] = useState(null)
    const [searchStaff, setSearchStaff] = useState(null)

    useEffect(() => {
        if (teamId) {
            loadTeamDetail()
            loadListEmployeeฺByTeamId(teamId)
            loadListTeamLeadByTeamId(teamId)
            loadListEmployeeInTeam()
        } else {
            loadListEmployee()
            loadListTeamLead()
        }
    }, [])

    useEffect(() => {

    }, [listEmployeeInTeam, listTeamLead, listEmployee])

    useEffect(() => {
        if (search != null && search != "") {
            let textSearch = setTimeout(async () => {
                try {
                    await loadListTeamLeadAfterSearch(search)
                } catch (error) {
                    enqueueSnackbar(error, { variant: 'error' })
                }
            }, 1000)

            return () => clearTimeout(textSearch)

        } else {
            let textSearch = setTimeout(async () => {
                try {
                    if (teamId) {
                        await loadListTeamLeadByTeamId(teamId)
                    } else {
                        await loadListTeamLead()
                    }

                } catch (error) {
                    enqueueSnackbar(error, { variant: 'error' })
                }
            }, 1000)

            return () => clearTimeout(textSearch)
        }

    }, [search])

    useEffect(() => {
        if (searchStaff != null && searchStaff != "") {
            let textSearch = setTimeout(async () => {
                try {
                    await loadListStaffAfterSearch(searchStaff)
                } catch (error) {
                    enqueueSnackbar(error, { variant: 'error' })
                }
            }, 1000)

            return () => clearTimeout(textSearch)

        } else {
            let textSearch = setTimeout(async () => {
                try {
                    if (teamId) {
                        await loadListEmployeeฺByTeamId(teamId)
                    } else {
                        await loadListEmployee()
                    }

                } catch (error) {
                    enqueueSnackbar(error, { variant: 'error' })
                }
            }, 1000)

            return () => clearTimeout(textSearch)
        }

    }, [searchStaff])

    const loadTeamDetail = async () => {
        setLoading(true)
        const res = await getTeamById(teamId)
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            let data = json.data
            setValue('name', data.name)
            if (data.employeeFirstName != null && data.employeeLastName != null) {
                var name = `${data.employeeFirstName}${" "}${data.employeeLastName}`
            } else {
                enqueueSnackbar(json.message, { variant: 'error' })
            }
            setTeamLead(name)
            setIdConfirm(data.employeeId)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
        setLoading(false)
    }

    const loadListTeamLead = async () => {
        setLoading(true)
        const axiosRes = await getCategoryEmployee({ filterRole: ROLES.TeamLead })
        const { data, status, message } = JSON.parse(axiosRes.data)
        if (status === API_RES_STATUS.success) {
            setListTeamLead(data)
            setListTeamLeadFull(data)
        } else {
            enqueueSnackbar(message, { variant: 'error' })
        }

        setLoading(false)
    }

    const loadListTeamLeadAfterSearch = async (search) => {
        let text = search.toLowerCase()
        let listTeamLeadAfterFilter = listTeamLeadFull.filter((Item) => {

            let role = teamId ? Item.roleName : Item.enterprisePositionName

            if (Item?.enterpriseName.toLowerCase().match(text)
                || Item?.firstName.toLowerCase().match(text)
                || Item?.lastName.toLowerCase().match(text)
                || role?.toLowerCase().match(text)
            )
                return Item
        })

        setListTeamLead(listTeamLeadAfterFilter)
    }

    const loadListStaffAfterSearch = async (search) => {
        let text = search.toLowerCase()
        let listEmployeeAfterFilter = listEmployeeFull.filter((Item) => {
            if (Item.firstName.toLowerCase().match(text)
                || Item.lastName.toLowerCase().match(text)
                || Item.positionName.toLowerCase().match(text)
            )
                return Item
        })

        setListEmployee(listEmployeeAfterFilter)
    }

    const loadListTeamLeadByTeamId = async (id) => {
        setLoading(true)
        const res = await getAllEmployeeTeamLeadByTeamId(id)
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            let data = json.data
            setListTeamLead(data)
            setListTeamLeadFull(data)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
        setLoading(false)
    }

    const loadListEmployee = async () => {
        setLoading(true)
        const res = await getAllEmployeeNotHaveTeam()
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            let data = json.data
            setListEmployee(data)
            setListEmployeeFull(data)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
        setLoading(false)
    }

    const loadListEmployeeฺByTeamId = async (id) => {
        setLoading(true)
        const res = await getAllEmployeeNotHaveTeamByTeamId(id)
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            let data = json.data
            setListEmployee(data)
            setListEmployeeFull(data)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
        setLoading(false)
    }

    const loadListEmployeeInTeam = async () => {
        setLoading(true)
        const res = await getAllEmployeeInTeam(teamId)
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            let data = json.data
            setListEmployeeInTeam(data)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
        setLoading(false)
    }

    const onSubmitForm = async (data) => {
        if (teamId) {
            await onEdit(teamId, data)
        } else {
            await onCreate(data)
        }
    }

    const onCreate = async (data) => {
        // console.log("onCreate")
        // console.log("teamLead Id >>", idConfirm)
        // console.log("team name >>", data.name)
        if (idConfirm == null) {
            enqueueSnackbar("กรุณาเลือกผู้ดูแลทีม", { variant: 'error' })
        }

        const res = await createTeam({ name: data.name, employeeId: idConfirm })
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            enqueueSnackbar('Create team successfully.', { variant: 'success' })
            navigation(-1)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
    }

    const onEdit = async (id, data) => {
        const res = await updateTeam({ id: id, name: data.name, employeeId: idConfirm })
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            loadTeamDetail()
            loadListTeamLeadByTeamId(json.data)
            loadListEmployeeฺByTeamId(json.data)
            loadListEmployeeInTeam()
            enqueueSnackbar('Update team successfully.', { variant: 'success' })
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
    }

    const onClickTeamOpenModal = () => {
        setOpenTeamModal(true)
    }

    const onClickTeamCancelModal = () => {
        setOpenTeamModal(false)
    }

    const onClickTeamSubmitModal = ({ id, page, name }) => {
        setOpenTeamModal(false)
        setIdConfirm(id)
        setTeamLead(name)
        setPage(page)
        setOpenConfirmModal(true)
    }

    const onTeamSubmitModal = async (id) => {

        // const resTeamLead = await updateTeamLead({ teamId, employeeId: id })
        // const resMember = await addEmployeeJoinTeam({ teamId, employeeId: id })

        // let jsonTeamLead = JSON.parse(resTeamLead.data)
        // if (jsonTeamLead.status === API_RES_STATUS.success) {
        //     enqueueSnackbar('Update team successfully.', { variant: 'success' })
        //     loadTeamDetail(teamId)
        // } else {
        //     enqueueSnackbar(jsonTeamLead.message, { variant: 'error' })
        // }

        // let jsonMember = JSON.parse(resMember.data)
        // if (jsonMember.status === API_RES_STATUS.success) {
        //     enqueueSnackbar('Add teamLead join the team successfully.', { variant: 'success' })
        //     loadTeamDetail(teamId)
        // } else {
        //     enqueueSnackbar(jsonMember.message, { variant: 'error' })
        // }

        // loadTeamDetail()
        // loadListTeamLead()
        // loadListEmployee()
        // loadListEmployeeInTeam()
        setOpenConfirmModal(false)
        // setIdConfirm(null)

    }

    const onClickStaffOpenModal = () => {
        setOpenStaffModal(true)
    }

    const onClickStaffCancelModal = () => {
        setOpenStaffModal(false)
    }

    const onClickStaffSubmitModal = async ({ id, page }) => {
        setOpenStaffModal(false)
        setPage(page)
        const res = await addEmployeeJoinTeam({ teamId, employeeId: id })
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            enqueueSnackbar('Add employee join the team successfully.', { variant: 'success' })
            loadTeamDetail()
            loadListEmployeeInTeam()
            loadListTeamLeadByTeamId(teamId)
            loadListEmployeeฺByTeamId(teamId)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
        setPage("")
        setOpenConfirmModal(false)
    }

    const onStaffSubmitModal = async (id) => {
        // const res = await addEmployeeJoinTeam({ teamId, employeeId: id })
        // console.log("res", res)
        // let json = JSON.parse(res.data)
        // if (json.status === API_RES_STATUS.success) {
        //     enqueueSnackbar('Add employee join the team successfully.', { variant: 'success' })
        //     loadTeamDetail()
        //     loadListEmployeeInTeam()
        //     loadListTeamLeadByTeamId(teamId)
        //     loadListEmployeeฺByTeamId(teamId)
        // } else {
        //     enqueueSnackbar(json.message, { variant: 'error' })
        // }
        // setOpenConfirmModal(false)
        // setIdConfirm(null)
        // setPage("")
    }

    const OnClickSubmitModal = async (id) => {
        const res = await deleteEmployeeInTeam({ teamId, employeeId: id })
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            enqueueSnackbar('Delete employee in team successfully.', { variant: 'success' })
            loadTeamDetail(teamId)
            loadListEmployeeInTeam()
            loadListTeamLeadByTeamId(teamId)
            loadListEmployeeฺByTeamId(teamId)
            setOpenModal(false)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
        setOpenStaffModal(false)
    }

    const OnClickInModal = () => {
        setOpenModal(false)
        setIdDelete(null)
    }

    const OnClickDelete = (id) => {
        setIdDelete(id)
        setOpenModal(true)
    }

    const OnClickSubmitConfirmModal = async (id) => {
        if (page === "team") {
            onTeamSubmitModal(id)
        } else {
            onStaffSubmitModal(id)
        }
    }

    const OnClickInConfirmModal = () => {
        setOpenConfirmModal(false)
        setIdConfirm(null)
    }

    const columns = [
        { label: "ลำดับ", minWidth: 50, align: 'left', },
        { label: "ชื่อ-นามสกุล", minWidth: 120, align: 'center' },
        { label: "ตำแหน่ง", minWidth: 120, align: 'center' },
        { label: "", minWidth: 120, align: 'center' },
    ]

    return (
        <>
            <form onSubmit={handleSubmit(onSubmitForm)}>
                <div style={{ margin: 40 }}>
                    <Grid container rowSpacing={1} >
                        <Grid item xs={6}>
                            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button onClick={() => navigation(ROUTE_PATH.TEAM)} style={{ width: '20%', borderRadius: 7, borderWidth: 1.5, marginRight: 20, color: '#12203A', borderColor: '#12203A' }} variant="outlined">
                                    ยกเลิก
                                </Button>
                                <Button type='submit' style={{ width: '20%', color: 'white', borderRadius: 7, backgroundColor: '#12203A' }} variant="contained">
                                    บันทึก
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                    <div style={{ marginTop: 30 }}>
                        <h3>สร้างทีม</h3>
                        <Card >
                            <CardContent style={{ margin: 20 }}>
                                <Grid container  >
                                    <Grid item xs={6}>
                                        <Grid container  >
                                            <Grid item mt={3} xs={4}>
                                                ชื่อทีม
                                            </Grid>
                                            <Grid item mt={2} xs={8}>
                                                <Controller style={{ marginTop: 0 }}
                                                    control={control}
                                                    rules={{
                                                        required: { value: true, message: "name team is required." },
                                                        min: { value: 0, message: "name team much be greater than or equal 0." }
                                                    }}
                                                    name="name"
                                                    render={o => <TextFieldValidate vo={o} type='text' errorMessage={errors.name?.message} />} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </div>
                    {/* {teamId !== "null"
                        ? <div style={{ marginTop: 40 }}>
                            <h3>ผู้ดูแลทีม</h3>
                            <Card >
                                <CardContent style={{ margin: 20 }}>
                                    <Grid container  >
                                        <Grid xs={6}>
                                            <Grid container  >
                                                <Grid mt={3} xs={4}>
                                                    เลือกผู้ดูแลทีม
                                                </Grid>
                                                <Grid mt={2} xs={8}>
                                                    <TextField value={teamLead} onClick={() => onClickTeamOpenModal()} disabled style={{ width: '100%' }} size='small' id="outlined-basic" variant="outlined" />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </div>
                        : <div style={{ marginTop: 40 }}>
                            <h3>ผู้ดูแลทีม</h3>
                            <Card >
                                <CardContent style={{ margin: 20 }}>
                                    กรุณาสร้างทีมก่อน
                                </CardContent>
                            </Card>
                        </div>
                    } */}
                    <div style={{ marginTop: 40 }}>
                        <h3>ผู้ดูแลทีม</h3>
                        <Card >
                            <CardContent style={{ margin: 20 }}>
                                <Grid container  >
                                    <Grid item xs={6}>
                                        <Grid container  >
                                            <Grid item mt={3} xs={4}>
                                                เลือกผู้ดูแลทีม
                                            </Grid>
                                            <Grid item mt={2} xs={8}>
                                                <TextField value={teamLead == null ? "เลือกผู้ดูแลทีม" : teamLead} onClick={() => onClickTeamOpenModal()} style={{ width: '100%' }} size='small' id="outlined-basic" variant="outlined" />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </div>
                    {teamId
                        ?
                        <div style={{ marginTop: 40 }}>
                            <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: 10 }}>
                                <h3>สมาชิกทีม</h3>
                                <Button onClick={() => onClickStaffOpenModal()} style={{ color: 'white', height: 40, borderRadius: 5, backgroundColor: '#12203A', marginLeft: 20, marginTop: 13 }} variant="contained">
                                    <AddIcon /> เพิ่ม สมาชิก
                                </Button>
                            </div>
                            <TableContainer className={classes.container}>
                                <Table stickyHeader aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column) => (
                                                <StyledTableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth }}
                                                >
                                                    {column.label}
                                                </StyledTableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            listEmployeeInTeam.map((row, i) => {
                                                return (
                                                    <StyledTableRow key={i}>
                                                        <StyledTableCell >{i + 1}</StyledTableCell>
                                                        <StyledTableCell align="center" >{row.firstName} {row.lastName}</StyledTableCell>
                                                        <StyledTableCell align="center" >{row.position}</StyledTableCell>
                                                        <StyledTableCell align="center">
                                                            <IconButton aria-label="delete" size="small" color='error' onClick={() => OnClickDelete(row.id)}  >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                                {listEmployeeInTeam.length === 0 ? <div style={{ display: 'flex', justifyContent: 'center' }}><Empty /></div> : null}
                            </TableContainer>
                        </div>
                        :
                        <div style={{ marginTop: 40 }}>
                            <h3>สมาชิกทีม</h3>
                            <Card >
                                <CardContent style={{ margin: 20 }}>
                                    กรุณาสร้างทีมก่อน
                                </CardContent>
                            </Card>
                        </div>
                    }
                    <TeamAdminModal data={listTeamLead} open={openTeamModal} onClickCancelModal={onClickTeamCancelModal} onClickSubmitModal={onClickTeamSubmitModal} onSreach={setSearch} />
                    <ListStaffModal data={listEmployee} open={openStaffModal} onClickCancelModal={onClickStaffCancelModal} onClickSubmitModal={onClickStaffSubmitModal} onSreach={setSearchStaff} />
                </div>
            </form>
            <AlertDeleteModal id={idDelete} open={openModal} OnClickInModal={OnClickInModal} OnClickSubmitModal={OnClickSubmitModal} />
            <AlertConfirmModal header='ยืนยันการเพิ่ม' id={idConfirm} open={openConfirmModal} OnClickInModal={OnClickInConfirmModal} OnClickSubmitModal={OnClickSubmitConfirmModal} />
        </>

    )
}

const useStyles = makeStyles((theme) => ({

}))