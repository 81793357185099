import axios from "axios"
import { ApiUrl } from "../constants/api_url"
import AxiosHelper from "../helper/axiosHelper"

export default async function getAllTeam({ currentPage = 1, pageSize = 10, filterEnterpriseId, searchKeyword }) {
    let params = {
        pageSize, currentPage, searchKeyword
    }
    if (filterEnterpriseId.length > 0) {
        params.filterEnterpriseId = filterEnterpriseId
    }
    let url = `${ApiUrl.TEAM}`
    if (Object.keys(params).length > 0) {
        params = new URLSearchParams(params)
        url = `${url}?${params.toString()}`
    }
    return await AxiosHelper.get(url)
}

export async function getTeamById(id) {
    return await AxiosHelper.get(ApiUrl.TEAM + "/" + id)
}

export async function createTeam({ name, employeeId }) {
    return await AxiosHelper.post(ApiUrl.TEAM, { name, employeeId })
}

export async function updateTeam({ id, name, employeeId }) {
    return await AxiosHelper.put(ApiUrl.TEAM + "/" + id, { id, name, employeeId })
}

export async function updateTeamAreaDetail({ id, name, employeeId }) {
    return await AxiosHelper.put(ApiUrl.TEAM + "/updateTeamAreaDetail/" + id, { id, name, employeeId })
}

export async function getAllEmployeeTeamLead() {
    return await AxiosHelper.get(ApiUrl.TEAM + "/" + "getAllTeamLead")
}

export async function getAllEmployeeTeamLeadByTeamId(id) {
    return await AxiosHelper.get(ApiUrl.TEAM + "/" + "getAllTeamLeadByTeamId" + "/" + id)
}

// export async function updateTeamLead({ teamId, employeeId }) {
//     return await AxiosHelper.put(ApiUrl.TEAM + "/updateTeamLead/" + teamId, { teamId, employeeId })
// }

export async function addEmployeeJoinTeam({ teamId, employeeId }) {
    return await AxiosHelper.put(ApiUrl.TEAM + "/addEmployeeJoinTeam/" + teamId, { teamId, employeeId })
}

export async function getAllEmployeeInTeam(id) {
    return await AxiosHelper.get(ApiUrl.TEAM + "/getAllEmployeeInTeam/" + id)
}

export async function deleteEmployeeInTeam({ teamId, employeeId }) {
    return await AxiosHelper.put(ApiUrl.TEAM + "/deleteEmployeeInTeam/" + teamId, { teamId, employeeId })
}

export async function getAllEmployeeByTeamId(id) {
    return await AxiosHelper.get(ApiUrl.TEAM + "/GetAllEmployeeByTeamId/" + id)
}

export async function deleteTeam(id) {
    return await AxiosHelper.delete(ApiUrl.TEAM + "/" + id)
}

export async function newImportTeam(formData) {

    return await AxiosHelper.post(ApiUrl.TEAM + "/import", {}, {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data: formData
    })
}