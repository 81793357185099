import React, { useState, useEffect, useContext } from 'react'
import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import AddIcon from '@mui/icons-material/Add'
import StandartValue from '../../components/StandartValue'
import { useNavigate, useParams } from 'react-router-dom'
import getAllMeasurementLength from '../../services/measurement'
import Skeleton from '@mui/material/Skeleton'
import { Controller, useForm } from 'react-hook-form'
import TextFieldValidate from '../../components/TextFieldValidate'
import { createStandard, deleteStandardValue, getAllStandardById, updateStandard } from '../../services/standard'
import { useSnackbar } from 'notistack'
import AlertDeleteModal from '../../components/AlertDeleteModal'
import Empty from '../../components/Empty'
import { API_RES_STATUS } from '../../constants/apiResponseStatus'
import { AppContext } from '../../contexts/AppContext'
import { ROLES } from '../../constants/enums'
import getAllEnterprise from '../../services/enterprise'
import getAllPlant from '../../services/plant'
import getAllPlantTypeByPlantId from '../../services/plantType'
import useCategoryEnterprise from '../../reactHook/useCategoryEnterprise'

export default function CreateStandardPage() {
    const { employee = {} } = useContext(AppContext)
    let { id: standardId } = useParams()
    const className = useStyles()
    const navigation = useNavigate()
    const [standardValue, setStandartValue] = useState([
        { value: "", measurementId: 0, days: 0, note: "" }
    ])
    const [loading, setLoading] = useState(false)
    const [listMeasurement, setListMeasurement] = useState([])
    const { enterprises } = useCategoryEnterprise()

    const { control, handleSubmit, setValue, formState: { errors } } = useForm()
    const { enqueueSnackbar, /*closeSnackbar*/ } = useSnackbar()
    const [openModal, setOpenModal] = useState(false)
    const [idDelete, setIdDelete] = useState(null)
    const [isEdit, setIsEdit] = useState(standardId ? true : false)
    const [idIndex, setIdIndex] = useState()

    const [listPlant, setListPlant] = useState([])
    const [listPlantType, setListPlantType] = useState([])

    const [valueEnterpriseId, setValueEnterpriseId] = useState('')
    const [plantSelected, setPlantSelected] = useState()
    const [plantTypeSelected, setPlantTypeSelected] = useState()

    useEffect(() => {
        loadMeasurement()
        loadPlant()
        if (standardId) {
            //load data standard
            loadStandardValue()
        }

    }, [])

    const loadMeasurement = async () => {
        setLoading(true)
        const res = await getAllMeasurementLength()
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            let data = json.data
            setListMeasurement(data)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
        setLoading(false)
    }

    const loadStandardValue = async () => {
        setLoading(true)
        const res = await getAllStandardById(standardId)
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            let data = json.data
            setValue('name', data.name)
            setValueEnterpriseId(data.enterpriseId)
            setPlantSelected(data.plantId)
            loadPlant(data.enterpriseId)
            loadPlantType(data.plantId)
            setPlantTypeSelected(data.plantTypeId)
            let listStandardValue = data.listStandardValue
            setStandartValue([...listStandardValue])
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
        setLoading(false)
    }

    const loadPlant = async (enterpriseId) => {
        const axiosRes = await getAllPlant({ filterEnterpriseId: enterpriseId })
        const { data, status, message = "" } = JSON.parse(axiosRes.data)
        if (status === API_RES_STATUS.success) {
            const { listData } = data
            setListPlant(listData)
        } else {
            enqueueSnackbar(message, { variant: 'error', autoHideDuration: 10000 })
        }
    }

    const loadPlantType = async (plantId) => {
        try {
            const res = await getAllPlantTypeByPlantId(plantId)
            if (res.status) {
                let json = JSON.parse(res.data)
                setListPlantType(json.data)
            } else {
                enqueueSnackbar(res.message, { variant: 'error' })
            }
        } catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' })
        }
    }

    const onSubmitForm = async (data) => {
        if (standardId) {
            await onEdit(
                standardId, data.name, standardValue
            )
        } else {
            await onCreate(data.name, standardValue)
        }
    }

    const onCreate = async (name, listStandardValue) => {
        var enterpriseId = employee.role === ROLES.AdminLing ? valueEnterpriseId : employee.enterpriseId
        const res = await createStandard({
            name, listStandardValue, enterpriseId: enterpriseId, plantTypeId: plantTypeSelected
        })
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            enqueueSnackbar('Create Standard successfully.', { variant: 'success' })
            navigation(-1)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
    }

    const onEdit = async (id, name, listStandardValue) => {
        let newValue = [...listStandardValue]
        for (let i = 0; i < newValue.length; i++) {
            newValue[i].value = String(newValue[i].value)
            newValue[i].days = String(newValue[i].days)
        }
        var enterpriseId = employee.role === ROLES.AdminLing ? valueEnterpriseId : employee.enterpriseId

        const res = await updateStandard({
            id, name, listStandardValue, enterpriseId: enterpriseId, plantTypeId: plantTypeSelected
        })
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            enqueueSnackbar('Update Standard successfully.', { variant: 'success' })
            navigation(-1)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
    }

    const addStandartValue = () => {
        let obj = { value: "", measurementId: 0, days: 0, note: "" }
        var newData = [...standardValue, obj]
        setStandartValue(newData)

        //todo update
    }

    const removeStandartValue = (index, standardValueId) => {

        if (standardValueId !== undefined) {
            setIdDelete(standardValueId)
            setOpenModal(true)
            setIdIndex(index)
        } else {
            let newValue = [...standardValue]
            newValue.splice(index, 1)
            setStandartValue(newValue)
        }
    }

    const onSetStandartValue = (index, id, inputStandardValue, measurementId, inputDays, inputNote) => {
        // console.log("onSetStandartValue index>>>" + index)
        // console.log("onSetStandartValue inputStandardValue>>>" + inputStandardValue)
        // console.log("onSetStandartValue inputDays>>>" + inputDays)
        // console.log("onSetStandartValue measurementId>>>" + measurementId)
        // console.log("onSetStandartValue inputNote>>>" + inputNote)

        let newValue = [...standardValue]
        if (standardId) {
            newValue[index].id = id
        }
        newValue[index].value = String(inputStandardValue)
        newValue[index].measurementId = measurementId
        newValue[index].days = String(inputDays)
        newValue[index].note = inputNote
        setStandartValue(newValue)
    }

    const OnClickSubmitModal = async (id) => {
        const res = await deleteStandardValue(id)
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            enqueueSnackbar('Delete standardValue successfully.', { variant: 'success' })
            setIdDelete(null)
            loadStandardValue()
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
        setOpenModal(false)
    }

    const OnClickInModal = () => {
        setOpenModal(false)
        setIdDelete(null)
    }

    const loadingPanel = <div style={{ marginRight: 10, marginLeft: 10 }}>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Skeleton variant="rect" height={50} width={"100%"} />
        </Grid>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="start"
            alignItems="center"
        >
            <Grid item xs={3} >
                <Skeleton variant="rect" height={50} width={"80%"} />
            </Grid>
            <Grid item xs={9} >
                <Skeleton variant="rect" height={50} width={"100%"} />
            </Grid>
        </Grid>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="start"
            alignItems="center"
        >
            <Grid item xs={3} >
                <Skeleton variant="rect" height={50} width={"80%"} />
            </Grid>
            <Grid item xs={9} >
                <Skeleton variant="rect" height={50} width={"100%"} />
            </Grid>
        </Grid>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="start"
            alignItems="center"
        >
            <Grid item xs={3} >
                <Skeleton variant="rect" height={50} width={"80%"} />
            </Grid>
            <Grid item xs={9} >
                <Skeleton variant="rect" height={50} width={"100%"} />
            </Grid>
        </Grid>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="start"
            alignItems="center"
        >
            <Grid item xs={3} >
                <Skeleton variant="rect" height={50} width={"80%"} />
            </Grid>
            <Grid item xs={9} >
                <Skeleton variant="rect" height={50} width={"100%"} />
            </Grid>
        </Grid>
    </div>

    return (
        <>
            <div style={{ margin: 40 }}>
                {
                    loading
                        ? loadingPanel
                        : <>
                            <form onSubmit={handleSubmit(onSubmitForm)}>
                                <Grid container  >
                                    <Grid item xs={12}>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Button onClick={() => { navigation(-1) }}
                                                style={{
                                                    width: '10%', color: '#12203A', borderRadius: 10, marginRight: 20,
                                                    backgroundColor: 'white',
                                                }} variant="contained"
                                            >
                                                ยกเลิก
                                            </Button>
                                            <Button type='submit' onClick={() => { }}
                                                style={{
                                                    width: '10%', color: 'white',
                                                    borderRadius: 10, backgroundColor: '#12203A'
                                                }}
                                                variant="contained"
                                            >
                                                บันทึก
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>

                                <Paper elevation={6} className={className.borderRadius10}>
                                    <div
                                        style={{
                                            flexDirection: 'row', display: 'flex',
                                            alignItems: 'center', marginBottom: 30
                                        }}>
                                        <Typography component="h6" variant="h6" style={{ marginRight: 20 }} >
                                            ชื่อค่ามาตรฐาน
                                        </Typography>
                                        <div style={{ width: '50%' }}>
                                            <Controller
                                                control={control}
                                                rules={{
                                                    required: { value: true, message: "name is required." },
                                                    min: { value: 0, message: "name much be greater than or equal 0." }
                                                }}
                                                name="name"
                                                render={o => <TextFieldValidate vo={o} type='text' errorMessage={errors.name?.message} />} />
                                        </div>
                                    </div>
                                    {employee.role === ROLES.AdminLing &&
                                        <div style={{
                                            flexDirection: 'row', display: 'flex',
                                            alignItems: 'center', marginBottom: 30
                                        }}>
                                            <Typography component="h6" variant="h6" style={{ marginRight: 20 }} >
                                                บริษัท
                                            </Typography>
                                            <FormControl size="small" sx={{ m: 1, minWidth: 220 }}>
                                                <InputLabel>เลือกบริษัท</InputLabel>
                                                <Select
                                                    label="เลือกบริษัท"
                                                    value={valueEnterpriseId}
                                                    onChange={(e) => {
                                                        setValueEnterpriseId(e.target.value)
                                                        loadPlant(e.target.value)
                                                    }}
                                                >
                                                    {enterprises.map((e) => {
                                                        return <MenuItem key={`select-enterprise-${e.id}`} value={e.id}>{e.name}</MenuItem>
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    }
                                    <div
                                        style={{
                                            flexDirection: 'row', display: 'flex',
                                            alignItems: 'center', marginBottom: 30
                                        }}>
                                        <Typography component="h6" variant="h6" style={{ marginRight: 20 }} >
                                            ชนิดพืช
                                        </Typography>
                                        <div style={{ width: '30%' }}>
                                            <FormControl style={{ width: '80%', marginBottom: 10 }}>
                                                <Select
                                                    size="small"
                                                    value={plantSelected ?? false}
                                                    onChange={e => {
                                                        setPlantSelected(e.target.value)
                                                        loadPlantType(e.target.value)
                                                    }}
                                                // error={errors.enterprisePositionId?.message}
                                                // helperText={errors.enterprisePositionId?.message}
                                                >
                                                    <MenuItem value={false} disabled>
                                                        <em>เลือกพืช</em>
                                                    </MenuItem>
                                                    {listPlant.map((row, i) => {
                                                        return <MenuItem key={i} value={row.id}>{row.name}</MenuItem>
                                                    })}
                                                </Select>
                                                {/* {plantSelected ? <FormHelperText error>กรอกข้อมูล ชนิดพืช</FormHelperText> : ""} */}
                                            </FormControl>
                                        </div>
                                        <Typography component="h6" variant="h6" style={{ marginRight: 20, }} >
                                            พันธ์พืช
                                        </Typography>
                                        <div style={{ width: '30%' }}>
                                            <FormControl style={{ width: '80%', marginBottom: 10 }}>
                                                <Select
                                                    size="small"
                                                    value={plantTypeSelected ?? false}
                                                    onChange={e => {
                                                        setPlantTypeSelected(e.target.value)
                                                    }}
                                                // error={errors.enterprisePositionId?.message}
                                                // helperText={errors.enterprisePositionId?.message}
                                                >
                                                    <MenuItem value={false} disabled>
                                                        <em>เลือกพันธ์พืช</em>
                                                    </MenuItem>
                                                    {listPlantType.map((row, i) => {
                                                        return <MenuItem key={i} value={row.id}>{row.name}</MenuItem>
                                                    })}
                                                </Select>
                                                {/* {errors.enterprisePositionId?.message ? <FormHelperText error>{errors.enterprisePositionId?.message}</FormHelperText> : ""} */}
                                            </FormControl>
                                        </div>
                                    </div>



                                    <div style={{
                                        flexDirection: 'row', display: 'flex',
                                        alignItems: 'center', marginBottom: 20
                                    }}>
                                        <Typography component="h6" variant="h6" style={{ marginRight: 20 }} >
                                            กำหนดค่าและช่วงเวลา
                                        </Typography>
                                    </div>

                                    <div style={{
                                        flexDirection: 'row', display: 'flex',
                                        alignItems: 'center', marginBottom: 20,
                                        width: '100%', marginLeft: 40, textAlign: 'center'
                                    }}>
                                        <Typography component="h6" variant="h6" style={{ flex: 2 }} >
                                            รายละเอียด
                                        </Typography>
                                        <Typography component="h6" variant="h6" style={{ flex: 1 }} >
                                            หน่วย
                                        </Typography>
                                        <Typography component="h6" variant="h6" style={{ flex: 2 }} >
                                            ช่วงเวลา (วัน)
                                        </Typography>
                                        <Typography component="h6" variant="h6" style={{ flex: 2, marginRight: 80 }} >
                                            คำแนะนำเมื่อไม่ผ่านเกณฑ์
                                        </Typography>
                                    </div>

                                    {standardValue.length
                                        ? standardValue.map((v, i) => {
                                            return <StandartValue key={i} onSetValue={onSetStandartValue} isEdit={isEdit}
                                                onDelete={removeStandartValue} index={i} standard={v.value} id={standardId ? v.id : null}
                                                measurementId={v.measurementId} days={v.days} note={v.note} listMeasurement={listMeasurement} />
                                        })
                                        : <div style={{
                                            flexDirection: 'row', display: 'flex',
                                            alignItems: 'center', width: '100%', marginLeft: 180, marginTop: -50
                                        }}>
                                            <Empty />
                                        </div>
                                    }

                                    <div style={{
                                        flexDirection: 'row', display: 'flex', justifyContent: 'center',
                                        alignItems: 'center', marginBottom: 20, width: '100%',
                                    }}>
                                        <Button size='large'
                                            onClick={() => addStandartValue()}
                                            style={{ width: '30%', color: 'white', backgroundColor: '#12203A', }}
                                            startIcon={<AddIcon />}>
                                            เพิ่มค่า
                                        </Button>
                                    </div>

                                </Paper>
                            </form>
                        </>}
            </div >
            <AlertDeleteModal id={idDelete} open={openModal} OnClickInModal={OnClickInModal} OnClickSubmitModal={OnClickSubmitModal} />
        </>
    )
}


const useStyles = makeStyles({
    borderRadius10: {
        borderRadius: 10,
        marginTop: 20,
        padding: 50
    },
    divLine: {
        width: 1, backgroundColor: '#12203A'
    },
    lableRow: {
        marginBottom: 10, flexDirection: 'row', display: 'flex', fontWeight: 'bold'
    }
})