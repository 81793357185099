import { Axios } from "axios"
import { ApiUrl } from "../constants/api_url"
import AxiosHelper from "../helper/axiosHelper"

export default async function getAllArea({ pageSize = 10, currentPage = 1, filterEnterpriseId = '', searchKeyword = '' }) {
    let params = {
        pageSize, currentPage
    }
    if (filterEnterpriseId.length > 0) {
        params.filterEnterpriseId = filterEnterpriseId
    }
    if (searchKeyword.length > 0) {
        params.searchKeyword = searchKeyword
    }
    let url = `${ApiUrl.AREA}`
    if (Object.keys(params).length > 0) {
        params = new URLSearchParams(params)
        url = `${url}?${params.toString()}`
    }
    return await AxiosHelper.get(url)
}

export async function getAreaById(id) {
    return await AxiosHelper.get(ApiUrl.AREA + "/" + id)
}

export async function createArea({ name, defaultStandardId, employeeId }) {
    return await AxiosHelper.post(ApiUrl.AREA, { name, defaultStandardId, employeeId })
}

export async function updateArea({ id, name, employeeId, defaultStandardId }) {
    return await AxiosHelper.put(ApiUrl.AREA + "/" + id, { name: name, employeeId: employeeId, defaultStandardId: defaultStandardId })
}

export async function getListTeamForAssignToArea({ filterEnterpriseId = "" }) {
    return await AxiosHelper.get(ApiUrl.AREA + "/GetListTeamForAssignToArea/" + filterEnterpriseId)
}

export async function AddTeamInArea({ areaId, teamId }) {
    return await AxiosHelper.put(ApiUrl.AREA + "/AddTeamInArea/" + areaId, { areaId: areaId, teamId: teamId })
}


