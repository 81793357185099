// const LING_MASTER_API_URL = `http://localhost:5281/`
const LING_MASTER_API_URL = `https://api.ling.nanth.cc/`

// const LING_FILE_API_URL = 'http://localhost:5139'
const LING_FILE_API_URL = 'https://fileupload.ling.nanth.cc'

const ADMIN_AREA = `admin`
const ACCOUNT_AREA = `account`
export const EXPORT_DATA_API_URL = `${LING_MASTER_API_URL}export-data`

export const ApiUrl = {

    ENTERPRISE: LING_MASTER_API_URL + `${ADMIN_AREA}/enterprise`,
    EMPLOYEE: LING_MASTER_API_URL + `${ADMIN_AREA}/employee`,
    TEAM: LING_MASTER_API_URL + `${ADMIN_AREA}/team`,
    POSITION: LING_MASTER_API_URL + `${ADMIN_AREA}/position`,
    ENTERPRISE_POSITION: LING_MASTER_API_URL + `${ADMIN_AREA}/enterpriseposition`,
    PLANT: LING_MASTER_API_URL + `${ADMIN_AREA}/plant`,
    MEASUREMENT: LING_MASTER_API_URL + `${ADMIN_AREA}/measurement`,
    STANDARD: LING_MASTER_API_URL + `${ADMIN_AREA}/standard`,
    AREA: LING_MASTER_API_URL + `${ADMIN_AREA}/area`,
    SCHEDULE: LING_MASTER_API_URL + `${ADMIN_AREA}/schedule`,
    PLOT: LING_MASTER_API_URL + `${ADMIN_AREA}/plot`,
    FARM: LING_MASTER_API_URL + `${ADMIN_AREA}/farm`,
    ACCOUNT_EMPLOYEE: LING_MASTER_API_URL + `${ACCOUNT_AREA}/employee`,
    COMMON: LING_MASTER_API_URL + `${ADMIN_AREA}/common`,
    DASHBOARD: LING_MASTER_API_URL + `${ADMIN_AREA}/dashboard`,
    USER: LING_MASTER_API_URL + `${ADMIN_AREA}/user`,
    UPLOAD_FILE: LING_FILE_API_URL + '/upload',
    EXPORT_DATA: LING_MASTER_API_URL + `${ADMIN_AREA}/exportdata`

}
