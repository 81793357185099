import { Button, CardContent, Grid, FormHelperText } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Card from '@mui/material/Card'
import TextField from '@mui/material/TextField'
import { useNavigate, useParams } from 'react-router-dom'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { createEnterprise, getEnterpriseById, updateEnterprise } from '../../services/enterprise'
import { Controller, useForm } from 'react-hook-form'
import TextFieldValidate from '../../components/TextFieldValidate'
import { useSnackbar } from 'notistack'
import Skeleton from '@mui/material/Skeleton'
import { API_RES_STATUS } from '../../constants/apiResponseStatus'

export default function CreateEnterprisePage() {
    let { id: enterpriseId } = useParams()
    const navigation = useNavigate()
    const { register, control, handleSubmit, setValue, formState: { errors } } = useForm()
    const { enqueueSnackbar, /*closeSnackbar*/ } = useSnackbar()
    const [loading, setLoading] = useState(false)
    const [messageTax, setMessageTax] = useState(true)

    useEffect(() => {
        if (enterpriseId) {
            loadEnterprise(enterpriseId)
        }
    }, [])

    const loadEnterprise = async (id) => {
        setLoading(true)
        const res = await getEnterpriseById(id)
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            let data = json.data
            setValue('nameEnterPrise', data.name)
            setValue('taxNo', data.taxId)
            setValue('phoneNumber', data.phoneNumber)
            setValue('email', data.email)
            setValue('address', data.address)
            setValue('isActive', Number(data.isActive))
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
        setLoading(false)
    }

    const onSubmitForm = async (data) => {
        if (data.taxNo.length === 13 && data.taxNo.length !== '') {
            let idCard = data.taxNo.split("")
            let total = 0
            let mul = 13

            for (let i = 0; i < idCard.length - 1; i++) {
                total = total + idCard[i] * mul
                mul = mul - 1
            }

            let mod = total % 11
            let nsub = 11 - mod
            let mod2 = nsub % 10
            if (mod2 === Number(idCard[12])) {
                setLoading(true)
                setMessageTax(true)
                if (enterpriseId) {
                    await onEdit(enterpriseId, data)
                } else {
                    await onCreate(data)
                }
                setLoading(false)
            }
            else {
                setLoading(true)
                setMessageTax(false)
                setLoading(false)
            }
        } else {
            enqueueSnackbar("หมายเลขผู้เสียภาษี ไม่ครบ 13 หลัก", { variant: 'error' })
        }
    }

    const onCreate = async (data) => {
        const res = await createEnterprise({
            name: data.nameEnterPrise, taxId: data.taxNo, phoneNumber: data.phoneNumber,
            email: data.email, address: data.address, isActive: data.isActive
        })
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            enqueueSnackbar('Create enterprise successfully.', { variant: 'success' })
            navigation(-1)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
    }

    const onEdit = async (id, data) => {
        const res = await updateEnterprise({
            id: id,
            name: data.nameEnterPrise, taxId: data.taxNo, phoneNumber: data.phoneNumber,
            email: data.email, address: data.address, isActive: data.isActive
        })
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            enqueueSnackbar('Update enterprise successfully.', { variant: 'success' })
            navigation(-1)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
    }

    const loadingPanel = <div style={{ marginRight: 10, marginLeft: 10 }}>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Skeleton variant="rect" height={50} width={"100%"} />
        </Grid>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="start"
            alignItems="center"
        >
            <Grid item xs={4} >
                <Skeleton variant="rect" height={50} width={"80%"} />
            </Grid>
            <Grid item xs={8} >
                <Skeleton variant="rect" height={50} width={"100%"} />
            </Grid>
        </Grid>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="start"
            alignItems="center"
        >
            <Grid item xs={4} >
                <Skeleton variant="rect" height={50} width={"80%"} />
            </Grid>
            <Grid item xs={8} >
                <Skeleton variant="rect" height={50} width={"100%"} />
            </Grid>
        </Grid>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="start"
            alignItems="center"
        >
            <Grid item xs={4} >
                <Skeleton variant="rect" height={50} width={"80%"} />
            </Grid>
            <Grid item xs={8} >
                <Skeleton variant="rect" height={50} width={"100%"} />
            </Grid>
        </Grid>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="start"
            alignItems="center"
        >
            <Grid item xs={4} >
                <Skeleton variant="rect" height={50} width={"80%"} />
            </Grid>
            <Grid item xs={8} >
                <Skeleton variant="rect" height={50} width={"100%"} />
            </Grid>
        </Grid>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="start"
            alignItems="center"
        >
            <Grid item xs={4} >
                <Skeleton variant="rect" height={50} width={"80%"} />
            </Grid>
            <Grid item xs={8} >
                <Skeleton variant="rect" height={50} width={"100%"} />
            </Grid>
        </Grid>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="start"
            alignItems="center"
        >
            <Grid item xs={4} >
                <Skeleton variant="rect" height={50} width={"80%"} />
            </Grid>
            <Grid item xs={8} >
                <Skeleton variant="rect" height={50} width={"100%"} />
            </Grid>
        </Grid>
    </div>

    return (
        <div style={{ margin: 40 }}>
            {
                loading
                    ? loadingPanel
                    : <form onSubmit={handleSubmit(onSubmitForm)}>
                        <Grid container rowSpacing={1} >
                            <Grid item xs={6}>
                                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <Button onClick={() => navigation(-1)} style={{ color: '#12203A', borderColor: '#12203A', borderRadius: 10, borderWidth: 2, marginRight: 20 }} variant="outlined">
                                        ย้อนกลับ
                                    </Button>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button onClick={() => navigation(-1)} style={{ width: '15%', color: '#12203A', borderColor: '#12203A', borderRadius: 10, borderWidth: 2, marginRight: 20 }} variant="outlined">
                                        ยกเลิก
                                    </Button>
                                    <Button type="submit" style={{ width: '15%', color: 'white', borderRadius: 10, backgroundColor: '#12203A' }} variant="contained">
                                        บันทึก
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                        <div style={{ marginTop: 30 }}>
                            <Card >
                                <CardContent style={{ margin: 20 }}>
                                    <Grid container  >
                                        <Grid item xs={6}>
                                            <Grid container  >
                                                <Grid item mt={2} xs={4}>
                                                    ชื่อบริษัท
                                                </Grid>
                                                <Grid item mt={1} xs={8}>
                                                    <Controller style={{ marginTop: 0 }}
                                                        control={control}
                                                        rules={{
                                                            required: { value: true, message: "nameEnterPrise is required." },
                                                            min: { value: 0, message: "nameEnterPrise much be greater than or equal 0." }
                                                        }}
                                                        name="nameEnterPrise"
                                                        render={o => <TextFieldValidate vo={o} type='text' errorMessage={errors.nameEnterPrise?.message} />} />
                                                </Grid>
                                                <Grid item mt={2} xs={4}>
                                                    หมายเลขผู้เสียภาษี
                                                </Grid>
                                                <Grid item mt={2} xs={8}>
                                                    <Controller
                                                        control={control}
                                                        rules={{
                                                            required: { value: true, message: "กรุณากรอกหมายเลขผู้เสียภาษี" }
                                                        }}
                                                        name="taxNo"
                                                        render={({ field: { value, onChange, ref } }) =>
                                                            <TextField
                                                                // label="กรุณากรอกเลขบัตรประจำตัวประชาชน 13 หลัก"
                                                                value={value ?? ""}
                                                                onChange={onChange}
                                                                disabled={false}
                                                                inputRef={ref}
                                                                type="text"
                                                                // helperText="กรุณากรอกเลขบัตรประจําตัวประชาชน / เลขที่ผู้เสียภาษี"
                                                                error={errors.taxNo?.message !== undefined && errors.taxNo?.message !== ''}
                                                                fullWidth size="small" margin="normal" variant="outlined"
                                                                inputProps={{ maxLength: 13 }}
                                                            />
                                                        }
                                                    />
                                                    {messageTax !== true ? <FormHelperText style={{ color: 'red' }} >กรุณากรอกหมายเลขผู้เสียภาษีให้ถูกต้อง</FormHelperText> : null}
                                                </Grid>
                                                <Grid item mt={2} xs={4}>
                                                    เบอร์โทรศัพท์
                                                </Grid>
                                                <Grid item mt={2} xs={8}>
                                                    <Controller style={{ marginTop: 0 }}
                                                        control={control}
                                                        rules={{
                                                            required: { value: true, message: "phoneNumber is required." },
                                                            min: { value: 0, message: "phoneNumber much be greater than or equal 0." }
                                                        }}
                                                        name="phoneNumber"
                                                        render={o => <TextFieldValidate vo={o} type='text' maxLength="10" errorMessage={errors.phoneNumber?.message} />} />
                                                </Grid>
                                                <Grid item mt={2} xs={4}>
                                                    อีเมล
                                                </Grid>
                                                <Grid item mt={2} xs={8}>
                                                    <TextField style={{ marginTop: 0 }}
                                                        color='labelBlack'
                                                        InputProps={{
                                                            style: {
                                                                backgroundColor: "white",
                                                                color: 'black',
                                                                Color: '#F08E26'
                                                            }
                                                        }}
                                                        variant="outlined"
                                                        size="small"
                                                        margin="normal"
                                                        fullWidth
                                                        // label="Email Address"
                                                        autoComplete="email"
                                                        autoFocus
                                                        {...register("email", {
                                                            required: { value: true, message: "email is required." },
                                                            pattern: { value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, message: "email is not valid." }
                                                        })}
                                                        helperText={errors.email?.message}
                                                        error={errors.email !== undefined}
                                                    />
                                                </Grid>
                                                <Grid item mt={2} xs={4}>
                                                    ที่อยู่
                                                </Grid>
                                                <Grid item mt={2} xs={8}>
                                                    <Controller style={{ marginTop: 0 }}
                                                        control={control}
                                                        rules={{
                                                            required: { value: true, message: "address is required." },
                                                            min: { value: 0, message: "address much be greater than or equal 0." }
                                                        }}
                                                        name="address"
                                                        render={o => <TextFieldValidate vo={o} type='text' multiline={true} row={4} errorMessage={errors.address?.message} />} />
                                                </Grid>
                                                <Grid item mt={2} xs={4}>
                                                    สถานะ
                                                </Grid>
                                                <Grid item mt={2} xs={8}>
                                                    <FormControl fullWidth variant="outlined" >
                                                        <Controller
                                                            control={control}
                                                            name="isActive"
                                                            render={({ field: { onChange, value, ref } }) => (
                                                                <Select style={{ width: '100%' }} size="small"
                                                                    inputRef={ref}
                                                                    value={value ?? ""}
                                                                    onChange={e => {
                                                                        onChange(e)
                                                                    }}
                                                                    labelId="demo-simple-select-outlined-label"
                                                                    id="demo-simple-select-outlined"
                                                                // label="isActive"
                                                                // errormessage={errors.installMentType?.message}
                                                                >
                                                                    <MenuItem value={1} >
                                                                        Active
                                                                    </MenuItem>
                                                                    <MenuItem value={0} >
                                                                        Inactive
                                                                    </MenuItem>
                                                                </Select>
                                                            )}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {/* <Grid xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
                                <div style={{ height: '100%', width: 1, background: '#F08E26' }}></div>
                            </Grid> */}
                                        <Grid item xs={6}>
                                            <Grid container  >

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </div>
                    </form>
            }

        </div>
    )
}