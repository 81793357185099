import React from 'react'
import { FormControl, IconButton, MenuItem, Select, TextField } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import ErrorIcon from '@mui/icons-material/Error'
import Tooltip from '@mui/material/Tooltip'

export default function StandartValue(props) {

    const { isEdit, onSetValue, standard, measurementId, days, note, index, onDelete, listMeasurement, id } = props
    return (
        <div key={index} style={{
            flexDirection: 'row', display: 'flex',
            alignItems: 'center', marginBottom: 20, width: '100%', marginLeft: 40,
        }}>
            <TextField
                size='small'
                type='number'
                style={{ width: '20%', flex: 2 }}
                variant="outlined"
                value={standard}
                onChange={(e) => {
                    onSetValue(index, id, e.target.value, measurementId, days, note)
                }}
            />
            <FormControl style={{ backgroundColor: 'white', width: '25%', flex: 1, marginLeft: 20, marginRight: 20 }} >
                <Select
                    size='small'
                    value={measurementId}
                    onChange={(e) => {
                        onSetValue(index, id, standard, e.target.value, days, note)
                    }}
                >
                    <MenuItem disabled value={0}>
                        <em>เลือก</em>
                    </MenuItem>
                    {listMeasurement.map((x, i) => {
                        return <MenuItem key={i} value={x.id}>{x.name}</MenuItem>
                    })}
                </Select>
            </FormControl>

            <TextField
                size='small'
                style={{ width: '20%', flex: 2 }}
                variant="outlined"
                value={days}
                onChange={(e) => {
                    onSetValue(index, id, standard, measurementId, e.target.value, note)
                }}
            />
            {
                index === 0
                    ? <Tooltip title="“ระบบจะคำนวนจากระยะวันที่เริ่มเพาะปลูก”" arrow>
                        <ErrorIcon color='primary' style={{ marginRight: 10, marginLeft: 10 }} />
                    </Tooltip>
                    : <Tooltip title="“ระบบจะเริ่มนับช่วงเวลาต่อจากวันที่ท่านได้ระบุไว้แถวก่อนหน้า”" arrow>
                        <ErrorIcon color='primary' style={{ marginRight: 10, marginLeft: 10 }} />
                    </Tooltip>
            }


            <TextField
                size='small'
                style={{ width: '20%', flex: 2 }}
                variant="outlined"
                value={note}
                onChange={(e) => {
                    onSetValue(index, id, standard, measurementId, days, e.target.value)
                }}
            />
            {
                isEdit ?
                    <IconButton aria-label="delete" style={{ marginLeft: 10, marginRight: 10 }} onClick={() => { onDelete(index, id) }} >
                        <DeleteIcon color='error' />
                    </IconButton>
                    : <IconButton aria-label="delete" style={{ marginLeft: 10, marginRight: 10 }} onClick={() => { onDelete(index) }} >
                        <DeleteIcon color='error' />
                    </IconButton>
            }
        </div>
    )
}
