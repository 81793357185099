import { ApiUrl } from "../constants/api_url"
import AxiosHelper from "../helper/axiosHelper"

export async function getAllCoordinatesByEnterpriseId(enterpriseId) {
    return await AxiosHelper.get(ApiUrl.DASHBOARD + "/getAllCoordinatesByEnterpriseId/" + enterpriseId)
}

export async function getAllPlotDashboard(enterpriseId) {
    return await AxiosHelper.get(ApiUrl.DASHBOARD + "/getAllPlotDashboard/" + enterpriseId)
}

export async function getAllAreaDashboard(enterpriseId) {
    return await AxiosHelper.get(ApiUrl.DASHBOARD + "/getAllAreaDashboard/" + enterpriseId)
}