import React, { useState } from 'react'
import Modal from '@mui/material/Modal'
import { makeStyles } from '@mui/styles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { Grid, OutlinedInput } from '@mui/material'

export default function StandardValueModal({ open = false, OnClickInModal = () => { }, OnClickSubmitModal = () => { }, objectStandard = {} }) {
    const classes = useStyles()
    const [inputValue, setInputValue] = useState()
    return (
        <div style={{ alignContent: 'center' }}>
            <Modal scrollable
                open={open}
                onClose={OnClickInModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={classes.modalStyle}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography id="modal-modal-title" variant="h6" component="h3">
                            กรุณากรอกค่าการเติบโต
                        </Typography>
                    </div>
                    <Box sx={{ width: '100%' }}>
                        <Grid container mt={1} rowSpacing={1} >
                            <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>
                                <div >
                                    {objectStandard.specifyDate}
                                </div>
                            </Grid>
                            <Grid item xs={9}>
                                <OutlinedInput onChange={(e) => setInputValue(parseFloat(e.target.value))} size="small" style={{ width: '100%', backgroundColor: '#fff' }}
                                    type="number" placeholder={"กรอกค่าการเจริญเติบโต"}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                        <Grid container mt={3} mb={2} rowSpacing={1}  >
                            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button onClick={() => OnClickInModal()} variant="contained" color='inherit' style={{ width: "50%", border: '2px solid #F08E26' }} >ยกเลิก</Button>
                            </Grid>
                            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button onClick={() => OnClickSubmitModal(inputValue, objectStandard)} variant="contained" color='primary' style={{ color: '#fff', width: "50%" }} >ตกลง</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Modal>
        </div >
    )
}

const useStyles = makeStyles((theme) => ({
    modalStyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        border: '1px solid #000',
        boxShadow: 24,
        backgroundColor: '#12203A',
        color: '#F08E26',
        padding: 20,
        // height: 500,
        overflow: "scroll",
        borderRadius: 10,
    },
    divLine: {
        height: 1.1, backgroundColor: '#12203A',
        width: '100%', marginTop: 10
    },
}))