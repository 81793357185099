import { ApiUrl } from "../constants/api_url"
import AxiosHelper from "../helper/axiosHelper"

export default async function getAllUser({ currentPage = 1, pageSize = 10, filterEnterpriseId, searchKeyword }) {
    let params = {
        pageSize, currentPage, searchKeyword
    }
    if (filterEnterpriseId.length > 0) {
        params.filterEnterpriseId = filterEnterpriseId
    }
    let url = `${ApiUrl.USER}`
    if (Object.keys(params).length > 0) {
        params = new URLSearchParams(params)
        url = `${url}?${params.toString()}`
    }
    return await AxiosHelper.get(url)
}

export async function getUserById(id) {
    return await AxiosHelper.get(ApiUrl.USER + "/" + id)
}

export async function createNewUser({ firstName, lastName, phoneNumber, uid,
    email, cardId, birthDate, address, lineId, enterpriseId }) {

    return await AxiosHelper.post(ApiUrl.USER + "/create",
        { firstName, lastName, phoneNumber, uid, email, cardId, birthDate, address, lineId, enterpriseId })
}

export async function updateUser({ firstName, lastName, phoneNumber, uid,
    email, cardId, birthDate, address, lineId, userId, enterpriseId }) {

    return await AxiosHelper.put(ApiUrl.USER + "/update/" + userId,
        { firstName, lastName, phoneNumber, uid, email, cardId, birthDate, address, lineId, enterpriseId })
}