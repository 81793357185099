import { Button, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, Skeleton, Tooltip } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import Card from '@mui/material/Card'
import TextField from '@mui/material/TextField'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { AppContext } from '../../contexts/AppContext'
import { ROLES } from '../../constants/enums'
import AlertConfirmModal from '../../components/AlertConfirmModal'
import { API_RES_STATUS } from '../../constants/apiResponseStatus'
import { getAllEnterprisePositionByEnterpriseId, updateEnterprisePosition } from '../../services/enterprisePosition'
import ErrorIcon from '@mui/icons-material/Error'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import useCategoryEnterprise from '../../reactHook/useCategoryEnterprise'
import { getAllMeasurementSizePlot, getDefaultMeasurementByEnterpriseId } from '../../services/measurement'
import { getEnterpriseById, updateDefaultMeasurementByEnterpriseId } from '../../services/enterprise'


export default function SizePage() {

    const { employee = {} } = useContext(AppContext)
    const { enterprises } = useCategoryEnterprise()
    const { enqueueSnackbar, /*closeSnackbar*/ } = useSnackbar()
    const [listMeasurement, setListMeasurement] = useState([])
    const [loading, setLoading] = useState(false)
    const [openConfirmModal, setOpenConfirmModal] = useState(false)
    const [filterEnterpriseId, setFilterEnterpriseId] = useState(null)
    const [measurementId, setMeasurementId] = useState('00000000-0000-0000-0000-000000000000')

    useEffect(() => {

        loadSizePlot()

        if (employee.role !== ROLES.AdminLing) {
            getDefaultMeasurement(employee.enterpriseId)
        }

        if (filterEnterpriseId && employee.role === ROLES.AdminLing) {
            getDefaultMeasurement(filterEnterpriseId)
        }

    }, [employee, filterEnterpriseId])

    const loadSizePlot = async () => {
        setLoading(true)

        const res = await getAllMeasurementSizePlot()
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            let data = json.data
            console.log('dataaaa ' + data)
            console.log(data)
            setListMeasurement(data)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }

        setLoading(false)
    }

    const getDefaultMeasurement = async (enterpriseId) => {
        setLoading(true)
        const res = await getDefaultMeasurementByEnterpriseId(enterpriseId)
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            let data = json.data
            setMeasurementId(data.measurementId)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }

        setLoading(false)
    }

    // const onSetValue = (index, role, inputValue) => {
    //     let newValue = [...listPosition]
    //     newValue[index].name = inputValue
    //     setListPosition(newValue)
    // }

    const OnSubmitModal = async () => {

        let enterpriseId = employee.role === ROLES.AdminLing ? filterEnterpriseId : employee.enterpriseId

        if (!enterpriseId && employee.role === ROLES.AdminLing) {
            enqueueSnackbar('เลือกบริษัทก่อนบันทึก', { variant: 'error' })
            setOpenConfirmModal(false)
            return
        }

        const res = await updateDefaultMeasurementByEnterpriseId(measurementId, enterpriseId)
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            enqueueSnackbar('Update Measurement successfully.', { variant: 'success' })
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }

        setOpenConfirmModal(false)
    }

    const OnCancelModal = () => {
        setOpenConfirmModal(false)
    }
    const onChangeEnterprise = (enterpriseId) => {
        setLoading(true)
        setFilterEnterpriseId(enterpriseId)
        setLoading(false)
    }

    const loadingPanel = <div style={{ marginRight: 10, marginLeft: 10 }}>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Skeleton variant="rect" height={50} width={"100%"} />
        </Grid>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="start"
            alignItems="center"
        >
            <Grid item xs={3} >
                <Skeleton variant="rect" height={50} width={"80%"} />
            </Grid>
            <Grid item xs={9} >
                <Skeleton variant="rect" height={50} width={"100%"} />
            </Grid>
        </Grid>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="start"
            alignItems="center"
        >
            <Grid item xs={3} >
                <Skeleton variant="rect" height={50} width={"80%"} />
            </Grid>
            <Grid item xs={9} >
                <Skeleton variant="rect" height={50} width={"100%"} />
            </Grid>
        </Grid>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="start"
            alignItems="center"
        >
            <Grid item xs={3} >
                <Skeleton variant="rect" height={50} width={"80%"} />
            </Grid>
            <Grid item xs={9} >
                <Skeleton variant="rect" height={50} width={"100%"} />
            </Grid>
        </Grid>
    </div>

    return (
        <div style={{ margin: 40 }}>
            {loading ? loadingPanel :
                <>
                    <Grid container rowSpacing={1} >
                        <Grid item xs={6}>
                            {employee.role === ROLES.AdminLing &&
                                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <FormControl sx={{ m: 1, minWidth: 220 }} size="small">
                                        <InputLabel >เลือกบริษัท</InputLabel>
                                        <Select
                                            label="เลือกบริษัท"
                                            value={filterEnterpriseId}
                                            onChange={(e) => { onChangeEnterprise(e.target.value) }}
                                        >
                                            {enterprises.map((e) => {
                                                return <MenuItem key={`select-enterprise-${e.id}`} value={e.id}>{e.name}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                            }
                        </Grid>
                        <Grid item xs={6}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                {/* <Button onClick={() => navigation(-1)} style={{ width: '20%', borderRadius: 7, borderWidth: 1.5, marginRight: 20, color: '#12203A', borderColor: '#12203A' }} variant="outlined">
                                    ยกเลิก
                                </Button> */}
                                <Button onClick={() => setOpenConfirmModal(true)} style={{ width: '20%', color: 'white', borderRadius: 7, backgroundColor: '#12203A' }} variant="contained">
                                    บันทึก
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                    <div style={{ marginTop: 30 }}>
                        <Card >
                            <CardContent style={{ margin: 20 }}>
                                <h3>กำหนดหน่วยวัดแปลง</h3>
                                <Grid container  >
                                    {

                                        <Grid item xs={12} mt={4}>
                                            <Grid container >
                                                <Grid item mt={2} xs={2}>
                                                    <div>
                                                        หน่วยวัดขนาดแปลงที่ใช้อยู่
                                                    </div>
                                                </Grid>
                                                <Grid item mt={1} xs={4}>
                                                    <FormControl style={{ backgroundColor: 'white', width: '100%' }} >
                                                        <Select
                                                            size='small'
                                                            value={measurementId}
                                                            onChange={(e) => {
                                                                setMeasurementId(e.target.value)
                                                            }}
                                                        >
                                                            <MenuItem key={'k'} value={'00000000-0000-0000-0000-000000000000'}>{'ตารางเมตร (ค่าเริ่มต้น)'}</MenuItem>
                                                            {listMeasurement.map((x, i) => {
                                                                return <MenuItem key={i} value={x.id}>{x.name}</MenuItem>
                                                            })}
                                                        </Select>

                                                    </FormControl>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                            </CardContent>
                        </Card>
                        <AlertConfirmModal header='ยืนยันการแก้ไข' open={openConfirmModal} OnClickInModal={OnCancelModal} OnClickSubmitModal={OnSubmitModal} />
                    </div>
                </>
            }
        </div>
    )
}
