import { Button, CardContent, FormControl, Grid, MenuItem, Select, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import Card from '@mui/material/Card'
import { useNavigate, useParams } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import SheduleValue from '../../components/ScheduleValue'
import { Controller, useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import TextFieldValidate from '../../components/TextFieldValidate'
import { createSchedule, deleteScheduleDateTime, getScheduleById, getScheduleDateTimeById, updateSchedule } from '../../services/schedule'
import Empty from '../../components/Empty'
import AlertDeleteModal from '../../components/AlertDeleteModal'
import { API_RES_STATUS } from '../../constants/apiResponseStatus'

export default function CreateSchedulePage() {
    const navigation = useNavigate()
    let { id: scheduleId } = useParams()
    const { control, handleSubmit, setValue, formState: { errors } } = useForm()
    const [isDate, setIsDate] = useState(false)
    const [loading, setLoading] = useState(false)
    const { enqueueSnackbar, /*closeSnackbar*/ } = useSnackbar()
    const [scheduleValue, setScheduleValue] = useState([
        { schedule: "", selectDate: 0 }
    ])
    const [openModal, setOpenModal] = useState(false)
    const [idDelete, setIdDelete] = useState(null)
    const [isEdit, setIsEdit] = useState(scheduleId ? true : false)
    const [idIndex, setIdIndex] = useState()

    useEffect(() => {
        if (scheduleId) {
            loadSchedule(scheduleId)
            loadScheduleTime(scheduleId)
        }
    }, [])

    const loadSchedule = async (id) => {
        setLoading(true)
        const res = await getScheduleById(id)
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            let data = json.data
            setValue('name', data.name)
            setValue('detail', data.detail)
            setIsDate(data.isTimeInterval)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
        setLoading(false)
    }

    const loadScheduleTime = async (id) => {
        setLoading(true)
        const res = await getScheduleDateTimeById(id)

        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            let listScheduleTime = json.data
            setScheduleValue([...listScheduleTime])
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
        setLoading(false)
    }

    const onSubmitForm = async (data) => {
        if (scheduleId) {
            await onEdit(scheduleId, data, scheduleValue)
        } else {
            await onCreate(data, scheduleValue)
        }
    }

    const onCreate = async (data, listScheduleDateTime) => {
        const res = await createSchedule({
            name: data.name, detail: data.detail, isTimeInterval: isDate, listScheduleDateTime
        })
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            enqueueSnackbar('Create enterprise successfully.', { variant: 'success' })
            navigation(-1)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
    }

    const onEdit = async (id, data, listScheduleDateTime) => {
        const res = await updateSchedule({
            id: id, name: data.name, detail: data.detail, isTimeInterval: isDate, listScheduleDateTime
        })
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            enqueueSnackbar('Update schedule successfully.', { variant: 'success' })
            navigation(-1)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
    }

    const addScheduleValue = () => {
        let obj = { schedule: "", selectDate: 0 }
        var newData = [...scheduleValue, obj]
        setScheduleValue(newData)
    }

    const removeScheduleValue = (index, scheduleDateTimeId) => {
        if (scheduleDateTimeId) {
            setIdDelete(scheduleDateTimeId)
            setOpenModal(true)
            setIdIndex(index)
        } else {
            let newValue = [...scheduleValue]
            newValue.splice(index, 1)
            setScheduleValue(newValue)
        }
    }

    const onSetScheduleValue = (index, inputScheduleValue, inputDatetime) => {
        let newValue = [...scheduleValue]
        newValue[index].schedule = inputScheduleValue
        newValue[index].selectDate = inputDatetime
        setScheduleValue(newValue)
    }

    const handleChange = (event) => {
        setIsDate(event.target.value)
    }

    const OnClickSubmitModal = async (id) => {
        const res = await deleteScheduleDateTime(id)
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            enqueueSnackbar('Delete ScheduleDateTime successfully.', { variant: 'success' })
            setIdDelete(null)
            loadSchedule(scheduleId)
            loadScheduleTime(scheduleId)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
        setOpenModal(false)
    }

    const OnClickInModal = () => {
        setOpenModal(false)
        setIdDelete(null)
    }

    return (
        <div style={{ margin: 40 }}>
            <form onSubmit={handleSubmit(onSubmitForm)}>
                <Grid container rowSpacing={1} >
                    <Grid item xs={6}>
                        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={() => navigation(-1)} style={{ width: '20%', borderRadius: 7, borderWidth: 1.5, marginRight: 20, color: '#12203A', borderColor: '#12203A' }} variant="outlined">
                                ยกเลิก
                            </Button>
                            <Button type='submit' style={{ width: '20%', color: 'white', borderRadius: 7, backgroundColor: '#12203A' }} variant="contained">
                                บันทึก
                            </Button>
                        </div>
                    </Grid>
                </Grid>
                <div style={{ marginTop: 30 }}>
                    <Card elevation={6}>
                        <CardContent style={{ margin: 20 }}>
                            <Grid container  >
                                <Grid item xs={12}>
                                    <Grid container  >
                                        <Grid item mt={2} xs={2}>
                                            <label style={{ marginLeft: 20, fontSize: 18, }}>ชื่อกำหนดการ </label>
                                        </Grid>
                                        <Grid item mt={1} xs={10} >
                                            <div style={{ marginTop: 0, width: '50%' }}>
                                                <Controller style={{ marginTop: 0 }}
                                                    control={control}
                                                    rules={{
                                                        required: { value: true, message: "name is required." },
                                                        min: { value: 0, message: "name much be greater than or equal 0." }
                                                    }}
                                                    name="name"
                                                    render={o => <TextFieldValidate vo={o} type='text' errorMessage={errors.name?.message} />} />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} mt={4}>
                                    <Grid container  >
                                        <Grid item mt={2} xs={2}>
                                            <label style={{ marginLeft: 20, fontSize: 18, }}>ประเภท </label>
                                        </Grid>
                                        <Grid item mt={1} xs={10}>
                                            <FormControl style={{ width: '50%', marginTop: 12 }}>
                                                <Select size='small'
                                                    value={isDate}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={true}>ระบุช่วงเวลา</MenuItem>
                                                    <MenuItem value={false}>ไม่ระบุช่วงเวลา</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {isDate ?
                                    // แบบกำหนดช่วงเวลา
                                    <>
                                        <Grid item xs={12} mt={3}>
                                            <Grid container  >
                                                <Grid item mt={2} xs={4} style={{ display: 'flex', justifyContent: 'start' }}>
                                                    <label style={{ marginLeft: 20, fontSize: 18, }}>รายละเอียด </label>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <div style={{
                                            flexDirection: 'row', display: 'flex',
                                            alignItems: 'center', marginBottom: 20,
                                            width: '60%', marginLeft: 40, textAlign: 'center'
                                        }}>
                                            <Typography component="h6" variant="h6" style={{ flex: 1 }} >
                                                กำหนดการ
                                            </Typography>
                                            <Typography component="h6" variant="h6" style={{ flex: 1 }} >
                                                ช่วงเวลา
                                            </Typography>
                                        </div>

                                        {scheduleValue.length
                                            ? scheduleValue.map((v, i) => {
                                                return <SheduleValue key={i} isEdit={isEdit} onSetValue={onSetScheduleValue} onDelete={removeScheduleValue} index={i} schedule={v.schedule} selectDate={v.selectDate} scheduleDateTimeId={v.id} />
                                            })
                                            : <div style={{
                                                flexDirection: 'row', display: 'flex',
                                                alignItems: 'center', width: '100%', marginLeft: 180, marginTop: -50
                                            }}>
                                                <Empty />
                                            </div>
                                        }

                                        <div style={{
                                            flexDirection: 'row', display: 'flex',
                                            alignItems: 'center', marginBottom: 20, width: '100%', marginLeft: 40,
                                        }}>
                                            <Button size='large'
                                                onClick={() => addScheduleValue()}
                                                style={{ width: '60%', color: 'white', backgroundColor: '#12203A' }}
                                                startIcon={<AddIcon />}>
                                                เพิ่มค่า
                                            </Button>
                                        </div></>
                                    :
                                    <Grid item xs={12} mt={2}>
                                        <Grid container  >
                                            <Grid item mt={2} xs={12}>
                                                <label style={{ marginLeft: 20, fontSize: 18, }}>รายละเอียด </label>
                                            </Grid>
                                            <Grid item mt={1} xs={0.5}>
                                            </Grid>
                                            <Grid item mt={2} xs={11.5}>
                                                <div style={{ width: '60%' }}>
                                                    <Controller style={{ marginTop: 0 }}
                                                        control={control}
                                                        rules={{
                                                            required: { value: true, message: "detail is required." },
                                                            min: { value: 0, message: "detail much be greater than or equal 0." }
                                                        }}
                                                        name="detail"
                                                        render={o => <TextFieldValidate vo={o} row={6} multiline={true} type='text' errorMessage={errors.detail?.message} />} />
                                                </div>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                        </CardContent>
                    </Card>
                </div>
            </form >
            <AlertDeleteModal id={idDelete} open={openModal} OnClickInModal={OnClickInModal} OnClickSubmitModal={OnClickSubmitModal} />

        </div >
    )
}
