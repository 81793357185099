import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
export default function DialogRequirePermission({ open, handleClose }) {
    return <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {"แจ้งเตือนขออนุญาติเข้าถึงตำแหน่งที่ตั้งของอุปกรณ์"}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                เพื่อใช้งานเว็บไซต์ได้เต็มประสิทธิภาพ กรุณาอนุญาติให้เว็บไซต์นี้เข้าถึงตำแหน่งที่ตั้งของอุปกรณ์ของคุณ
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} autoFocus color="secondary">
                ปิด
            </Button>
        </DialogActions>
    </Dialog>
}