import React, { useState, useEffect } from 'react'
import { Button, Grid, Paper, TextField, Typography, MenuItem, Select, FormControl, FormHelperText } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useNavigate, useLocation } from 'react-router-dom'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { styled } from '@mui/material/styles'
import { Controller, useForm } from 'react-hook-form'
import TextFieldValidate from '../../components/TextFieldValidate'
import createPlot, { getPlotDetailByPlotId, updatePlot, uploadFile } from '../../services/plot'
import { useSnackbar } from 'notistack'
import getAllPlant from '../../services/plant'
import getAllStandard, { getEndDateFarmByStandardId } from '../../services/standard'
import createFarm, { deleteFarm, finishFarm, updateFarm } from '../../services/farm'
import dayjs from 'dayjs'
import Empty from '../../components/Empty'
import { API_RES_STATUS } from '../../constants/apiResponseStatus'
import { getAllMeasurementWeight, getDefaultMeasurementByEmployeeId } from '../../services/measurement'
import getAllPlantTypeByPlantId, { getPlantTypeByPlantId } from '../../services/plantType'
import { getDefaultMeasurementDisplay } from '../../helper/measurementHelper'
import Resizer from "react-image-file-resizer"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#12203A",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#D3DCEC",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 1,
    },
}))

export const resizeFile = ({
    file,
    width = 200,
    height = 200,
    extension = "PNG",
    outputType = "base64"
}) => {
    return new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            width,
            height,
            extension,
            70,
            0,
            (uri) => {
                resolve(uri)
            },
            outputType
        )
    })
}

export default function CreatePlotPage() {

    const { state } = useLocation()
    const { mapImage = "", staffId, listCoordinatesForCreate = [], plotId, areaSizeForCreate = 0, addressPlot } = state
    console.log("mapImage>>>", mapImage)
    console.log("staffId>>>", staffId)
    console.log("listCoordinatesForCreate>>>", listCoordinatesForCreate)
    console.log("plotId>>>", plotId)
    console.log("areaSizeForCreate>>>", areaSizeForCreate)
    console.log("addressPlot>>>", addressPlot)
    const className = useStyles()
    const navigation = useNavigate()
    const { control, register, handleSubmit, setValue, formState: { errors } } = useForm()
    const { enqueueSnackbar, /*closeSnackbar*/ } = useSnackbar()
    const [plotIdd, setPlotIdd] = useState()
    const [plantSelected, setPlantSelected] = useState(false)
    const [plantTypeSelected, setPlantTypeSelected] = useState(false)
    const [standardSelected, setStandardSelected] = useState(false)
    const [measurementWeightSelected, setMeasurementWeightSelected] = useState(false)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [farmProcessingId, setFarmProcessingId] = useState(null)
    const [plantSelectedFarmProcessing, setPlantSelectedFarmProcessing] = useState(false)
    const [plantTypeSelectedFarmProcessing, setPlantTypeSelectedFarmProcessing] = useState(false)
    const [standardSelectedFarmProcessing, setStandardSelectedFarmProcessing] = useState(false)
    const [measurementSelectedFarmProcessing, setMeasurementSelectedFarmProcessing] = useState(false)
    const [startDateFarmProcessing, setStartDateFarmProcessing] = useState(null)
    const [endDateFarmProcessing, setEndDateFarmProcessing] = useState(null)
    const [isProcessingFarmProcessing, setIsProcessingFarmProcessing] = useState(null)
    const [quantityProductFarmProcessing, setQuantityProductFarmProcessing] = useState(null)

    const [farmPendingId, setFarmPendingId] = useState(null)
    const [plantSelectedFarmPending, setPlantSelectedFarmPending] = useState(false)
    const [plantTypeSelectedFarmPending, setPlantTypeSelectedFarmPending] = useState(false)
    const [standardSelectedFarmPending, setStandardSelectedFarmPending] = useState(false)
    const [measurementSelectedFarmPending, setMeasurementSelectedFarmPending] = useState(false)
    const [startDateFarmPending, setStartDateFarmPending] = useState(null)
    const [endDateFarmPending, setEndDateFarmPending] = useState(null)
    const [isProcessingFarmPending, setIsProcessingFarmPending] = useState(null)

    const [listFarmCompleted, setListFarmCompleted] = useState([])
    const [isDisableFarm, setIsDisableFarm] = useState(false)
    const [isDisableInProgressFarm, setIsDisableInProgressFarm] = useState(false)
    const [isDisablePandingFarm, setIsDisablePandingFarm] = useState(false)

    const [listPlant, setListPlant] = useState([])
    const [listPlantType, setListPlantType] = useState([])
    const [listPlantTypeProcessing, setListPlantTypeProcessing] = useState([])
    const [listPlantTypePending, setListPlantTypePending] = useState([])
    const [listStandard, setListStandard] = useState([])
    const [listMeasurementWeight, setListMeasurementWeight] = useState([])

    const [amountDaysFarmPending, setAmountDaysFarmPending] = useState(null)
    const [amountDays, setAmountDays] = useState(null)

    // const [plantId, setPlantId] = useState(null)
    // const [plantTypeId, setPlantTypeId] = useState(null)
    // const [standardId, setStandardId] = useState(null)
    const [messageTax, setMessageTax] = useState(true)
    const [disPlayPlotSize, setDisPlayPlotSize] = useState(null)
    const [isDisable, setIsDisable] = useState(false)

    useEffect(() => {
        setValue("size", areaSizeForCreate)
        setValue("addressPlot", addressPlot)
        getDefualtMeasurementByEnterprise(areaSizeForCreate)
        if (staffId !== "null") {
            // console.log("staffId >>>>>>>>>>>", staffId)
            // console.log("listCoordinates >>>>>>>>>>>", listCoordinates)
            // console.log("plotId >>>>>>>>>>>", plotId)
        }

        if (plotId !== null) {
            loadPlot(plotId)
            setIsDisableFarm(true)
        }

        if (isDisableFarm) {
            loadPlant()
            // loadPlantType()
            loadStandard()
            loadMeasurementWeight()
        }

    }, [isDisableFarm, isDisableInProgressFarm, isDisablePandingFarm])

    const loadPlot = async (plotId) => {

        const axiosRes = await getPlotDetailByPlotId(plotId)
        let json = JSON.parse(axiosRes.data)
        const { data, status, message } = json
        if (status === API_RES_STATUS.success) {

            const farmProcessing = data.farmProcessing
            const farmPending = data.farmPending

            if (farmProcessing.id !== null) {
                setFarmProcessingId(farmProcessing.id)
                setPlantSelectedFarmProcessing(farmProcessing.plantId)
                loadPlantTypeProcessing(farmProcessing.plantId)
                setPlantTypeSelectedFarmProcessing(farmProcessing.plantTypeId)
                setStandardSelectedFarmProcessing(farmProcessing.standardId)
                setMeasurementSelectedFarmProcessing(farmProcessing.measurementId)
                setStartDateFarmProcessing(dayjs(farmProcessing.startDate).format("YYYY-MM-DD"))
                setEndDateFarmProcessing(dayjs(farmProcessing.endDate).format("YYYY-MM-DD"))
                setIsProcessingFarmProcessing(farmProcessing.isProcessing)
                setIsDisableInProgressFarm(true)
            }

            if (farmPending.id !== null) {
                setFarmPendingId(farmPending.id)
                setPlantSelectedFarmPending(farmPending.plantId)
                loadPlantTypePending(farmPending.plantId)
                setPlantTypeSelectedFarmPending(farmPending.plantTypeId)
                setStandardSelectedFarmPending(farmPending.standardId)
                setMeasurementSelectedFarmPending(farmPending.measurementId)
                setStartDateFarmPending(dayjs(farmPending.startDate).format("YYYY-MM-DD"))
                setEndDateFarmPending(dayjs(farmPending.endDate).format("YYYY-MM-DD"))
                setIsProcessingFarmPending(farmPending.isProcessing)
                onGetEndDateFarmPendingByStandardId(farmPending.standardId)
                setIsDisablePandingFarm(true)
            }

            setValue("userId", data.userId)
            setValue("email", data.email)
            setValue("size", data.size)
            setValue("firstName", data.firstName)
            setValue("lastName", data.lastName)
            setValue("uid", data.uid)
            setValue("cardId", data.cardId)
            setValue("phoneNumber", data.phoneNumber)
            setValue("addressUser", data.addressUser)
            setValue("plotName", data.plotName)
            setValue("deedNo", data.deedNo)
            setValue("addressPlot", data.addressPlot)
            setValue("detail", data.detail)
            setListFarmCompleted(data.listFarmCompleted)
            await getDefualtMeasurementByEnterprise(data.size)

        } else {
            enqueueSnackbar(message, { variant: 'error' })
        }
    }

    const getDefualtMeasurementByEnterprise = async (sizePlotSqMeter) => {
        const axiosRes = await getDefaultMeasurementByEmployeeId(staffId)
        let json = JSON.parse(axiosRes.data)
        const { data, status, message } = json
        if (status === API_RES_STATUS.success) {
            let disPlaySizePlot = await getDefaultMeasurementDisplay(data.measurementName, sizePlotSqMeter)
            setDisPlayPlotSize(disPlaySizePlot)
        } else {
            enqueueSnackbar(message, { variant: 'error' })
        }
    }

    const loadPlant = async () => {
        const axiosRes = await getAllPlant({ staffId: staffId })
        const { data, status, message = "" } = JSON.parse(axiosRes.data)
        if (status === API_RES_STATUS.success) {
            const { listData } = data
            setListPlant(listData)
        } else {
            enqueueSnackbar(message, { variant: 'error', autoHideDuration: 10000 })
        }
    }

    const loadPlantType = async (plantId) => {
        const axiosRes = await getAllPlantTypeByPlantId(plantId)
        const { data, status, message = "" } = JSON.parse(axiosRes.data)
        if (status === API_RES_STATUS.success) {
            console.log('loadplantType dddd')
            setListPlantType(data)
        } else {
            enqueueSnackbar(message, { variant: 'error' })
        }
    }

    const loadPlantTypeProcessing = async (plantId) => {
        const axiosRes = await getAllPlantTypeByPlantId(plantId)
        const { data, status, message = "" } = JSON.parse(axiosRes.data)
        if (status === API_RES_STATUS.success) {
            setListPlantTypeProcessing(data)
        } else {
            enqueueSnackbar(message, { variant: 'error' })
        }
    }

    const loadPlantTypePending = async (plantId) => {
        const axiosRes = await getAllPlantTypeByPlantId(plantId)
        const { data, status, message = "" } = JSON.parse(axiosRes.data)
        if (status === API_RES_STATUS.success) {
            setListPlantTypePending(data)
        } else {
            enqueueSnackbar(message, { variant: 'error' })
        }
    }

    const loadStandard = async () => {
        const axiosRes = await getAllStandard({ staffId: staffId })
        const { data, status, message = "" } = JSON.parse(axiosRes.data)
        if (status === API_RES_STATUS.success) {
            setListStandard(data.listData)
        } else {
            setValue("standard", 0)
            enqueueSnackbar(message, { variant: 'error' })
        }
    }

    const loadMeasurementWeight = async () => {
        const axiosRes = await getAllMeasurementWeight()
        const { data, status, message = "" } = JSON.parse(axiosRes.data)
        if (status === API_RES_STATUS.success) {
            setListMeasurementWeight(data)
        } else {
            enqueueSnackbar(message, { variant: 'error' })
        }
    }

    const onSubmitForm = async (data) => {
        setIsDisable(true)
        const coordinates = [{ latitude: 1.11111, longitude: 2.222222 }]

        if (data.cardId.length === 13 && data.cardId.length !== '') {
            let idCard = data.cardId.split("")
            let total = 0
            let mul = 13

            for (let i = 0; i < idCard.length - 1; i++) {
                total = total + idCard[i] * mul
                mul = mul - 1
            }

            let mod = total % 11
            let nsub = 11 - mod
            let mod2 = nsub % 10
            if (mod2 === Number(idCard[12])) {
                setMessageTax(true)
                if (plotId !== null) {
                    //todo this coordinates mock
                    await onEdit(data, coordinates)
                } else {
                    await onCreate(data, listCoordinatesForCreate)
                }
            }
            else {
                setMessageTax(false)
            }
        }
        setIsDisable(false)
    }

    const onCreate = async (input, coordinates) => {
        let imageMain = ""
        if (mapImage?.length > 0) {
            const resImage = await fetch(mapImage)
            const imageBlob = await resImage.blob()

            const imageResize = await resizeFile({ file: imageBlob, width: 950, height: 950 })
            const resImage1 = await fetch(imageResize)
            const imageBlob1 = await resImage1.blob()

            const formDataMainImage = new FormData()
            formDataMainImage.append('formFiles', imageBlob1, `Image_Capture_Map${addressPlot}.png`)

            const axiosRes = await uploadFile(formDataMainImage)
            const json = axiosRes?.data || {}
            const { data = [], status, message = "มีบางอย่างผิดพลาด" } = json
            if (status !== API_RES_STATUS.success) {
                enqueueSnackbar(message, { variant: 'error' })
                return
            }
            imageMain = data[0]
        }

        const axiosRes = await createPlot({
            staffId, email: input.email,
            firstName: input.firstName,
            lastName: input.lastName,
            uid: input.uid, cardId: input.cardId,
            phoneNumber: input.phoneNumber,
            addressUser: input.addressUser,
            size: input.size, plotName: input.plotName,
            deedNo: input.deedNo, addressPlot: input.addressPlot,
            detail: input.detail, coordinates, uploadMainImageId: imageMain
        })
        const { data, status, message = "" } = JSON.parse(axiosRes.data)
        if (status === API_RES_STATUS.success) {
            enqueueSnackbar('Create area successfully.', { variant: 'success' })
            setIsDisableFarm(true)
            setPlotIdd(data)
        } else {
            enqueueSnackbar(message, { variant: 'error' })
        }
    }

    const onEdit = async (input, coordinates) => {

        const axiosRes = await updatePlot({
            plotId: plotId,
            staffId, email: input.email,
            firstName: input.firstName,
            lastName: input.lastName,
            uid: input.uid, cardId: input.cardId,
            phoneNumber: input.phoneNumber,
            addressUser: input.addressUser,
            size: input.size, plotName: input.plotName,
            deedNo: input.deedNo, addressPlot: input.addressPlot,
            detail: input.detail, coordinates
        })

        const { data, status, message = "" } = JSON.parse(axiosRes.data)
        if (status === API_RES_STATUS.success) {
            enqueueSnackbar('Update Plot successfully.', { variant: 'success' })
            loadPlot(plotId)
        } else {
            enqueueSnackbar(message, { variant: 'error' })
        }
    }

    const onSubmitFarm = async () => {

        let input = 0
        input = (!plantSelected) ? input = 1 :
            (!plantTypeSelected) ? input = 2 :
                (!standardSelected) ? input = 3 :
                    (startDate == null) ? input = 4 :
                        (endDate == null) ? input = 5 :
                            (!measurementWeightSelected) ? input = 6 : input

        switch (input) {
            case 1:
                enqueueSnackbar("กรุณากรอกข้อมูล พืช", { variant: 'error' })
                return

            case 2:
                enqueueSnackbar("กรุณากรอกข้อมูล ชนิดพืช", { variant: 'error' })
                return

            case 3:
                enqueueSnackbar("กรุณากรอกข้อมูล ค่ามาตรฐาน", { variant: 'error' })
                return

            case 4:
                enqueueSnackbar("กรุณากรอกข้อมูล วันที่ปลูก", { variant: 'error' })
                return

            case 5:
                enqueueSnackbar("กรุณากรอกข้อมูล วันที่เก็บเกี่ยว", { variant: 'error' })
                return
            case 6:
                enqueueSnackbar("กรุณากรอกข้อมูล หน่วยวัด", { variant: 'error' })
                return
            default:
                break
        }

        const axiosRes = await createFarm({
            plantTypeId: plantTypeSelected, standardId: standardSelected,
            startDate: startDate, endDate: endDate, plotId: plotId === null ? plotIdd : plotId, measurementId: measurementWeightSelected
        })
        const { data, status, message = "" } = JSON.parse(axiosRes.data)
        if (status === API_RES_STATUS.success) {

            if (data.isProcessing) {
                setFarmProcessingId(data.id)
                setPlantSelectedFarmProcessing(data.plantId)
                setPlantTypeSelectedFarmProcessing(data.plantTypeId)
                setStandardSelectedFarmProcessing(data.standardId)
                setMeasurementSelectedFarmProcessing(data.measurementId)
                setStartDateFarmProcessing(dayjs(data.startDate).format("YYYY-MM-DD"))
                setEndDateFarmProcessing(dayjs(data.endDate).format("YYYY-MM-DD"))
                setIsProcessingFarmProcessing(data.isProcessing)
                setIsDisableInProgressFarm(true)
            } else {
                setFarmPendingId(data.id)
                setPlantSelectedFarmPending(data.plantId)
                setPlantTypeSelectedFarmPending(data.plantTypeId)
                setStandardSelectedFarmPending(data.standardId)
                setMeasurementSelectedFarmPending(data.measurementId)
                setStartDateFarmPending(dayjs(data.startDate).format("YYYY-MM-DD"))
                setEndDateFarmPending(dayjs(data.endDate).format("YYYY-MM-DD"))
                setIsProcessingFarmPending(data.isProcessing)
                setIsDisablePandingFarm(true)
            }
            setPlantSelected(false)
            setPlantTypeSelected(false)
            setStandardSelected(false)
            setMeasurementWeightSelected(false)
            setStartDate(null)
            setEndDate(null)

            enqueueSnackbar('Create farm successfully.', { variant: 'success' })

        } else {
            enqueueSnackbar(message, { variant: 'error' })
        }
    }

    const onGetEndDateFarmByStandardId = async (standardId) => {
        const axiosRes = await getEndDateFarmByStandardId(standardId)
        const { data, status, message = "" } = JSON.parse(axiosRes.data)
        if (status === API_RES_STATUS.success) {
            setAmountDays(data.amountDays)
        } else {
            enqueueSnackbar(message, { variant: 'error' })
        }
    }

    const onGetEndDateFarmPendingByStandardId = async (standardId) => {
        const axiosRes = await getEndDateFarmByStandardId(standardId)
        const { data, status, message = "" } = JSON.parse(axiosRes.data)
        if (status === API_RES_STATUS.success) {
            setAmountDaysFarmPending(data.amountDays)
        } else {
            enqueueSnackbar(message, { variant: 'error' })
        }
    }

    const onUpdateFarmPending = async (plantTypeId, standardId, startDate, endDate, farmId, isProcessing, measurementId) => {
        const axiosRes = await updateFarm(
            plantTypeId, standardId,
            startDate, endDate, farmId, isProcessing, measurementId
        )
        const { data, status, message = "" } = JSON.parse(axiosRes.data)
        if (status === API_RES_STATUS.success) {
            setFarmPendingId(data.id)
            setPlantSelectedFarmPending(data.plantId)
            setPlantTypeSelectedFarmPending(data.plantTypeId)
            setStandardSelectedFarmPending(data.standardId)
            setStartDateFarmPending(dayjs(data.startDate).format("YYYY-MM-DD"))
            setEndDateFarmPending(dayjs(data.endDate).format("YYYY-MM-DD"))
            setIsProcessingFarmPending(data.isProcessing)
            enqueueSnackbar('Update successfully.', { variant: 'success' })
        } else {
            enqueueSnackbar(message, { variant: 'error' })
        }
    }

    const onDeleteFarmPending = async (farmId) => {
        const axiosRes = await deleteFarm(farmId)
        const { data, status, message = "" } = JSON.parse(axiosRes.data)
        if (status === API_RES_STATUS.success) {
            setFarmPendingId(null)
            setPlantSelectedFarmPending(false)
            setPlantTypeSelectedFarmPending(false)
            setStandardSelectedFarmPending(false)
            setStartDateFarmPending(null)
            setEndDateFarmPending(null)
            setIsProcessingFarmPending(false)
            setIsDisablePandingFarm(false)
            enqueueSnackbar('delete successfully.', { variant: 'success' })
        } else {
            enqueueSnackbar(message, { variant: 'error' })
        }
    }

    const onFinishFarm = async (plantTypeId, standardId, startDate,
        endDate, farmId, isProcessing, quantityProduct, measurementId) => {

        const axiosRes = await finishFarm(
            plantTypeId, standardId,
            startDate, endDate, farmId,
            isProcessing, quantityProduct, measurementId
        )

        const { data, status, message = "" } = JSON.parse(axiosRes.data)
        if (status === API_RES_STATUS.success) {

            setQuantityProductFarmProcessing(null)

            setFarmProcessingId(null)
            setPlantSelectedFarmProcessing(null)
            setPlantTypeSelectedFarmProcessing(null)
            setStandardSelectedFarmProcessing(null)
            setStartDateFarmProcessing(null)
            setEndDateFarmProcessing(null)
            setIsProcessingFarmProcessing(null)
            setIsDisableInProgressFarm(false)

            if (isDisablePandingFarm) {
                setFarmPendingId(null)
                setPlantSelectedFarmPending(null)
                setPlantTypeSelectedFarmPending(null)
                setStandardSelectedFarmPending(null)
                setStartDateFarmPending(null)
                setEndDateFarmPending(null)
                setIsProcessingFarmPending(null)
                setIsDisablePandingFarm(false)
            }

            enqueueSnackbar('Update successfully.', { variant: 'success' })
        } else {
            enqueueSnackbar(message, { variant: 'error' })
        }
    }

    const columns = [
        { label: "No", minWidth: 50, align: 'start', },
        { label: "ชนิดพืช", minWidth: 120, align: 'center' },
        { label: "พันธ์พืช", minWidth: 120, align: 'center' },
        { label: "ค่ามาตรฐาน", minWidth: 120, align: 'center' },
        { label: "ผลผลิต", minWidth: 120, align: 'center' },
        { label: "วันที่ปลูก", minWidth: 120, align: 'center' },
        { label: "วันที่เก็บเกี่ยว", minWidth: 120, align: 'center' },
        { label: "วันที่เสร็จสิ้น", minWidth: 120, align: 'center' },
    ]

    return (
        <>
            <div style={{ margin: 40 }}>
                <form onSubmit={handleSubmit(onSubmitForm)}>
                    <Grid container  >
                        <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button onClick={() => { navigation(-1) }}
                                    style={{
                                        width: '10%', color: '#12203A', borderRadius: 10, marginRight: 20,
                                        backgroundColor: 'white',
                                    }} variant="contained"
                                >
                                    ยกเลิก
                                </Button>
                                <Button
                                    type="submit"
                                    disabled={isDisable}
                                    style={{
                                        width: '10%', color: 'white',
                                        borderRadius: 10, backgroundColor: '#12203A'
                                    }}
                                    variant="contained">
                                    บันทึก
                                </Button>
                            </div>
                        </Grid>
                    </Grid>

                    <Paper elevation={6} className={className.borderRadius10}>

                        <Grid container direction={'row'} style={{ padding: 40, justifyContent: 'space-around' }}>
                            {/* <Grid item flexDirection={"column"} xs={12} sm={12} md={5}>
                <Typography component="h6" variant="h6" style={{ marginBottom: 10 }}>User ID</Typography>
                <Controller style={{ marginTop: 0 }}
                  control={control}
                  rules={{
                    required: { value: true, message: "userID team is required." },
                    min: { value: 0, message: "userID team much be greater than or equal 0." }
                  }}
                  name="userID"
                  render={o => <TextFieldValidate placeholder={"กรอก User ID"} vo={o} type='text' errorMessage={errors.userID?.message} />} />
              </Grid> */}

                            <Grid item flexDirection={"column"} xs={12} sm={12} md={5}>
                                <Typography component="h6" variant="h6" style={{ marginBottom: 10 }}>Email</Typography>
                                <Controller style={{ marginTop: 0 }}
                                    control={control}
                                    rules={{
                                        required: { value: true, message: "Email team is required." },
                                        min: { value: 0, message: "Email team much be greater than or equal 0." }
                                    }}
                                    name="email"
                                    render={o => <TextFieldValidate placeholder={"กรอก Email"} vo={o} type='text' errorMessage={errors.email?.message} />} />

                            </Grid>

                            <Grid item flexDirection={"column"} xs={12} sm={12} md={5}>
                                <Typography component="h6" variant="h6" style={{ marginBottom: 10 }}>ขนาดพื้นที่</Typography>
                                <Controller style={{ marginTop: 0 }}
                                    control={control}
                                    rules={{
                                        required: { value: true, message: "size team is required." },
                                        min: { value: 0, message: "size team much be greater than or equal 0." }
                                    }}
                                    name="size"
                                    render={o => <TextFieldValidate placeholder={"กรอกขนาดพื้นที่"} vo={o}
                                        type='text' errorMessage={errors.size?.message}
                                        isDisabled={true} display={'none'} />
                                    } />
                                <TextField style={{ marginTop: 0 }}
                                    value={disPlayPlotSize}
                                    disabled={true}
                                    type={'text'}
                                    inputProps={{
                                        maxLength: '200',
                                    }}
                                    multiline={false}
                                    helperText={errors.size?.message}
                                    error={errors.size?.message !== undefined && errors.size?.message !== ''}
                                    fullWidth size="small" margin="normal" variant="outlined" />
                            </Grid>

                            <Grid item flexDirection={"column"} xs={12} sm={12} md={5}>
                                <Typography component="h6" variant="h6" style={{ marginBottom: 10 }}>ชื่อ</Typography>
                                <Controller style={{ marginTop: 0 }}
                                    control={control}
                                    rules={{
                                        required: { value: true, message: "firstName team is required." },
                                        min: { value: 0, message: "firstName team much be greater than or equal 0." }
                                    }}
                                    name="firstName"
                                    render={o => <TextFieldValidate placeholder={"กรอกชื่อ"} vo={o} type='text' errorMessage={errors.firstName?.message} />} />
                            </Grid>

                            <Grid item flexDirection={"column"} xs={12} sm={12} md={5}>
                                <Typography component="h6" variant="h6" style={{ marginBottom: 10 }}>นามสกุล</Typography>
                                <Controller style={{ marginTop: 0 }}
                                    control={control}
                                    rules={{
                                        required: { value: true, message: "lastName team is required." },
                                        min: { value: 0, message: "lastName team much be greater than or equal 0." }
                                    }}
                                    name="lastName"
                                    render={o => <TextFieldValidate placeholder={"กรอกนามสกุล"} vo={o} type='text' errorMessage={errors.lastName?.message} />} />
                            </Grid>

                            <Grid item flexDirection={"column"} xs={12} sm={12} md={5}>
                                <Typography component="h6" variant="h6" style={{ marginBottom: 10 }}>หมายเลขเกษตรกร</Typography>
                                <Controller style={{ marginTop: 0 }}
                                    control={control}
                                    rules={{
                                        required: { value: true, message: "uid team is required." },
                                        min: { value: 0, message: "uid team much be greater than or equal 0." }
                                    }}
                                    name="uid"
                                    render={o => <TextFieldValidate placeholder={"กรอกหมายเลขเกษตรกร"} vo={o} type='text' errorMessage={errors.uid?.message} />} />
                            </Grid>

                            <Grid item flexDirection={"column"} xs={12} sm={12} md={5}>
                                <Typography component="h6" variant="h6" >หมายเลขบัตรประชาชน</Typography>
                                <Controller
                                    control={control}
                                    rules={{
                                        required: { value: true, message: "กรุณากรอกเลขบัตรประจําตัวประชาชน" },
                                    }}
                                    name="cardId"
                                    render={({ field: { value, onChange, ref } }) =>
                                        <TextField style={{ marginTop: 8 }}
                                            // label="กรุณากรอกเลขบัตรประจำตัวประชาชน 13 หลัก"
                                            value={value ?? ""}
                                            onChange={onChange}
                                            disabled={false}
                                            inputRef={ref}
                                            type="text"
                                            {...register("cardId", {
                                                required: { value: messageTax, message: "cardId is required." },
                                                minLength: {
                                                    value: 10,
                                                    message: "กรุณากรอกหมายเลขโทรศัพท์ 10 หลัก"
                                                }
                                            })}
                                            helperText={messageTax !== true ? <div style={{ color: 'red' }}>กรุณากรอกเลขบัตรประจําตัวประชาชนให้ถูกต้อง</div> : errors.cardId?.message}
                                            // helperText={messageTax !== true ? <div style={{ color: 'red' }}>กรุณากรอกเลขบัตรประจําตัวประชาชนให้ถูกต้อง</div> : "กรุณากรอกเลขบัตรประจําตัวประชาชน 13 หลัก"}
                                            error={errors.cardId?.message !== undefined && errors.cardId?.message !== ''}
                                            fullWidth size="small" margin="normal" variant="outlined"
                                            inputProps={{ maxLength: 13 }}
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item flexDirection={"column"} xs={12} sm={12} md={5}>
                                <Typography component="h6" variant="h6" style={{ marginBottom: 10 }}>เบอร์โทรศัพท์</Typography>
                                <Controller style={{ marginTop: 0 }}
                                    control={control}
                                    rules={{
                                        required: { value: true, message: "phoneNumber team is required." },
                                        min: { value: 0, message: "phoneNumber team much be greater than or equal 0." }
                                    }}
                                    name="phoneNumber"
                                    render={o => <TextFieldValidate placeholder={"กรอกเบอร์โทรศัพท์"} maxLength={10} vo={o} type='text' errorMessage={errors.phoneNumber?.message} />} />
                            </Grid>

                            <Grid item flexDirection={"column"} xs={12} sm={12} md={5}>
                                <Typography component="h6" variant="h6" style={{ marginBottom: 10 }}>ที่อยู่ เกษตรกร</Typography>
                                <Controller style={{ marginTop: 0 }}
                                    control={control}
                                    rules={{
                                        required: { value: true, message: "addressUser team is required." },
                                        min: { value: 0, message: "addressUser team much be greater than or equal 0." }
                                    }}
                                    name="addressUser"
                                    render={o => <TextFieldValidate placeholder={"กรอกที่อยู่เกษตรกร"} vo={o} type='text' errorMessage={errors.addressUser?.message} />} />
                            </Grid>

                            <Grid item flexDirection={"column"} xs={12} sm={12} md={5}>
                                <Typography component="h6" variant="h6" style={{ marginBottom: 10 }}>ชื่อแปลง</Typography>
                                <Controller style={{ marginTop: 0 }}
                                    control={control}
                                    rules={{
                                        required: { value: true, message: "plotName team is required." },
                                        min: { value: 0, message: "plotName team much be greater than or equal 0." }
                                    }}
                                    name="plotName"
                                    render={o => <TextFieldValidate placeholder={"กรอกชื่อแปลง"} vo={o} type='text' errorMessage={errors.plotName?.message} />} />
                            </Grid>

                            <Grid item flexDirection={"column"} xs={12} sm={12} md={5}>
                                <Typography component="h6" variant="h6" style={{ marginBottom: 10 }}>หมายเลขโฉนด</Typography>
                                <Controller style={{ marginTop: 0 }}
                                    control={control}
                                    rules={{
                                        required: { value: true, message: "deedNo team is required." },
                                        min: { value: 0, message: "deedNo team much be greater than or equal 0." }
                                    }}
                                    name="deedNo"
                                    render={o => <TextFieldValidate placeholder={"กรอกหมายเลขโฉนด"} vo={o} type='text' errorMessage={errors.deedNo?.message} />} />
                            </Grid>

                            <Grid item flexDirection={"column"} xs={12} sm={12} md={5}>
                                <Typography component="h6" variant="h6" style={{ marginBottom: 10 }}>ที่อยู่ แปลง</Typography>
                                <Controller style={{ marginTop: 0 }}
                                    control={control}
                                    rules={{
                                        required: { value: true, message: "addressPlot team is required." },
                                        min: { value: 0, message: "addressPlot team much be greater than or equal 0." }
                                    }}
                                    name="addressPlot"
                                    render={o => <TextFieldValidate placeholder={"กรอกที่อยู่แปลง"} vo={o} type='text' errorMessage={errors.addressPlot?.message} />} />
                            </Grid>

                            <Grid item flexDirection={"column"} xs={12} sm={12} md={5}>
                                <Typography component="h6" variant="h6" style={{ marginBottom: 10 }}>รายละเอียดแปลง</Typography>
                                <Controller style={{ marginTop: 0 }}
                                    control={control}
                                    rules={{
                                        required: { value: true, message: "detail team is required." },
                                        min: { value: 0, message: "detail team much be greater than or equal 0." }
                                    }}
                                    name="detail"
                                    render={o => <TextFieldValidate placeholder={"กรอกรายละเอียดแปลง"} vo={o} type='text' errorMessage={errors.detail?.message} />} />
                            </Grid>

                        </Grid>
                    </Paper>
                </form>

                {isDisableFarm &&
                    <>
                        {isDisableInProgressFarm &&
                            <Paper elevation={6} className={className.borderRadius10} style={{ backgroundColor: '#12203A' }}>
                                <Grid container direction={'row'} style={{ paddingLeft: 40, paddingTop: 40 }}>
                                    <Typography component="h6" variant="h6" style={{ marginBottom: 10, color: '#F3AE11' }}>กำลังดำเนินการ</Typography>
                                </Grid>

                                <Grid container direction={'row'} style={{ padding: 40, paddingTop: 0, paddingBottom: 0, justifyContent: 'space-around' }}>

                                    <Grid item flexDirection={"column"} xs={3} sm={12} md={3}>
                                        <Typography component="h6" variant="h6" style={{ marginBottom: 10, color: '#F3AE11' }}>ชนิดพืช</Typography>
                                        <FormControl style={{ width: '100%', marginBottom: 10, backgroundColor: 'lightgray', borderRadius: 10, color: 'white' }}>
                                            <Select
                                                disabled
                                                size="small"
                                                value={plantSelectedFarmProcessing ?? false}
                                                onChange={e => {
                                                    setPlantSelectedFarmProcessing(e.target.value)
                                                }}
                                            >
                                                <MenuItem value={false} disabled>
                                                    <em>เลือกพืช</em>
                                                </MenuItem>
                                                {listPlant.map((row, i) => {
                                                    return <MenuItem key={i} value={row.id}>{row.name}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item flexDirection={"column"} xs={3} sm={12} md={3}>
                                        <Typography component="h6" variant="h6" style={{ marginBottom: 10, color: '#F3AE11' }}>พันธ์พืช</Typography>
                                        <FormControl style={{ width: '100%', marginBottom: 10, backgroundColor: 'lightgray', borderRadius: 10, color: 'white' }}>
                                            <Select
                                                disabled
                                                style={{ color: 'white' }}
                                                ClassName={className.select}
                                                size="small"
                                                value={plantTypeSelectedFarmProcessing ?? false}
                                                onChange={e => {
                                                    setPlantTypeSelectedFarmProcessing(e.target.value)
                                                }}
                                            // error={errors.enterprisePositionId?.message}
                                            // helperText={errors.enterprisePositionId?.message}
                                            >
                                                <MenuItem value={false} disabled>
                                                    <em>เลือกพันธ์พืช</em>
                                                </MenuItem>
                                                {listPlantTypeProcessing.map((row, i) => {
                                                    return <MenuItem key={i} value={row.id}>{row.name}</MenuItem>
                                                })}
                                            </Select>
                                            {/* {errors.enterprisePositionId?.message ? <FormHelperText error>{errors.enterprisePositionId?.message}</FormHelperText> : ""} */}
                                        </FormControl>
                                    </Grid>

                                    <Grid item flexDirection={"column"} xs={3} sm={12} md={3}>
                                        <Typography component="h6" variant="h6" style={{ marginBottom: 10, color: '#F3AE11' }}>ค่ามาตรฐาน</Typography>
                                        <FormControl style={{ width: '100%', marginBottom: 10, backgroundColor: 'lightgray', borderRadius: 10, color: 'white' }}>
                                            <Select
                                                disabled
                                                style={{ color: 'white' }}
                                                ClassName={className.select}
                                                size="small"
                                                value={standardSelectedFarmProcessing ?? false}
                                                onChange={e => {
                                                    setStandardSelectedFarmProcessing(e.target.value)
                                                }}
                                            // error={errors.enterprisePositionId?.message}
                                            // helperText={errors.enterprisePositionId? .message}
                                            >
                                                <MenuItem value={false} disabled>
                                                    <em>เลือกค่ามาตรฐาน</em>
                                                </MenuItem>
                                                {listStandard.map((row, i) => {
                                                    return <MenuItem key={i} value={row.id}>{row.name}</MenuItem>
                                                })}
                                            </Select>
                                            {/* {errors.enterprisePositionId?.message ? <FormHelperText error>{errors.enterprisePositionId?.message}</FormHelperText> : ""} */}
                                        </FormControl>
                                    </Grid>

                                </Grid>

                                <Grid container direction={'row'} style={{ padding: 40, paddingBottom: 0, paddingTop: 0, justifyContent: 'space-around' }}>
                                    <Grid item flexDirection={"column"} xs={3} sm={12} md={3}>
                                        <Typography component="h6" variant="h6" style={{ marginBottom: 10, color: '#F3AE11' }}>วันที่ปลูก</Typography>
                                        <TextField size='small'
                                            disabled
                                            style={{ width: '100%', marginBottom: 10, backgroundColor: 'lightgray', borderRadius: 10 }}
                                            variant="outlined"
                                            type="date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                style: { color: 'white' },
                                                min: new Date().toISOString().slice(0, 10),
                                            }}
                                            className={className.input}
                                            value={startDateFarmProcessing}
                                            onChange={(e) => {
                                                setStandardSelectedFarmProcessing(e.target.value)
                                            }}
                                        />
                                    </Grid>

                                    <Grid item flexDirection={"column"} xs={3} sm={12} md={3}>
                                        <Typography component="h6" variant="h6" style={{ marginBottom: 10, color: '#F3AE11' }}>วันที่เก็บเกี่ยว</Typography>
                                        <TextField size='small'
                                            disabled
                                            style={{ width: '100%', marginBottom: 10, backgroundColor: 'lightgray', borderRadius: 10 }}
                                            variant="outlined"
                                            type="date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                style: { color: 'white' }
                                            }}
                                            value={endDateFarmProcessing}
                                            onChange={(e) => {
                                                setEndDateFarmProcessing(e.target.value)
                                            }}
                                        />
                                    </Grid>

                                    <Grid item flexDirection={"column"} xs={3} sm={12} md={3}>
                                        <Typography component="h6" variant="h6" style={{ marginBottom: 10, color: '#F3AE11' }}>หน่วยของผลผลิต</Typography>
                                        <FormControl style={{ width: '100%', marginBottom: 10, backgroundColor: 'lightgray', borderRadius: 10, color: 'white' }}>
                                            <Select
                                                disabled
                                                size="small"
                                                value={measurementSelectedFarmProcessing ?? false}
                                                onChange={e => {
                                                    setMeasurementSelectedFarmProcessing(e.target.value)
                                                }}
                                            // error={errors.enterprisePositionId?.message}
                                            // helperText={errors.enterprisePositionId?.message}
                                            >
                                                <MenuItem value={false} disabled>
                                                    <em>เลือกหน่วยวัด</em>
                                                </MenuItem>
                                                {listMeasurementWeight.map((row, i) => {
                                                    return <MenuItem key={i} value={row.id}>{row.name}</MenuItem>
                                                })}
                                            </Select>
                                            {/* {errors.enterprisePositionId?.message ? <FormHelperText error>{errors.enterprisePositionId?.message}</FormHelperText> : ""} */}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container direction={'row'} style={{ padding: 40, paddingTop: 1, justifyContent: 'space-around' }}>
                                    <Grid item flexDirection={"column"} xs={3} sm={12} md={3}>
                                        <Typography component="h6" variant="h6" style={{ marginBottom: 10, color: '#F3AE11' }}>ผลผลิต</Typography>
                                        <TextField size='small'
                                            type={'number'}
                                            style={{ width: '100%', marginBottom: 10, backgroundColor: 'gray', borderRadius: 10 }}
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                style: { color: 'white' }
                                            }}
                                            value={quantityProductFarmProcessing}
                                            onChange={(e) => {
                                                setQuantityProductFarmProcessing(e.target.value)
                                            }}
                                        />
                                    </Grid>

                                    <Grid item flexDirection={"column"} xs={3} sm={12} md={3}></Grid>
                                    <Grid item flexDirection={"column"} xs={3} sm={12} md={3}></Grid>
                                </Grid>

                                <Grid container direction={'row'} style={{ padding: 40, paddingTop: 0, justifyContent: 'space-around' }}>
                                    <Grid item flexDirection={"column"} xs={3} sm={12} md={3}>
                                        <Button onClick={() => {
                                            onFinishFarm(plantTypeSelectedFarmProcessing,
                                                standardSelectedFarmProcessing, startDateFarmProcessing,
                                                endDateFarmProcessing, farmProcessingId,
                                                isProcessingFarmProcessing, quantityProductFarmProcessing,
                                                measurementSelectedFarmProcessing)
                                        }}
                                            style={{
                                                width: '100%', color: '#12203A', borderRadius: 10,
                                                backgroundColor: '#F3AE11',
                                            }} variant="contained"
                                        >
                                            บันทึกการเก็บเกี่ยวฤดูกาลนี้
                                        </Button>
                                    </Grid>

                                </Grid>

                            </Paper>}

                        {isDisablePandingFarm &&
                            <Paper elevation={6} className={className.borderRadius10} style={{ backgroundColor: '#F3AE11' }}>
                                <Grid container direction={'row'} style={{ paddingLeft: 40, paddingTop: 40 }}>
                                    <Typography component="h6" variant="h6" style={{ marginBottom: 10, color: '#12203A' }}>กำหนดการที่รอดำเนินการ</Typography>
                                </Grid>

                                <Grid container direction={'row'} style={{ padding: 40, paddingTop: 0, paddingBottom: 0, justifyContent: 'space-around' }}>

                                    <Grid item flexDirection={"column"} xs={3} sm={12} md={3}>
                                        <Typography component="h6" variant="h6" style={{ marginBottom: 10, color: '#12203A' }}>ชนิดพืช</Typography>
                                        <FormControl style={{ width: '100%', marginBottom: 10, backgroundColor: 'gray', borderRadius: 10, color: 'white' }}>
                                            <Select
                                                style={{ color: 'white' }}
                                                ClassName={className.select}
                                                size="small"
                                                value={plantSelectedFarmPending ?? false}
                                                onChange={e => {
                                                    setPlantSelectedFarmPending(e.target.value)
                                                    loadPlantTypePending(e.target.value)
                                                }}
                                            // error={errors.enterprisePositionId?.message}
                                            // helperText={errors.enterprisePositionId?.message}
                                            >
                                                <MenuItem value={false} disabled>
                                                    <em>เลือกพืช</em>
                                                </MenuItem>
                                                {listPlant.map((row, i) => {
                                                    return <MenuItem key={i} value={row.id}>{row.name}</MenuItem>
                                                })}
                                            </Select>
                                            {/* {errors.enterprisePositionId?.message ? <FormHelperText error>{errors.enterprisePositionId?.message}</FormHelperText> : ""} */}
                                        </FormControl>
                                    </Grid>

                                    <Grid item flexDirection={"column"} xs={3} sm={12} md={3}>
                                        <Typography component="h6" variant="h6" style={{ marginBottom: 10, color: '#12203A' }}>พันธ์พืช</Typography>
                                        <FormControl style={{ width: '100%', marginBottom: 10, backgroundColor: 'gray', borderRadius: 10, color: 'white' }}>
                                            <Select
                                                style={{ color: 'white' }}
                                                ClassName={className.select}
                                                size="small"
                                                value={plantTypeSelectedFarmPending ?? false}
                                                onChange={e => {
                                                    setPlantTypeSelectedFarmPending(e.target.value)
                                                }}
                                            // error={errors.enterprisePositionId?.message}
                                            // helperText={errors.enterprisePositionId?.message}
                                            >
                                                <MenuItem value={false} disabled>
                                                    <em>เลือกพืช</em>
                                                </MenuItem>
                                                {listPlantTypePending.map((row, i) => {
                                                    return <MenuItem key={i} value={row.id}>{row.name}</MenuItem>
                                                })}
                                            </Select>
                                            {/* {errors.enterprisePositionId?.message ? <FormHelperText error>{errors.enterprisePositionId?.message}</FormHelperText> : ""} */}
                                        </FormControl>
                                    </Grid>

                                    <Grid item flexDirection={"column"} xs={3} sm={12} md={3}>
                                        <Typography component="h6" variant="h6" style={{ marginBottom: 10, color: '#12203A' }}>ค่ามาตรฐาน</Typography>
                                        <FormControl style={{ width: '100%', marginBottom: 10, backgroundColor: 'gray', borderRadius: 10, color: 'white' }}>
                                            <Select
                                                style={{ color: 'white' }}
                                                ClassName={className.select}
                                                size="small"
                                                value={standardSelectedFarmPending ?? false}
                                                onChange={e => {
                                                    setStandardSelectedFarmPending(e.target.value)
                                                    onGetEndDateFarmPendingByStandardId(e.target.value)
                                                    //todo set
                                                }}
                                            // error={errors.enterprisePositionId?.message}
                                            // helperText={errors.enterprisePositionId?.message}
                                            >
                                                <MenuItem value={false} disabled>
                                                    <em>เลือกพืช</em>
                                                </MenuItem>
                                                {listStandard.map((row, i) => {
                                                    return <MenuItem key={i} value={row.id}>{row.name}</MenuItem>
                                                })}
                                            </Select>
                                            {/* {errors.enterprisePositionId?.message ? <FormHelperText error>{errors.enterprisePositionId?.message}</FormHelperText> : ""} */}
                                        </FormControl>
                                    </Grid>

                                </Grid>

                                <Grid container direction={'row'} style={{ padding: 40, paddingTop: 0, justifyContent: 'space-around' }}>

                                    <Grid item flexDirection={"column"} xs={3} sm={12} md={3}>
                                        <Typography component="h6" variant="h6" style={{ marginBottom: 10, color: '#12203A' }}>วันที่ปลูก</Typography>
                                        <TextField size='small'
                                            style={{ width: '100%', marginBottom: 10, backgroundColor: 'gray', borderRadius: 10 }}
                                            id="filled-disabled"
                                            variant="outlined"
                                            type="date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                style: { color: 'white' }
                                            }}
                                            inputProps={{
                                                min: new Date().toISOString().slice(0, 10),
                                            }}
                                            value={startDateFarmPending}
                                            onChange={(e) => {
                                                setStartDateFarmPending(e.target.value)
                                                setEndDateFarmPending(dayjs(e.target.value).add(amountDaysFarmPending, 'day').format("YYYY-MM-DD"))
                                            }}
                                        />
                                    </Grid>

                                    <Grid item flexDirection={"column"} xs={3} sm={12} md={3} >
                                        <Typography component="h6" variant="h6" style={{ marginBottom: 10, color: '#12203A' }}>วันที่เก็บเกี่ยว</Typography>
                                        <TextField size='small'
                                            style={{ width: '100%', marginBottom: 10, backgroundColor: 'gray', borderRadius: 10 }}
                                            disabled
                                            id="filled-disabled"
                                            variant="outlined"
                                            type="date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                style: { color: 'white' }
                                            }}
                                            value={endDateFarmPending}
                                        // onChange={(e) => {
                                        //   setEndDateFarmPending(e.target.value)
                                        // }}
                                        />
                                    </Grid>

                                    <Grid item flexDirection={"column"} xs={3} sm={12} md={3}>
                                        <Typography component="h6" variant="h6" style={{ marginBottom: 10, color: '#12203A' }}>หน่วยของผลผลิต</Typography>
                                        <FormControl style={{ width: '100%', marginBottom: 10, backgroundColor: 'gray', borderRadius: 10, color: 'white' }}>
                                            <Select
                                                size="small"
                                                value={measurementSelectedFarmPending ?? false}
                                                onChange={e => {
                                                    setMeasurementSelectedFarmPending(e.target.value)
                                                }}
                                            // error={errors.enterprisePositionId?.message}
                                            // helperText={errors.enterprisePositionId?.message}
                                            >
                                                <MenuItem value={false} disabled>
                                                    <em>เลือกหน่วยวัด</em>
                                                </MenuItem>
                                                {listMeasurementWeight.map((row, i) => {
                                                    return <MenuItem key={i} value={row.id}>{row.name}</MenuItem>
                                                })}
                                            </Select>
                                            {/* {errors.enterprisePositionId?.message ? <FormHelperText error>{errors.enterprisePositionId?.message}</FormHelperText> : ""} */}
                                        </FormControl>
                                    </Grid>

                                </Grid>

                                <Grid item container direction={'row'} style={{ padding: 40, paddingTop: 0, justifyContent: 'center' }}>
                                    <Button onClick={() => { onDeleteFarmPending(farmPendingId) }}
                                        style={{
                                            width: '20%', color: '#12203A', borderRadius: 10,
                                            backgroundColor: 'white', marginRight: 20
                                        }} variant="contained"
                                    >
                                        ลบ
                                    </Button>

                                    <Button onClick={() => {
                                        onUpdateFarmPending(plantTypeSelectedFarmPending,
                                            standardSelectedFarmPending, startDateFarmPending,
                                            endDateFarmPending, farmPendingId, isProcessingFarmPending,
                                            measurementSelectedFarmPending)
                                    }}
                                        style={{
                                            width: '20%', color: 'white', borderRadius: 10,
                                            backgroundColor: '#12203A',
                                        }} variant="contained"
                                    >
                                        แก้ไข
                                    </Button>

                                </Grid>

                            </Paper >
                        }

                        {farmProcessingId == null
                            ? <Paper Paper elevation={6} className={className.borderRadius10} >
                                <Grid container direction={'row'} style={{ paddingLeft: 40, paddingTop: 40 }}>
                                    <Typography component="h6" variant="h6" style={{ marginBottom: 10, color: '#12203A' }}>กำหนดการช่วงเวลาเก็บเกี่ยว</Typography>
                                </Grid>

                                <Grid container direction={'row'} style={{ padding: 40, paddingTop: 0, paddingBottom: 0, justifyContent: 'space-around' }}>

                                    <Grid item flexDirection={"column"} xs={3} sm={12} md={3}>
                                        <Typography component="h6" variant="h6" style={{ marginBottom: 10, color: '#12203A' }}>ชนิดพืช</Typography>
                                        <FormControl style={{ width: '100%', marginBottom: 10 }}>
                                            <Select
                                                size="small"
                                                value={plantSelected ?? false}
                                                onChange={e => {
                                                    setPlantSelected(e.target.value)
                                                    loadPlantType(e.target.value)
                                                }}
                                            // error={errors.enterprisePositionId?.message}
                                            // helperText={errors.enterprisePositionId?.message}
                                            >
                                                <MenuItem value={false} disabled>
                                                    <em>เลือกพืช</em>
                                                </MenuItem>
                                                {listPlant.map((row, i) => {
                                                    return <MenuItem key={i} value={row.id}>{row.name}</MenuItem>
                                                })}
                                            </Select>
                                            {/* {plantSelected ? <FormHelperText error>กรอกข้อมูล ชนิดพืช</FormHelperText> : ""} */}
                                        </FormControl>

                                    </Grid>

                                    <Grid item flexDirection={"column"} xs={3} sm={12} md={3}>
                                        <Typography component="h6" variant="h6" style={{ marginBottom: 10, color: '#12203A' }}>พันธ์พืช</Typography>
                                        <FormControl style={{ width: '100%', marginBottom: 10 }}>
                                            <Select
                                                size="small"
                                                value={plantTypeSelected ?? false}
                                                onChange={e => {
                                                    setPlantTypeSelected(e.target.value)
                                                }}
                                            // error={errors.enterprisePositionId?.message}
                                            // helperText={errors.enterprisePositionId?.message}
                                            >
                                                <MenuItem value={false} disabled>
                                                    <em>เลือกพันธ์พืช</em>
                                                </MenuItem>
                                                {listPlantType.map((row, i) => {
                                                    return <MenuItem key={i} value={row.id}>{row.name}</MenuItem>
                                                })}
                                            </Select>
                                            {/* {errors.enterprisePositionId?.message ? <FormHelperText error>{errors.enterprisePositionId?.message}</FormHelperText> : ""} */}
                                        </FormControl>
                                    </Grid>

                                    <Grid item flexDirection={"column"} xs={3} sm={12} md={3}>
                                        <Typography component="h6" variant="h6" style={{ marginBottom: 10, color: '#12203A' }}>ค่ามาตรฐาน</Typography>
                                        <FormControl style={{ width: '100%', marginBottom: 10 }}>
                                            <Select
                                                size="small"
                                                value={standardSelected ?? false}
                                                onChange={e => {
                                                    setStandardSelected(e.target.value)
                                                    onGetEndDateFarmByStandardId(e.target.value)
                                                }}
                                            // error={errors.enterprisePositionId?.message}
                                            // helperText={errors.enterprisePositionId?.message}
                                            >
                                                <MenuItem value={false} disabled>
                                                    <em>เลือกพันธ์พืช</em>
                                                </MenuItem>
                                                {listStandard.map((row, i) => {
                                                    return <MenuItem key={i} value={row.id}>{row.name}</MenuItem>
                                                })}
                                            </Select>
                                            {/* {errors.enterprisePositionId?.message ? <FormHelperText error>{errors.enterprisePositionId?.message}</FormHelperText> : ""} */}
                                        </FormControl>
                                    </Grid>

                                </Grid>

                                <Grid container direction={'row'} style={{ padding: 40, paddingTop: 0, justifyContent: 'space-around' }}>

                                    <Grid item flexDirection={"column"} xs={3} sm={12} md={3} >
                                        <Typography component="h6" variant="h6" style={{ marginBottom: 10, color: '#12203A' }}>วันที่ปลูก</Typography>
                                        <TextField size='small'
                                            style={{ width: '100%', marginBottom: 10 }}
                                            variant="outlined"
                                            type="date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={startDate}
                                            // inputProps={{
                                            //     min: new Date().toISOString().slice(0, 10),
                                            // }}
                                            onChange={(e) => {
                                                setStartDate(e.target.value)
                                                setEndDate(dayjs(e.target.value).add(amountDays, 'day').format("YYYY-MM-DD"))
                                            }}
                                        />
                                    </Grid>

                                    <Grid item flexDirection={"column"} xs={3} sm={12} md={3} >
                                        <Typography component="h6" variant="h6" style={{ marginBottom: 10, color: '#12203A' }}>วันที่เก็บเกี่ยว</Typography>
                                        <TextField size='small'
                                            style={{ width: '100%', marginBottom: 10 }}
                                            variant="outlined"
                                            type="date"
                                            disabled
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={endDate}
                                        // onChange={(e) => {
                                        //   setEndDate(e.target.value)
                                        // }}
                                        />
                                    </Grid>

                                    <Grid item flexDirection={"column"} xs={3} sm={12} md={3}>
                                        <Typography component="h6" variant="h6" style={{ marginBottom: 10, color: '#12203A' }}>หน่วยของผลผลิต</Typography>
                                        <FormControl style={{ width: '100%', marginBottom: 10 }}>
                                            <Select
                                                size="small"
                                                value={measurementWeightSelected ?? false}
                                                onChange={e => {
                                                    setMeasurementWeightSelected(e.target.value)
                                                }}
                                            // error={errors.enterprisePositionId?.message}
                                            // helperText={errors.enterprisePositionId?.message}
                                            >
                                                <MenuItem value={false} disabled>
                                                    <em>เลือกหน่วยวัด</em>
                                                </MenuItem>
                                                {listMeasurementWeight.map((row, i) => {
                                                    return <MenuItem key={i} value={row.id}>{row.name}</MenuItem>
                                                })}
                                            </Select>
                                            {/* {errors.enterprisePositionId?.message ? <FormHelperText error>{errors.enterprisePositionId?.message}</FormHelperText> : ""} */}
                                        </FormControl>
                                    </Grid>

                                </Grid>

                                <Grid container direction={'row'} style={{ padding: 40, paddingTop: 0, justifyContent: 'space-around' }}>
                                    <Grid item flexDirection={"column"} xs={3} sm={12} md={3} >
                                        <Button onClick={() => { onSubmitFarm() }}
                                            style={{
                                                width: '100%', color: '#12203A', borderRadius: 10,
                                                backgroundColor: '#F3AE11',
                                            }} variant="contained"
                                        >
                                            เพิ่มช่วงเวลาเก็บเกี่ยว
                                        </Button>
                                    </Grid>

                                </Grid>

                            </Paper>
                            : <Paper Paper elevation={6} className={className.borderRadius10} >
                                <Grid container direction={'row'} style={{ paddingLeft: 40, paddingTop: 40 }}>
                                    <Typography component="h6" variant="h6" style={{ marginBottom: 10, color: '#12203A' }}>กำหนดการช่วงเวลาเก็บเกี่ยว</Typography>
                                </Grid>

                                <Grid container direction={'row'} style={{ padding: 40, paddingTop: 0, paddingBottom: 0, justifyContent: 'space-around' }}>

                                    <Grid item flexDirection={"column"} xs={3} sm={12} md={3}>
                                        <Typography component="h6" variant="h6" style={{ marginBottom: 10, color: '#12203A' }}>ชนิดพืช</Typography>
                                        <FormControl style={{ width: '100%', marginBottom: 10 }}>
                                            <Select
                                                size="small"
                                                value={plantSelected ?? false}
                                                onChange={e => {
                                                    setPlantSelected(e.target.value)
                                                    loadPlantType(e.target.value)
                                                }}
                                            // error={errors.enterprisePositionId?.message}
                                            // helperText={errors.enterprisePositionId?.message}
                                            >
                                                <MenuItem value={false} disabled>
                                                    <em>เลือกพืช</em>
                                                </MenuItem>
                                                {listPlant.map((row, i) => {
                                                    return <MenuItem key={i} value={row.id}>{row.name}</MenuItem>
                                                })}
                                            </Select>
                                            {/* {plantSelected ? <FormHelperText error>กรอกข้อมูล ชนิดพืช</FormHelperText> : ""} */}
                                        </FormControl>

                                    </Grid>

                                    <Grid item flexDirection={"column"} xs={3} sm={12} md={3}>
                                        <Typography component="h6" variant="h6" style={{ marginBottom: 10, color: '#12203A' }}>พันธ์พืช</Typography>
                                        <FormControl style={{ width: '100%', marginBottom: 10 }}>
                                            <Select
                                                size="small"
                                                value={plantTypeSelected ?? false}
                                                onChange={e => {
                                                    setPlantTypeSelected(e.target.value)
                                                }}
                                            // error={errors.enterprisePositionId?.message}
                                            // helperText={errors.enterprisePositionId?.message}
                                            >
                                                <MenuItem value={false} disabled>
                                                    <em>เลือกพันธ์พืช</em>
                                                </MenuItem>
                                                {listPlantType.map((row, i) => {
                                                    return <MenuItem key={i} value={row.id}>{row.name}</MenuItem>
                                                })}
                                            </Select>
                                            {/* {errors.enterprisePositionId?.message ? <FormHelperText error>{errors.enterprisePositionId?.message}</FormHelperText> : ""} */}
                                        </FormControl>
                                    </Grid>

                                    <Grid item flexDirection={"column"} xs={3} sm={12} md={3}>
                                        <Typography component="h6" variant="h6" style={{ marginBottom: 10, color: '#12203A' }}>ค่ามาตรฐาน</Typography>
                                        <FormControl style={{ width: '100%', marginBottom: 10 }}>
                                            <Select
                                                size="small"
                                                value={standardSelected ?? false}
                                                onChange={e => {
                                                    setStandardSelected(e.target.value)
                                                    onGetEndDateFarmByStandardId(e.target.value)
                                                }}
                                            // error={errors.enterprisePositionId?.message}
                                            // helperText={errors.enterprisePositionId?.message}
                                            >
                                                <MenuItem value={false} disabled>
                                                    <em>เลือกพันธ์พืช</em>
                                                </MenuItem>
                                                {listStandard.map((row, i) => {
                                                    return <MenuItem key={i} value={row.id}>{row.name}</MenuItem>
                                                })}
                                            </Select>
                                            {/* {errors.enterprisePositionId?.message ? <FormHelperText error>{errors.enterprisePositionId?.message}</FormHelperText> : ""} */}
                                        </FormControl>
                                    </Grid>

                                </Grid>

                                <Grid container direction={'row'} style={{ padding: 40, paddingTop: 0, justifyContent: 'space-around' }}>

                                    <Grid item flexDirection={"column"} xs={3} sm={12} md={3} >
                                        <Typography component="h6" variant="h6" style={{ marginBottom: 10, color: '#12203A' }}>วันที่ปลูก</Typography>
                                        <TextField size='small'
                                            style={{ width: '100%', marginBottom: 10 }}
                                            variant="outlined"
                                            type="date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={startDate}
                                            inputProps={{
                                                min: new Date().toISOString().slice(0, 10),
                                            }}
                                            onChange={(e) => {
                                                setStartDate(e.target.value)
                                                setEndDate(dayjs(e.target.value).add(amountDays, 'day').format("YYYY-MM-DD"))
                                            }}
                                        />
                                    </Grid>

                                    <Grid item flexDirection={"column"} xs={3} sm={12} md={3} >
                                        <Typography component="h6" variant="h6" style={{ marginBottom: 10, color: '#12203A' }}>วันที่เก็บเกี่ยว</Typography>
                                        <TextField size='small'
                                            style={{ width: '100%', marginBottom: 10 }}
                                            variant="outlined"
                                            type="date"
                                            disabled
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={endDate}
                                        // onChange={(e) => {
                                        //   setEndDate(e.target.value)
                                        // }}
                                        />
                                    </Grid>

                                    <Grid item flexDirection={"column"} xs={3} sm={12} md={3}>
                                        <Typography component="h6" variant="h6" style={{ marginBottom: 10, color: '#12203A' }}>หน่วยของผลผลิต</Typography>
                                        <FormControl style={{ width: '100%', marginBottom: 10 }}>
                                            <Select
                                                size="small"
                                                value={measurementWeightSelected ?? false}
                                                onChange={e => {
                                                    setMeasurementWeightSelected(e.target.value)
                                                }}
                                            // error={errors.enterprisePositionId?.message}
                                            // helperText={errors.enterprisePositionId?.message}
                                            >
                                                <MenuItem value={false} disabled>
                                                    <em>เลือกหน่วยวัด</em>
                                                </MenuItem>
                                                {listMeasurementWeight.map((row, i) => {
                                                    return <MenuItem key={i} value={row.id}>{row.name}</MenuItem>
                                                })}
                                            </Select>
                                            {/* {errors.enterprisePositionId?.message ? <FormHelperText error>{errors.enterprisePositionId?.message}</FormHelperText> : ""} */}
                                        </FormControl>
                                    </Grid>

                                </Grid>

                                <Grid container direction={'row'} style={{ padding: 40, paddingTop: 0, justifyContent: 'space-around' }}>
                                    <Grid item flexDirection={"column"} xs={3} sm={12} md={3} >
                                        <Button onClick={() => { onSubmitFarm() }}
                                            style={{
                                                width: '100%', color: '#12203A', borderRadius: 10,
                                                backgroundColor: '#F3AE11',
                                            }} variant="contained"
                                        >
                                            เพิ่มช่วงเวลาเก็บเกี่ยว
                                        </Button>
                                    </Grid>

                                </Grid>

                            </Paper>

                        }
                        <TableContainer className={className.container}>
                            <Table stickyHeader aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <StyledTableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                            >
                                                {column.label}
                                            </StyledTableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {listFarmCompleted.map((x, i) => {
                                        return <StyledTableRow key={i}>
                                            <StyledTableCell >{i + 1}</StyledTableCell>
                                            <StyledTableCell align="center" >{x.plantName}</StyledTableCell>
                                            <StyledTableCell align="center" >{x.plantTypeName}</StyledTableCell>
                                            <StyledTableCell align="center" >{x.standardName}</StyledTableCell>
                                            <StyledTableCell align="center" >{x.quantityProduct} {x.measurementName}</StyledTableCell>
                                            <StyledTableCell align="center" >{dayjs(x.startDate).format("DD-MM-YYYY")}</StyledTableCell>
                                            <StyledTableCell align="center" >{dayjs(x.endDate).format("DD-MM-YYYY")}</StyledTableCell>
                                            <StyledTableCell align="center" >{dayjs(x.completedDate).format("DD-MM-YYYY")}</StyledTableCell>
                                        </StyledTableRow>
                                    })}
                                </TableBody>
                            </Table>
                            {listFarmCompleted.length === 0 ? <div style={{ display: 'flex', justifyContent: 'center' }}><Empty /></div> : null}
                        </TableContainer>
                    </>}
            </div >
        </>
    )
}

const useStyles = makeStyles({
    borderRadius10: {
        borderRadius: 10,
        marginTop: 20
    },
    divLine: {
        height: 2, backgroundColor: '#12203A',
        width: '100%', marginTop: 40, marginBottom: 40
    },
    container: {
        marginTop: 20
    },
})