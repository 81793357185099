import { Button, Card, Container, FormControl, FormHelperText, Grid, MenuItem, Select } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { ROUTE_PATH } from '../../constants/paths'
import { useNavigate, useParams } from 'react-router-dom'
import OutlinedInput from '@mui/material/OutlinedInput'
import SearchIcon from '@mui/icons-material/Search'
import { makeStyles } from '@mui/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { styled } from '@mui/material/styles'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import AreaSupervisorModal from '../../components/AreaSupervisorModal'
import { useSnackbar } from 'notistack'
import { Controller, useForm } from 'react-hook-form'
import getAllStandard from '../../services/standard'
import TextFieldValidate from '../../components/TextFieldValidate'
import { AddTeamInArea, createArea, getAreaById, getListTeamForAssignToArea, updateArea } from '../../services/area'

import Empty from '../../components/Empty'
import { API_RES_STATUS } from '../../constants/apiResponseStatus'
import { AppContext } from '../../contexts/AppContext'
import { ROLES } from '../../constants/enums'
import { getCategoryEmployee } from '../../services/common'
import { StyledTableCell, StyledTableRow } from '../../components/StyledTable'

export default function CreateAreaPage() {
    const { employee = {} } = useContext(AppContext)
    let { id: areaId } = useParams()
    const classes = useStyles()
    const navigation = useNavigate()
    const { control, handleSubmit, setValue, formState: { errors } } = useForm()
    const { enqueueSnackbar, /*closeSnackbar*/ } = useSnackbar()
    const [openModal, setOpenModal] = useState(false)
    const [listAreaManager, setListAreaManager] = useState([])
    const [listAreaManagerFull, setListAreaManagerFull] = useState([])
    const [areaSupervisor, setAreaSupervisor] = useState("")
    const [employeeId, setEmployeeId] = useState(null)
    const [listStandard, setListStandard] = useState([])
    const [loading, setLoading] = useState(false)
    const [teamSupervisor, setTeamSupervisor] = useState("")
    const [teamId, setTeamId] = useState(0)
    const [search, setSearch] = useState(null)
    const [listTeamForAssignToArea, setListTeamForAssignToArea] = useState([])
    const [empEnterpriseId, setEmpEnterpriseId] = useState()

    const [areaDetail, setAreaDetail] = useState({})

    useEffect(() => {
        if (!areaId) {
            loadListStandard(employeeId)
        }
        loadListAreaManager()
        if (areaId) { // edit mode
            loadAreaDetail()
        }
    }, [areaId])

    useEffect(() => {

        if (search !== null && search !== "") {
            let textSearch = setTimeout(async () => {
                try {
                    await loadListAreaManagerAfterSearch(search)
                } catch (error) {
                    enqueueSnackbar(error, { variant: 'error' })
                }
            }, 1000)

            return () => clearTimeout(textSearch)

        } else {
            let textSearch = setTimeout(async () => {
                try {
                    await loadListAreaManager()
                } catch (error) {
                    enqueueSnackbar(error, { variant: 'error' })
                }
            }, 1000)

            return () => clearTimeout(textSearch)
        }

    }, [search])

    const loadListAreaManager = async () => {
        const axiosRes = await getCategoryEmployee({ filterRole: ROLES.AreaManager })
        const { data, status, message } = JSON.parse(axiosRes.data)
        if (status === API_RES_STATUS.success) {
            setListAreaManager(data)
            setListAreaManagerFull(data)
        }
    }

    const loadListTeamForAssignToArea = async (enterpriseId) => {
        const axiosRes = await getListTeamForAssignToArea({ filterEnterpriseId: enterpriseId })
        const { data, status, message } = JSON.parse(axiosRes.data)
        if (status === API_RES_STATUS.success) {
            setListTeamForAssignToArea(data)
        }
    }

    const loadAreaDetail = async () => {
        setLoading(true)
        const res = await getAreaById(areaId)

        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            let data = json.data
            await loadListStandard(data.employeeId)
            await loadListTeamForAssignToArea(data.employeeEnterpriseId)
            setValue('name', data.name)
            setValue('standard', data.defaultStandardId)
            setAreaSupervisor(data.employeeName)
            setEmployeeId(data.employeeId)
            setAreaDetail(data)
            setEmpEnterpriseId(data.employeeEnterpriseId)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
        setLoading(false)
    }

    const loadListAreaManagerAfterSearch = async (search) => {

        let text = search.toLowerCase()
        let listAreaManagerAfterFilter = listAreaManagerFull.filter((Item) => {
            if (Item.enterpriseName.toLowerCase().match(text)
                || Item.firstName.toLowerCase().match(text)
                || Item.lastName.toLowerCase().match(text)
                || Item.enterprisePositionName.toLowerCase().match(text)
            )
                return Item
        })

        setListAreaManager(listAreaManagerAfterFilter)
    }

    const loadListStandard = async (empId) => {
        let id = areaId ? empId : empId ? empId : '00000000-0000-0000-0000-000000000000'
        const res = await getAllStandard({ staffId: id })
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            let data = json.data
            setListStandard(data.listData)
        } else {
            setValue("standard", 0)
            enqueueSnackbar(json.message, { variant: 'error' })
        }
    }

    const onClickOpenModal = () => {
        setOpenModal(true)
    }

    const onClickSubmint = async ({ id, firstName, lastName }) => {
        setAreaSupervisor(`${firstName} ${lastName}`)
        setEmployeeId(id)
        if (ROLES.AdminLing === employee.role) {  // <--- get new standard by enterprise of selected areaManager
            await loadListStandard(id)
            setValue("standard", 0)
        }
        setOpenModal(false)
    }

    const onClickCancel = () => {
        setOpenModal(false)
    }

    const onSubmitForm = async (data) => {
        if (areaId) {
            await onEdit(areaId, data)
        } else {
            await onCreate(data)
        }
    }

    const onCreate = async (data) => {
        if (data.standard === 0) {
            enqueueSnackbar('ไม่พบ ค่ามาตรฐาน กรุณาสร้าง ค่ามาตรฐาน ก่อน', { variant: 'error' })
        }
        if (employeeId === null) {
            enqueueSnackbar('ไม่พบ Area Manager กรุณาสร้าง Area Manager ก่อน', { variant: 'error' })
            return
        }

        const res = await createArea({ name: data.name, employeeId: employeeId, defaultStandardId: data.standard })
        let json = JSON.parse(res.data)

        if (json.status === API_RES_STATUS.success) {
            enqueueSnackbar('Create area successfully.', { variant: 'success' })
            navigation(ROUTE_PATH.EDIT_AREA.replace(":id", json.data))
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
    }

    const onEdit = async (id, data) => {

        if (data.standard === 0) {
            enqueueSnackbar('ไม่พบ ค่ามาตรฐาน กรุณาเลือกหรือสร้าง ค่ามาตรฐาน ก่อน', { variant: 'error' })
            return
        }
        if (employeeId === null) {
            enqueueSnackbar('ไม่พบ Area Manager กรุณาเลือก Area Manager ก่อน', { variant: 'error' })
            return
        }

        const res = await updateArea({
            id: id, name: data.name,
            employeeId: employeeId,
            defaultStandardId: data.standard
        })
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            enqueueSnackbar('Update area successfully.', { variant: 'success' })
            navigation(ROUTE_PATH.EDIT_AREA.replace(":id", json.data))
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
    }

    const onChangeSelect = async (_teamId) => {
        const { teamId, teamLeadFirstName = "", teamLeadLastName = "" } = listTeamForAssignToArea.find(team => team.teamId === _teamId) || {}
        setTeamSupervisor(`${teamLeadFirstName} ${teamLeadLastName}`)
        setTeamId(_teamId)
    }

    const onAddTeam = async () => {
        const res = await AddTeamInArea({ areaId: areaId, teamId: teamId })
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            enqueueSnackbar('เพิ่มทีมสำเร็จ', { variant: 'success' })
            loadAreaDetail()
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
    }

    const columns = [
        { label: "ลำดับ", minWidth: 50, align: 'left', },
        { label: "ชื่อทีม", minWidth: 120, align: 'center' },
        { label: "ผู้รับผิดชอบ", minWidth: 120, align: 'center' },
        { label: "จำนวน Staff", minWidth: 120, align: 'center' },
        { label: "จำนวนพื้นที่ทั้งหมด (แปลง)", minWidth: 120, align: 'center' },
        { label: "", minWidth: 120, align: 'center' },
    ]

    const { teamDetailModels = [] } = areaDetail

    return (
        <>
            <div style={{ margin: 40 }}>
                <form onSubmit={handleSubmit(onSubmitForm)}>
                    <Grid container >
                        <Grid item xs={6}>
                        </Grid>
                        <Grid item xs={6}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button onClick={() => navigation(ROUTE_PATH.AREA_MANAGEMENT)} style={{ color: '#12203A', borderColor: '#12203A', borderRadius: 5, marginRight: 20, width: '20%' }} variant="outlined">
                                    ยกเลิก
                                </Button>
                                {employee.role !== ROLES.TeamLead &&
                                    <Button type='submit' style={{ color: 'white', borderRadius: 5, backgroundColor: '#12203A', width: '20%' }} variant="contained">
                                        บันทึก
                                    </Button>
                                }
                            </div>
                        </Grid>
                    </Grid>
                    <h4>พื้นที่</h4>
                    <Card style={{ marginTop: 20, borderRadius: 12 }}>
                        <Container style={{ marginTop: 25, marginBottom: 30, marginRight: 50, marginLeft: 50 }}>
                            <Grid container >
                                <Grid item xs={6} style={{ width: '70%' }}>
                                    <label>ชื่อพื้นที่</label>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 10, width: '70%' }}>
                                        <Controller style={{ marginTop: 0 }}
                                            control={control}
                                            rules={{
                                                required: { value: true, message: "name team is required." },
                                                min: { value: 0, message: "name team much be greater than or equal 0." }
                                            }}
                                            name="name"
                                            render={o => <TextFieldValidate placeholder={"กรอกชื่อพื้นที่"} vo={o} type='text' errorMessage={errors.name?.message} />} />
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <label>ผู้ดูแลพื้นที่</label>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 10 }}>
                                        <OutlinedInput onClick={() => onClickOpenModal()} size="small" style={{ width: '70%' }}
                                            endAdornment={<SearchIcon />}
                                            placeholder={"ค้นหาชื่อ"}
                                            disabled
                                            value={areaSupervisor}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={6} mt={2}>
                                    <FormControl variant="outlined" style={{ width: '70%', marginTop: 12 }} >
                                        <label style={{ marginBottom: 5 }}>ค่ามาตรฐาน</label>
                                        <Controller
                                            control={control}
                                            rules={{
                                                required: { value: true, message: "กรุณาเลือกค่ามาตรฐาน" }
                                            }}
                                            name="standard"
                                            render={({ field: { onChange, value, ref } }) => (
                                                <><Select size='small'
                                                    inputRef={ref}
                                                    value={value ?? 0}
                                                    onChange={e => {
                                                        onChange(e)
                                                    }}
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    errormessage={errors.standard?.message}
                                                >
                                                    <MenuItem key={"xx"} value={0}>เลือกค่ามาตรฐาน</MenuItem>
                                                    {
                                                        listStandard.map((item, i) =>
                                                            <MenuItem key={i} value={item.id} >
                                                                {item.name}
                                                            </MenuItem>
                                                        )
                                                    }
                                                </Select>
                                                    {errors.standard?.message ? <FormHelperText error>{errors.standard?.message}</FormHelperText> : ""}</>
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Container>
                    </Card>
                </form>
                {!areaId
                    ? <>
                        <h4>เพิ่มทีม</h4>
                        <Card style={{ marginTop: 20, borderRadius: 12 }}>
                            <Container style={{ marginTop: 40, marginBottom: 40, marginRight: 20, marginLeft: 20 }}>
                                <label>กรุณาสร้างพื้นที่ก่อน</label>
                            </Container>
                        </Card>
                    </>
                    : <>
                        <h4>เพิ่มทีม</h4>
                        <Card style={{ marginTop: 20, borderRadius: 12 }}>
                            <Container style={{ marginTop: 25, marginBottom: 30, marginRight: 50, marginLeft: 50 }}>

                                <Grid container >
                                    <Grid item xs={6}>
                                        <FormControl variant="outlined" style={{ width: '70%', marginTop: 12 }} >
                                            <label style={{ marginBottom: 5 }}>เลือกเพิ่มทีม</label>
                                            <Select size='small'
                                                value={teamId ?? ""}
                                                defaultValue={0}
                                                onChange={e => {
                                                    onChangeSelect(e.target.value)
                                                }}
                                            >
                                                <MenuItem key={"xxx"} defaultValue={0} value={0}>เลือกทีมที่ดูแล</MenuItem>
                                                {
                                                    listTeamForAssignToArea.map((team, i) =>
                                                        <MenuItem key={i} value={team.teamId} >
                                                            {team.teamName}
                                                        </MenuItem>
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <label>ผู้ดูแลทีม</label>
                                        <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 10 }}>
                                            <OutlinedInput value={teamSupervisor} disabled size="small" style={{ width: '70%' }}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                                <div style={{ marginTop: 30, justifyContent: 'center', display: 'flex' }}>
                                    <Button onClick={() => onAddTeam()} style={{ minWidth: 150 }} variant="contained" disabled={teamId === 0} color="secondary">
                                        เพิ่ม
                                    </Button>
                                </div>
                            </Container>
                        </Card>
                    </>

                }
                {!areaId
                    ? <>
                        <h4>รายการทีม</h4>
                        <Card style={{ marginTop: 20, borderRadius: 12 }}>
                            <Container style={{ marginTop: 40, marginBottom: 40, marginRight: 20, marginLeft: 20 }}>
                                <label>กรุณาสร้างพื้นที่ก่อน</label>
                            </Container>
                        </Card>
                    </>
                    : <>
                        <h4>รายการทีม</h4>
                        <div style={{ marginTop: 20 }}>
                            <TableContainer className={classes.container}>
                                <Table stickyHeader aria-label="customized table" >
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column, i) => (
                                                <StyledTableCell
                                                    key={i}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth }}
                                                >
                                                    {column.label}
                                                </StyledTableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            (teamDetailModels.map((row, i) => {
                                                const { id, name, teamLeadFirstName, teamLeadLastName, totalStaff, totalPlot } = row
                                                return (
                                                    <StyledTableRow key={i}>
                                                        <StyledTableCell align="left">{i + 1}</StyledTableCell>
                                                        <StyledTableCell align="center" >{name}</StyledTableCell>
                                                        <StyledTableCell align="center" >{teamLeadFirstName} {teamLeadLastName}</StyledTableCell>
                                                        <StyledTableCell align="center" >{totalStaff}</StyledTableCell>
                                                        <StyledTableCell align="center" >{totalPlot}</StyledTableCell>
                                                        <StyledTableCell align="center">
                                                            <Button onClick={() => navigation(ROUTE_PATH.DETAIL_TEAM_AREA.replace(":id", id))} variant="outlined" color='inherit' startIcon={<ModeEditOutlineOutlinedIcon />}></Button>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                )
                                            }))
                                        }

                                    </TableBody>
                                </Table>
                                {teamDetailModels.length === 0 ? <div style={{ display: 'flex', justifyContent: 'center' }}><Empty /></div> : null}
                            </TableContainer>
                        </div>
                    </>
                }
                <AreaSupervisorModal data={listAreaManager} open={openModal} onClickCancel={onClickCancel} onClickSubmint={onClickSubmint} onSearch={setSearch} />
            </div>

        </>
    )
}
const useStyles = makeStyles((theme) => ({

}))