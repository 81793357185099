import { ApiUrl } from "../constants/api_url"
import AxiosHelper from "../helper/axiosHelper"


export async function getCategoryEnterprise() {
    return await AxiosHelper.get(`${ApiUrl.COMMON}/getCategoryEnterprise`)
}

export async function getCategoryEnterprisePosition({ filterEnterpriseId = "" } = {}) {
    let url = `${ApiUrl.COMMON}/getCategoryEnterprisePosition`
    if (filterEnterpriseId.length > 0) {
        url = `${url}?filterEnterpriseId=${filterEnterpriseId}`
    }
    return await AxiosHelper.get(url)
}

export async function getCategoryArea({ filterEnterpriseId = "" } = {}) {
    let url = `${ApiUrl.COMMON}/getCategoryArea`
    if (filterEnterpriseId.length > 0) {
        url = `${url}?filterEnterpriseId=${filterEnterpriseId}`
    }
    return await AxiosHelper.get(url)
}

export async function getCategoryTeam({ filterEnterpriseId = "", filterAreaId = "" }) {
    let params = {}
    if (filterEnterpriseId.length > 0) {
        params.filterEnterpriseId = filterEnterpriseId
    }
    if (filterAreaId.length > 0) {
        params.filterAreaId = filterAreaId
    }
    let url = `${ApiUrl.COMMON}/getCategoryTeam`
    if (Object.keys(params).length > 0) {
        params = new URLSearchParams(params)
        url = `${url}?${params.toString()}`
    }
    return await AxiosHelper.get(url)
}

export async function getCategoryEmployee({ filterEnterpriseId = "", filterAreaId = "", filterTeamId = "", filterRole = -1 }) {
    let params = {}
    if (filterEnterpriseId.length > 0) {
        params.filterEnterpriseId = filterEnterpriseId
    }
    if (filterAreaId.length > 0) {
        params.filterAreaId = filterAreaId
    }
    if (filterTeamId.length > 0) {
        params.filterTeamId = filterTeamId
    }
    if (filterRole > 0) {
        params.filterRole = filterRole
    }
    let url = `${ApiUrl.COMMON}/getCategoryEmployee`
    if (Object.keys(params).length > 0) {
        params = new URLSearchParams(params)
        url = `${url}?${params.toString()}`
    }
    return await AxiosHelper.get(url)
}