import React from 'react'
import Modal from '@mui/material/Modal'
import { makeStyles } from '@mui/styles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import { Grid } from '@mui/material'
import TextField from '@mui/material/TextField'
import Empty from './Empty'

export default function TeamAdminModal({ data = [], open = false, onClickCancelModal = () => { }, onClickSubmitModal = () => { }, onSreach = () => { } }) {
    const classes = useStyles()
    return (
        <div style={{ alignContent: 'center' }}>
            <Modal scrollable
                open={open}
                onClose={onClickCancelModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={classes.modalStyle}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            เลือกผู้ดูแลทีม
                        </Typography>
                        <Typography id="modal-modal-title" variant="h6" component="h2" >
                            <TextField onChange={(e) => onSreach(e.target.value)} style={{ width: '100%', }} size='small' id="outlined-basic" variant="outlined" placeholder='ค้นหา' />
                        </Typography>
                    </div>
                    <Box sx={{ width: '100%' }}>
                        <Grid container mt={1} rowSpacing={1} >
                            <Grid item xs={1}>
                                ลำดับ
                            </Grid>
                            <Grid item xs={3}>
                                ชื่อ-นามสกุล
                            </Grid>
                            <Grid item xs={3}>
                                ตำแหน่ง
                            </Grid>
                            <Grid item xs={3}>
                                ชื่อบริษัท
                            </Grid>
                            <Grid item xs={2}>

                            </Grid>
                        </Grid>
                        <div className={classes.divLine} style={{ marginTop: 10 }}></div>
                        {
                            data.map((data, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        <Grid container mt={1} mb={1} rowSpacing={1} >
                                            <Grid item xs={1}>
                                                {i + 1}
                                            </Grid>
                                            <Grid item xs={3}>
                                                {data.firstName} {data.lastName}
                                            </Grid>
                                            <Grid item xs={3}>
                                                {data.roleName}
                                            </Grid>
                                            <Grid item xs={3}>
                                                {data.enterpriseName}
                                            </Grid>
                                            <Grid item xs={2} style={{ paddingTop: 1 }}>
                                                <Button onClick={() => onClickSubmitModal({ id: data.id, page: "team", name: `${data.firstName}` + " " + `${data.lastName}` })} variant="contained" color="success"><AddIcon /></Button>
                                            </Grid>
                                        </Grid>
                                        <div className={classes.divLine}></div>
                                    </React.Fragment>
                                )
                            })
                        }
                        {data.length === 0 ? <div style={{ display: 'flex', justifyContent: 'center' }}><Empty /></div> : null}
                    </Box>
                </Box>
            </Modal>
        </div >
    )
}

const useStyles = makeStyles((theme) => ({
    modalStyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        border: '1px solid #000',
        boxShadow: 24,
        backgroundColor: 'white',
        padding: 20,
        height: 500,
        overflow: "scroll",
        borderRadius: 10,
    },
    divLine: {
        height: 1.1, backgroundColor: '#12203A',
        width: '100%',
    },
}))