import React, { useContext, useEffect, useState, useRef } from 'react'
import { makeStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import { Button, Modal, TablePagination, Box, Tooltip, InputLabel, InputAdornment } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import OutlinedInput from '@mui/material/OutlinedInput'
import SearchIcon from '@mui/icons-material/Search'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { styled } from '@mui/material/styles'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from '../../constants/paths'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import getAllEmployee, { deleteEmployee, newImportStaff } from '../../services/employee'
import Skeleton from '@mui/material/Skeleton'
import Empty from '../../components/Empty'
import AlertDeleteModal from '../../components/AlertDeleteModal'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import * as XLSX from 'xlsx'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import { useSnackbar } from 'notistack'
import { API_RES_STATUS } from '../../constants/apiResponseStatus'
import { AppContext } from '../../contexts/AppContext'
import { ROLES } from '../../constants/enums'
import StyledDataGrid from '../../components/StyledDataGrid'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import useCategoryEnterprise from '../../reactHook/useCategoryEnterprise'
import useCategoryEmployee from '../../reactHook/useCategoryEmployee'
import useCategoryEnterprisePosition from '../../reactHook/useCategoryEnterprisePosition'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#12203A",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#D3DCEC",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 1,
    },
}))

export default function StaffPage() {
    const { employee = {} } = useContext(AppContext)
    const classes = useStyles()
    const navigation = useNavigate()
    const [loading, setLoading] = useState(false)
    const [listEmployee, setListEmployee] = useState([])
    const [openModal, setOpenModal] = useState(false)
    const [idDelete, setIdDelete] = useState(null)
    const [openModalImport, setOpenModalImport] = useState(false)
    const [records, setRecords] = useState([])
    const [disableUploadFile, setDisableUploadFile] = useState(false)
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [acceptedFiles, setAcceptedFiles] = useState([])
    const { enqueueSnackbar, /*closeSnackbar*/ } = useSnackbar()
    const [tablePageSize, setTablePageSize] = useState(10)
    const [tableCurrentPage, setTableCurrentPage] = useState(0)
    const [tableTotalRow, setTableTotalRow] = useState(0)

    const { enterprises } = useCategoryEnterprise()
    const { employees, setFilterEmpByEnterpriseId, setFilterRole } = useCategoryEmployee()
    const { enterprisesPosition, setFillterEnterprisesPositionByEnterpriseId } = useCategoryEnterprisePosition()

    const [searchKeyword, setSearchKeyword] = useState('')
    const [filterEnterpriseId, setFilterEnterpriseId] = useState('')
    const [filterRoleEnum, setFilterRoleEnum] = useState('')

    useEffect(() => {
        loadEmployee()
    }, [tablePageSize, tableCurrentPage, filterEnterpriseId, searchKeyword, filterRoleEnum])

    const onFilterSearchKeywordChange = (event) => {
        setSearchKeyword(event.target.value)
    }

    const loadEmployee = async () => {
        setLoading(true)
        if (employee.role !== ROLES.AdminLing) {
            setFillterEnterprisesPositionByEnterpriseId(employee.enterpriseId)
        }
        const axiosRes = await getAllEmployee({ pageSize: tablePageSize, currentPage: tableCurrentPage + 1, filterEnterpriseId, searchKeyword, filterRole: filterRoleEnum })
        const { data, status, message = "" } = JSON.parse(axiosRes.data)
        if (status === API_RES_STATUS.success) {
            const { listData, totalRow = 0 } = data
            setListEmployee(listData)
            setTableTotalRow(totalRow)
        } else {
            enqueueSnackbar(message, { variant: 'error', autoHideDuration: 10000 })
        }
        setLoading(false)
    }

    const onInputChange = (value, key) => {
        switch (key) {
            case 'enterpriseId':
                setFilterEmpByEnterpriseId(value)
                setFilterEnterpriseId(value)
                setFillterEnterprisesPositionByEnterpriseId(value)

                setFilterRole('')
                setFilterRoleEnum('')
                break

            case 'role':
                setFilterRole(value)
                setFilterRoleEnum(value)
                break

            default:
                break
        }
    }

    const columns = [
        {
            field: 'name',
            headerName: 'ชื่อ-นามสกุล',
            flex: 3
        },
        {
            field: 'positionName',
            headerName: "ตำแหน่ง",
            flex: 3
        },
        {
            field: 'action',
            headerName: "",
            renderCell: ({ row }) => {
                return <>
                    <IconButton
                        size="small"
                        onClick={() => navigation(ROUTE_PATH.EDIT_STAFF.replace(":id", row.id))}
                    >
                        <ModeEditOutlineOutlinedIcon />
                    </IconButton>
                    <Tooltip title="คัดลอก ID" placement='top'>
                        <IconButton
                            size="small"
                            onClick={() => {
                                const { id } = row
                                navigator.clipboard.writeText(id)
                            }}
                        >
                            <ContentCopyIcon />
                        </IconButton>
                    </Tooltip>
                    {/* <IconButton
                        size="small"
                        color='error'
                        onClick={() => OnClickDelete(row.id)}  >
                        <DeleteIcon />
                    </IconButton> */}

                </>
            },
            flex: 1

        },
    ]

    const OnClickSubmitModal = async (id) => {
        const res = await deleteEmployee(id)
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            enqueueSnackbar('Delete staff successfully.', { variant: 'success' })
            setIdDelete(null)
            loadEmployee()
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }

        setOpenModal(false)
    }

    const OnClickInModal = () => {
        setOpenModal(false)
        setIdDelete(null)
    }

    const OnClickDelete = (id) => {
        setIdDelete(id)
        setOpenModal(true)
    }

    const OnDownloadStaffTemplateFile = () => {
        var header = Object.values(columnNamesModal)

        const objDataMock = {
            firstName: 'testfirstName',
            lastName: 'testlastName',
            phoneNumber: '0987654321',
            email: 'test@test.com',
            password: 'password',
            staff_Id: 'test10001',
            citizen_Id: '8488058443176',
            enterprisePosition_Id: '00000000-0000-0000-0000-000000000000',
            enterprise_Id: '00000000-0000-0000-0000-000000000000'
        }

        const ws = XLSX.utils.json_to_sheet([objDataMock], { header: header })
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1")

        XLSX.writeFile(wb, 'import_Staff_template.xlsx')
    }

    const onDropped = (e, isInput) => {
        e.preventDefault()

        const files = isInput ? e.target.files : e.dataTransfer.files
        let extension = files[0].name.split('.').pop()
        if (extension !== 'xlsx') {
            enqueueSnackbar('File type invalid.', { variant: 'error' })
            return
        }

        setAcceptedFiles(files)

        let i, f
        for (i = 0, f = files[i]; i !== files.length; ++i) {
            var reader = new FileReader()
            // var name = f.name
            reader.onload = function (e) {
                var data = e.target.result // <-- 'data' contains the sheet data
                // console.log("data e.target.result >>>" + data)

                var xlsx = XLSX.read(data, { type: 'binary', codepage: 65001 })  // <--'xlsx' is an object that holds the relevant data
                // console.log(`xlsx.SheetNames[0] >>`, xlsx.SheetNames[0])

                const json = XLSX.utils.sheet_to_json(xlsx.Sheets[xlsx.SheetNames[0]])
                // console.log(`json >>>`, json)

                const row = json[0]

                if (row == undefined) {
                    enqueueSnackbar('sorry data not found', { variant: 'error' })
                    return
                }

                const keys = Object.keys(row)

                loop1: for (let i = 0; i < keys.length; i++) {
                    const k = keys[i]
                    for (let j = 0; j < columnsModal.length; j++) {
                        const mk = columnsModal[j]
                        if (mk.value === k) {
                            continue loop1
                        }
                    }
                    // console.log('column not found', k)

                    enqueueSnackbar('column not found ' + k, { variant: 'error' })
                    return
                }

                if (json === null) {
                    enqueueSnackbar('File incorrect format.', { variant: 'error' })
                    return
                }
                if (json.length === 0) {
                    enqueueSnackbar('Empty file.', { variant: 'error' })
                    return
                }

                validate(json)
                setRecords(json)

            }
            reader.readAsBinaryString(f)
        }

    }

    const onDragOver = (event) => {
        event.preventDefault()
    }

    const handleChangePage = (event, newPage) => { setPage(newPage) }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const clear = () => {
        setRecords([])
        setDisableUploadFile(false)
    }

    const validate = jsonList => {

        jsonList.forEach(row => {

            columnsModal.forEach(column => {
                let validField = false

                switch (column.value) {
                    case columnNamesModal.FirstName:
                        validField = column.isValid(row)
                        break
                    case columnNamesModal.LastName:
                        validField = column.isValid(row)
                        break
                    case columnNamesModal.PhoneNumber:
                        validField = column.isValid(row)
                        break
                    case columnNamesModal.Email:
                        validField = column.isValid(row)
                        break
                    case columnNamesModal.Password:
                        validField = column.isValid(row)
                        break
                    case columnNamesModal.StaffId:
                        validField = column.isValid(row)
                        break
                    case columnNamesModal.CityzenId:
                        validField = column.isValid(row)
                        break
                    case columnNamesModal.EnterprisePositionId:
                        validField = column.isValid(row)
                        break
                    case columnNamesModal.EnterpriseId:
                        validField = column.isValid(row)
                        break
                    default:
                        validField = true
                        break
                }
                if (!disableUploadFile && !validField) {
                    setDisableUploadFile(true)
                }
                row[column.value + '-valid'] = validField
            })


        })
    }

    const uploadNewStaff = async () => {
        const formData = new FormData()
        formData.append("staffFile", acceptedFiles[0])
        const jsonObj = records.map(item => ({
            firstName: item[columnNamesModal.FirstName],
            lastName: item[columnNamesModal.LastName],
            phoneNumber: item[columnNamesModal.PhoneNumber],
            email: item[columnNamesModal.Email],
            password: item[columnNamesModal.Password],
            staffId: item[columnNamesModal.StaffId],
            cityzenId: item[columnNamesModal.CityzenId],
            enterprisePositionId: item[columnNamesModal.EnterprisePositionId],
            enterpriseId: item[columnNamesModal.EnterpriseId],
        }))
        const jsonString = JSON.stringify(jsonObj)
        formData.append("json", jsonString)

        try {
            const res = await newImportStaff(formData)
            let json = res.data
            if (json.status === API_RES_STATUS.success) {
                enqueueSnackbar("import staff success.", { variant: 'success' })
                loadEmployee()
                setRecords([])
                setOpenModalImport(false)
            } else {
                enqueueSnackbar(json.message, { variant: 'error' })
            }

        } catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' })
        }


    }

    const inputRef = useRef(null)
    const handleClick = () => inputRef.current.click()

    const loadingPanel = <div style={{ marginRight: 10, marginLeft: 10 }}>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Skeleton variant="rect" height={50} width={"100%"} />
        </Grid>
        <Grid style={{ marginTop: 10 }}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Skeleton variant="rect" height={500} width={"100%"} />
        </Grid>
    </div>

    return (
        <>
            <div style={{ margin: 40 }}>
                {employee.role === ROLES.AdminLing &&
                    <FormControl style={{ width: '40%', marginBottom: 20 }} size="small">
                        <InputLabel >เลือกบริษัท</InputLabel>
                        <Select
                            label="เลือกบริษัท"
                            value={filterEnterpriseId}
                            onChange={(e) => { onInputChange(e.target.value, 'enterpriseId') }}
                        >
                            <MenuItem value={''}>ทั้งหมด</MenuItem>
                            {enterprises.map((e) => {
                                return <MenuItem key={`select-enterprise-${e.id}`} value={e.id}>{e.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                }
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid xs={6}>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: 22 }}>
                            <FormControl style={{ width: '40%', marginRight: 20 }} size="small">
                                <InputLabel >เลือกตำแหน่ง</InputLabel>
                                <Select
                                    label="เลือกตำแหน่ง"
                                    value={filterRoleEnum}
                                    onChange={(e) => {
                                        onInputChange(e.target.value, 'role')
                                    }}
                                >
                                    <MenuItem value={''}>ทั้งหมด</MenuItem>
                                    {enterprisesPosition.map((l, i) => {
                                        return <MenuItem key={`select-role-${l.id}`} value={l.role}>{l.enterprisePositionName}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                            <OutlinedInput
                                size="small"
                                style={{ width: '50%' }}
                                placeholder='ชื่อพนักงาน, ชื่อตำแหน่ง'
                                value={searchKeyword}
                                onChange={onFilterSearchKeywordChange}
                                startAdornment={
                                    <InputAdornment position='start'>
                                        <SearchIcon />
                                    </InputAdornment>
                                }
                            />
                        </div>
                    </Grid>
                    <Grid xs={6}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={() => OnDownloadStaffTemplateFile()} style={{ color: '#2A1D0E', borderColor: '#2A1D0E', borderRadius: 5, marginRight: 16 }} variant="outlined">
                                ดาวน์โหลด เทมเพลต
                            </Button>
                            <Button onClick={() => {
                                setRecords([])
                                setOpenModalImport(true)
                            }} style={{ color: '#2A1D0E', borderColor: '#2A1D0E', borderRadius: 5, marginRight: 16 }} variant="outlined">
                                อัพโหลดไฟล์
                            </Button>
                            {employee.role !== ROLES.Staff &&
                                <Button onClick={() => navigation(ROUTE_PATH.CREATE_STAFF)} style={{ color: 'white', borderRadius: 5, backgroundColor: '#12203A' }} variant="contained">
                                    <AddIcon />
                                    เพิ่ม พนักงาน
                                </Button>
                            }
                        </div>
                    </Grid>
                </Grid>
                {/* {
                    loading
                        ? loadingPanel
                        : */}
                <Grid item sm={12}>
                    <Box sx={{ height: '70vh', width: '100%', marginTop: 2 }}>
                        <StyledDataGrid
                            rows={listEmployee}
                            columns={columns}
                            paginationMode="server"
                            rowCount={tableTotalRow}
                            page={tableCurrentPage}
                            pageSize={tablePageSize}
                            loading={loading}
                            pagination
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            onPageChange={(newPage) => setTableCurrentPage(newPage)}
                            onPageSizeChange={(newPageSize) => setTablePageSize(newPageSize)}
                        />
                    </Box>
                </Grid>
                {/* } */}
            </div>
            <AlertDeleteModal id={idDelete} open={openModal} OnClickInModal={OnClickInModal} OnClickSubmitModal={OnClickSubmitModal} />

            <Modal
                open={openModalImport}
                onClose={() => setOpenModalImport(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <>
                    {records.length > 0 ?
                        <div style={{ marginLeft: '10%', marginRight: '10%', marginTop: '10%', backgroundColor: 'white', padding: 20, maxHeight: '70vh', paddingBottom: 40 }}>
                            <Box display="flex" flexDirection="row-reverse" my={2}>
                                <Button style={{ minWidth: 200 }} onClick={() => clear()} variant='contained' color='error' disabled={records.length === 0}>ยกเลิก</Button>
                                <Button style={{ minWidth: 200, marginRight: 5 }} onClick={() => { uploadNewStaff() }} disabled={disableUploadFile}
                                    variant='contained' color='success'>ยืนยัน</Button>
                            </Box>

                            <TableContainer className={classes.container} style={{ maxHeight: '50vh' }}>
                                <Table stickyHeader aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            {columnsModal.map((c, ci) =>
                                                <StyledTableCell
                                                    align={c.align}
                                                    style={{ minWidth: c.columnWidth }}
                                                    key={ci}>{c.value}</StyledTableCell>)}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {records.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowi) => {

                                            return <StyledTableRow key={rowi}>
                                                {columnsModal.map((c, ci) => {

                                                    return <StyledTableCell
                                                        align="center"
                                                        key={rowi + '' + ci}>
                                                        <span >{row[c.value] ? row[c.value] + '' : null} </span>
                                                        {!row[c.value + '-valid'] && <><br /><span style={{ color: 'red' }}>{c.errorMessage}</span></>}
                                                    </StyledTableCell>
                                                })}

                                            </StyledTableRow>
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={records.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </div>
                        :
                        <div
                            style={{
                                display: 'flex', flexDirection: 'column', justifyContent: 'center',
                                alignItems: 'center', backgroundColor: 'rgb(245, 245, 245)', cursor: 'pointer',
                                minHeight: '200px', border: '4px dashed gray',
                                marginLeft: '10%', marginRight: '10%', marginTop: '10%'
                            }}
                            onClick={handleClick}
                            dropzone="true"
                            onDragOver={(event) => { onDragOver(event) }}
                            onDrop={(event) => { onDropped(event, false) }}>
                            <input type="file" style={{ display: 'none' }} ref={inputRef} onChange={(event) => { onDropped(event, true) }} />
                            <CloudUploadOutlinedIcon style={{ fontSize: 100, color: 'gray' }} />
                            <span style={{ fontsize: '1.5rem', fontWeight: 'bold' }}>Drop file here</span>
                            <span style={{ fontsize: '1.5rem', fontWeight: 'bold' }}>You have to drop xls file only</span>
                        </div>
                    }
                </>
            </Modal>
        </>
    )
}

const useStyles = makeStyles((theme) => ({

}))


const columnNamesModal = {
    FirstName: 'firstName',
    LastName: 'lastName',
    PhoneNumber: 'phoneNumber',
    Email: 'email',
    Password: 'password',
    StaffId: 'staff_Id',
    CityzenId: 'citizen_Id',
    EnterprisePositionId: 'enterprisePosition_Id',
    EnterpriseId: 'enterprise_Id'
}

const columnsModal = [
    {
        columnWidth: 120,
        value: columnNamesModal.FirstName,
        errorMessage: 'กรุณากรอกชื่อ',
        align: 'center',
        isValid: row => row[columnNamesModal.FirstName] !== " " && row[columnNamesModal.FirstName]
    },
    {
        columnWidth: 120,
        value: columnNamesModal.LastName,
        errorMessage: 'กรุณากรอกนามสกุล',
        align: 'center',
        isValid: row => row[columnNamesModal.LastName] !== " " && row[columnNamesModal.LastName]
    },
    {
        columnWidth: 120,
        value: columnNamesModal.PhoneNumber,
        errorMessage: 'กรุณาตรวจสอบเบอร์โทรศัพท์',
        align: 'center',
        isValid: row => {
            var pattern = new RegExp('^[0-9]{10}$')
            return row[columnNamesModal.PhoneNumber] ? pattern.test(row[columnNamesModal.PhoneNumber]) : false
        }
    },
    {
        columnWidth: 120,
        value: columnNamesModal.Email,
        errorMessage: 'กรุณาตรวจสอบ Email',
        align: 'center',
        isValid: row => {
            var pattern = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
            return !row[columnNamesModal.Email] ? true : pattern.test(row[columnNamesModal.Email])
        }
    },
    {
        columnWidth: 120,
        value: columnNamesModal.Password,
        errorMessage: 'กรุณากรอกรหัส Password',
        align: 'center',
        isValid: row => row[columnNamesModal.Password] !== " " && row[columnNamesModal.Password]
    },
    {
        columnWidth: 120,
        value: columnNamesModal.StaffId,
        errorMessage: 'กรุณากรอกรหัส StaffId',
        align: 'center',
        isValid: row => row[columnNamesModal.StaffId] !== " " && row[columnNamesModal.StaffId]
    },
    {
        columnWidth: 120,
        value: columnNamesModal.CityzenId,
        errorMessage: 'กรุณาตรวจสอบ CityzenId',
        align: 'center',
        isValid: row => {

            if (row[columnNamesModal.CityzenId]?.length === 13 && row[columnNamesModal.CityzenId]?.length !== '') {

                let idCard = row[columnNamesModal.CityzenId].split("")
                let total = 0
                let mul = 13

                for (let i = 0; i < idCard.length - 1; i++) {
                    total = total + idCard[i] * mul
                    mul = mul - 1
                }

                let mod = total % 11
                let nsub = 11 - mod
                let mod2 = nsub % 10

                return mod2 === Number(idCard[12])
            }

            return false
        }
    },
    {
        columnWidth: 120,
        value: columnNamesModal.EnterprisePositionId,
        errorMessage: 'กรุณาตรวจสอบ EnterprisePositionId',
        align: 'center',
        isValid: row => {
            var pattern = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$', 'i')
            return pattern.test(row[columnNamesModal.EnterprisePositionId])
        }
    },
    {
        columnWidth: 120,
        value: columnNamesModal.EnterpriseId,
        errorMessage: 'กรุณาตรวจสอบ EnterpriseId',
        align: 'center',
        isValid: row => {
            var pattern = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$', 'i')
            return pattern.test(row[columnNamesModal.EnterpriseId])
        }
    },
]
