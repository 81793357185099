import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import { Button } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#12203A",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#D3DCEC",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 1,
    },
}))

export default function ReportPage() {
    const classes = useStyles()

    const columns = [
        { label: "No", minWidth: 50, align: 'start', },
        { label: "ชื่อ", minWidth: 120, align: 'center' },
        { label: "จำนวนเงิน", minWidth: 120, align: 'center' },
        { label: "ขนาดพื้นที่ทั้งหมด", minWidth: 120, align: 'center' },
        { label: "ผลผลิต", minWidth: 120, align: 'center' },
    ]

    const [age, setAge] = useState('')

    const handleChange = (event) => {
        setAge(event.target.value)
    }
    return (
        <>
            <div style={{ margin: 40 }}>
                <div style={{ marginTop: 20, marginBottom: 40 }}>
                    <text style={{ fontSize: 22, color: '#F08E26' }}>Report Performance</text>
                </div>
                <Grid container >
                    <Grid mb={2} xs={6}>
                        <div>
                            <label >Enterprise </label>
                            <FormControl style={{ width: "60%", marginLeft: 20 }} >
                                {/* <InputLabel id="demo-simple-select-label">เปิดสิทธิการใช้งาน</InputLabel> */}
                                <Select size='small'
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={age}
                                    // label="เปิดสิทธิการใช้งาน"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={10}>1</MenuItem>
                                    <MenuItem value={20}>2</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                    </Grid>
                    <Grid mt={2} xs={10}>
                        <div >
                            <text style={{}}>วันที่เริ่มต้น</text>
                            <TextField style={{ marginLeft: 30, }} type="date" id="outlined-basic" variant="outlined" />
                            <text style={{ marginLeft: 30, }}>วันที่สิ้นสุด</text>
                            <TextField style={{ marginLeft: 30, height: 20 }} type="date" id="outlined-basic" variant="outlined" />
                            <Button style={{ marginLeft: 30, width: '10%', height: '5%', color: '#F08E26', borderColor: '#F08E26', borderWidth: 2, borderRadius: 10, backgroundColor: 'white' }} variant="outlined">
                                ค้นหา
                            </Button>
                        </div>
                    </Grid>
                    <Grid xs={2}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button style={{ width: '60%', color: 'white', borderRadius: 10 }} variant="contained">
                                Export
                            </Button>
                        </div>
                    </Grid>
                </Grid>

                <div style={{ marginTop: 20 }}>
                    <TableContainer className={classes.container}>
                        <Table stickyHeader aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <StyledTableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow>
                                    <StyledTableCell >{"01"}</StyledTableCell>
                                    <StyledTableCell align="center" >{"อลงกต อินทรปรีดา"}</StyledTableCell>
                                    <StyledTableCell align="center" >{"20"}</StyledTableCell>
                                    <StyledTableCell align="center" >{"840"}</StyledTableCell>
                                    <StyledTableCell align="center" >{"843"}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell >{"02"}</StyledTableCell>
                                    <StyledTableCell align="center" >{"อลงกต อินทรปรีดา"}</StyledTableCell>
                                    <StyledTableCell align="center" >{"20"}</StyledTableCell>
                                    <StyledTableCell align="center" >{"840"}</StyledTableCell>
                                    <StyledTableCell align="center" >{"843"}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell >{"03"}</StyledTableCell>
                                    <StyledTableCell align="center" >{"อลงกต อินทรปรีดา"}</StyledTableCell>
                                    <StyledTableCell align="center" >{"20"}</StyledTableCell>
                                    <StyledTableCell align="center" >{"840"}</StyledTableCell>
                                    <StyledTableCell align="center" >{"843"}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell >{"04"}</StyledTableCell>
                                    <StyledTableCell align="center" >{"อลงกต อินทรปรีดา"}</StyledTableCell>
                                    <StyledTableCell align="center" >{"20"}</StyledTableCell>
                                    <StyledTableCell align="center" >{"840"}</StyledTableCell>
                                    <StyledTableCell align="center" >{"843"}</StyledTableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                        {/* {rows.length === 0 ? <div style={{ display: 'flex', justifyContent: 'center' }}><Empty /></div> : null} */}
                    </TableContainer>
                </div>
            </div>
        </>
    )
}

const useStyles = makeStyles((theme) => ({

}))