import { Button, CardContent, FormHelperText, Grid } from '@mui/material'
import React, { useState, useEffect, useContext } from 'react'
import Card from '@mui/material/Card'
import TextField from '@mui/material/TextField'
import { useNavigate, useParams } from 'react-router-dom'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { useSnackbar } from 'notistack'
import { createEmployee, getEmployeeById, updateEmployee } from '../../services/employee'
import { Controller, useForm } from 'react-hook-form'
import Skeleton from '@mui/material/Skeleton'
import TextFieldValidate from '../../components/TextFieldValidate'
import getAllEnterprisePosition, { getAllEnterprisePositionByEnterpriseId } from '../../services/enterprisePosition'
import getAllEnterprise from '../../services/enterprise'
import { AppContext } from '../../contexts/AppContext'
import { ROLES } from '../../constants/enums'
import { API_RES_STATUS } from '../../constants/apiResponseStatus'

export default function CreateStaffPage() {
    const { employee = {} } = useContext(AppContext)
    let { id: employeeId } = useParams()
    const navigation = useNavigate()
    const { enqueueSnackbar, /*closeSnackbar*/ } = useSnackbar()
    const [loading, setLoading] = useState(false)
    const { register, control, handleSubmit, setValue, formState: { errors } } = useForm()
    const [listEnterprisePosition, setListEnterprisePosition] = useState([])
    const [listEnterprise, setListEnterprise] = useState([])
    const [messageTax, setMessageTax] = useState(true)

    useEffect(() => {
        loadEnterprisePositions()
        loadEnterprise()
        if (employeeId) {
            loadEmployee(employeeId)
        }
    }, [])

    const loadEnterprisePositions = async () => {
        setLoading(true)
        const res = await getAllEnterprisePosition()
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            let data = json.data
            setListEnterprisePosition(data)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
        setLoading(false)
    }

    const loadEnterprise = async () => {
        setLoading(true)
        const res = await getAllEnterprise()
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            let data = json.data
            setListEnterprise(data)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
        setLoading(false)
    }

    const loadEmployee = async (id) => {
        setLoading(true)
        const res = await getEmployeeById(id)
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            let data = json.data
            if (employee.role === ROLES.AdminLing) {
                OnChooseEnterprise(data.enterpriseId)
            }

            setValue('firstName', data.firstName)
            setValue('lastName', data.lastName)
            setValue('phoneNumber', data.phoneNumber)
            setValue('email', data.email)
            setValue('staffId', data.staffId)
            setValue('citizenId', data.citizenId)
            // setValue('password', data.password)
            setValue('enterprisePositionId', data.enterprisePositionId)
            setValue('enterpriseId', data.enterpriseId)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
        setLoading(false)
    }

    const onSubmitForm = async (data) => {
        if (data.citizenId.length === 13 && data.citizenId.length !== '') {
            let idCard = data.citizenId.split("")
            let total = 0
            let mul = 13

            for (let i = 0; i < idCard.length - 1; i++) {
                total = total + idCard[i] * mul
                mul = mul - 1
            }

            let mod = total % 11
            let nsub = 11 - mod
            let mod2 = nsub % 10
            if (mod2 === Number(idCard[12])) {
                setLoading(true)
                setMessageTax(true)
                if (employeeId) {
                    await onEdit(employeeId, data)
                } else {
                    await onCreate(data)
                }
                setLoading(false)
            }
            else {
                setLoading(true)
                setMessageTax(false)
                setLoading(false)
            }
        }
    }
    const onCreate = async (data) => {
        const res = await createEmployee({
            firstName: data.firstName, lastName: data.lastName, phoneNumber: data.phoneNumber,
            email: data.email, staffId: data.staffId, citizenId: data.citizenId,
            password: data.password, enterprisePositionId: data.enterprisePositionId,
            enterpriseId: employee.role === ROLES.AdminLing ? data.enterpriseId : employee.enterpriseId
        })
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            enqueueSnackbar('Create enterprise successfully.', { variant: 'success' })
            navigation(-1)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
    }

    const onEdit = async (id, data) => {
        const res = await updateEmployee({
            id, firstName: data.firstName, lastName: data.lastName, phoneNumber: data.phoneNumber,
            email: data.email, staffId: data.staffId, citizenId: data.citizenId,
            enterprisePositionId: data.enterprisePositionId,
            enterpriseId: data.enterpriseId
        })
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            enqueueSnackbar('Update enterprise successfully.', { variant: 'success' })
            navigation(-1)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
    }

    const OnChooseEnterprise = async (enterpriseId) => {
        const res = await getAllEnterprisePositionByEnterpriseId(enterpriseId)
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            let data = json.data
            setListEnterprisePosition(data)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }

    }

    const loadingPanel = <div style={{ marginRight: 10, marginLeft: 10 }}>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Skeleton variant="rect" height={50} width={"100%"} />
        </Grid>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="start"
            alignItems="center"
        >
            <Grid item xs={3} >
                <Skeleton variant="rect" height={50} width={"80%"} />
            </Grid>
            <Grid item xs={9} >
                <Skeleton variant="rect" height={50} width={"100%"} />
            </Grid>
        </Grid>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="start"
            alignItems="center"
        >
            <Grid item xs={3} >
                <Skeleton variant="rect" height={50} width={"80%"} />
            </Grid>
            <Grid item xs={9} >
                <Skeleton variant="rect" height={50} width={"100%"} />
            </Grid>
        </Grid>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="start"
            alignItems="center"
        >
            <Grid item xs={3} >
                <Skeleton variant="rect" height={50} width={"80%"} />
            </Grid>
            <Grid item xs={9} >
                <Skeleton variant="rect" height={50} width={"100%"} />
            </Grid>
        </Grid>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="start"
            alignItems="center"
        >
            <Grid item xs={3} >
                <Skeleton variant="rect" height={50} width={"80%"} />
            </Grid>
            <Grid item xs={9} >
                <Skeleton variant="rect" height={50} width={"100%"} />
            </Grid>
        </Grid>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="start"
            alignItems="center"
        >
            <Grid item xs={3} >
                <Skeleton variant="rect" height={50} width={"80%"} />
            </Grid>
            <Grid item xs={9} >
                <Skeleton variant="rect" height={50} width={"100%"} />
            </Grid>
        </Grid>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="start"
            alignItems="center"
        >
            <Grid item xs={3} >
                <Skeleton variant="rect" height={50} width={"80%"} />
            </Grid>
            <Grid item xs={9} >
                <Skeleton variant="rect" height={50} width={"100%"} />
            </Grid>
        </Grid>
    </div>


    return (
        <div style={{ margin: 40 }}>
            {
                loading
                    ? loadingPanel
                    : <form onSubmit={handleSubmit(onSubmitForm)}>
                        <Grid container rowSpacing={1} >
                            <Grid item xs={6}>
                                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button onClick={() => navigation(-1)} style={{ width: '20%', borderRadius: 7, borderWidth: 1.5, marginRight: 20, color: '#12203A', borderColor: '#12203A' }} variant="outlined">
                                        ยกเลิก
                                    </Button>
                                    <Button type='submit' style={{ width: '20%', color: 'white', borderRadius: 7, backgroundColor: '#12203A' }} variant="contained">
                                        บันทึก
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                        <div style={{ marginTop: 30 }}>
                            <Card >
                                <CardContent style={{ margin: 20 }}>
                                    <Grid container  >
                                        <Grid item xs={5}>
                                            <Grid container  >
                                                <Grid item mt={3} xs={3}>
                                                    ชื่อ
                                                </Grid>
                                                <Grid item mt={1} xs={9}>
                                                    <Controller style={{ marginTop: 0 }}
                                                        control={control}
                                                        rules={{
                                                            required: { value: true, message: "firstName is required." },
                                                            min: { value: 0, message: "firstName much be greater than or equal 0." }
                                                        }}
                                                        name="firstName"
                                                        render={o => <TextFieldValidate vo={o} type='text' errorMessage={errors.firstName?.message} />} />
                                                </Grid>
                                                <Grid item mt={3} xs={3}>
                                                    นามสกุล
                                                </Grid>
                                                <Grid item mt={2} xs={9}>
                                                    <Controller style={{ marginTop: 0 }}
                                                        control={control}
                                                        rules={{
                                                            required: { value: true, message: "lastName is required." },
                                                            min: { value: 0, message: "lastName much be greater than or equal 0." }
                                                        }}
                                                        name="lastName"
                                                        render={o => <TextFieldValidate vo={o} type='text' errorMessage={errors.lastName?.message} />} />
                                                </Grid>
                                                <Grid item mt={3} xs={3}>
                                                    เบอร์โทรศัพท์
                                                </Grid>
                                                <Grid item mt={2} xs={9}>
                                                    <Controller style={{ marginTop: 0 }}
                                                        control={control}
                                                        rules={{
                                                            required: { value: true, message: "phoneNumber is required." },
                                                            min: { value: 0, message: "phoneNumber much be greater than or equal 0." }
                                                        }}
                                                        {...register("phoneNumber", {
                                                            required: { value: messageTax, message: "phoneNumber is required." },
                                                            minLength: {
                                                                value: 10,
                                                                message: "กรุณากรอกหมายเลขโทรศัพท์ 10 หลัก"
                                                            }
                                                        })}
                                                        name="phoneNumber"
                                                        render={o => <TextFieldValidate vo={o} type='text' maxLength="10" errorMessage={errors.phoneNumber?.message} />} />
                                                </Grid>

                                                <Grid item mt={3} xs={3}>
                                                    Staff ID
                                                </Grid>
                                                <Grid item mt={2} xs={9}>
                                                    <Controller style={{ marginTop: 0 }}
                                                        control={control}
                                                        rules={{
                                                            required: { value: true, message: "staffId is required." },
                                                            min: { value: 0, message: "staffId much be greater than or equal 0." }
                                                        }}
                                                        name="staffId"
                                                        render={o => <TextFieldValidate vo={o} type='text' errorMessage={errors.staffId?.message} />} />
                                                </Grid>
                                                <Grid item mt={3} xs={3}>
                                                    หมายเลขบัตรประชาชน
                                                </Grid>
                                                <Grid item mt={2} xs={9}>
                                                    <Controller
                                                        control={control}
                                                        rules={{
                                                            required: { value: true, message: "กรุณากรอกเลขบัตรประจําตัวประชาชน" }
                                                        }}
                                                        name="citizenId"
                                                        render={({ field: { value, onChange, ref } }) =>
                                                            <TextField
                                                                // label="กรุณากรอกเลขบัตรประจำตัวประชาชน 13 หลัก"
                                                                value={value ?? ""}
                                                                onChange={onChange}
                                                                disabled={false}
                                                                inputRef={ref}
                                                                type="text"
                                                                {...register("citizenId", {
                                                                    required: { value: messageTax, message: "citizenId is required." },
                                                                    minLength: {
                                                                        value: 13,
                                                                        message: "กรุณากรอกเลขบัตรประจำตัวประชาชน 13 หลัก"
                                                                    }
                                                                })}
                                                                error={errors.citizenId?.message !== undefined && errors.citizenId?.message !== ''}
                                                                helperText={messageTax !== true ? <div style={{ color: 'red' }}>กรุณากรอกเลขบัตรประจําตัวประชาชนให้ถูกต้อง</div> : errors.citizenId?.message}
                                                                fullWidth size="small" margin="normal" variant="outlined"
                                                                inputProps={{ maxLength: 13 }}
                                                            />
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
                                            <div style={{ height: '100%', width: 1, background: '#F08E26' }}></div>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Grid container  >
                                                <Grid item mt={3} xs={3}>
                                                    อีเมล
                                                </Grid>
                                                <Grid item mt={2} xs={9}>
                                                    <TextField style={{ marginTop: 0 }}
                                                        color='primary'
                                                        InputProps={{
                                                            style: {
                                                                backgroundColor: "white",
                                                            }
                                                        }}
                                                        variant="outlined"
                                                        size="small"
                                                        margin="normal"
                                                        fullWidth
                                                        // label="Email Address"
                                                        autoComplete="email"
                                                        autoFocus
                                                        {...register("email", {
                                                            required: { value: true, message: "email is required." },
                                                            pattern: { value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, message: "email is not valid." }
                                                        })}
                                                        helperText={errors.email?.message}
                                                        error={errors.email !== undefined}
                                                    />
                                                </Grid>
                                                <Grid item mt={3} xs={3}>
                                                    รหัสผ่าน
                                                </Grid>
                                                <Grid item mt={2} xs={9}>
                                                    <TextField
                                                        color='primary'
                                                        InputProps={{
                                                            style: {
                                                                backgroundColor: "white",
                                                            }
                                                        }}
                                                        variant="outlined"
                                                        size="small"
                                                        margin="normal"
                                                        fullWidth
                                                        // label="Password"
                                                        autoComplete="current-password"
                                                        type="password"
                                                        {...register("password", {
                                                            required: { value: !employeeId ? true : false, message: "Password is required." },
                                                            minLength: {
                                                                value: 6,
                                                                message: "กรอกรหัสผ่านมากกว่า 6 ตัว"
                                                            }
                                                        })}
                                                        helperText={errors.password?.message}
                                                        error={errors.password !== undefined}
                                                        disabled={employeeId ? true : false}
                                                    />
                                                </Grid>
                                                <Grid item mt={5} xs={12}>
                                                    กำหนดตำแหน่ง
                                                </Grid>
                                                {employee.role === ROLES.AdminLing &&
                                                    <>
                                                        <Grid item mt={3} xs={3}>
                                                            Enterprise
                                                        </Grid>
                                                        <Grid item mt={2} xs={9}>
                                                            <FormControl fullWidth >
                                                                <Controller
                                                                    control={control}
                                                                    name="enterpriseId"
                                                                    rules={{
                                                                        required: { value: true, message: "enterpriseId is required." },
                                                                        // min: { value: 0, message: "address much be greater than or equal 0." }
                                                                    }}
                                                                    render={({ field: { onChange, value, ref } }) => (
                                                                        <><Select style={{ width: '100%' }}
                                                                            size="small"
                                                                            inputRef={ref}
                                                                            value={value ?? false}
                                                                            onChange={e => {
                                                                                onChange(e)
                                                                                OnChooseEnterprise(e.target.value)
                                                                            }}
                                                                        // labelId="demo-simple-select-outlined-label"
                                                                        // id="demo-simple-select-outlined"
                                                                        // label="positionId"
                                                                        // error={errors.enterpriseId?.message}
                                                                        // helperText={errors.enterpriseId?.message}
                                                                        >
                                                                            <MenuItem value={false} disabled>
                                                                                <em>เลือก บริษัท</em>
                                                                            </MenuItem>
                                                                            {listEnterprise.map((row, i) => {
                                                                                return <MenuItem key={i} value={row.id}>{row.name}</MenuItem>
                                                                            })}
                                                                        </Select>
                                                                            {errors.enterpriseId?.message ? <FormHelperText error>{errors.enterpriseId?.message}</FormHelperText> : ""}
                                                                        </>
                                                                    )}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                    </>}
                                                <Grid item mt={3} xs={3}>
                                                    ตำแหน่ง
                                                </Grid>
                                                <Grid item mt={2} xs={9}>
                                                    <FormControl fullWidth >
                                                        <Controller
                                                            control={control}
                                                            name="enterprisePositionId"
                                                            rules={{
                                                                required: { value: true, message: "enterprisePositionId is required." },
                                                                // min: { value: 0, message: "address much be greater than or equal 0." }
                                                            }}
                                                            render={({ field: { onChange, value, ref } }) => (
                                                                <><Select style={{ width: '100%' }}
                                                                    size="small"
                                                                    inputRef={ref}
                                                                    value={value ?? false}
                                                                    onChange={e => {
                                                                        onChange(e)
                                                                    }}
                                                                // labelId="demo-simple-select-outlined-label"
                                                                // id="demo-simple-select-outlined"
                                                                // label="positionId"
                                                                // error={errors.enterprisePositionId?.message}
                                                                // helperText={errors.enterprisePositionId?.message}
                                                                >
                                                                    <MenuItem value={false} disabled>
                                                                        <em>เลือกตำแหน่ง</em>
                                                                    </MenuItem>
                                                                    {listEnterprisePosition.map((row, i) => {
                                                                        return <MenuItem key={i} value={row.id}>{row.name}</MenuItem>
                                                                    })}
                                                                </Select>
                                                                    {errors.enterprisePositionId?.message ? <FormHelperText error>{errors.enterprisePositionId?.message}</FormHelperText> : ""}
                                                                </>
                                                            )}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </div>
                    </form>
            }
        </div >
    )
}
