/* eslint-disable jsx-a11y/alt-text */
import { Box } from '@mui/material'
import React from 'react'
import EmptyIcon from '../../assets/images/empty.png'

export default function Empty() {
    return (
        <Box display='flex' alignItems="center" flexDirection="column" m={10} >
            <img src={EmptyIcon} width={150} height={150} />
        </Box>
    )
}
