import { IconButton, TextField, Tooltip, useTheme } from '@mui/material'
import { GoogleMap, useJsApiLoader, DrawingManager } from '@react-google-maps/api'
import { useCallback, useRef, useState } from 'react'
import { googleMapAPIKey, googleMapLibraries } from '../../constants/common'
import SearchIcon from '@mui/icons-material/Search'
import GpsFixedIcon from '@mui/icons-material/GpsFixed'
import PolylineIcon from '@mui/icons-material/Polyline'
import PanToolIcon from '@mui/icons-material/PanTool'
import DeleteIcon from '@mui/icons-material/Delete'
import Geocode from "react-geocode"
import { useCustomMapControl } from '../../components/Map/useCustomMapControl'
import { styled } from '@mui/system'
import "./drawingMap.css"
import DialogRequirePermission from '../../components/Dialog/DialogRequirePermission'
import { getDefaultMeasurementDisplay } from '../../helper/measurementHelper'



function DrawingMap({ onPolygonComplete, onDeletePolygon, measurementName, captureMap }) {

    const [center, setCenter] = useState({
        lat: -3.745,
        lng: -38.523
    })
    const [drawingMode, setDrawingMode] = useState(null)
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: googleMapAPIKey,
        libraries: googleMapLibraries
    })
    const btnDelRef = useRef(null)
    const btnGotoCurrentLocRef = useRef(null)
    const btnActivePolygonModeRef = useRef(null)
    const btnActivePanToolModeRef = useRef(null)
    const btnSearchRef = useRef(null)
    const [polygon, setPolygon] = useState(null)
    const _theme = useTheme()
    const primaryMainColor = _theme.palette.primary.main
    const CustomIconButton = styled(IconButton)(({ theme }) => ({
        color: primaryMainColor
    }))
    const CustomTextField = styled(TextField)(({ theme }) => ({
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: primaryMainColor,
            },
        },
    }))
    const containerStyle = {
        width: '100%',
        height: '70vh'
    }

    const drawingManagerOptions = {
        drawingControl: false,
        polygonOptions: {
            strokeColor: '#f08e26'
        }
    }

    const mapOptions = {
        disableDefaultUI: false,
        mapTypeControl: true,
        streetViewControl: false,
        mapTypeId: 'hybrid',
    }


    const [map, setMap] = useState(null)

    const [openDialogPermission, setOpenDialogPermission] = useState(false)
    const onCloseDialogPermission = () => setOpenDialogPermission(false)

    const _computeArea = async (_polygon) => {
        const textComputeArea = document.getElementById("text-result-compute-area")
        let result = 0
        if (!isLoaded || !textComputeArea) {
            return result
        }
        if (_polygon) {
            result = window.google.maps.geometry.spherical.computeArea(_polygon.getPath())
            textComputeArea.textContent = await getDefaultMeasurementDisplay(measurementName, result)
        } else {
            textComputeArea.textContent = `----`
        }
        return result
    }

    const IconFactory = (icon, onClick = () => { }, title = "") => {
        return <Tooltip title={title}>
            <CustomIconButton className='icon-container' onClick={onClick}>
                {icon}
            </CustomIconButton>
        </Tooltip>
    }

    useCustomMapControl(map, window.google.maps.ControlPosition.TOP_CENTER,
        <div style={{ display: 'flex' }}>
            <div style={{ background: 'white', paddingTop: 10, paddingBottom: 10, paddingLeft: 20, paddingRight: 20, borderRadius: 5, minWidth: 150, border: 'soild 1px', fontSize: 16 }}>
                ขนาดพื้นที่ <span id="text-result-compute-area">----</span>
            </div>
        </div>

    )

    useCustomMapControl(map, window.google.maps.ControlPosition.TOP_RIGHT,
        <div style={{ display: 'flex', background: 'white' }}>
            <CustomTextField size='small' id="text-search-map" />
            {IconFactory(<SearchIcon />, () => { btnSearchRef.current.click() }, "ค้นหาสถานที่")}
        </div>

    )

    useCustomMapControl(map, window.google.maps.ControlPosition.LEFT_CENTER,
        <div className="icon-container">
            {IconFactory(<PolylineIcon />, () => { btnActivePolygonModeRef.current.click() }, "วาดแปลง")}
            {IconFactory(<PanToolIcon />, () => { btnActivePanToolModeRef.current.click() }, "เลื่อนแผนที่")}
            {IconFactory(<DeleteIcon />, () => { btnDelRef.current.click() }, "ลบแปลงที่วาดทั้งหมด")}
        </div>
    )


    useCustomMapControl(map, window.google.maps.ControlPosition.RIGHT_BOTTOM,
        <div className='icon-container'>
            {IconFactory(<GpsFixedIcon />, () => {
                btnGotoCurrentLocRef.current.click()
            }, "ไปยังตำแหน่งปัจจุบัน")}

        </div>
    )



    const onLoad = useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds(center)
        map.fitBounds(bounds)
        _gotoCurrentLocation()
        setMap(map)
    }, [])

    const _gotoCurrentLocation = () => {
        if (navigator.geolocation) {
            const onSuccess = (position) => {
                const { coords } = position
                const { latitude, longitude } = coords
                setCenter({ lat: latitude, lng: longitude })
            }
            const onFail = () => {
                setOpenDialogPermission(true)
            }
            navigator.geolocation.getCurrentPosition(onSuccess, onFail)
        }
    }

    const onUnmount = useCallback(function callback(map) {
        setMap(null)
    }, [])

    const onActiveDrawingPolygonMode = useCallback(() => {
        _onDeletePolygon()
        setDrawingMode('polygon')
    }, [])

    const onActivePanToolsMode = useCallback(() => {
        setDrawingMode(null)
    }, [])

    const onSearchMap = () => {
        const textSearch = document.getElementById("text-search-map")
        let request = {
            query: textSearch.value,
            fields: ['name', 'geometry'],
        }

        var service = new window.google.maps.places.PlacesService(map)

        service.findPlaceFromQuery(request, function (results, status) {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                if (results.length > 0) {
                    map.setCenter(results[0].geometry.location)
                }
            }
        })
    }

    const _onPolygonComplete = async _polygon => {
        _polygon.setOptions({ fillColor: '#f0a556', strokeColor: '#f08e26' })
        let resultPolygon = {
            areaSize: 0,
            listCoordinates: []
        }
        let address = null
        let arrayResult = _polygon.getPath().getArray()
        if (arrayResult.length > 2) {
            onActivePanToolsMode()
            setPolygon(_polygon)
            const listCoordinates = arrayResult.map(value => {
                return { Latitude: value.lat(), Longitude: value.lng() }
            })
            const areaSize = await _computeArea(_polygon)
            resultPolygon.areaSize = areaSize
            resultPolygon.listCoordinates = listCoordinates

            Geocode.setApiKey(googleMapAPIKey)
            Geocode.setLanguage("th")
            Geocode.setRegion("th")
            Geocode.setLocationType("ROOFTOP")
            Geocode.enableDebug()

            // Get address from latitude & longitude.
            Geocode.fromLatLng(listCoordinates[0].Latitude, listCoordinates[0].Longitude).then(
                (response) => {
                    address = response.results[0].formatted_address
                    onPolygonComplete(resultPolygon, address)
                },
                (error) => {
                    console.error(error)
                    onPolygonComplete(resultPolygon, address)
                }
            )

        } else {
            _polygon.setMap(null)
        }


    }

    const _onDeletePolygon = () => {
        if (polygon) {
            polygon.setMap(null)
            _clearPolygon()
            _computeArea(null)

            onDeletePolygon()
        }
    }

    const _clearPolygon = () => {
        setPolygon(null)
    }
    return isLoaded ? (
        <div className='drawing-map-area'>
            <button style={{ display: 'none' }} ref={btnDelRef}
                onClick={() => { _onDeletePolygon() }}>btnDelRe
            </button>
            <button style={{ display: 'none' }} ref={btnGotoCurrentLocRef}
                onClick={() => { _gotoCurrentLocation() }}>btnGotoCurrentLocRef
            </button>
            <button style={{ display: 'none' }} ref={btnActivePolygonModeRef}
                onClick={() => { onActiveDrawingPolygonMode() }}>btnActivePolygonModeRef
            </button>
            <button style={{ display: 'none' }} ref={btnActivePanToolModeRef}
                onClick={() => { onActivePanToolsMode() }}>btnActivePanToolModeRef
            </button>
            <button style={{ display: 'none' }} ref={btnSearchRef}
                onClick={() => { onSearchMap() }}>btnSearchRef
            </button>

            <div id="my-node1">
                <GoogleMap
                    id="my-node"
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={15}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                    options={mapOptions}

                >
                    <DrawingManager
                        drawingMode={drawingMode}
                        options={drawingManagerOptions}
                        onPolygonComplete={_onPolygonComplete}
                    />
                    { /* Child components, such as markers, info windows, etc. */}
                    <></>
                </GoogleMap>
            </div>

            <DialogRequirePermission open={openDialogPermission} handleClose={onCloseDialogPermission} />
        </div >

    ) : <></>
}


export default DrawingMap