import React, { useState, useEffect, useContext } from 'react'
import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import DeleteIcon from '@mui/icons-material/Delete'
import { useNavigate, useParams } from 'react-router-dom'
import { ROUTE_PATH } from '../../constants/paths'
import Skeleton from '@mui/material/Skeleton'
import { createPlant, getPlantById, updatePlant } from '../../services/plant'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { styled } from '@mui/material/styles'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import { useSnackbar } from 'notistack'
import getAllPlantTypeByPlantId, { createPlantType, deletePlantType, updatePlantType } from '../../services/plantType'
import AlertPlantTypeValueModal from '../../components/AlertPlantTypeValueModal'
import AlertDeleteModal from '../../components/AlertDeleteModal'
import Empty from '../../components/Empty'
import { API_RES_STATUS } from '../../constants/apiResponseStatus'
import { AppContext } from '../../contexts/AppContext'
import { ROLES } from '../../constants/enums'
import getAllEnterprise from '../../services/enterprise'
import useCategoryEnterprise from '../../reactHook/useCategoryEnterprise'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#12203A",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#D3DCEC",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 1,
    },
}))

export default function CreatePlantsPage() {
    const { employee = {} } = useContext(AppContext)
    let { id: plantId } = useParams()
    const className = useStyles()
    const [plant, setPlant] = useState("")
    const [plantType, setPlantType] = useState("")
    const [isEditPlantType, setIsEditPlantType] = useState(false)
    const [loading, setLoading] = useState(false)
    // const { register, control, handleSubmit, setValue, formState: { errors } } = useForm()
    const columns = [
        { label: "ลำดับ", minWidth: 50, align: 'left', },
        { label: "ชื่อพันธ์", minWidth: 120, align: 'center' },
        { label: "", minWidth: 120, align: 'center' },
    ]
    const [listPlantType, setListPlantType] = useState([])
    const navigation = useNavigate()
    const { enqueueSnackbar, /*closeSnackbar*/ } = useSnackbar()
    const { enterprises } = useCategoryEnterprise()
    const [isEditPlantTypeModal, setIsEditPlantTypeModal] = useState(false)
    const [isDeleteModal, setIsDeleteModal] = useState(false)
    const [plantTypeId, setPlantTypeId] = useState(null)
    const [plantTypeOldName, setPlantTypeOldName] = useState(null)
    const [isEdit, setIsEdit] = useState(plantId ? true : false)
    const [valueEnterpriseId, setValueEnterpriseId] = useState()

    useEffect(() => {
        if (plantId) {
            loadPlant(plantId)
            loadPlantType(plantId)
            setIsEditPlantType(true)
        }
    }, [])

    const loadPlant = async (id) => {
        setLoading(true)
        const res = await getPlantById(id)
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            let data = json.data
            setPlant(data.name)
            setValueEnterpriseId(data.enterpriseId)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
        setLoading(false)
    }

    const loadPlantType = async (id) => {
        setLoading(true)
        const res = await getAllPlantTypeByPlantId(id)
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            let data = json.data
            setListPlantType(data)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
        setLoading(false)
    }

    const onSubmitFormPlant = async (data) => {
        if (plantId) {
            await onEditPlant(plantId, data)
        } else {
            await onCreatePlant(data)
        }
    }

    const onSubmitFormPlantType = async (data) => {
        await onCreatePlantType(data)
    }
    const onCreatePlant = async (data) => {
        var enterpriseId = employee.role === ROLES.AdminLing ? valueEnterpriseId : employee.enterpriseId
        const res = await createPlant({ namePlant: data, enterpriseId: enterpriseId })
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            enqueueSnackbar('Create plant successfully.', { variant: 'success' })
            navigation(ROUTE_PATH.EDIT_PLANTS.replace(":id", json.data))
            setIsEdit(true)
            setIsEditPlantType(true)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
            setIsEditPlantType(false)
        }
    }

    const onEditPlant = async (id, data) => {
        var enterpriseId = employee.role === ROLES.AdminLing ? valueEnterpriseId : employee.enterpriseId
        const res = await updatePlant({ id, name: data, enterpriseId: enterpriseId })
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            enqueueSnackbar('Update plant successfully.', { variant: 'success' })
            navigation(-1)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
    }

    const onCreatePlantType = async (data) => {
        const res = await createPlantType({ plantId, plantTypeName: data })
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            enqueueSnackbar('Create PlantType successfully.', { variant: 'success' })
            loadPlantType(plantId)
            setPlantType(null)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
    }

    const onEditPlantType = async (id, data) => {
        const res = await updatePlantType({ id, name: data })
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            enqueueSnackbar('Update enterprise successfully.', { variant: 'success' })
            loadPlantType(plantId)
            setIsEditPlantTypeModal(false)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
    }

    const openModalEditPlantType = async (id, data) => {
        setPlantTypeId(id)
        setPlantTypeOldName(data)
        setIsEditPlantTypeModal(true)
    }

    const openModalDeletePlantType = async (id) => {
        setPlantTypeId(id)
        setIsDeleteModal(true)
    }

    const onDeletePlantType = async (id) => {
        const res = await deletePlantType(id)
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            enqueueSnackbar('Delete Plant Type successfully.', { variant: 'success' })
            loadPlantType(plantId)
            setIsDeleteModal(false)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
    }

    const loadingPanel = <div style={{ marginRight: 10, marginLeft: 10 }}>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Skeleton variant="rect" height={50} width={"100%"} />
        </Grid>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="left"
            alignItems="center"
        >
            <Grid item xs={3} >
                <Skeleton variant="rect" height={50} width={"80%"} />
            </Grid>
            <Grid item xs={9} >
                <Skeleton variant="rect" height={50} width={"100%"} />
            </Grid>
        </Grid>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="left"
            alignItems="center"
        >
            <Grid item xs={3} >
                <Skeleton variant="rect" height={50} width={"80%"} />
            </Grid>
            <Grid item xs={9} >
                <Skeleton variant="rect" height={50} width={"100%"} />
            </Grid>
        </Grid>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="left"
            alignItems="center"
        >
            <Grid item xs={3} >
                <Skeleton variant="rect" height={50} width={"80%"} />
            </Grid>
            <Grid item xs={9} >
                <Skeleton variant="rect" height={50} width={"100%"} />
            </Grid>
        </Grid>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="left"
            alignItems="center"
        >
            <Grid item xs={3} >
                <Skeleton variant="rect" height={50} width={"80%"} />
            </Grid>
            <Grid item xs={9} >
                <Skeleton variant="rect" height={50} width={"100%"} />
            </Grid>
        </Grid>
    </div>

    return (
        <>
            <div style={{ margin: 40 }}>
                {
                    loading
                        ? loadingPanel
                        : <>
                            <Grid container  >
                                <Grid item xs={12}>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button onClick={() => { navigation(ROUTE_PATH.PLANTS) }}
                                            style={{
                                                width: '10%', color: '#12203A', borderRadius: 10, marginRight: 20,
                                                backgroundColor: 'white',
                                            }} variant="contained"
                                        >
                                            ยกเลิก
                                        </Button>
                                        <Button onClick={() => { onSubmitFormPlant(plant) }}
                                            style={{
                                                width: '10%', color: 'white',
                                                borderRadius: 10, backgroundColor: '#12203A'
                                            }}
                                            variant="contained"
                                            type='button'>
                                            บันทึก
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>

                            <Paper elevation={6} className={className.borderRadius10}>
                                {isEditPlantType ? <div style={{
                                    flexDirection: 'row', display: 'flex', justifyContent: 'space-between'
                                }}>
                                    <h2>พืช</h2>
                                    <Button style={{ marginTop: 20, marginBottom: 20 }}
                                        onClick={() => setIsEdit(!isEdit)}
                                        variant="outlined"
                                        color='inherit'
                                        startIcon={<ModeEditOutlineOutlinedIcon style={{ color: 'black', marginLeft: 10 }} />}>
                                    </Button>
                                </div> : <h2>พืช</h2>}
                                <div
                                    style={{
                                        flexDirection: 'row', display: 'flex',
                                        alignItems: 'center', marginBottom: 30
                                    }}>
                                    <Typography component="h6" variant="h6" style={{ marginRight: 20 }} >
                                        ชื่อพืช :
                                    </Typography>
                                    <TextField
                                        disabled={isEdit}
                                        size='small'
                                        style={{ width: '50%' }}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={plant}
                                        onChange={(e) => {
                                            setPlant(e.target.value)
                                        }}
                                        placeholder="กรอกชื่อพืช"
                                    />
                                </div>
                                {employee.role === ROLES.AdminLing &&
                                    <div style={{
                                        flexDirection: 'row', display: 'flex',
                                        alignItems: 'center', marginBottom: 30
                                    }}>
                                        <Typography component="h6" variant="h6" style={{ marginRight: 20 }} >
                                            บริษัท :
                                        </Typography>
                                        <FormControl size="small" sx={{ m: 1, minWidth: 220 }}>
                                            <InputLabel>เลือกบริษัท</InputLabel>
                                            <Select
                                                label="เลือกบริษัท"
                                                value={valueEnterpriseId}
                                                onChange={(e) => {
                                                    setValueEnterpriseId(e.target.value)
                                                }}
                                            >
                                                {enterprises.map((e) => {
                                                    return <MenuItem key={`select-enterprise-${e.id}`} value={e.id}>{e.name}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                }

                            </Paper>

                            <Paper elevation={6} style={{ backgroundColor: isEditPlantType ? 'white' : 'lightgray' }} className={className.borderRadius10}>
                                <h2>พันธุ์พืช</h2>
                                <div
                                    style={{
                                        flexDirection: 'row', display: 'flex',
                                        alignItems: 'center', marginBottom: 30
                                    }}>
                                    <Typography component="h6" variant="h6" style={{ marginRight: 20 }} >
                                        ชื่อพันธุ์พืช :
                                    </Typography>
                                    <TextField
                                        size='small'
                                        style={{ width: '50%', marginRight: 20 }}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={plantType}
                                        onChange={(e) => {
                                            setPlantType(e.target.value)
                                        }}
                                        disabled={!isEditPlantType}
                                    />
                                    <Button onClick={() => { onSubmitFormPlantType(plantType) }}
                                        disabled={!isEditPlantType}
                                        style={{
                                            width: '15%', color: 'white',
                                            borderRadius: 10, backgroundColor: '#12203A'
                                        }}
                                        variant="contained"
                                        type='button'>
                                        เพิ่มพันธุ์พืช
                                    </Button>
                                </div>
                            </Paper>

                            <div style={{ marginTop: 20 }}>
                                <TableContainer className={className.container}>
                                    <Table stickyHeader aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                {columns.map((column, i) => (
                                                    <StyledTableCell
                                                        key={i}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth }}
                                                    >
                                                        {column.label}
                                                    </StyledTableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {listPlantType.map((row, i) => {
                                                return (
                                                    <StyledTableRow key={i}>
                                                        <StyledTableCell >{i + 1}</StyledTableCell>
                                                        <StyledTableCell align="center" >{row.name}</StyledTableCell>
                                                        <StyledTableCell align="center">
                                                            <Button onClick={() => openModalEditPlantType(row.id, row.name)} variant="outlined" color='inherit' startIcon={<ModeEditOutlineOutlinedIcon />} style={{ marginRight: 20 }}></Button>
                                                            {/* <Button onClick={() => openModalDeletePlantType(row.id, row.name)} variant="outlined" color='error' startIcon={<DeleteIcon style={{ color: 'red', marginLeft: 10 }} />}></Button> */}
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                )
                                            })
                                            }
                                        </TableBody>
                                    </Table>
                                    {listPlantType.length === 0 ? <div style={{ display: 'flex', justifyContent: 'center' }}><Empty /></div> : null}
                                </TableContainer>
                            </div>
                        </>
                }
            </div >
            <AlertPlantTypeValueModal
                open={isEditPlantTypeModal}
                id={plantTypeId}
                name={plantTypeOldName}
                OnClickInModal={() => setIsEditPlantTypeModal(false)}
                OnClickSubmitModal={onEditPlantType}
            />
            <AlertDeleteModal
                open={isDeleteModal}
                id={plantTypeId}
                OnClickInModal={() => setIsDeleteModal(false)}
                OnClickSubmitModal={onDeletePlantType}
            />
        </>
    )
}


const useStyles = makeStyles({
    borderRadius10: {
        borderRadius: 20,
        marginTop: 30,
        paddingRight: 30,
        paddingLeft: 30,
        paddingTop: 30,
        paddingBottom: 40
    },
    divLine: {
        width: 1, backgroundColor: '#2A1D0E'
    },
    lableRow: {
        marginBottom: 10, flexDirection: 'row', display: 'flex', fontWeight: 'bold'
    }
})
