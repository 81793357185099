import { Card, CardContent, Grid } from '@mui/material'
import React from 'react'
import { makeStyles } from '@mui/styles'

export default function PerformanceDetailCard() {
    const classes = useStyles()

    return (
        <Card style={{ borderRadius: 15 }}>
            <CardContent style={{ margin: 20 }}>
                <Grid container  >
                    <Grid item xs={3}>
                        <Grid container  >
                            <Grid item mt={2} xs={12}>
                                <label className={classes.fontTitle}>หมายเลขพื้นที่</label>
                                <label className={classes.fontVal} style={{ marginLeft: 12 }}>59485058544</label>
                            </Grid>
                            <Grid item mt={2} xs={12}>
                                <label className={classes.fontTitle}>ชื่อแปลง </label>
                                <label style={{ marginLeft: 12 }}>: สวนของป้ารัตนา</label>
                            </Grid>
                            <Grid item mt={2} xs={12}>
                                <label className={classes.fontTitle}>พื้นที่ </label>
                                <label style={{ marginLeft: 12 }}>: เมืองชัยภูมิ</label>
                            </Grid>
                            <Grid item mt={2} xs={12}>
                                <label className={classes.fontTitle}>ที่อยู่ </label>
                                <label style={{ marginLeft: 12 }}>: 13.7618 , 100.5324</label>
                            </Grid>
                            <Grid item mt={2} xs={12}>
                                <label className={classes.fontTitle}>ปริมาณผลผลิต </label>
                                <label style={{ marginLeft: 12 }}>: 10 ตัน</label>
                            </Grid>
                            <Grid item mt={2} xs={12}>
                                <label className={classes.fontTitle}>ช่วงเวลาเก็บเกี่ยว</label>
                            </Grid>
                            <Grid item ml={5} mt={2} xs={12}>
                                <label className={classes.fontTitle}>เริ่มต้น  </label>
                                <label style={{ marginLeft: 12 }}>01/04/2565</label>
                            </Grid>
                            <Grid item ml={5} mt={2} xs={12}>
                                <label className={classes.fontTitle}>สิ้นสุด  </label>
                                <label style={{ marginLeft: 12 }}> 30/06/2565</label>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ height: '100%', width: 1, background: '#F08E26' }}></div>
                    </Grid>
                    <Grid item xs={7}>
                        <Grid container  >
                            <div style={{ margin: 10 }} >
                                <img style={{ width: 150, height: 150, borderRadius: 10 }} src="https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350" />
                            </div>
                            <div style={{ margin: 10 }} >
                                <img style={{ width: 150, height: 150, borderRadius: 10 }} src="https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350" />
                            </div>
                            <div style={{ margin: 10 }} >
                                <img style={{ width: 150, height: 150, borderRadius: 10 }} src="https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350" />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

const useStyles = makeStyles((theme) => ({
    fontTitle: {
        color: '#F08E26'
    },
    fontVal: {
        color: '#000'
    },
}))