import { Button, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, Tooltip } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import Card from '@mui/material/Card'
import TextField from '@mui/material/TextField'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { AppContext } from '../../contexts/AppContext'
import { ROLES } from '../../constants/enums'
import AlertConfirmModal from '../../components/AlertConfirmModal'
import { API_RES_STATUS } from '../../constants/apiResponseStatus'
import { getAllEnterprisePositionByEnterpriseId, updateEnterprisePosition } from '../../services/enterprisePosition'
import ErrorIcon from '@mui/icons-material/Error'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import useCategoryEnterprise from '../../reactHook/useCategoryEnterprise'

export default function RoleManagementPage() {
    const { employee = {} } = useContext(AppContext)
    const navigation = useNavigate()
    const { enqueueSnackbar, /*closeSnackbar*/ } = useSnackbar()
    const [listPosition, setListPosition] = useState([])
    const [loading, setLoading] = useState(false)
    const [openConfirmModal, setOpenConfirmModal] = useState(false)
    const [filterEnterpriseId, setFilterEnterpriseId] = useState('')
    const { enterprises } = useCategoryEnterprise()

    useEffect(() => {
        loadPosition(employee.enterpriseId)
    }, [employee])

    const loadPosition = async (enterpriseId) => {
        setLoading(true)
        const res = await getAllEnterprisePositionByEnterpriseId(enterpriseId)
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            let data = json.data
            data.sort((a, b) => (a.role > b.role) ? 1 : -1)
            setListPosition(data)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
        setLoading(false)
    }

    const onSetValue = (index, role, inputValue) => {
        let newValue = [...listPosition]
        newValue[index].name = inputValue
        setListPosition(newValue)
    }

    const OnClickSubmitConfirmModal = async () => {
        const res = await updateEnterprisePosition({ id: employee.enterpriseId, liseEnterprisePosition: listPosition })
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            enqueueSnackbar('Save Position successfully.', { variant: 'success' })
            loadPosition()
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
        setOpenConfirmModal(false)
    }

    const OnClickInConfirmModal = () => {
        setOpenConfirmModal(false)
    }
    const onInputChange = (value, key) => {
        setFilterEnterpriseId(value)
        loadPosition(value)
    }

    return (
        <div style={{ margin: 40 }}>
            <Grid container rowSpacing={1} >
                <Grid item xs={6}>
                    {employee.role === ROLES.AdminLing ? <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <FormControl sx={{ m: 1, minWidth: 220 }} size="small">
                            <InputLabel >เลือกบริษัท</InputLabel>
                            <Select
                                label="เลือกบริษัท"
                                value={filterEnterpriseId}
                                onChange={(e) => { onInputChange(e.target.value, 'enterpriseId') }}
                            >
                                {enterprises.map((e) => {
                                    return <MenuItem key={`select-enterprise-${e.id}`} value={e.id}>{e.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </div> : <></>}
                </Grid>
                <Grid item xs={6}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={() => navigation(-1)} style={{ width: '20%', borderRadius: 7, borderWidth: 1.5, marginRight: 20, color: '#12203A', borderColor: '#12203A' }} variant="outlined">
                            ยกเลิก
                        </Button>
                        <Button onClick={() => setOpenConfirmModal(true)} style={{ width: '20%', color: 'white', borderRadius: 7, backgroundColor: '#12203A' }} variant="contained">
                            บันทึก
                        </Button>
                    </div>
                </Grid>
            </Grid>
            <div style={{ marginTop: 30 }}>
                <Card >
                    <CardContent style={{ margin: 20 }}>
                        <h3>กำหนดชื่อตำแหน่ง<span style={{ fontSize: 14, marginLeft: 16, color: '#BF7C1A' }}>กรุณากำหนดชื่อตำแหน่งที่ต้องการแสดง</span></h3>
                        <Grid container  >
                            {
                                listPosition.map((item, i) => {
                                    return (
                                        <Grid key={i} item xs={12} mt={4}>
                                            <Grid container >
                                                <Grid item mt={2} xs={3.5}>
                                                    <div>
                                                        {item.id}
                                                    </div>
                                                </Grid>
                                                <Grid item mt={2} mr={2} xs={.5}>
                                                    <ContentCopyIcon style={{ marginLeft: 10, marginRight: 10 }} onClick={() => {
                                                        enqueueSnackbar('copied.', { variant: 'success' })
                                                        navigator.clipboard.writeText(item.id)
                                                    }} color='info' />
                                                </Grid>
                                                <Grid item mt={1} xs={4}>
                                                    <TextField
                                                        size='small'
                                                        style={{ width: '90%' }}
                                                        id="filled-disabled"
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        value={item.name}
                                                        onChange={(e) => {
                                                            // setValue(e.target.value)
                                                            onSetValue(i, item.role, e.target.value)
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item mt={2} xs={1}>
                                                    {
                                                        (item.role === ROLES.AdminLing &&
                                                            <Tooltip title="แอดมินของระบบ" arrow>
                                                                <ErrorIcon color='primary' style={{ marginRight: 10 }} />
                                                            </Tooltip>
                                                        )
                                                    }
                                                    {
                                                        (item.role === ROLES.Directer &&
                                                            <Tooltip title="ระดับผู้บริหาร" arrow>
                                                                <ErrorIcon color='primary' style={{ marginRight: 10 }} />
                                                            </Tooltip>
                                                        )
                                                    }
                                                    {
                                                        (item.role === ROLES.Admin &&
                                                            <Tooltip title="พนักงานระดับ แอดมิน จะสามารถใช้ระบบได้ทั้งหมด สร้างทีม และสามารถสร้างพนักงานได้" arrow>
                                                                <ErrorIcon color='primary' style={{ marginRight: 10 }} />
                                                            </Tooltip>
                                                        )
                                                    }
                                                    {
                                                        (item.role === ROLES.AreaManager &&
                                                            <Tooltip title="พนักงานระดับ หัวหน้าพื้นที่ จะสามารถเห็นการทำงานของคนในพื้นที่และในแต่ละทีมได้ สามารถสร้างทีมและกำหนดหัวหน้าทีมได้" arrow>
                                                                <ErrorIcon color='primary' style={{ marginRight: 10 }} />
                                                            </Tooltip>
                                                        )
                                                    }
                                                    {
                                                        (item.role === ROLES.TeamLead &&
                                                            <Tooltip title="พนักงานระดับ หัวหน้าทีม จะสามารถเห็นการทำงานของคนในทีมได้ และสามารถเพิ่มลูกทีมในทีมตัวเองได้" arrow>
                                                                <ErrorIcon color='primary' style={{ marginRight: 10 }} />
                                                            </Tooltip>
                                                        )
                                                    }
                                                    {
                                                        (item.role === ROLES.Staff &&
                                                            <Tooltip title="พนักงานระดับ พนักงาน จะสามารถเห็นเพียงงานของตนเองเท่านั้น" arrow>
                                                                <ErrorIcon color='primary' style={{ marginRight: 10 }} />
                                                            </Tooltip>
                                                        )
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </CardContent>
                </Card>
                <AlertConfirmModal header='ยืนยันการแก้ไข' open={openConfirmModal} OnClickInModal={OnClickInConfirmModal} OnClickSubmitModal={OnClickSubmitConfirmModal} />
            </div>
        </div>
    )
}
