import './App.css'
import AppRouter from './navigation/AppRouter'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { SnackbarProvider } from 'notistack'
import AppContextProvider from './contexts/AppContext'

const theme = createTheme({
    palette: {
        primary: {
            main: '#F08E26',
        },
        secondary: {
            main: '#12203a'
        },
        labelBlack: {
            main: '#040404'
        },
        primaryTextWhite: {
            main: '#12203A',
            contrastText: "#FFFFFF "
        },
        primaryTextBlack: {
            main: '#F08E26',
            contrastText: "#040404"
        },
    },
})

function App() {
    return (
        <ThemeProvider theme={theme}>
            <AppContextProvider>
                <SnackbarProvider maxSnack={3}>
                    <AppRouter />
                </SnackbarProvider>
            </AppContextProvider>
        </ThemeProvider>
    )
}

export default App
