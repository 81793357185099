import axios from 'axios'
import { LOCAL_STORAGE_KEY } from '../constants/localStorageKey'

// axios interceptors
// Add a response interceptor
axios.interceptors.response.use(function (response) {
    return response
}, function (error) {
    const { response } = error
    const { status } = response
    if (status === 401) {
        localStorage.setItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN, "")
        window.location.href = '/login'
        return
    }
    return Promise.reject(error)
})

const defaultHeader = {
    'Accept': 'application/json',
    // 'Accept-Charset': 'UTF-8',
    'Content-Type': 'application/json',
    // 'Authorization': `Bearer ${accessToken}`
}

const addHeaderAuthorization = (header) => {
    return { ...header, 'Authorization': `Bearer ${localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN)}` }
}

const AxiosHelper = {
    get: async (url, customOptions, requireAuth = true) => {
        if (customOptions == null) {
            let header = defaultHeader
            if (requireAuth) {
                header = addHeaderAuthorization(header)
            }

            let res = await axios({
                method: "GET",
                url: url,
                headers: header
            })
            return res
        } else {
            return await axios({ method: "GET", url: url, customOptions })
        }
    },
    post: async (url, body, customOptions, requireAuth = true) => {
        if (customOptions == null) {
            let header = defaultHeader
            if (requireAuth) {
                header = addHeaderAuthorization(header)
            }
            let res = await axios(
                {
                    method: "POST",
                    url: url,
                    headers: header,
                    data: JSON.stringify(
                        body
                    )
                }
            )
            return res
        } else {

            if (requireAuth) {
                customOptions["headers"] = addHeaderAuthorization(customOptions["headers"])
            }
            let res = await axios(
                {
                    method: "POST",
                    url: url,
                    ...customOptions
                }
            )

            return res
        }
    },
    put: async (url, body, customOptions, requireAuth = true) => {
        if (customOptions == null) {
            let header = defaultHeader
            if (requireAuth) {
                header = addHeaderAuthorization(header)
            }
            let res = await axios(
                {
                    method: "PUT",
                    url: url,
                    headers: header,
                    data: JSON.stringify(
                        body
                    )
                }
            )
            return res
        } else {
            return await axios(
                {
                    method: "PUT",
                    url: url,
                    ...customOptions
                }
            )
        }
    },
    delete: async (url, body, customOptions, requireAuth = true) => {
        if (customOptions == null) {
            let header = defaultHeader
            if (requireAuth) {
                header = addHeaderAuthorization(header)
            }
            let res = await axios(
                {
                    method: "DELETE",
                    url: url,
                    headers: header,
                    // data: JSON.stringify(
                    //     body
                    // )
                }
            )
            return res
        } else {
            return await axios(
                {
                    method: "DELETE",
                    url: url,
                    ...customOptions
                }
            )
        }
    },
}

export default AxiosHelper