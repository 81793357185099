import { GoogleMap, Polygon, useLoadScript } from "@react-google-maps/api"
import { useCallback, useEffect, useState } from "react"
import DialogRequirePermission from "../../components/Dialog/DialogRequirePermission"
import { googleMapLibraries, googleMapAPIKey } from "../../constants/common"

export default function Map({ polygonPath = [] }) {
    // state
    const [center, setCenter] = useState({
        lat: -3.745,
        lng: -38.523
    })
    const [map, setMap] = useState(null)


    const [openDialogPermission, setOpenDialogPermission] = useState(false)
    const onCloseDialogPermission = () => setOpenDialogPermission(false)
    //


    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: googleMapAPIKey,
        libraries: googleMapLibraries,
    })
    const mapOptions = {
        disableDefaultUI: false,
        mapTypeControl: true,
        streetViewControl: false,
        mapTypeId: 'hybrid'
    }

    // callback
    const onUnmount = useCallback((map) => {
        setMap(null)
    }, [])

    const onLoad = useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds(center)
        map.fitBounds(bounds)
        setMap(map)
    }, [])

    //

    useEffect(() => {
        if (map && polygonPath.length > 0) {
            let bound = new window.google.maps.LatLngBounds()
            polygonPath.forEach(latLng => {
                bound.extend(latLng)
            })
            const centerMap = bound.getCenter()
            setCenter({ lat: centerMap.lat(), lng: centerMap.lng() })
        }
    }, [map, polygonPath])


    if (loadError) {
        return "Error loading maps"
    }
    if (!isLoaded) {
        return "loading Maps"
    }

    if (polygonPath.length === 0) {
        return ''
    }


    return <><GoogleMap
        mapContainerStyle={{ width: '100%', height: '70vh' }}
        center={center}
        zoom={14}
        onUnmount={onUnmount}
        onLoad={onLoad}
        options={mapOptions}
    >
        <Polygon
            options={{
                strokeColor: '#f08e26',
                fillColor: '#f0a556',
            }}
            path={polygonPath}
        />
    </GoogleMap>
        <DialogRequirePermission open={openDialogPermission} handleClose={onCloseDialogPermission} />
    </>
}