import React, { useContext, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import { Button, IconButton, InputAdornment, InputLabel, Skeleton, Tooltip } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import OutlinedInput from '@mui/material/OutlinedInput'
import SearchIcon from '@mui/icons-material/Search'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from '../../constants/paths'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import getAllArea from '../../services/area'
import { AppContext } from '../../contexts/AppContext'
import { ROLES } from '../../constants/enums'
import { API_RES_STATUS } from '../../constants/apiResponseStatus'
import StyledDataGrid from '../../components/StyledDataGrid'
import { useSnackbar } from 'notistack'
import useCategoryEnterprise from '../../reactHook/useCategoryEnterprise'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'


export default function AreaManagementPage() {
    const { employee = {} } = useContext(AppContext)
    const navigation = useNavigate()
    const [listArea, setListArea] = useState([])
    const [loading, setLoading] = useState(false)
    const { enqueueSnackbar } = useSnackbar()

    const [tablePageSize, setTablePageSize] = useState(10)
    const [tableCurrentPage, setTableCurrentPage] = useState(0)
    const [tableTotalRow, setTableTotalRow] = useState(0)

    const { enterprises } = useCategoryEnterprise()

    const [filterEnterpriseId, setFilterEnterpriseId] = useState('')
    const [searchKeyword, setSearchKeyword] = useState('')

    useEffect(() => {
        loadArea()
    }, [tablePageSize, tableCurrentPage, filterEnterpriseId, filterEnterpriseId])

    const loadArea = async () => {
        setLoading(true)
        const axiosRes = await getAllArea({ pageSize: tablePageSize, currentPage: tableCurrentPage + 1, filterEnterpriseId, searchKeyword })
        const { data, status, message = "" } = JSON.parse(axiosRes.data)
        if (status === API_RES_STATUS.success) {
            const { listData, totalRow = 0 } = data
            setListArea(listData)
            setTableTotalRow(totalRow)
        } else {
            enqueueSnackbar(message, { variant: 'error', autoHideDuration: 10000 })
        }
        setLoading(false)
    }

    const columns = [
        { headerName: "ชื่อพื้นที่", field: "name", flex: 1 },
        { headerName: "ผู้รับผิดชอบ", field: "areaSupervisor", flex: 1 },
        { headerName: "จำนวนทีม", field: "quantityTeam", flex: 1 },
        { headerName: "จำนวน Staff", field: "quantityEmployee", flex: 1 },
        {
            field: 'action-view',
            headerName: "รายละเอียด",
            renderCell: ({ row }) => {
                return <>
                    <IconButton
                        size="small"
                        onClick={() => navigation(ROUTE_PATH.EDIT_AREA.replace(":id", row.id))}
                    >
                        <ModeEditOutlineOutlinedIcon />
                    </IconButton>
                    <Tooltip title="คัดลอก ID" placement='top'>
                        <IconButton
                            size="small"
                            onClick={() => {
                                const { id } = row
                                navigator.clipboard.writeText(id)
                            }}
                        >
                            <ContentCopyIcon />
                        </IconButton>
                    </Tooltip>
                </>
            },
            flex: 1
        },
    ]


    const onFilterEnterpriseChange = (event) => {
        setFilterEnterpriseId(event.target.value)
    }

    const onFilterSearchKeywordChange = (event) => {
        setSearchKeyword(event.target.value)
    }

    const onInputSearchKeyDown = (e) => {
        if (e.keyCode === 13) {
            onSearchClick()
        }
    }

    const onSearchClick = () => {
        loadArea()
    }

    const loadingPanel = <div style={{ marginRight: 10, marginLeft: 10 }}>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Skeleton variant="rect" height={50} width={"100%"} />
        </Grid>
        <Grid style={{ marginTop: 10 }}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Skeleton variant="rect" height={500} width={"100%"} />
        </Grid>
    </div>

    return (
        <>
            <div style={{ margin: 40 }}>
                {
                    loading
                        ? loadingPanel
                        : <>
                            <Grid container rowSpacing={1} columnSpacing={1} >
                                <Grid item sm={12} md={6} >
                                    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                        {employee.role === ROLES.AdminLing &&
                                            <FormControl sx={{ m: 1, width: '100%' }} size="small">
                                                <InputLabel >เลือกบริษัท</InputLabel>
                                                <Select
                                                    label="เลือกบริษัท"
                                                    value={filterEnterpriseId}
                                                    onChange={onFilterEnterpriseChange}
                                                >
                                                    <MenuItem value={''}>ทั้งหมด</MenuItem>
                                                    {enterprises.map((e) => {
                                                        return <MenuItem key={`select-enterprise-${e.id}`} value={e.id}>{e.name}</MenuItem>
                                                    })}
                                                </Select>
                                            </FormControl>
                                        }
                                        <FormControl sx={{ m: 1, width: '100%' }} size="small">
                                            <OutlinedInput
                                                placeholder='ชื่อพื้นที่, ชื่อผู้รับผิดชอบ'
                                                value={searchKeyword}
                                                onChange={onFilterSearchKeywordChange}
                                                onKeyDown={onInputSearchKeyDown}
                                                startAdornment={
                                                    <InputAdornment position='start'>
                                                        <SearchIcon onClick={onSearchClick} />
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </div>
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        {employee.role === ROLES.AdminLing
                                            || employee.role === ROLES.Directer
                                            || employee.role === ROLES.Admin
                                            || employee.role === ROLES.AreaManager
                                            ? <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <Button onClick={() => navigation(ROUTE_PATH.CREATE_AREA)} style={{ color: 'white', borderRadius: 5, backgroundColor: '#12203A' }} variant="contained">
                                                    <AddIcon style={{ marginRight: 2 }} />
                                                    สร้างพื้นที่
                                                </Button>
                                            </div>
                                            : <></>
                                        }
                                    </div>

                                </Grid>
                                <Grid item sm={12}>
                                    <Box sx={{ height: '70vh', width: '100%' }}>
                                        <StyledDataGrid
                                            rows={listArea}
                                            columns={columns}
                                            paginationMode="server"
                                            rowCount={tableTotalRow}
                                            page={tableCurrentPage}
                                            pageSize={tablePageSize}
                                            loading={loading}
                                            pagination
                                            rowsPerPageOptions={[10, 25, 50, 100]}
                                            onPageChange={(newPage) => setTableCurrentPage(newPage)}
                                            onPageSizeChange={(newPageSize) => setTablePageSize(newPageSize)}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>

                        </>
                }
            </div>
        </>
    )
}