
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { useState } from 'react';
import DialogGenSecretKey from './dialogGenKey';
import { EXPORT_DATA_API_URL } from '../../constants/api_url'

const jsExmaple = `const myHeaders = new Headers();
myHeaders.append("x-api-key", "your secret key");

const requestOptions = {
  method: "GET",
  headers: myHeaders,
  redirect: "follow"
};

fetch("${EXPORT_DATA_API_URL}/plot", requestOptions)
  .then((response) => response.text())
  .then((result) => console.log(result))
  .catch((error) => console.error(error));`

export default function ApiSettingsPage() {
    const mockDataResponse = {
        "status": "success",
        "data": [
            {
                "uid": "696465fa-eabb-4f6b-a40f-01a01d2244bc",
                "fullName": "name surname",
                "deedNo": "1",
                "cardId": "1100000000000",
                "size": 74.02,
                "address": "address",
                "detail": "detail",
                "farms": [],
                "areaName": "Area 1",
                "teamName": "team 1",
                "employeeFullName": "name surname",
                "enterpriseName": "CompanyA",
                "createdDate": "2024-09-04T05:18:50.2497",
                "updatedDate": "2024-09-04T05:18:50.2497"
            }
        ]
    }
    const mockData401Response = {
        "type": "https://tools.ietf.org/html/rfc7235#section-3.1",
        "title": "Unauthorized",
        "status": 401,
        "traceId": "00-3b710fc95634e4dcb7ab48a0fecd38ec-b16adc1fb92ea286-00"
    }

    function createData(
        name,
        description,
        type
    ) {
        return { name, description, type };
    }
    // คำอธิบายอ้างอิงจาก summary ใน class model หลังบ้าน 
    const rows = [
        createData('status', 'สถานะการตอบกลับ success | error | 401', 'string | number'),
        createData('message', 'ข้อความกรณีเกิดข้อผิดพลาด', 'string'),
        createData('data', 'ข้อมูลตอบกลับ', 'object'),
        createData('data.plots', 'ข้อมูลแปลงทั้งหมด', 'array object'),
        createData('data.plots[].uid', 'ID ของเกษตรในระบบ', 'GUID'),
        createData('data.plots[].fullName', 'ชื่อเต็มเกษตรกร', 'string'),
        createData('data.plots[].deedNo', 'หมายเลขโฉนด', 'string'),
        createData('data.plots[].cardId', 'หมายเลขทะเบียนเกษตรกร', 'string'),
        createData('data.plots[].size', 'ขนาดพื้นที่ หน่วยเป็น ตรม. ', 'float'),
        createData('data.plots[].address', 'ที่อยู่แปลง', 'string'),
        createData('data.plots[].detail', 'รายละเอียดแปลง', 'string'),
        createData('data.plots[].farms', 'ข้อมูลฟาร์มในแปลง', 'array object'),
        createData('data.plots[].farms[].plantTypeName', 'ชนิดพืช', 'string'),
        createData('data.plots[].farms[].plantName', 'พันธุ์พืช', 'string'),
        createData('data.plots[].farms[].startDate', 'วันที่ปลูก', 'string'),
        createData('data.plots[].farms[].completedDate', 'วันที่เก็บเกี่ยว', 'string'),
        createData('data.plots[].areaName', 'ชื่อพื้นที่', 'string'),
        createData('data.plots[].teamName', 'ชื่อทีม', 'string'),
        createData('data.plots[].employeeFullName', 'ชื่อพนักงาน', 'string'),
        createData('data.plots[].enterpriseName', 'ชื่อบริษัท', 'string'),
        createData('data.plots[].createdDate', 'วันที่สร้างแปลง', 'string'),
        createData('data.plots[].updatedDate', 'วันที่ อัพเดทแปลงล่าสุด', 'string')
    ];


    return <div style={{ margin: 40 }}>
        <Grid container spacing={3}>
            <Grid container item direction="row" justifyContent="space-between">
                <Grid item xs={12} md={6}><h3>รายการ API</h3></Grid>
                <Grid item xs={12} md={3}>
                    <DialogGenSecretKey />
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <b>ข้อมูลพื้นที่แปลงของทุกบริษัท</b>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4}><b>Base URL </b></Grid>
                            <Grid item xs={12} md={8} style={{ wordBreak: 'break-word' }}>{`${EXPORT_DATA_API_URL}/plot`}</Grid>
                            <Grid item xs={12} md={4}><b>คำอธิบายข้อมูล</b></Grid>
                            <Grid item xs={12} md={8} style={{ wordBreak: 'break-word' }}>ข้อมูลพื้นที่แปลงของทุกบริษัท</Grid>
                            <Grid item xs={12} md={4}><b>HTTP Request Method</b></Grid>
                            <Grid item xs={12} md={8} style={{ wordBreak: 'break-word' }}>GET</Grid>
                            <Grid item xs={12} md={4}><b>Query String</b></Grid>
                            <Grid item xs={12} md={8} style={{ wordBreak: 'break-word' }}>ไม่มี</Grid>
                            <Grid item xs={12} md={4}><b>Authentication</b></Grid>
                            <Grid item xs={12} md={8} style={{ wordBreak: 'break-word' }}>แนบ Secret Key มาใน Header x-api-key</Grid>

                            <Grid item xs={12}><b>รายละเอียดข้อมูลตอบกลับ</b></Grid>
                            <Grid item xs={12}>
                                <TableContainer>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>ชื่อฟิลด์</TableCell>
                                                <TableCell >คำอธิบาย</TableCell>
                                                <TableCell >ประเภทตัวแปร</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell >{row.description}</TableCell>
                                                    <TableCell >{row.type}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item xs={12}><b>ตัวอย่างข้อมูลเมื่อเรียกใช้งานสำเร็จ</b></Grid>
                            <Grid item xs={12}>
                                <div><b>HTTP Request Status 200 Ok</b></div>
                                <div style={{ overflowX: 'scroll' }}><pre>{JSON.stringify(mockDataResponse, null, 2)}</pre></div>
                            </Grid>
                            <Grid item xs={12}><b>ตัวอย่างข้อมูลเมื่อ Secret Key ไม่ถูกต้อง</b></Grid>
                            <Grid item xs={12}>
                                <div><b>HTTP Request Status 401</b></div>
                                <div style={{ overflowX: 'scroll' }}><pre>{JSON.stringify(mockData401Response, null, 2)}</pre></div>
                            </Grid>
                            <Grid item xs={12}><b>ตัวอย่างการใช้งานใน ภาษา Javascript</b></Grid>
                            <Grid item xs={12}>
                                <div style={{ overflowX: 'scroll' }}>
                                    <pre>
                                        {jsExmaple}
                                    </pre>
                                </div>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
    </div>
}