import { ApiUrl } from "../constants/api_url"
import AxiosHelper from "../helper/axiosHelper"

export default async function getAllSchedule() {
    return await AxiosHelper.get(ApiUrl.SCHEDULE)
}

export async function getScheduleById(id) {
    return await AxiosHelper.get(ApiUrl.SCHEDULE + "/" + id)
}

export async function getScheduleDateTimeById(id) {
    return await AxiosHelper.get(ApiUrl.SCHEDULE + "/getScheduleDateTimeById/" + id)
}

export async function createSchedule({ name, detail, isTimeInterval, listScheduleDateTime }) {
    return await AxiosHelper.post(ApiUrl.SCHEDULE, { name, detail, isTimeInterval: Boolean(isTimeInterval), listScheduleDateTime })
}

export async function updateSchedule({ id, name, detail, isTimeInterval, listScheduleDateTime }) {
    return await AxiosHelper.put(ApiUrl.SCHEDULE + "/" + id, { id, name, detail, isTimeInterval: Boolean(isTimeInterval), listScheduleDateTime })
}

export async function getAllScheduleDetailByEnterpriseId(enterpriseId) {
    return await AxiosHelper.get(ApiUrl.SCHEDULE + "/getAllScheduleDetailByEnterpriseId/" + enterpriseId)
}

export async function deleteSchedule(id) {
    return await AxiosHelper.delete(ApiUrl.SCHEDULE + "/" + id)
}

export async function deleteScheduleDateTime(id) {
    return await AxiosHelper.delete(ApiUrl.SCHEDULE + "/ScheduleDateTime/" + id)
}