import React, { useContext, useState } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { makeStyles, useTheme } from '@mui/styles'
import { useForm } from 'react-hook-form'
import { CircularProgress, Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import BackgroundImage from '../../assets/images/Landing.png'
import { ROUTE_PATH } from '../../constants/paths'
import Avatar from '@mui/material/Avatar'
import { signInEmployee } from '../../services/employee'
import { API_RES_STATUS } from '../../constants/apiResponseStatus'
import { LOCAL_STORAGE_KEY } from '../../constants/localStorageKey'
import { AppContext } from '../../contexts/AppContext'
export default function LoginPage() {
    const theme = useTheme()
    const classes = useStyles()
    const navigation = useNavigate()
    const { loadEmployee } = useContext(AppContext)

    const { register, handleSubmit, formState: { errors } } = useForm()
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [signinErrorMessage, setSigninErrorMessage] = useState('')

    const onSubmitLoginForm = async (formData) => {
        const { email, password } = formData
        setSigninErrorMessage('')
        setErrorMessage('')
        setLoading(true)
        const axiosRes = await signInEmployee({ email, password })
        let json = JSON.parse(axiosRes.data)
        const { data, status, message } = json
        if (status === API_RES_STATUS.success) {
            localStorage.setItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN, data)
            await loadEmployee()
            navigation(ROUTE_PATH.HOME, { replace: true })
        } else if (status === API_RES_STATUS.error) {
            setSigninErrorMessage(message)
        }
        setLoading(false)
    }

    return (
        <div className={classes.paperContainer}>
            <div className={classes.row}>
                <Avatar
                    sx={{ width: 120, height: 120 }}
                    alt="Remy Sharp"
                    src={require("../../assets/images/Logo.png")} />
            </div>
            <Typography component="h2" variant="h2">
                LING
            </Typography>
            <Typography component="h2" variant="h2">
                ENTERPRISE
            </Typography>
            <Typography className={classes.errorMessage}>
                {errorMessage}
            </Typography>

            <Grid container style={{ justifyContent: 'center' }}>
                <Grid item xs={10} sm={6} md={4} className={classes.signInContainer}>
                    <form className={classes.form} onSubmit={handleSubmit(onSubmitLoginForm)}>
                        <TextField
                            color='labelBlack'
                            InputProps={{
                                style: {
                                    backgroundColor: "white",
                                    color: 'black'
                                }
                            }}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label="Email Address"
                            autoComplete="email"
                            autoFocus
                            {...register("email", {
                                required: { value: true, message: "Email is required." },
                                pattern: { value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, message: "Email is not valid." }
                            })}
                            helperText={errors.email?.message}
                            error={errors.email !== undefined}
                            disabled={loading}
                        />
                        <TextField
                            color='labelBlack'
                            InputProps={{
                                style: {
                                    backgroundColor: "white",
                                    color: 'black'
                                }
                            }}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label="Password"
                            autoComplete="current-password"
                            type="password"
                            {...register("password", {
                                required: { value: true, message: "Password is required." },
                            })}
                            helperText={errors.password?.message}
                            error={errors.password !== undefined}
                            disabled={loading}
                        />
                        <div className={classes.wrapper}>
                            <Button type="submit"
                                fullWidth
                                variant="contained"
                                color="primaryTextWhite"
                                className={classes.submit}
                                disabled={loading}
                            >
                                <div>LOG IN</div>

                            </Button>
                            {signinErrorMessage.length > 0 && <div className={classes.errorSignInBox}>{signinErrorMessage}</div>}
                            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                    </form>
                </Grid>
            </Grid>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    paperContainer: {
        flexGrow: 1,
        height: '100vh',
        textAlign: 'center',
        backgroundImage: `url(${BackgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),

    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        color: 'white'
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -8,
        marginLeft: -12,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    errorMessage: {
        color: theme.palette.error.main
    },
    errorSignInBox: {
        marginTop: 20,
        color: theme.palette.error.main,
        borderRadius: 20
    },
    signInContainer: {
        background: 'white',
        padding: 40,
        borderRadius: 30
    },
    row: { display: 'flex', justifyContent: 'center', paddingTop: 50, marginBottom: 40 },
}))