import { SIZE_NAME } from "../constants/measurementSizeName"
import { stringHelper } from "./helper"

export default function sqmToRai(sqMeter) {

    let sqMeterTotal = sqMeter

    let raiResult = sqMeter / 1600
    let rai = Math.floor(raiResult)

    if (rai < 1) {
        raiResult = 0
    } else {
        raiResult = rai
        let sqMeterOfRai = rai * 1600
        sqMeterTotal = sqMeter - sqMeterOfRai
    }

    let nganResult = ((sqMeterTotal % 1600) / 400)
    let ngan = Math.floor(nganResult)

    if (ngan < 1) {
        nganResult = 0
    } else {
        nganResult = ngan
        let sqMeterOfNgan = ngan / sqMeterTotal
        sqMeterTotal = sqMeterTotal - sqMeterOfNgan
    }

    let sqWaResult = Math.round((fmod(sqMeterTotal, 400) / 4), 2)

    return [raiResult, nganResult, sqWaResult]
}

// export default function raiToSqMeter(rai, ngan, sqWa) {
//     //todo
// }

function fmod(a, b) {
    return Number((a - (Math.floor(a / b) * b)).toPrecision(8))
}

export function sqmToSqKiloMeter(sqMeter) {
    let result = sqMeter / 1000000
    return result
}

export function sqKiloMeterToSqMeter(sqKiloMeter) {
    let result = sqKiloMeter * 1000000
    return result
}
export function sqmToSqFoot(sqMeter) {
    let result = sqMeter * 10.76391
    return result
}
export function sqFootToSqMeter(sqFoot) {
    let result = sqFoot / 10.76391
    return result
}

export function sqmToSqYards(sqMeter) {
    let result = sqMeter * 1.19599
    return result
}

export function yardsToSqMeter(yards) {
    let result = yards / 1.19599
    return result
}

export function sqmToSqMiles(sqMeter) {
    let resultThem = sqMeter * 3.861022
    let result = resultThem * Math.pow(10, -7)
    return result
}

// export function milesToSqMeter(miles) {
//     //todo
// }

export function sqmToHectare(sqMeter) {
    let result = sqMeter * 0.0001
    return result
}

export function hectareToSqMeter(hectare) {
    let result = hectare / 0.0001
    return result
}

export function sqmToAcre(sqMeter) {
    let result = sqMeter * 0.000247
    return result
}

export function acreToSqMeter(acre) {
    let result = acre / 0.000247
    return result
}

export async function getDefaultMeasurementDisplay(measurementName = SIZE_NAME.sqMeter, sizeSqMeter) {

    let result = ""

    switch (measurementName) {
        case SIZE_NAME.sqMeter:
            result = `${stringHelper.numberWithCommas(sizeSqMeter.toFixed(3))} ตารางเมตร`
            break
        case SIZE_NAME.rai_ngan_sqWa:
            let [rai, ngan, sqWa] = sqmToRai(sizeSqMeter)
            result = `${rai} ไร่ ${ngan} งาน ${sqWa} ตารางวา`
            break
        case SIZE_NAME.sqKiloMeter:
            let sqKiloMeter = sqmToSqKiloMeter(sizeSqMeter)
            result = `${sqKiloMeter} ตารางกิโลเมตร`
            break
        case SIZE_NAME.sqFoot:
            let sqFoot = sqmToSqFoot(sizeSqMeter)
            result = `${stringHelper.numberWithCommas(sqFoot.toFixed(3))} ตารางฟุต`
            break
        case SIZE_NAME.sqYards:
            let sqYards = sqmToSqYards(sizeSqMeter)
            result = `${stringHelper.numberWithCommas(sqYards.toFixed(3))} ตารางหลา`
            break
        case SIZE_NAME.sqMiles:
            let sqMiles = sqmToSqMiles(sizeSqMeter)
            result = `${sqMiles} ตารางไมล์`
            break
        case SIZE_NAME.hectare:
            let hectare = sqmToHectare(sizeSqMeter)
            result = `${hectare} เฮกตาร์`
            break
        case SIZE_NAME.acre:
            let acre = sqmToAcre(sizeSqMeter)
            result = `${acre} เอเคอร์`
            break

        default:
            result = `${stringHelper.numberWithCommas(sizeSqMeter.toFixed(3))} ตารางเมตร`
            break
    }

    return result
}