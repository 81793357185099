import React, { useEffect, useState, useContext } from 'react'
import Grid from '@mui/material/Grid'
import { Button, Tooltip, InputAdornment, InputLabel, Select, MenuItem } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import OutlinedInput from '@mui/material/OutlinedInput'
import SearchIcon from '@mui/icons-material/Search'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from '../../constants/paths'
import FormControl from '@mui/material/FormControl'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { API_RES_STATUS } from '../../constants/apiResponseStatus'
import StyledDataGrid from '../../components/StyledDataGrid'
import getAllUser from '../../services/user'
import { AppContext } from '../../contexts/AppContext'
import { ROLES } from '../../constants/enums'
import useCategoryEnterprise from '../../reactHook/useCategoryEnterprise'

export default function UserPage() {
    const { employee = {} } = useContext(AppContext)
    const navigation = useNavigate()
    const [listUser, setListUser] = useState([])
    const [loading, setLoading] = useState(false)
    const [tableCurrentPage, setTableCurrentPage] = useState(0)
    const [tablePageSize, setTablePageSize] = useState(10)
    const [tableTotalRow, setTableTotalRow] = useState(0)
    const [searchKeyword, setSearchKeyword] = useState('')
    const [filterEnterpriseId, setFilterEnterpriseId] = useState('')
    const { enterprises } = useCategoryEnterprise()

    useEffect(() => {
        loadUser()
    }, [tablePageSize, tableCurrentPage, searchKeyword, filterEnterpriseId])

    const loadUser = async () => {
        setLoading(true)
        let enterpriseId = filterEnterpriseId
        if (employee.role !== ROLES.AdminLing) {
            enterpriseId = employee.enterpriseId
        }
        const axiosRes = await getAllUser({
            currentPage: tableCurrentPage + 1,
            pageSize: tablePageSize, filterEnterpriseId: enterpriseId, searchKeyword
        })
        const { data, status, message } = JSON.parse(axiosRes.data)
        if (status === API_RES_STATUS.success) {
            const { listData, totalRow } = data
            setListUser(listData)
            setTableTotalRow(totalRow)
        }
        setLoading(false)
    }

    const columns = [
        {
            field: 'name',
            headerName: 'ชื่อเกษตรกร',
            flex: 3
        },
        {
            field: 'uid',
            headerName: "หมายเลขเกษตรกร",
            flex: 3
        },
        {
            field: 'enterpriseName',
            headerName: "บริษัท",
            flex: 3
        },
        {
            field: 'action',
            headerName: "",
            renderCell: ({ row }) => {
                return <>
                    <IconButton
                        size="small"
                        onClick={() => navigation(ROUTE_PATH.EDIT_USER.replace(":id", row.id))}
                    >
                        <ModeEditOutlineOutlinedIcon />
                    </IconButton>
                    <Tooltip title="คัดลอก ID" placement='top'>
                        <IconButton
                            size="small"
                            onClick={() => {
                                const { id } = row
                                navigator.clipboard.writeText(id)
                            }}
                        >
                            <ContentCopyIcon />
                        </IconButton>
                    </Tooltip>
                </>
            },
            flex: 1

        },
    ]

    const onFilterSearchKeywordChange = (event) => {
        setSearchKeyword(event.target.value)
    }

    const onSearchClick = () => {
        loadUser()
    }

    return (
        <>
            <div style={{ margin: 40 }}>
                {employee.role === ROLES.AdminLing &&
                    <Grid container rowSpacing={1} columnSpacing={1}>
                        <Grid item sm={12} md={12}>
                            <FormControl style={{ width: '40%', marginBottom: 10, marginLeft: 10 }} size="small">
                                <InputLabel >เลือกบริษัท</InputLabel>
                                <Select
                                    label="เลือกบริษัท"
                                    value={filterEnterpriseId}
                                    onChange={(e) => { setFilterEnterpriseId(e.target.value) }}
                                >
                                    <MenuItem value={''}>ทั้งหมด</MenuItem>
                                    {enterprises.map((e) => {
                                        return <MenuItem key={`select-enterprise-${e.id}`} value={e.id}>{e.name}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                }
                <Grid container rowSpacing={1} columnSpacing={1}>
                    <Grid item sm={12} md={6}>
                        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <FormControl sx={{ m: 1, width: '100%' }} size="small">
                                <OutlinedInput
                                    placeholder='ชื่อเกษตรกร, หมายเลขเกษตรกร'
                                    value={searchKeyword}
                                    onChange={onFilterSearchKeywordChange}
                                    startAdornment={
                                        <InputAdornment position='start'>
                                            <SearchIcon onClick={onSearchClick} />
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>
                    </Grid>

                    <Grid item sm={12} md={6} >
                        {employee.role !== ROLES.AdminLing ? <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={() => navigation(ROUTE_PATH.CREATE_USER)}
                                style={{ color: 'white', borderRadius: 5, backgroundColor: '#12203A' }}
                                variant="contained">
                                <AddIcon />
                                สร้างเกษตรกร
                            </Button>
                        </div> : <></>}
                    </Grid>

                    <Grid item xs={12}>
                        <Box sx={{ height: '70vh', width: '100%' }}>
                            <StyledDataGrid
                                rows={listUser}
                                columns={columns}
                                paginationMode="server"
                                loading={loading}
                                rowCount={tableTotalRow}
                                page={tableCurrentPage}
                                pageSize={tablePageSize}
                                pagination
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                onPageChange={(newPage) => setTableCurrentPage(newPage)}
                                onPageSizeChange={(newPageSize) => setTablePageSize(newPageSize)}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </div>

        </>
    )
}
