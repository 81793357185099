import { Button, Card, Container, Grid, } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ROUTE_PATH } from '../../constants/paths'
import { useNavigate, useParams } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { styled } from '@mui/material/styles'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import { getAllEmployeeByTeamId, getTeamById, updateTeamAreaDetail } from '../../services/team'
import { Controller, useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import TextFieldValidate from '../../components/TextFieldValidate'
import { API_RES_STATUS } from '../../constants/apiResponseStatus'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#12203A",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#D3DCEC",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 1,
    },
}))

export default function DetailTeamPage() {
    let { id: teamId } = useParams()
    // console.log("teamId", teamId)
    const classes = useStyles()
    const navigation = useNavigate()
    const { control, handleSubmit, setValue, formState: { errors } } = useForm()
    const [isEddit, setIsEddit] = useState(true)
    const [empId, setEmpId] = useState()

    const [loading, setLoading] = useState(false)
    const [listEmployee, setListEmployee] = useState([])
    const { enqueueSnackbar, /*closeSnackbar*/ } = useSnackbar()

    useEffect(() => {
        loadTeamDetail()
        loadEmployee()
    }, [])

    const loadTeamDetail = async () => {
        setLoading(true)
        const res = await getTeamById(teamId)
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            let data = json.data
            setValue('name', data.name)
            setValue('teamAdmin', data.employeeFirstName + " " + data.employeeLastName)
            setEmpId(data.employeeId)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
        setLoading(false)
    }

    const loadEmployee = async () => {
        setLoading(true)
        const res = await getAllEmployeeByTeamId(teamId)
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            setListEmployee(json.data)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
        setLoading(false)
    }

    const onSubmitForm = async (data) => {
        await onEdit(data)
    }

    const onEdit = async (data) => {
        const res = await updateTeamAreaDetail({
            id: teamId, name: data.name, employeeId: empId
        })
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            enqueueSnackbar('Update name team successfully.', { variant: 'success' })
            navigation(ROUTE_PATH.DETAIL_TEAM_AREA.replace(":id", teamId))
            setIsEddit(true)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
    }

    const columns = [
        { label: "ลำดับ", minWidth: 50, align: 'left', },
        { label: "ชื่อ", minWidth: 120, align: 'center' },
        { label: "จำนวนแปลงที่ดูแล", minWidth: 120, align: 'center' },
        { label: "ผลการทำงาน", minWidth: 120, align: 'center' },
        { label: "", minWidth: 120, align: 'center' },
    ]

    return (
        <>
            <form onSubmit={handleSubmit(onSubmitForm)}>
                <div style={{ margin: 40 }}>
                    <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={6}>
                        </Grid>
                        <Grid item xs={6}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button onClick={() => navigation(-1)} style={{ color: '#12203A', borderColor: '#12203A', borderRadius: 5, marginRight: 20, width: '20%' }} variant="outlined">
                                    ยกเลิก
                                </Button>
                                {
                                    !isEddit &&
                                    <Button type='submit' style={{ color: 'white', borderRadius: 5, backgroundColor: '#12203A', width: '20%' }} variant="contained">
                                        บันทึก
                                    </Button>
                                }
                            </div>
                        </Grid>
                    </Grid>
                    <h4>รายละเอียดทีม</h4>
                    <Card style={{ marginTop: 20, borderRadius: 12 }}>
                        <Container style={{ marginTop: 25, marginBottom: 40, marginRight: 50, marginLeft: 50 }}>
                            <div style={{ justifyContent: 'flex-end', display: 'flex', marginRight: 40 }}>
                                <Button onClick={() => { setIsEddit(!isEddit) }} variant="outlined" color='inherit' startIcon={<ModeEditOutlineOutlinedIcon />}></Button>
                            </div>
                            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={6}>
                                    <label>ชื่อทีม</label>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 10, width: '70%' }}>
                                        <Controller style={{ marginTop: 0 }}
                                            control={control}
                                            rules={{
                                                required: { value: true, message: "name is required." },
                                                min: { value: 0, message: "name much be greater than or equal 0." }
                                            }}
                                            name="name"
                                            render={o => <TextFieldValidate vo={o} isDisabled={isEddit} type='text' errorMessage={errors.name?.message} />} />
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <label>ผู้ดูแลทีม</label>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 10, width: '70%' }}>
                                        <Controller style={{ marginTop: 0 }}
                                            control={control}
                                            rules={{
                                                required: { value: true, message: "name is required." },
                                                min: { value: 0, message: "name much be greater than or equal 0." }
                                            }}
                                            name="teamAdmin"
                                            render={o => <TextFieldValidate vo={o} isDisabled={true} type='text' errorMessage={errors.name?.message} />} />
                                    </div>
                                </Grid>
                            </Grid>
                        </Container>
                    </Card>
                    <h4>สมาชิกในทีม</h4>
                    <div style={{ marginTop: 20 }}>
                        <TableContainer className={classes.container}>
                            <Table stickyHeader aria-label="customized table" >
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column, i) => (
                                            <StyledTableCell
                                                key={i}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                            >
                                                {column.label}
                                            </StyledTableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        listEmployee.map((row, i) => {
                                            return (
                                                <StyledTableRow key={i}>
                                                    <StyledTableCell align='left' >{i + 1}</StyledTableCell>
                                                    <StyledTableCell align="center" >{row.firstName} {row.lastName}</StyledTableCell>
                                                    <StyledTableCell align="center" >{row.quantityPlot}</StyledTableCell>
                                                    <StyledTableCell align="center" >
                                                        {
                                                            row.performance
                                                                ?
                                                                <label style={{ color: 'green' }}>{"ผ่านค่ามาตรฐาน"}</label>
                                                                :
                                                                <label style={{ color: 'red' }}>{"ไม่ผ่านค่ามาตรฐาน"}</label>
                                                        }
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        <Button onClick={() => navigation(ROUTE_PATH.AREA_MANAGEMENT_STAFF_LIST.replace(":id", row.id))} variant="outlined" color='inherit' startIcon={<ModeEditOutlineOutlinedIcon />}></Button>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                            {/* {rows.length === 0 ? <div style={{ display: 'flex', justifyContent: 'center' }}><Empty /></div> : null} */}
                        </TableContainer>
                    </div>
                </div>
            </form>
        </>
    )
}
const useStyles = makeStyles((theme) => ({

}))