// string helper
import { useLoadScript } from "@react-google-maps/api"
import { LOCAL_STORAGE_KEY } from "../constants/localStorageKey"

function isString(str) {
    return typeof str === 'string' || str instanceof String
}
function numberWithCommas(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
const stringHelper = {
    isString,
    numberWithCommas
}
// end string helper

// auth helper
function isAuthenticated() {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN)
    if (stringHelper.isString(accessToken)) {
        return accessToken.length > 0
    }
    return false
}

function clearAccessToken() {
    localStorage.setItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN, "")
}
const authHelper = {
    isAuthenticated,
    clearAccessToken
}
// end auth helper

export {
    stringHelper,
    authHelper
}