import { ApiUrl } from "../constants/api_url"
import AxiosHelper from "../helper/axiosHelper"

export default async function getAllEnterprise() {
    return await AxiosHelper.get(ApiUrl.ENTERPRISE)
}

export async function getAllEnterpriseByRole({ pageSize = 10, currentPage = 1, searchKeyword = '' }) {
    let params = {
        pageSize, currentPage
    }
    if (searchKeyword.length > 0) {
        params.searchKeyword = searchKeyword
    }
    let url = `${ApiUrl.ENTERPRISE + "/GetAllByRole"}`
    if (Object.keys(params).length > 0) {
        params = new URLSearchParams(params)
        url = `${url}?${params.toString()}`
    }
    return await AxiosHelper.get(url)
}


export async function getEnterpriseById(enterpriseId) {
    return await AxiosHelper.get(ApiUrl.ENTERPRISE + "/" + enterpriseId)
}

export async function createEnterprise({ name, taxId, phoneNumber, email, address, isActive }) {
    return await AxiosHelper.post(ApiUrl.ENTERPRISE, { name, taxId, phoneNumber, email, address, isActive: Boolean(isActive) })
}

export async function updateEnterprise({ id, name, taxId, phoneNumber, email, address, isActive }) {
    return await AxiosHelper.put(ApiUrl.ENTERPRISE + "/" + id, { id, name, taxId, phoneNumber, email, address, isActive: Boolean(isActive) })
}

export async function deleteEnterprise(id) {
    return await AxiosHelper.delete(ApiUrl.ENTERPRISE + "/" + id)
}

export async function updateDefaultMeasurementByEnterpriseId(measurementId, enterpriseId) {
    return await AxiosHelper.put(ApiUrl.ENTERPRISE + "/updateDefaultMeasurement/" + enterpriseId, { measurementId })
}