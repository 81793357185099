import React from 'react'
import TextField from '@mui/material/TextField'

export default function TextFieldValidate(props) {

  const { vo = {}, errorMessage, type = 'text', name = " ", row, multiline = false, isDisabled = false, maxLength = "200", display = 'block' } = props
  const { field } = vo
  const { value, onChange, isWorking, ref } = field

  return (
    <TextField style={{ marginTop: 0, display: display }}
      value={value || ""}
      onChange={onChange}
      disabled={isDisabled}
      inputRef={ref}
      type={type}
      inputProps={{
        maxLength: maxLength,
      }}
      multiline={multiline}
      rows={row}
      helperText={errorMessage}
      error={errorMessage !== undefined && errorMessage !== ''}
      fullWidth size="small" margin="normal" variant="outlined" />
  )
}
