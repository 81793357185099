import { ApiUrl } from "../constants/api_url"
import AxiosHelper from "../helper/axiosHelper"

export default async function getAllPlantTypeByPlantId(id) {
    return await AxiosHelper.get(ApiUrl.PLANT + "/GetAllPlantType" + "/" + id)
}

export async function getAllPlantType() {
    return await AxiosHelper.get(ApiUrl.PLANT + "/GetAllPlantType")
}

export async function createPlantType({ plantId, plantTypeName }) {
    return await AxiosHelper.post(ApiUrl.PLANT + "/createPlantType", { plantId, plantTypeName })
}

export async function updatePlantType({ id, name }) {
    return await AxiosHelper.put(ApiUrl.PLANT + "/UpdateTypePlant" + "/" + id, { name })
}

export async function deletePlantType(id) {
    return await AxiosHelper.delete(ApiUrl.PLANT + "/DeletePlantType/" + id)
}