import { useEffect } from "react"
import { createRoot } from 'react-dom/client';

/**
   * *** Warnning *** ใช้สำหรับเพิ่ม component ลงไปใน Map @react-google-maps/api
   * อาจพบปัญหาเรื่อง callback ของ component set state ทำงานไม่ถูกต้องเนื่องด้วยข้อจำกัด ให้ใช้วิธี triger click ปุ่มข้างนอก Map แทน note at react version 18.2.0
   */
export const useCustomMapControl = (
    map,
    controlPosition,
    component
) => {
    useEffect(() => {
        if (map && controlPosition) {
            const containerControl = document.createElement('div')
            containerControl.style.padding = '10px'
            const root = createRoot(containerControl)
            root.render(component)
            map.controls[controlPosition].push(containerControl);
        }
        return () => {
            if (map && controlPosition) {
                map.controls[controlPosition].clear();
            }
        }
    }, [map, controlPosition])
}