import { Card, CardContent, Grid } from '@mui/material'
import React from 'react'
import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from '../constants/paths'

export default function PerfomanceCard() {
  const classes = useStyles()
  const navigation = useNavigate()
  return (
    <Card style={{ borderRadius: 15, marginTop: 20 }} >
      <CardContent style={{ margin: 10, }}>
        <Grid container  >
          <Grid item xs={4}>
            <label>หมายเลขพื้นที่ </label> <label> 59485058544</label>
          </Grid>
          <Grid item xs={3}>
            <label>ปริมาณผลผลิต</label>
          </Grid>
          <Grid item xs={3}>
            <label>ช่วงเวลาเก็บเกี่ยว</label>
          </Grid>
          <Grid item xs={2}>
          </Grid>
        </Grid>
        <Grid mt={2} container  >
          <Grid item xs={4}>
            <label>ชื่อแปลง สวนของนายชาติชาย</label>
          </Grid>
          <Grid item xs={3}>
            <label className={classes.fontStyleColor} style={{ fontSize: 22 }}>10 ตัน</label>
          </Grid>
          <Grid item xs={3}>
            <label style={{ marginRight: 16 }}>เริ่มต้น</label>  <label className={classes.fontStyleColor}>01/04/2565</label>
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
            <label onClick={() => navigation(ROUTE_PATH.DETAIL_PERFOMANCE)} className={classes.fontStyleColor} style={{ fontWeight: 'bold' }}>ดูรายละเอียด</label>
          </Grid>
        </Grid>
        <Grid mt={1} container  >
          <Grid item xs={4}>
            <label>พื้นที่ : เมืองชัยภูมิ</label>
          </Grid>
          <Grid item xs={3}>
          </Grid>
          <Grid item xs={3}>
            <label style={{ marginRight: 16 }}>สิ้นสุด</label>  <label className={classes.fontStyleColor}>30/06/2565</label>
          </Grid>
          <Grid item xs={2}>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  fontStyleColor: {
    fontSize: 16,
    color: '#F08E26'
  },
}))
