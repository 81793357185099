import { ApiUrl } from "../constants/api_url"
import AxiosHelper from "../helper/axiosHelper"

export default async function getAllPlant({ pageSize = 10, currentPage = 1, filterEnterpriseId = '', searchKeyword = '', staffId = '' }) {
    let params = {
        pageSize, currentPage, staffId
    }
    if (filterEnterpriseId.length > 0) {
        params.filterEnterpriseId = filterEnterpriseId
    }
    if (searchKeyword.length > 0) {
        params.searchKeyword = searchKeyword
    }
    let url = `${ApiUrl.PLANT}`
    if (Object.keys(params).length > 0) {
        params = new URLSearchParams(params)
        url = `${url}?${params.toString()}`
    }
    return await AxiosHelper.get(url)
}

export async function getPlantById(id) {
    return await AxiosHelper.get(ApiUrl.PLANT + "/" + id)
}

export async function createPlant({ namePlant, enterpriseId }) {
    return await AxiosHelper.post(ApiUrl.PLANT + "/createPlant", { namePlant, enterpriseId })
}

export async function updatePlant({ id, name, enterpriseId }) {
    return await AxiosHelper.put(ApiUrl.PLANT + "/" + id, { id, name, enterpriseId })
}

export async function deletePlant(id) {
    return await AxiosHelper.delete(ApiUrl.PLANT + "/" + id)
}