import { GlobalStyles } from "@mui/material"
import { styled } from "@mui/styles"
import { DataGrid } from "@mui/x-data-grid"

export default function StyledDataGrid(props) {
    return <>
        <DataGrid
            {...props}
            sx={[
                {
                    ".MuiDataGrid-row:nth-of-type(odd)": { backgroundColor: "#D3DCEC" },
                    ".MuiDataGrid-columnHeaders": { backgroundColor: "#12203A", color: "white" },
                },
                (theme) => ({
                    ".MuiDataGrid-row:hover": { backgroundColor: "#aabbda" }
                })
            ]}
        /></>
}