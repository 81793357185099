import { useEffect, useState } from "react";
import { API_RES_STATUS } from "../constants/apiResponseStatus";
import { getCategoryTeam } from "../services/common";

export default function useCategoryTeam() {
    const [teams, setTeams] = useState([])
    const [filterEnterpriseId, setFilterTeamByEnterpriseId] = useState('')
    const [filterAreaId, setFilterTeamByAreaId] = useState('')

    useEffect(() => {
        const loadTeam = async () => {
            const axiosRes = await getCategoryTeam({ filterEnterpriseId: filterEnterpriseId, filterAreaId: filterAreaId })
            const json = JSON.parse(axiosRes.data)
            const { data, status, message } = json
            if (status === API_RES_STATUS.success) {
                setTeams(data)
            }
        }
        loadTeam()
    }, [filterEnterpriseId, filterAreaId])

    return { teams, setFilterTeamByEnterpriseId, setFilterTeamByAreaId }
}