import React from 'react'
import { FormControl, IconButton, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import ErrorIcon from '@mui/icons-material/Error'

export default function SheduleValue(props) {

    const { scheduleDateTimeId, isEdit, onSetValue, schedule, selectDate, index, onDelete } = props
    var items = []
    for (var i = 1; i < 31; i++) {
        items.push(
            <MenuItem key={i} value={i}>{i} วัน</MenuItem>
        )
    }

    return (
        <div style={{
            flexDirection: 'row', display: 'flex',
            alignItems: 'center', marginBottom: 20, width: '100%', marginLeft: 40,
        }}>
            <TextField
                size='small'
                style={{ width: '20%', flex: 4, marginRight: 40 }}
                id="filled-disabled"
                variant="outlined"
                value={schedule}
                onChange={(e) => {
                    // setValue(e.target.value)
                    onSetValue(index, e.target.value, selectDate)
                }}
            />
            <FormControl style={{ backgroundColor: 'white', width: '25%', flex: 4 }} >
                <Select
                    size='small'
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectDate}
                    onChange={(e) => {
                        // setSelect(e.target.value)
                        onSetValue(index, schedule, e.target.value)
                    }}
                >
                    <MenuItem disabled value={0}>
                        <em>เลือกช่วงเวลา</em>
                    </MenuItem>
                    {items}
                </Select>
            </FormControl>
            {
                isEdit ?
                    <IconButton aria-label="delete" style={{ marginLeft: 10, }} onClick={() => { onDelete(index, scheduleDateTimeId) }} >
                        <DeleteIcon color='error' />
                    </IconButton>
                    : <IconButton aria-label="delete" style={{ marginLeft: 10, }} onClick={() => { onDelete(index) }} >
                        <DeleteIcon color='error' />
                    </IconButton>
            }
            <Typography component="h6" variant="h6" color={'#F08E26'} style={{ flex: 5, display: 'flex', alignItems: 'center' }} >
                <Tooltip title="ระบบคำนวนจากวันที่เริ่มทำการเพาะปลูก" arrow>
                    <ErrorIcon color='primary' style={{ marginRight: 10 }} />
                </Tooltip>
            </Typography>
        </div>
    )
}
