
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { createNewSecretKey } from '../../services/exportData'
import { useState } from 'react'
import Alert from '@mui/material/Alert';
import { Grid } from '@mui/material'
import { API_RES_STATUS } from '../../constants/apiResponseStatus'
export default function DialogGenSecretKey() {
    const [open, setOpen] = useState(false)
    const [newSecretKey, setNewSecertKey] = useState("")
    const [showSuccessMsg, setShowSuccessMsg] = useState(false)
    const [showErrorMsg, setShowErrorMsg] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState(null)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        reset()
    }

    const reset = () => {
        setNewSecertKey("")
        setShowSuccessMsg(false)
        setShowErrorMsg(false)
        setIsLoading(false)
    }

    const onCopyClick = () => {
        navigator.clipboard.writeText(newSecretKey)
    }

    const onCreateNewSecretKey = async () => {
        setIsLoading(true)
        setShowSuccessMsg(false)
        setShowErrorMsg(false)
        try {
            const res = await createNewSecretKey()
            let json = JSON.parse(res.data)
            const { data, status, message } = json
            if (status === API_RES_STATUS.success) {
                setNewSecertKey(data)
                setShowSuccessMsg(true)
            } else {
                setShowErrorMsg(true)
                setErrorMsg(message)
            }
        } catch (e) {
            setShowErrorMsg(true)
        }
        setIsLoading(false)

    }

    return (
        <>
            <Button fullWidth variant="outlined" onClick={handleClickOpen}>
                สร้าง Secret Key ใหม่
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault()
                        const formData = new FormData(event.currentTarget)
                        const formJson = Object.fromEntries(formData.entries())
                        const email = formJson.email
                        console.log(email)
                        handleClose()
                    },
                }}
            >
                <DialogTitle>สร้าง Secret Key ใหม่</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Alert severity="info"> โปรดระวังเมื่อสร้าง Secret Key อันใหม่ Secret Key อันก่อนหน้าจะถูกยกเลิก</Alert>
                    </DialogContentText>
                    <Grid style={{ marginTop: 20 }} container spacing={2}>
                        {!showSuccessMsg && <Grid item xs={12}>
                            <Button disabled={isLoading} variant="outlined" onClick={onCreateNewSecretKey}>สร้าง Secret Key</Button>
                        </Grid>}

                        {showSuccessMsg && <Grid item xs={12}>
                            <Alert severity="success">กรุณาเก็บ Secert Key นี้ไว้ คุณไม่สามารถเรียกคืน Secert Key นี้ใหม่ได้เมื่อมันหาย</Alert>
                        </Grid>}
                        {showErrorMsg && <Grid item xs={12}>
                            <Alert severity="error">{errorMsg || 'มีบางอย่างไม่ถูกต้อง'}</Alert>
                        </Grid>}
                        {showSuccessMsg && <Grid item xs={12}>
                            <Grid container item spacing={2}>
                                <Grid item xs={2}>
                                    <Button variant='outlined' onClick={onCopyClick}>Copy</Button>
                                </Grid>
                                <Grid item xs={10}>
                                    <TextField
                                        fullWidth
                                        value={newSecretKey}
                                        variant="standard"
                                    />
                                </Grid>

                            </Grid>

                        </Grid>}
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>ปิด</Button>
                </DialogActions>
            </Dialog >
        </>
    )
}
