import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import { Box, Button, Tooltip } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import OutlinedInput from '@mui/material/OutlinedInput'
import SearchIcon from '@mui/icons-material/Search'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { styled } from '@mui/material/styles'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from '../../constants/paths'
import getAllEnterprise, { deleteEnterprise, getAllEnterpriseByRole } from '../../services/enterprise'
import dayjs from 'dayjs'
import Skeleton from '@mui/material/Skeleton'
import Empty from '../../components/Empty'
import AlertDeleteModal from '../../components/AlertDeleteModal'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import { API_RES_STATUS } from '../../constants/apiResponseStatus'
import { useSnackbar } from 'notistack'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import StyledDataGrid from '../../components/StyledDataGrid'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#12203A",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#D3DCEC",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 1,
    },
}))

export default function EnterprisePage() {
    const { enqueueSnackbar, /*closeSnackbar*/ } = useSnackbar()
    const classes = useStyles()
    const navigation = useNavigate()
    const [listEnterprise, setListEnterprise] = useState([])
    const [loading, setLoading] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [idDelete, setIdDelete] = useState(null)
    const [search, setSearch] = useState(null)
    const [tablePageSize, setTablePageSize] = useState(10)
    const [tableCurrentPage, setTableCurrentPage] = useState(0)
    const [tableTotalRow, setTableTotalRow] = useState(0)
    const [searchKeyword, setSearchKeyword] = useState('')

    useEffect(() => {
        loadEnterprise()
    }, [tablePageSize, tableCurrentPage])

    useEffect(() => {

        if (search != null && search != "") {
            let textSearch = setTimeout(async () => {
                try {
                    await loadListEnterpriseAfterSearch(search)
                } catch (error) {
                    enqueueSnackbar(error, { variant: 'error' })
                }
            }, 1000)

            return () => clearTimeout(textSearch)

        }
        else {
            let textSearch = setTimeout(async () => {
                try {
                    await loadEnterprise()
                } catch (error) {
                    enqueueSnackbar(error, { variant: 'error' })
                }
            }, 1000)

            return () => clearTimeout(textSearch)
        }

    }, [search])

    const loadEnterprise = async () => {
        setLoading(true)
        const axiosRes = await getAllEnterpriseByRole({ pageSize: tablePageSize, currentPage: tableCurrentPage + 1, searchKeyword })
        const { data, status, message = "" } = JSON.parse(axiosRes.data)
        if (status === API_RES_STATUS.success) {
            const { listData, totalRow = 0 } = data
            setListEnterprise(listData)
            setTableTotalRow(totalRow)
        } else {
            enqueueSnackbar(message, { variant: 'error', autoHideDuration: 10000 })
        }
        setLoading(false)
    }

    const OnClickSubmitModal = async (id) => {
        const res = await deleteEnterprise(id)
        let json = JSON.parse(res.data)
        if (json.status === API_RES_STATUS.success) {
            enqueueSnackbar('Delete enterprise successfully.', { variant: 'success' })
            navigation(-1)
        } else {
            enqueueSnackbar(json.message, { variant: 'error' })
        }
        setIdDelete(null)
        loadEnterprise()
        setOpenModal(false)
    }

    const OnClickInModal = () => {
        setOpenModal(false)
        setIdDelete(null)
    }

    const OnClickDelete = (id) => {
        setIdDelete(id)
        setOpenModal(true)
    }

    const loadListEnterpriseAfterSearch = async (search) => {
        let text = search.toLowerCase()
        let listEnterpriseFilter = listEnterprise.filter((Item) => {
            if (Item.name.toLowerCase().match(text))
                return Item
        })
        setListEnterprise(listEnterpriseFilter)
    }

    const columns = [
        { headerName: "ชื่อ", field: "name", flex: 1 },
        {
            headerName: "วันที่สร้าง", field: "createOnUtc",
            renderCell: ({ row }) => {
                return dayjs(row.createdOnUtc, "YYYY-MM-DD+h:mm").format('DD/MM/YYYY')
            },
            flex: 1
        },
        {
            headerName: "สถานะ", field: "isActive",
            renderCell: ({ row }) => {
                return <>
                    {row.isActive
                        ? <StyledTableCell align="center" style={{ color: 'green' }} >Active</StyledTableCell>
                        : <StyledTableCell align="center" style={{ color: 'red' }} >Inactive</StyledTableCell>
                    }
                </>
            },
            flex: 1
        },
        {
            field: 'action-view',
            headerName: "รายละเอียด",
            renderCell: ({ row }) => {
                return <>
                    <IconButton
                        size="small"
                        onClick={() => navigation(ROUTE_PATH.EDIT_ENTERPRISE.replace(":id", row.id))}
                    >
                        <ModeEditOutlineOutlinedIcon />
                    </IconButton>
                    <Tooltip title="คัดลอก ID" placement='top'>
                        <IconButton
                            size="small"
                            onClick={() => {
                                const { id } = row
                                navigator.clipboard.writeText(id)
                            }}
                        >
                            <ContentCopyIcon />
                        </IconButton>
                    </Tooltip>
                </>
            },
            flex: 1
        },
    ]

    const loadingPanel = <div style={{ marginRight: 10, marginLeft: 10 }}>
        <Grid style={{ marginTop: 30 }}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Skeleton variant="rect" height={50} width={"100%"} />
        </Grid>
        <Grid style={{ marginTop: 10 }}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Skeleton variant="rect" height={500} width={"100%"} />
        </Grid>
    </div>

    return (
        <>
            <div style={{ margin: 40 }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6}>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: 20 }}>
                            <OutlinedInput size="small" style={{ width: '50%' }}
                                placeholder='ชื่อบริษัท'
                                startAdornment={<SearchIcon />}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={() => navigation(ROUTE_PATH.CREATE_ENTERPRISE)} style={{ width: '20%', color: 'white', borderRadius: 10, backgroundColor: '#12203A' }} variant="contained">
                                <AddIcon />
                                สร้าง
                            </Button>
                        </div>
                    </Grid>
                </Grid>
                {
                    loading
                        ? loadingPanel
                        : <div style={{ marginTop: 20 }}>
                            <Grid item sm={12}>
                                <Box sx={{ height: '70vh', width: '100%' }}>
                                    <StyledDataGrid
                                        rows={listEnterprise}
                                        columns={columns}
                                        paginationMode="server"
                                        rowCount={tableTotalRow}
                                        page={tableCurrentPage}
                                        pageSize={tablePageSize}
                                        loading={loading}
                                        pagination
                                        rowsPerPageOptions={[10, 25, 50, 100]}
                                        onPageChange={(newPage) => setTableCurrentPage(newPage)}
                                        onPageSizeChange={(newPageSize) => setTablePageSize(newPageSize)}
                                    />
                                </Box>
                            </Grid>
                        </div >
                }
            </div >
            <AlertDeleteModal id={idDelete} open={openModal} OnClickInModal={OnClickInModal} OnClickSubmitModal={OnClickSubmitModal} />
        </>
    )
}

const useStyles = makeStyles((theme) => ({

}))