export const ROUTE_PATH = {
    HOME: "/",
    LOGIN: "/login",
    STAFF: "/staff",
    CREATE_STAFF: "/staff/create/",
    EDIT_STAFF: "/staff/create/:id",
    TEAM: "/team",
    CREATE_TEAM: "/team/create/",
    EDIT_TEAM: "/team/create/:id",
    PERFOMANCE: "/performance",
    LIST_PERFOMANCE: "/performance/list",
    DETAIL_PERFOMANCE: "/performance/detail",
    REPORT: "/report",
    ENTERPRISE: "/enterprise",
    CREATE_ENTERPRISE: "/enterprise/create/",
    EDIT_ENTERPRISE: "/enterprise/create/:id",
    PLOT: "/plot",
    CREATE_PLOT: "/plot/create",
    AREA_MANAGEMENT: '/areamanagement',
    AREA_MANAGEMENT_DETAIL: '/areamanagement/detail/:id',
    CREATE_AREA: '/areamanagement/create/',
    EDIT_AREA: '/areamanagement/create/:id',
    DETAIL_TEAM_AREA: '/areamanagement/detailteam/:id',
    AREA_MANAGEMENT_STAFF_LIST: '/areamanagement/staff/:id',
    CREATE_AREA_MAP: '/areamanagement/createareamap/:id',
    ROLE_MANAGEMENT: '/rolemanagement',
    SCHEDULE_MANAGEMENT: '/schedulemanagement',
    CREATE_SCHEDULE: '/schedulemanagement/create/',
    EDIT_SCHEDULE: '/schedulemanagement/create/:id',
    STANDARD: '/standart',
    STANDARD_CREATE: '/standart/create/',
    STANDARD_EDIT: '/standart/create/:id',
    PLANTS: '/plants',
    CREATE_PLANTS: '/plants/create/',
    EDIT_PLANTS: '/plants/create/:id',
    PRIVACY_CENTER: 'privacy-center',
    USER: "/user",
    CREATE_USER: "/user/create/",
    EDIT_USER: "/user/create/:id",
    SIZE: "/measuement",
    API_SETTINGS: "/api-settings"

}
