import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import { Button, } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { ROUTE_PATH } from '../../constants/paths'
import { useLoadScript } from "@react-google-maps/api"
import DrawingMap from './DrawingMap'
import { googleMapAPIKey, googleMapLibraries } from '../../constants/common'
import { getDefaultMeasurementByEmployeeId } from '../../services/measurement'
import { API_RES_STATUS } from '../../constants/apiResponseStatus'
import { useSnackbar } from 'notistack'
import * as htmlToImage from 'html-to-image'
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image'
export default function CreateAreaMapPage() {

    let { id: staffId } = useParams()
    const navigation = useNavigate()
    const { enqueueSnackbar, /*closeSnackbar*/ } = useSnackbar()
    const [polygonResult, setPolygonResult] = useState(null)
    const [address, setAddress] = useState(null)
    const [defualtMeasurementName, setDefualtMeasurementName] = useState(null)
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: googleMapAPIKey,
        libraries: googleMapLibraries,
    })

    useEffect(() => {
        getDefualtMeasurementByEnterprise()
    }, [])

    const getDefualtMeasurementByEnterprise = async () => {
        const axiosRes = await getDefaultMeasurementByEmployeeId(staffId)
        let json = JSON.parse(axiosRes.data)
        const { data, status, message } = json
        if (status === API_RES_STATUS.success) {
            setDefualtMeasurementName(data.measurementName)
        } else {
            enqueueSnackbar(message, { variant: 'error' })
        }
    }

    if (loadError) {
        return "Error loading maps"
    }
    if (!isLoaded) {
        return "loading Maps"
    }

    const onCompleDrawMap = (result, address) => {
        setPolygonResult(result)
        setAddress(address)
    }

    const onDeletePolygon = () => {
        setPolygonResult(null)
    }

    const onCaptureMap = () => {
        return new Promise((resolve, reject) => {
            var node = document.getElementById('my-node')
            htmlToImage.toPng(node)
                .then(function (dataUrl) {
                    const img = new Image()
                    img.src = dataUrl
                    resolve(img.src)
                })
                .catch(function (error) {
                    console.error('oops, something went wrong!', error)
                    reject(error)
                })
        })

    }


    return (
        <div style={{ margin: 40 }}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                </Grid>
                <Grid item xs={6}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={() => navigation(-1)} color="secondary" variant="outlined">
                            ยกเลิก
                        </Button>
                        <Button onClick={async () => {
                            const img = await onCaptureMap()
                            console.log('img', img)
                            navigation(ROUTE_PATH.CREATE_PLOT, { state: { mapImage: img, staffId: staffId, listCoordinatesForCreate: polygonResult.listCoordinates, areaSizeForCreate: polygonResult.areaSize, plotId: null, addressPlot: address } })
                        }} color="secondary" variant="contained" style={{ marginLeft: 20 }} disabled={polygonResult === null}>
                            บันทึก
                        </Button>
                    </div>
                </Grid>
            </Grid>
            <div style={{ marginTop: 20 }}>
                <DrawingMap onPolygonComplete={onCompleDrawMap} onDeletePolygon={onDeletePolygon} measurementName={defualtMeasurementName} />
            </div>
        </div>
    )
}
