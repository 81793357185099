import { createContext, useEffect, useState } from "react"
import { API_RES_STATUS } from "../constants/apiResponseStatus"
import { LOCAL_STORAGE_KEY } from "../constants/localStorageKey"
import { getEmployeeById } from "../services/employee"

export const AppContext = createContext({})

export default function AppContextProvider({ children }) {
    const [employee, setEmployee] = useState({})

    const loadEmployee = async () => {
        const jwtAccessToken = localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN)
        if (jwtAccessToken.length > 0) {
            const { nameid } = parseJwt(jwtAccessToken)
            const axiosRes = await getEmployeeById(nameid)
            let { data, status } = JSON.parse(axiosRes.data)
            if (status === API_RES_STATUS.success) {
                setEmployee(data)
            }
        }
    }

    useEffect(() => {
        loadEmployee()
    }, [])
    const dataContext = {
        employee,
        loadEmployee
    }

    return <AppContext.Provider value={dataContext}>
        {children}
    </AppContext.Provider>
}
// move to helper
function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};