import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import OutlinedInput from '@mui/material/OutlinedInput'
import SearchIcon from '@mui/icons-material/Search'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { styled } from '@mui/material/styles'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from '../../constants/paths'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#12203A",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#D3DCEC",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 1,
    },
}))

export default function PerfomancePage() {
    const classes = useStyles()
    const navigation = useNavigate()
    const columns = [
        { label: "ลำดับ", minWidth: 50, align: 'left', },
        { label: "ชื่อ", minWidth: 120, align: 'center' },
        { label: "ขอบเขตการดูแล", minWidth: 120, align: 'center' },
        { label: "", minWidth: 120, align: 'center' },
    ]

    return (
        <>
            <div style={{ margin: 40 }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid xs={6}>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: 20 }}>
                            <OutlinedInput size="small" style={{ width: '40%' }}
                                startAdornment={<SearchIcon />}
                            />
                        </div>
                    </Grid>
                    <Grid xs={6}>
                        {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button style={{ width: '20%', color: 'white', borderRadius: 10 }} variant="contained">
                                <AddIcon />
                                สร้าง
                            </Button>
                        </div> */}
                    </Grid>
                </Grid>

                <div style={{ marginTop: 30 }}>
                    <TableContainer className={classes.container}>
                        <Table stickyHeader aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <StyledTableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow>
                                    <StyledTableCell >{"01"}</StyledTableCell>
                                    <StyledTableCell align="center" >{"อลงกต"}</StyledTableCell>
                                    <StyledTableCell align="center" >{"เมืองชัยภูมิ"}</StyledTableCell>
                                    <StyledTableCell align="center">
                                        <Button onClick={() => navigation(ROUTE_PATH.LIST_PERFOMANCE)} variant="outlined" color='inherit' startIcon={<ModeEditOutlineOutlinedIcon />}></Button>
                                    </StyledTableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                        {/* {rows.length === 0 ? <div style={{ display: 'flex', justifyContent: 'center' }}><Empty /></div> : null} */}
                    </TableContainer>
                </div>
            </div>
        </>
    )
}

const useStyles = makeStyles((theme) => ({

}))