import React, { useContext, useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import { useNavigate } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { ROUTE_PATH } from '../../constants/paths'
import Grid from '@mui/material/Grid'
import MenuItems from './MenuItem'
import { AppContext } from '../../contexts/AppContext'
import { authHelper } from '../../helper/helper'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { useSnackbar } from 'notistack'
import { List, ListItem, ListItemButton, ListItemText } from '@mui/material'
const useStyles = makeStyles({
    backgroundColorApp: {
        backgroundColor: '#12203A'
    },
    marginTopBar: {
        marginLeft: 20,
        marginRight: 20,
    }
})

export default function LayoutPage({ hideTitle, children }) {
    const { employee = {} } = useContext(AppContext)
    const className = useStyles()
    const [anchorElNav, setAnchorElNav] = useState(null)
    const [anchorElUser, setAnchorElUser] = useState(null)
    const { enqueueSnackbar, /*closeSnackbar*/ } = useSnackbar()
    const navigate = useNavigate()

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget)
    }
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null)
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }
    return (
        <div >
            <AppBar position="static" style={{ backgroundColor: '#12203A' }}>
                <div className={className.marginTopBar}>
                    <Toolbar disableGutters style={{ backgroundColor: '#12203A' }}>
                        <Avatar style={{ width: 50, height: 50 }} sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
                            alt="Remy Sharp"
                            src={require("../../assets/images/Logo.png")} />
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={() => {
                                    handleCloseNavMenu()
                                }}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                <MenuItem key={'Home'}
                                    onClick={() => {
                                        handleCloseNavMenu()
                                        navigate(ROUTE_PATH.HOME)
                                    }}>
                                    <Typography textAlign="center">{'Home'}</Typography>
                                </MenuItem>
                                <MenuItem key={'Enterprise'}
                                    onClick={() => {
                                        handleCloseNavMenu()
                                        navigate(ROUTE_PATH.ENTERPRISE)
                                    }}>
                                    <Typography textAlign="center">{'Enterprise'}</Typography>
                                </MenuItem>
                                <MenuItem key={'Staff'}
                                    onClick={() => {
                                        handleCloseNavMenu()
                                        navigate(ROUTE_PATH.STAFF)
                                    }}>
                                    <Typography textAlign="center">{'Staff'}</Typography>
                                </MenuItem>
                                <MenuItem key={'Perfomance'}
                                    onClick={() => {
                                        handleCloseNavMenu()
                                        navigate(ROUTE_PATH.PERFOMANCE)
                                    }}>
                                    <Typography textAlign="center">{'Perfomance'}</Typography>
                                </MenuItem>
                                <MenuItem key={'Report'}
                                    onClick={() => {
                                        handleCloseNavMenu()
                                        navigate(ROUTE_PATH.REPORT)
                                    }}>
                                    <Typography textAlign="center">{'Report'}</Typography>
                                </MenuItem>
                            </Menu>
                        </Box>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            <div>
                                <label style={{ color: '#f29c13', fontSize: 22, fontWeight: 'bold' }}>Ling Enterprise
                                    <span style={{ color: '#fff', fontSize: 18 }}> : {employee.enterpriseName}</span>
                                    <ContentCopyIcon style={{ marginLeft: 10, marginRight: 10, fontSize: 18 }} onClick={() => {
                                        enqueueSnackbar('Enterprise ID copied.', { variant: 'success' })
                                        navigator.clipboard.writeText(employee.enterpriseId)
                                    }} color='info' />
                                </label>
                            </div>
                        </Box>
                        <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title="Open settings">
                                <Button
                                    key={'Admin'}
                                    onClick={handleOpenUserMenu}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                >
                                    {employee.enterprisePositionName}
                                </Button>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                <MenuItem key={'Logout'} onClick={() => {
                                    handleCloseUserMenu()
                                    authHelper.clearAccessToken()
                                    navigate('/login', { replace: true })
                                }}>
                                    <Typography textAlign="center">{"ออกจากระบบ"}</Typography>
                                </MenuItem>
                            </Menu>
                        </Box>

                    </Toolbar>
                </div>
            </AppBar>
            <Grid container  >
                <Grid item xs={2} style={{ backgroundColor: '#12203A' }} >
                    <MenuItems />
                </Grid>
                <Grid item xs={10}>
                    {children}
                </Grid>
                <Grid item xs={12}>
                    <div style={{
                        display:'flex', backgroundColor: '#12203A', color:'white', padding:20, justifyContent:'center', fontSize:14, gap:20, alignItems:'center'}}
                    >
                    <div>Copyright © 2023 L I N G CO., LTD. All rights reserved.</div>
                    <Button sx={{fontSize:14, color:'white'}} 
                        onClick={()=>{
                            navigate(`/${ROUTE_PATH.PRIVACY_CENTER}`)
                        }}
                    >Privacy Center</Button>
                    </div>
                    
                </Grid>
            </Grid>
        </div >
    )
}