import { useEffect, useState } from "react"
import { API_RES_STATUS } from "../constants/apiResponseStatus"
import { getCategoryEnterprisePosition } from "../services/common"

/**
 * ฟังชันสำหรับ Get ค่าลิสรายการบริษัท สำหรับทำ SelectField
 * @returns 1. react state enterprises
 */
export default function useCategoryEnterprisePosition() {
    const [enterprisesPosition, setEnterprisesPosition] = useState([])
    const [fillterEnterprisesPositionByEnterpriseId, setFillterEnterprisesPositionByEnterpriseId] = useState('')

    useEffect(() => {
        const loadEnterprisePosition = async () => {
            const axiosRes = await getCategoryEnterprisePosition({ filterEnterpriseId: fillterEnterprisesPositionByEnterpriseId })
            const json = JSON.parse(axiosRes.data)
            const { data, status, message } = json
            if (status === API_RES_STATUS.success) {
                setEnterprisesPosition(data)
            }
        }
        loadEnterprisePosition()
    }, [fillterEnterprisesPositionByEnterpriseId])

    return { enterprisesPosition, setFillterEnterprisesPositionByEnterpriseId }
}